import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    MenuItem,
    Select,
    TextField,
    Grid,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import { useNavigate } from "react-router-dom";
import AppContext from 'context/AppContext';

const useStyles = makeStyles(() => ({
    root: {}
}));

const NewUserForm = ({ className, states, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({
        roles: 'ROLE_USER'
    });
    const [valuesPassword, setValuesPassword] = useState({});
    const [alertMessage, setAlertMessage] = useState('');
    const { appValues } = useContext(AppContext);
    let navigate = useNavigate();

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleClickShowPassword = (event) => {
        let id = event.target.closest('button').dataset.id;
        setValuesPassword({ ...valuesPassword, [id]: !valuesPassword[[id]] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (values.password && values.password !== values.passwordrepeat) {
            setAlertMessage(t('messages.passwords_not_match'));
            return;
        }
        let tmpValues = values;
        delete tmpValues.passwordrepeat;
        tmpValues.roles = [ tmpValues.roles ];
        secureFetch(getApiRoute('admin_user'), tmpValues, 'POST').then(json => {
            navigate('/app/admin/user/list');
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.username')}
                                        name="username"
                                        type="email"
                                        onChange={handleChange}
                                        value={values.username || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.variable_number')}
                                        name="variable_number"
                                        onChange={handleChange}
                                        value={values.variable_number || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel id="roles-select-label">{t('label.roles')}</InputLabel>
                                            <Select
                                                id="roles-select"
                                                labelId="roles-select-label"
                                                value={values.roles || ''}
                                                name="roles"
                                                onChange={handleChange}
                                            >
                                                {appValues.roles.map((role) => <MenuItem key={role} value={role}>{role}</MenuItem>)}
                                            </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl className={clsx(classes.margin, classes.textField)} fullWidth>
                                        <InputLabel htmlFor="showPassword">{t('label.password')}</InputLabel>
                                        <Input
                                            id="showPassword"
                                            type={valuesPassword.showPassword ? 'text' : 'password'}
                                            value={values.password || ''}
                                            name='password'
                                            onChange={handleChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        data-id='showPassword'
                                                    >
                                                        {valuesPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={clsx(classes.margin, classes.textField)} fullWidth>
                                        <InputLabel htmlFor="showPasswordRepeat">{t('label.password_repeat')}</InputLabel>
                                        <Input
                                            id="showPasswordRepeat"
                                            type={valuesPassword.showPasswordRepeat ? 'text' : 'password'}
                                            value={values.passwordrepeat || ''}
                                            name='passwordrepeat'
                                            onChange={handleChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        data-id='showPasswordRepeat'
                                                    >
                                                        {valuesPassword.showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </CardContent>
                {
                    alertMessage === '' ? null :
                        <Box px={2}>
                            <Alert variant="filled" severity="error">
                                {alertMessage}
                            </Alert>
                        </Box>
                }
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button color="primary" variant="contained" type="submit">
                        {t('button.create')}
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

NewUserForm.propTypes = {
    className: PropTypes.string
};

export default NewUserForm;
