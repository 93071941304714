import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute, { getLogoPath } from 'routes/apiRoutes';
import AppContext from 'context/AppContext';

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        height: 100,
        width: 170,
        paddingBottom: theme.spacing(1),
        '& img': {
            objectFit: 'contain'
        }
    }
}));

const UserLogo = ({ className, user, loadUser, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues, setAppValues } = useContext(AppContext);

    const onChange = (event) => {
        const files = Array.from(event.target.files);
        const formData = new FormData()

        files.forEach((file, i) => {
            formData.append('logo', file)
        })

        if (appValues.user.roles.includes('ROLE_USER') || !loadUser ) {
            secureFetch( getApiRoute( 'logo' ), formData, 'POST',  true, false ).then(() => {
                secureFetch(getApiRoute('user')).then(json => {
                    setAppValues({...appValues, user: json});
                });
            })
        }
        else if (appValues.user.roles.includes('ROLE_ADMIN')) {
            secureFetch(getApiRoute('admin_logo', [user.id]), formData, 'POST', true, false).then(() => {
                if (user.id === appValues.user.id) {
                    secureFetch(getApiRoute('user')).then(json => {
                        setAppValues({...appValues, user: json});
                    });
                }
                loadUser();
            })
        }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                >
                    <Avatar
                        className={classes.avatar}
                        variant='square'
                        alt='logo'
                        src={!user.logo ? '/images/unknown-logo.png' : getLogoPath(user.logo)}
                    />
                    <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h3"
                    >
                        {user.username}
                    </Typography>
                </Box>
            </CardContent>
            <Divider />
            <CardActions>
                <Button
                    color="primary"
                    variant="text"
                    component="label"
                    fullWidth
                >
                    { t('button.upload_logo') }
                    <input
                        type="file"
                        name="logo"
                        style={{ display: "none" }}
                        onChange={onChange}
                    />
                </Button>
            </CardActions>
        </Card>
    );
};

UserLogo.propTypes = {
    className: PropTypes.string
};

export default UserLogo;
