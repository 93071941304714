import React from 'react';
import { useTranslation } from "react-i18next";
import {
    makeStyles,
    Tooltip,
    Typography,
    Button,
    Box,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { Package as PackageIcon } from 'react-feather';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {formatDate, openNewTab} from "../../helpers";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {},
    rowButtons: {
        padding: '5px'
    }
}));

const LogTableActions = ({ className, data, ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box display="flex">
            <Tooltip title={t('tooltip.detail')} aria-label={t('tooltip.detail')}>
                <IconButton
                    aria-label={t('tooltip.detail')}
                    className={classes.rowButtons}
                    onClick={handleClickOpen}
                    size="small"
                >
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            {
                data.package_id &&
                <Tooltip title={t('tooltip.package')} aria-label={t('tooltip.package')}>
                    <IconButton
                        aria-label={t('tooltip.package')}
                        className={classes.rowButtons}
                        size="small"
                        onClick={() => openNewTab("/app/package/detail/" + data.package_id)}
                    >
                        <PackageIcon/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
            >
                <DialogContent>
                    <Typography variant="h3" component="h3">
                        {t('title.detail')}
                    </Typography>
                    <List className={classes.root}>
                        <ListItem dense disableGutters >
                            <ListItemText 
                                primary={t('label.created_at')} 
                                secondary={formatDate(data.created_at, true)}
                            />
                        </ListItem>
                        <ListItem dense disableGutters >
                            <ListItemText 
                                primary={t('label.type')} 
                                secondary={data.type}
                            />
                        </ListItem>
                        <ListItem dense disableGutters >
                            <ListItemText 
                                primary={t('label.status')} 
                                secondary={data.status}
                            />
                        </ListItem>
                        <ListItem dense disableGutters >
                            <ListItemText 
                                primary={t('label.message')} 
                                secondary={data.message}
                            />
                        </ListItem>
                        <ListItem dense disableGutters >
                            <ListItemText 
                                primary={t('label.package_reference_number')} 
                                secondary={data.package_reference_number}
                            />
                        </ListItem>
                        <ListItem dense disableGutters >
                            <ListItemText 
                                primary={t('label.others')} 
                                secondaryTypographyProps={ {component: 'div'} }
                                secondary={
                                    <pre>
                                        {JSON.stringify(data.others, null, 2)}
                                    </pre>
                                }
                            />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {t('button.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default LogTableActions;