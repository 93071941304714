import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import {
    Tooltip,
    IconButton,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import getApiRoute from 'routes/apiRoutes';
import ProgressButton from 'components/ProgressButton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteIconDialog({ data, loadPriceList, ...rest }) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDuplicate = () => {
        if (name === '') {
            return Promise.resolve(null);
        }
        let promise = secureFetch(getApiRoute('admin_pricelist', [data.id]), { name: name }, 'POST');
        promise.then((json) => {
            setOpen(false);
            loadPriceList();
        })
        return promise;
    };

    return (
        <div>
            <Tooltip title={t('tooltip.duplicate')} aria-label={t('tooltip.duplicate')} onClick={handleClickOpen}>
                <IconButton
                    aria-label={t('tooltip.duplicate')}
                    size="small"
                >
                    <FileCopyIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <form onSubmit={(e) => e.preventDefault()}>
                    <DialogTitle>{t('title.duplicate_pricelist') + ' - ' + data.name}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            onChange={(event) => setName(event.target.value) }
                            value={name}
                            label={t('label.title')}
                            required
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            {t('button.close')}
                        </Button>
                        <ProgressButton
                            type="submit"
                            name={t('button.create')}
                            onClick={handleDuplicate}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
