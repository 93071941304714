import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';

const PasswordForm = ({ className, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const [valuesPassword, setValuesPassword] = useState({});
    const [alertMessage, setAlertMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleClickShowPassword = (event) => {
        let id = event.target.closest('button').dataset.id;
        setValuesPassword({ ...valuesPassword, [id]: !valuesPassword[[id]] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if ( values.password && values.password !== values.passwordrepeat ) {
            setAlertMessage( t('messages.passwords_not_match') );
            return;
        }
        secureFetch( getApiRoute('edit_password'), { password: values.password }, 'PUT' ).then(json => {
            setAlertMessage('');
            setSuccessMessage(t('messages.password_was_changed'));
            setValues({});
        });
    };

    return (
        <form
            autoComplete="off"
            onSubmit={handleSubmit}
            {...rest}
        >
            <Card>
                <CardHeader
                    subheader={t('messages.change_pass_desc')}
                    title={t('title.change_pass')}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="showPassword">{ t('label.password') }</InputLabel>
                                <Input
                                    id="showPassword"
                                    type={valuesPassword.showPassword ? 'text' : 'password'}
                                    value={values.password || ''}
                                    name='password'
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                data-id='showPassword'
                                            >
                                                {valuesPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="showPasswordRepeat">{ t('label.password_repeat') }</InputLabel>
                                <Input
                                    id="showPasswordRepeat"
                                    type={valuesPassword.showPasswordRepeat ? 'text' : 'password'}
                                    value={values.passwordrepeat || ''}
                                    name='passwordrepeat'
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                data-id='showPasswordRepeat'
                                            >
                                                {valuesPassword.showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                { 
                    alertMessage === '' ? null : 
                    <Box px={2}>
                        <Alert variant="filled" severity="error">
                            {alertMessage}
                        </Alert>
                    </Box>
                }
                { 
                    successMessage === '' ? null : 
                    <Box px={2}>
                        <Alert variant="filled" severity="success">
                            {successMessage}
                        </Alert>
                    </Box>
                }
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button color="primary" variant="contained" type="submit">
                        {t('button.save')}
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

PasswordForm.propTypes = {
    className: PropTypes.string
};

export default PasswordForm;
