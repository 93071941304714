import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles
} from '@material-ui/core';
import {formatNumber, getService, getStandard, isUser} from 'helpers';
import { useTranslation } from "react-i18next";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ClientTable from 'components/ClientTable';
import { useNavigate } from "react-router-dom";
import { getShipperLogoPath } from "helpers";
import AppContext from 'context/AppContext';
import Shader from "../../components/Shader";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    avatar: {
        height: 56,
        width: 56,
        '& svg': {
            fontSize: '2.5em'
        }
    },
    differenceUp: {
        color: colors.green[900]
    },
    differenceDown: {
        color: colors.red[900]
    },
    differenceValue: {
        color: colors.green[900],
        marginRight: theme.spacing(1)
    },
    card: {
        height: '100%',
        '& *': {
            color: 'white'
        }
    },
    shipperBox: {
        backgroundColor: "white",
        padding: theme.spacing(1),
        borderBottom: "1px solid black",
        "& .shipperBoxItem": {
            transition: '0.3s',
            "&:hover": {
                transform: "scale(1.1)",
            }
        }
    },
    shipperVal: {
        fontWeight: "bolder",
    }
}));

const PackageStatusCard = ({ iconBg, to, icon, title, type, data, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [shipper, setShipper] = React.useState(null);
    let navigate = useNavigate();
    const { appValues } = useContext(AppContext);

    const handleDialogkOpenClose = () => {
        setOpenDialog(!openDialog);
    };

    const onShipperClick = (e, s) => {
        e.preventDefault();
        setShipper(s);
        handleDialogkOpenClose();
    }
    let route = type === 'service' ? '/app/package/service': '/app/package/';

    const settings = {
        rows: shipper ? data[shipper][type] : [], //filter by selected shipper
        loading: false,
        colOrder: {
            order: 'asc',
            orderBy: 'name'
        },
        headCells: [
            { id: 'name', numeric: false, label: t('label.name'), search: true },
            { id: 'count', numeric: true, label: t('label.count'), search: false },
        ],
        rowClick: (event, data) => {
            let sh = appValues.shippers.find(s => s.shortcut === shipper);
            let filter = {
                last_status: [ data ],
                shipper: sh.id
            };
            navigate(route, {state: filter});
        }
    };

    const onClickAll = () => {
        let sh = appValues.shippers.find(s => s.shortcut === shipper);
        let filter = {
            last_status: data[shipper][type],
            shipper: sh.id
        };
        navigate(route, { state: filter });
    };

    let sum = 0;
    for (const key in data) {
        let subData = data[key][type];
        if (subData !== undefined) {
            for (const d of subData) {
                sum += d.count;
            }
        }
    }

    return (
        <React.Fragment>
            <div>
                <Card
                    className={clsx(classes.root, "cardHover")}
                    style={{ backgroundColor: iconBg }}
                >
                    <CardContent className={classes.card} >
                        <Grid
                            container
                            justify="space-between"
                            spacing={3}
                            wrap="nowrap"
                        >
                            <Grid item>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="h6"
                                    className="uppercase"
                                    style={{color: 'rgba(255,255,255,0.8)'}}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="h3"
                                >
                                    {formatNumber(sum)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Avatar className={classes.avatar} style={{ backgroundColor: iconBg }}>
                                    {icon}
                                </Avatar>
                            </Grid>
                        </Grid>
                        <Box
                            mt={2}
                            display="flex"
                            alignItems="center"
                        >
                        </Box>
                    </CardContent>
                    <Box className={classes.shipperBox}>
                        <Grid container spacing={2}>
                            {
                                data && Object.keys(data).map((shipper, index) =>
                                {
                                    if (shipper !== 'DPD') {
                                        let count = 0;
                                        if (data[shipper][type] !== undefined) {
                                            for (const d of data[shipper][type]) {
                                                count += d.count;
                                            }
                                        }

                                        const shaded = isUser(appValues.user) && (type === 'service' ? !getService(appValues.user).find(uspl => uspl.shipper.shortcut === shipper) : !getStandard(appValues.user).find(uspl => uspl.shipper.shortcut === shipper))
                                        return (
                                            <Shader shaded={shaded} index={type + index} key={type + index + '_shader'}>
                                                <Grid key={type + index} item className="shipperBoxItem"
                                                      onClick={(e) => onShipperClick(e, shipper)}>
                                                    <Box textAlign="center">
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="h6"
                                                            className={classes.shipperVal}
                                                        >
                                                            {count}
                                                        </Typography>
                                                    </Box>
                                                    <img src={getShipperLogoPath(shipper)} alt={shipper} height={15}/>
                                                </Grid>
                                            </Shader>
                                        )
                                    } else if (type == 'service'){
                                        let count = 0;
                                        if (data[shipper][type] !== undefined) {
                                            for (const d of data[shipper][type]) {
                                                count += d.count;
                                            }
                                        }

                                        const shaded = isUser(appValues.user) && (type === 'service' ? !getService(appValues.user).find(uspl => uspl.shipper.shortcut === shipper) : !getStandard(appValues.user).find(uspl => uspl.shipper.shortcut === shipper))
                                        return (
                                            <Shader shaded={shaded} index={type + index} key={type + index + '_shader'}>
                                                <Grid key={type + index} item className="shipperBoxItem"
                                                      onClick={(e) => onShipperClick(e, shipper)}>
                                                    <Box textAlign="center">
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="h6"
                                                            className={classes.shipperVal}
                                                        >
                                                            {count}
                                                        </Typography>
                                                    </Box>
                                                    <img src={getShipperLogoPath(shipper)} alt={shipper} height={15}/>
                                                </Grid>
                                            </Shader>
                                        )
                                    }
                                })
                            }
                        </Grid>
                    </Box>
                </Card>
            </div>

            <Dialog
                maxWidth="xs"
                open={openDialog}
                fullWidth
                onClose={handleDialogkOpenClose}
            >
                <DialogTitle disableTypography={true}>
                    <Grid container justify="space-between" spacing={3} wrap="nowrap" >
                        <Grid item>
                            <Typography
                                variant="h4"
                            >
                                {title} - {shipper}
                            </Typography>
                        </Grid>
                        <Button color="primary" onClick={onClickAll}>
                            { t('button.all') }
                        </Button>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <ClientTable settings={settings} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogkOpenClose} color="secondary">
                        { t('button.close') }
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
};

PackageStatusCard.propTypes = {
    iconBg: PropTypes.string,
    to: PropTypes.string,
    icon: PropTypes.element,
    title: PropTypes.string,
};

export default PackageStatusCard;
