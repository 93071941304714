import React, { useContext } from 'react';
import {
    Box,
    IconButton,
    Button,
    Divider,
    makeStyles
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from "react-i18next";
import {
    Package as PackageIcon,
} from 'react-feather';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import NewPackageAddressShipper from './NewPackageAddressShipper';
import AddIcon from '@material-ui/icons/Add';
import AppContext from 'context/AppContext';
import Alert from '@material-ui/lab/Alert';
import ChargeCredit from 'views/DashboardView/ChargeCredit';
import UserUpdatableContext from "../../../context/UserUpdatableContext";
import {getDefaultAddress} from "../../../helpers";
import Grid from "@material-ui/core/Grid";
import PickupAddressSelector from "../../../components/PickupAddressSelector";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    root: {},
    button: {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0"
    }
}));

const NewPackageBtnDialog = ({ className, buttonVariant = 'icon', address, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [packageOpen, setPackageOpen] = React.useState(false);
    const { appValues } = useContext(AppContext);
    const { userUpdatable } = useContext(UserUpdatableContext);
    const [pickup, setPickup] = React.useState(getDefaultAddress(appValues.user));

    const handlePackageOpenClose = () => {
        setPackageOpen(!packageOpen);
    };

    return (
        <React.Fragment>
            {
                buttonVariant === 'icon' ?
                <Tooltip title={t('tooltip.new_package')} aria-label={t('tooltip.new_package')}>
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={handlePackageOpenClose}>
                        <PackageIcon />
                    </IconButton>
                </Tooltip>
                :
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handlePackageOpenClose}
                >
                    {t('button.create_package')}
                </Button>
            }
            <Dialog
                open={packageOpen}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth='xs'
                onClose={handlePackageOpenClose}
                aria-labelledby="new-product-slide-title"
                aria-describedby="new-product-slide-description"
            >
                <DialogTitle disableTypography={true}>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography variant="h2">
                                {t('tooltip.new_package')}
                            </Typography>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <PickupAddressSelector asSelect={true} onSelect={(addr) => {setPickup(addr)}} pickup={pickup} />*/}
                        {/*</Grid>*/}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {
                        userUpdatable.credit < 0.01 ?
                        <Box>
                                <Alert variant="filled" severity="error">
                                    {t('messages.low_credit')}
                                </Alert>
                                <Box pt={2} />
                                <div onClick={handlePackageOpenClose}>
                                    <ChargeCredit  />
                                </div>
                                <Box pt={2} />
                        </Box> :
                        appValues.user.user_shipper_price_lists.map( (item, i) => {
                            return (
                                item.shipper.shortcut.toUpperCase() !== 'PACKETA' && item.shipper.id !== 4 && //excluded 123kurier from dialog for all
                                <React.Fragment key={item.shipper.id}>
                                    <Divider />
                                    <Box p={1} />
                                    <NewPackageAddressShipper handlePackageClose={handlePackageOpenClose} shipper={item.shipper} address={address} pickup={pickup}/>
                                    <Box p={1} />
                                </React.Fragment>
                            )
                        })
                    }
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default NewPackageBtnDialog;
