import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import PartnerProfitStatisticsTable from 'views/Statistics/PartnerProfitStatistics/PartnerProfitStatisticsTable';
import {
    Typography,
    Box,
    Button,
    Grid,
    colors,
} from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/Timeline';
import { NavLink as RouterLink } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import UserStatusCard from './UserStatusCard';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';

const UserDashboard = ({ count, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [userCount, setUserCount] = useState(0);

    useEffect(() => {
        secureFetch(getApiRoute('partner_user', ['count'])).then(json => {
            setUserCount(json);
        })
    }, [secureFetch]);


    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <UserStatusCard
                    title={t('title.users')}
                    count={userCount}
                    icon={<PeopleIcon />}
                    iconBg={colors.green[600]}
                    location="/app/partner/user/list"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h1">
                    {t('title.partner_profit')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <PartnerProfitStatisticsTable />
                <Box my={2} textAlign="center" >
                    <Button
                        variant="contained"
                        color="primary"
                        to={'/app/partner/statistics/partner'}
                        component={RouterLink}
                        startIcon={<TimelineIcon />}
                    >
                        {t('button.profit')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default UserDashboard;
