import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Box,
    Tooltip,
    makeStyles
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AddIcon from '@material-ui/icons/Add';
import { NavLink as RouterLink } from 'react-router-dom';
import DeleteIconDialog from 'components/DeleteIconDialog';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { formatDate, getShipperIcon } from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const ServiceTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);

    const load = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('admin_service_list') ).then(json => {
            setRows(json);
            setLoading(false);
        });
    }, [secureFetch])

    useEffect(() => {
        load();
    }, [load]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'name'
        },
        headCells: [
            { id: 'shipper', numeric: false, label: t('label.shipper'), search: true, render: (data) => getShipperIcon(data) },
            { id: 'code', numeric: false, label: t('label.code'), search: true },
            { id: 'name', numeric: false, label: t('label.name'), search: true },
            { id: 'enabled', numeric: false, label: t('label.enabled'), search: false, render: (data) => formatDate(data.enabled_from) + ' - ' + formatDate(data.enabled_to) },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                return (
                    <Box display="flex">
                        <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')}>
                            <IconButton
                                aria-label={t('tooltip.edit')}
                                className={classes.rowButtons}
                                component={RouterLink}
                                size="small"
                                to={"/app/admin/service/" + data.id}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <DeleteIconDialog
                            text={
                                <React.Fragment>
                                    {t('messages.really_delete')}
                                    <br/>
                                    <pre>
                                        {JSON.stringify(data, null, 2)}
                                    </pre>
                                </React.Fragment>
                            }
                            route={getApiRoute('admin_service', [data.id])}
                            onDelete={load}
                        />
                        <Tooltip style={{fontSize: '1rem'}} title={data.description || t('messages.no_tooltip')}>
                            <IconButton size="small" aria-label="delete">
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        size="small"
                        startIcon={<AddIcon />}
                        component={RouterLink}
                        to={'/app/admin/service/new'}
                    >
                        {t('button.create_service')}
                    </Button>
                </React.Fragment>
            );
        }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default ServiceTable;