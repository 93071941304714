import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    Grid,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { useNavigate } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AppContext from 'context/AppContext';

const useStyles = makeStyles(() => ({
    root: {}
}));

const NewPriceListForm = ({ className, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({ type: 'sale' });
    let navigate = useNavigate();
    const { appValues } = useContext(AppContext);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch(getApiRoute('admin_pricelist'), values, 'POST').then(json => {
            navigate('/app/admin/pricelist/list');
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.name')}
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="type">{t('label.type')}</InputLabel>
                                        <Select
                                            labelId="type"
                                            value={ values.type || 'sale' }
                                            name="type"
                                            onChange={handleChange}
                                        >
                                            {appValues.pricelistTypes.map((type) => (
                                                <MenuItem
                                                    key={type}
                                                    value={type}
                                                >
                                                    {t('label.' + type)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {
                                    values.type !== 'fix'? null :
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('label.price')}
                                            name={"price"}
                                            type="number"
                                            onChange={handleChange}
                                            value={values["price"] || ''}
                                            inputProps={{ step: "0.001" }}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="shipper">{t('label.shipper')}</InputLabel>
                                        <Select
                                            labelId="shipper"
                                            value={ values.shipper || '' }
                                            name="shipper"
                                            onChange={handleChange}
                                            required
                                        >
                                            {appValues.shippers.map((option) => (
                                                <MenuItem 
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.shortcut}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.create')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
};

NewPriceListForm.propTypes = {
    className: PropTypes.string
};

export default NewPriceListForm;
