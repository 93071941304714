import React, {useState, useEffect, useContext, useCallback} from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    makeStyles, Typography
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DateTimePicker } from "@material-ui/pickers";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import CreditLogTable from 'views/CreditLogView/CreditLogTable';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import sk from "date-fns/locale/sk";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {formatNumber, getNeoshipServiceTypes} from "../../../helpers";
import MenuItem from "@material-ui/core/MenuItem";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(() => ({
    root: {}
}));

const Credit = ({ className, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [values, setValues] = useState({
        created_at: new Date(),
        type: '10'
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [user, setUser] = useState(rest.user);
    const [neoshipPriceList, setNeoshipPriceList] = useState({});
    const secureFetch = useContext(SecureFetchContext);

    useEffect(() => {
        setUser(rest.user);
        loadNeoshipPriceList();
    }, [rest.user]);

    const loadNeoshipPriceList = useCallback(() => {
        secureFetch( getApiRoute('admin_service_neoship') ).then(json => {
            setNeoshipPriceList(json);
        });
    }, [secureFetch])

    const handleNeoshipServicePriceChange = (event) => {
        if (event.target.value !== 0 && neoshipPriceList.find(servicePrice => servicePrice.type === event.target.value)) {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
                credit: formatNumber(-neoshipPriceList.find(servicePrice => servicePrice.type === event.target.value).price * 1.2, 2),
                message: getNeoshipServiceTypes().find((val) => (val.value === event.target.value)).name
            });
        } else {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
                credit: "",
                message: ""
            });
        }
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let tmpVal = Object.assign({}, values);
        tmpVal.created_at = {};
        tmpVal.created_at.date = values.created_at.toISOString().split('T')[0];
        tmpVal.created_at.time = {};
        tmpVal.created_at.time.hour = values.created_at.getHours();
        tmpVal.created_at.time.minute = values.created_at.getMinutes();
        tmpVal.created_at.time.second = values.created_at.getSeconds();
        secureFetch(getApiRoute('admin_creditlog', [user.id]), tmpVal, 'POST' ).then(json => {
            setSuccessMessage(t('messages.data_saved'));
            setUser({ ...user}); // trigger reload table
        });
    }

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Grid container spacing={3}>
                <Grid item lg={5} xl={4}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <InputLabel shrink>
                                        {t('label.type')}
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label={t('label.type')}
                                        value={values.type || ''}
                                        name="type"
                                        onChange={handleNeoshipServicePriceChange}
                                        required
                                    >
                                        <MenuItem key='10' value='10'>
                                            Kredit
                                        </MenuItem>
                                        {getNeoshipServiceTypes().map((val) => (
                                            val.value !== 21 &&
                                            <MenuItem
                                                key={val.value}
                                                value={val.value}
                                            >
                                                {val.name}
                                            </MenuItem>
                                        ))
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.credit')}
                                        name="credit"
                                        onChange={handleChange}
                                        value={values.credit || ''}
                                        type="number"
                                        InputProps={{
                                            step: "0.001",
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.message')}
                                        name="message"
                                        onChange={handleChange}
                                        value={values.message || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={sk}>
                                        <DateTimePicker
                                            autoOk
                                            ampm={false}
                                            disableFuture
                                            onChange={(val) => setValues({ ...values, created_at: val })}
                                            value={values.created_at || ''}
                                            label={t('label.operation_date')}
                                            inputFormat="dd.MM.yyyy HH:mm"
                                            renderInput={(params) => <TextField
                                                {...params}
                                                fullWidth
                                                required
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.manual_update ? true : false}
                                                onChange={(e) => setValues({ ...values, manual_update: e.target.checked })}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label={t('label.manual_update')}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        {
                            successMessage === '' ? null :
                                <Box px={2}>
                                    <Alert variant="filled" severity="success">
                                        {successMessage}
                                    </Alert>
                                </Box>
                        }
                        <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.recalculate')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
                <Grid item lg={5} xl={4}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <InfoOutlinedIcon />
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Kredit</strong> - prijaté peniaze od zákazníka
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        (napr. aj vyplatenie dobropisu pri ukončení zákazníka, vrátenie platby na požiadanie zákazníka)
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Debet</strong> - operácie, ktoré sú zákazníkovi účtované za služby Neoshipu
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        (napr. aj vrátenie za nesprávne účtovanie prepravného, služby alebo Neoshipového poplatku )
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={12}>
                    <Card>
                        <CreditLogTable user={user} />
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
};

export default Credit;
