import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    Typography,
    makeStyles
} from '@material-ui/core';
import {
    BarChart as BarChartIcon,
    User as UserIcon,
    RefreshCcw as RefreshCcwIcon,
    CreditCard as CreditCardIcon,
    List as ListIcon,
    Package as PackageIcon
} from 'react-feather';
import NavItem from './NavItem';
import { useTranslation } from "react-i18next";
import EuroIcon from '@material-ui/icons/Euro';
import PlaceIcon from '@material-ui/icons/Place';
import Collapse from '@material-ui/core/Collapse';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AppContext from 'context/AppContext';
import PeopleIcon from '@material-ui/icons/People';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import MessageIcon from '@material-ui/icons/Message';
import MoneyIcon from '@material-ui/icons/Money';
import TimelineIcon from '@material-ui/icons/Timeline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { getLogoPath } from 'routes/apiRoutes';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import DescriptionIcon from '@material-ui/icons/Description';
import PhoneIcon from '@material-ui/icons/Phone';
import TotalCreditSmall from "../../../views/DashboardView/TotalCreditSmall";
import ContactsIcon from '@material-ui/icons/Contacts';
import Receipt from '@material-ui/icons/Receipt';
import UserUpdatableContext from "../../../context/UserUpdatableContext";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > div': {
            boxShadow: '0 0 0.4rem 0 rgba(0,0,0,0.3)',
            zIndex: 20,
        }
    },
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        outline: 'none',
        width: 110,
        height: 64,
        paddingBottom: theme.spacing(1),
        '& img': {
            objectFit: 'contain'
        }
    },
    nested: {
        paddingLeft: theme.spacing(2),
        '& .MuiButtonBase-root': {
            paddingTop: theme.spacing(1) / 2,
            paddingBottom: theme.spacing(1) / 2
        }
    },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    const { t } = useTranslation();
    const { appValues } = useContext(AppContext);
    const { userUpdatable } = useContext(UserUpdatableContext);

    let items = [
        {
            href: '/app/dashboard',
            icon: BarChartIcon,
            title: 'Dashboard'
        },
    ];

    if ( appValues.user && (appValues.user.roles.includes('ROLE_USER') || appValues.user.roles.includes('ROLE_ADMIN')) ) {
        items = items.concat([
            {
                href: '/app/package',
                icon: PackageIcon,
                title: t('navbar.packages'),
                enableParent: true,
                children: appValues.user.roles.includes('ROLE_ADMIN') ? null : [
                    {
                        href: '/app/package/printeddocuments',
                        icon: DescriptionIcon,
                        title: t('navbar.printed_documents')
                    },
                ]
            },
            {
                href: '/app/cod',
                icon: CreditCardIcon,
                title: t('navbar.cod'),
                children: [
                    {
                        href: '/app/cod/unpaid',
                        icon: LocalShippingIcon,
                        title: t('navbar.package_unpaid')
                    },
                    {
                        href: '/app/cod/paid',
                        icon: AccountBalanceWalletIcon,
                        title: t('navbar.package_paid')
                    },
                ]
            },
            {
                href: '/app/calculation',
                icon: RefreshCcwIcon,
                title: t('navbar.calculation')
            },
            {
                href: '/app/parcelshop',
                icon: PlaceIcon,
                title: t('navbar.parcelshop')
            },
            {
                href: '/app/account',
                icon: UserIcon,
                title: t('navbar.account')
            },
            {
                href: '/app/log',
                icon: ListIcon,
                title: t('navbar.log')
            },
            {
                href: '/app/creditlog',
                icon: ListIcon,
                title: t('navbar.creditlog')
            },
            {
                href: '/app/messages',
                icon: MessageIcon,
                title: t('navbar.messages')
            },
        ])
    }

    if ( appValues.user && appValues.user.roles.includes('ROLE_ADMIN') ) {
        items = items.concat([
            {
                href: '/app/admin/user/list',
                icon: PeopleIcon,
                title: t('navbar.users')
            },
            {
                href: '/app/admin/shipper/list',
                icon: LocalShippingIcon,
                title: t('navbar.shippers')
            },
            {
                href: '/app/admin/pricelist/list',
                icon: EuroIcon,
                title: t('navbar.pricelists'),
                children: [
                    {
                        href: '/app/admin/pricelist/list',
                        icon: LocalShippingIcon,
                        title: t('navbar.pricelist_transit')
                    },
                    {
                        href: '/app/admin/service/neoship',
                        icon: Receipt,
                        title: t('navbar.services_neoship')
                    },
                ]
            },
            {
                href: '/app/admin/codlimit',
                icon: MoneyOffIcon,
                title: t('navbar.cod_limits')
            },
            {
                href: '/app/admin/service/list',
                icon: RoomServiceIcon,
                title: t('navbar.services')
            },
            {
                href: '/app/admin/partnercommission/list',
                icon: MoneyIcon,
                title: t('navbar.partnercommission')
            },
            {
                href: '/app/admin/statistics',
                icon: TimelineIcon,
                title: t('navbar.statistics'),
                children: [
                    {
                        href: '/app/admin/statistics/profit',
                        icon: MonetizationOnIcon,
                        title: t('navbar.profit')
                    },
                    {
                        href: '/app/admin/statistics/packagecount',
                        icon: TimelineIcon,
                        title: t('navbar.package_count')
                    },
                    {
                        href: '/app/admin/statistics/notpriced',
                        icon: MoneyOffIcon,
                        title: t('navbar.not_priced')
                    },
                    {
                        href: '/app/admin/statistics/partner',
                        icon: MonetizationOnIcon,
                        title: t('navbar.partner_profit')
                    },
                    {
                        href: '/app/admin/statistics/returnedpackage',
                        icon: KeyboardReturnIcon,
                        title: t('navbar.package_returned')
                    },
                ]
            },
        ])
    }
    else if (appValues.user && appValues.user.roles.includes('ROLE_USER') ) {
        items = items.concat([
            {
                href: '/app/address',
                icon: ContactsIcon,
                title: t('navbar.addresses')
            },
            {
                href: '/app/pricelist',
                icon: EuroIcon,
                title: t('navbar.pricelists'),
                children: [
                    {
                        href: '/app/pricelist',
                        icon: LocalShippingIcon,
                        title: t('navbar.pricelist_transit')
                    },
                    {
                        href: '/app/service/neoship/list',
                        icon: Receipt,
                        title: t('navbar.services_neoship')
                    },
                ]
            },
            {
                href: '/app/admin/statistics',
                icon: TimelineIcon,
                title: t('navbar.statistics'),
                children: [
                    {
                        href: '/app/statistics/packagecount',
                        icon: TimelineIcon,
                        title: t('navbar.package_count')
                    },
                    {
                        href: '/app/statistics/returnedpackage',
                        icon: KeyboardReturnIcon,
                        title: t('navbar.package_returned')
                    },
                ]
            },
        ])
    }
    else if (appValues.user && appValues.user.roles.includes('ROLE_PARTNER') ) {
        items = items.concat([
            {
                href: '/app/partner/user/list',
                icon: PeopleIcon,
                title: t('navbar.users')
            },
            {
                href: '/app/partner/statistics',
                icon: TimelineIcon,
                title: t('navbar.statistics'),
                children: [
                    {
                        href: '/app/partner/statistics/partner',
                        icon: MonetizationOnIcon,
                        title: t('navbar.partner_profit')
                    },
                    {
                        href: '/app/partner/statistics/packagecount',
                        icon: TimelineIcon,
                        title: t('navbar.package_count')
                    },
                ]
            },
        ])
    }

    items = items.concat([
        {
            href: '/app/documents',
            icon: DescriptionIcon,
            title: t('navbar.documents')
        },
        {
            href: '/app/contacts',
            icon: PhoneIcon,
            title: t('navbar.contacts')
        },
    ])

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);


    const [open, setOpen] = React.useState({});

    const handleOpen = (e, title) => {
        e.preventDefault();
        setOpen({ ...open, [title]: !open[title] });
    };

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <Avatar
                    className={classes.avatar}
                    component={RouterLink}
                    variant='square'
                    alt='logo'
                    src={!appValues.user.logo ? '/images/unknown-logo.png' : getLogoPath(appValues.user.logo)}
                    to="/app/account"
                />
                <Typography
                    className={classes.name}
                    color="textPrimary"
                    variant="h5"
                >
                    {appValues.user.username}
                </Typography>
                <Box
                    display={(appValues.user && appValues.user.roles.includes('ROLE_ADMIN'))? 'none' : 'block'}
                    style={{width: "100%"}}
                >
                    <TotalCreditSmall count={userUpdatable.credit} />
                </Box>
                {/* <Typography
                    color="textSecondary"
                    variant="body2"
                >
                    {appValues.user.invoice_address.email}
                </Typography> */}
            </Box>
            <Divider />
            <Box p={2} display="flex" justifyContent="space-between" flexDirection="column" height={"100%"}>
                <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                >
                    {items.map((item) => (
                        <React.Fragment key={item.title}>
                            <NavItem
                                href={item.href}
                                title={item.title}
                                icon={item.icon}
                                onClick={item.children && !item.enableParent ? (e) =>  handleOpen(e, item.title) : null }
                                onArrowClick={item.children ? (e) =>  handleOpen(e, item.title) : null }
                                children={ item.children }
                                enableParent={item.enableParent}
                                open={ open[item.title] }
                            />
                            {
                                item.children &&
                                item.children.map( (children) =>
                                    <Collapse in={ open[item.title] } timeout="auto" key={children.title} unmountOnExit>
                                        <NavItem
                                            href={children.href}
                                            title={children.title}
                                            icon={children.icon}
                                            className={classes.nested}
                                            open={open[item.title]}
                                        />
                                    </Collapse> 
                                )

                            }
                        </React.Fragment>
                    ))}
                </List>
                <div>
                    <iframe 
                        style={{maxWidth: '100%'}} 
                        width="241" 
                        height="140" 
                        src="https://www.youtube-nocookie.com/embed/72hqNilQpzs" 
                        title="Neoship - nová aplikácia" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                    />
                </div>         
            </Box>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                    className={classes.root}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavBar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false
};

export default NavBar;
