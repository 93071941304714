import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles, Tooltip
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import AppContext from 'context/AppContext';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import {getErrorMessage, isInputError} from "../../../helpers";

const useStyles = makeStyles(() => ({
    root: {}
}));

const ExpeditionaryAddressForm = ({ className, user, loadUser, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [values, setValues] = useState({});
    const { appValues } = useContext(AppContext);
    const [successMessage, setSuccessMessage] = useState('');
    const secureFetch = useContext(SecureFetchContext);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        user.expeditionary_address = user.expeditionary_address ? user.expeditionary_address : {};
        setValues({
            name: user.expeditionary_address.name,
            email: user.expeditionary_address.email,
            phone: user.expeditionary_address.phone,
            state: user.expeditionary_address.state ? user.expeditionary_address.state.id : appValues.states[ appValues.states.findIndex(i => i.code === 'SK') ].id || null,
        })
    }, [user, appValues.states]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch( getApiRoute('admin_user', [user.id]), { 'expeditionary_address': values}, 'PUT', false ).then(json => {
            setSuccessMessage(t('messages.data_saved'));
            loadUser();
        }).catch( (json) => {
            setErrors(json.expeditionary_address);
        } );
    }

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Card>
                <CardHeader
                    subheader={t('messages.expeditionary_contact_desc')}
                    title={t('title.expeditionary_contact')}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label={t('label.name')}
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name || ''}
                                        error={ isInputError(errors, "name") }
                                        helperText={ getErrorMessage(errors, "name") }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.contact_email')}
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email || ''}
                                        required
                                        error={ isInputError(errors, "email") }
                                        helperText={ getErrorMessage(errors, "email") }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.phone')}
                                        name="phone"
                                        onChange={handleChange}
                                        value={values.phone || ''}
                                        required
                                        error={ isInputError(errors, "phone") }
                                        helperText={ getErrorMessage(errors, "phone") }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                { 
                    successMessage === '' ? null : 
                    <Box px={2}>
                        <Alert variant="filled" severity="success">
                            {successMessage}
                        </Alert>
                    </Box>

                }
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button color="primary" variant="contained" type="submit">
                        { t('button.save') }
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

ExpeditionaryAddressForm.propTypes = {
    className: PropTypes.string,
    states: PropTypes.array
};

export default ExpeditionaryAddressForm;
