import React, { useContext } from 'react';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    colors,
    makeStyles
} from '@material-ui/core';
import AppContext from 'context/AppContext';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    avatar: {
        height: 56,
        width: 56,
        '& svg': {
            fontSize: '2.5em'
        }
    },
    green: {
        color: colors.green[900]
    },
    card: {
        height: '100%',
        '& *': {
            color: 'white'
        }
    }
}));

const PackageCodCard = ({ iconBg, to, icon, title, count, data, dataKey, location, iconStyles = {}, ...rest }) => {
    const classes = useStyles();
    //const { t } = useTranslation();
    const { appValues } = useContext(AppContext);
    let navigate = useNavigate();

    if (Object.keys(data).length === 0) {
        return (null);
    }

    return (
        <React.Fragment>
            <Card
                className={clsx(classes.root, "cardHover")}
                onClick={() => navigate(...location)}
            >
                <CardContent className={classes.card} style={{ backgroundColor: iconBg }}>
                    <Grid
                        container
                        justify="space-between"
                        spacing={3}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="h6"
                                className="uppercase"
                                style={{ color: 'rgba(255,255,255,0.8)' }}
                            >
                                {title}
                            </Typography>
                            {
                                data.sum[dataKey].length === 0 &&
                                <Typography
                                    color="textPrimary"
                                    variant="h3"
                                >
                                    0
                                </Typography>
                            }
                            {
                                data.sum[dataKey].map((val, index) => {
                                    if (val.cod_currency_code !== 'EUR') {
                                        return null;
                                    }
                                    return (
                                        <Typography
                                            key={index}
                                            color="textPrimary"
                                            variant="h3"
                                        >
                                            { val.total} { appValues.currencies.length === 0 ? null : appValues.currencies.find((cur) => cur.code === val.cod_currency_code).symbol}
                                        </Typography>
                                    )
                                })
                            }
                            {
                                data.sum[dataKey].map((val, index) => {
                                    if (val.cod_currency_code === 'EUR') {
                                        return null;
                                    }
                                    return (
                                        <Typography
                                            key={index}
                                            color="textPrimary"
                                            variant="body1"
                                        >
                                            {val.total} { appValues.currencies.length === 0 ? null : appValues.currencies.find((cur) => cur.code === val.cod_currency_code).symbol}
                                        </Typography>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item>
                            <Avatar className={classes.avatar} style={{ backgroundColor: iconBg, ...iconStyles }}>
                                {icon}
                            </Avatar>
                        </Grid>
                    </Grid>
                    <Box
                        mt={2}
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                    >
                        <Typography
                            variant="subtitle2"
                            className={classes.green}
                        >
                            { data.local.count[dataKey] + data.international.count[dataKey] + data.parcelshop_local.count[dataKey] + data.parcelshop_international.count[dataKey] }&nbsp;
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                            celkovo,
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            className={classes.green}
                        >
                            &nbsp;{data.local.count[dataKey] + data.parcelshop_local.count[dataKey]}&nbsp;
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                            lokálnych, 
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            className={classes.green}
                        >
                            &nbsp;{data.international.count[dataKey] + data.parcelshop_international.count[dataKey]}&nbsp;
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                            medzinárodných
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </React.Fragment >
    );
};

export default PackageCodCard;
