import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent, Container, Grid,
    makeStyles, Typography
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import {getNeoshipServiceTypes} from "../../helpers";
import Page from "../../components/Page";
import AppContext from "../../context/AppContext";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const ServiceNeoshipTableList = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [discount, setDiscount] = useState('');
    const { appValues } = useContext(AppContext);
    const [user, setUser] = useState(appValues.user);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);

    const load = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('user_service_neoship') ).then(json => {
            setRows(json);
        });
        secureFetch( getApiRoute('user_discount', [user.id]) ).then(json => {
            json.forEach((data) => {
                if (new Date(new Date(data.start_date).setDate(1)).setHours(0,0,0,0) <= new Date(new Date().setDate(1)).setHours(0,0,0,0) &&
                    new Date(new Date().setDate(1)).setHours(0,0,0,0) <= new Date(new Date(data.end_date).setDate(1)).setHours(0,0,0,0)) {
                    setDiscount(data);
                }
            })
            setLoading(false);
        });

    }, [secureFetch])

    useEffect(() => {
        load();
    }, [load]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'type'
        },
        headCells: [
            { id: 'type', numeric: false, label: t('label.type'), search: false, render: (data) => getNeoshipServiceTypes().find((val) => val.value === parseInt(data.type)).name},
            { id: 'price', numeric: false, label: t('label.price'), search: false, render: (data) => data.price  + ' €' }
        ]
    };

    return (
        <Page
            className={classes.root}
            title={t('navbar.services_neoship')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.services_neoship')}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card
                            className={clsx(classes.root, className)}
                            {...rest}
                        >
                            {
                                discount !== '' &&
                                <Alert severity="success">
                                    <AlertTitle>Aktívna zľava</AlertTitle>
                                    V aktuálnom období od <strong>{new Date(discount.start_date).toLocaleDateString("fr-FR").slice(3)}</strong> do <strong>{new Date(discount.end_date).toLocaleDateString("fr-FR").slice(3)}</strong> máte aktivovanú zľavu na Servisné poplatky vo výške <strong>{discount.discount} %</strong>
                                </Alert>
                            }
                            <CardContent>
                                <ClientTable settings={settings} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

export default ServiceNeoshipTableList;