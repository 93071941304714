import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import {
    Typography,
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PackageListActionsDialog = React.forwardRef(({ buttonName, title, message, onDelete, disabled, ...rest }, ref) => {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        onDelete();
        setOpen(false);
    };

    return (
        <div ref={ref}>
            <MenuItem
                title={t('tooltip.delete')} aria-label={t('tooltip.delete')} onClick={handleClickOpen} disabled={disabled}
            >
                { buttonName }
            </MenuItem>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" component="div" dangerouslySetInnerHTML={{
                        __html: message
                    }}>

                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {t('button.close')}
                    </Button>
                    <Button onClick={handleDelete} color="primary">
                        { buttonName }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
})

export default PackageListActionsDialog;
