import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    IconButton,
    Tooltip,
    Grid,
    makeStyles
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Box from '@material-ui/core/Box';
import { useTranslation } from "react-i18next";
import { DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableFilter from './TableFilter';
import ExportButtons from './ExportButtons';
import sk from "date-fns/locale/sk";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    searchCell: {
        paddingTop: '0px',
    },
    searchCellButton: {
        padding: '0 !important',
        borderBottom: '0',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableFilter: {
        marginBottom: theme.spacing(2),
    },

}));

const ClientTableHead = (props) => {
    const { order, orderBy, onRequestSort, headCells, filterChange, timeoutTime, topRightButtons, addFilter, pagination, exportData, topLeftButtons, hasSubRows, filter, setFilter, superHeader } = props;
    const { state } = useLocation();
    const classes = useStyles();
    const { t } = useTranslation();
    const [typingTimeout, setTypingTimeout] = React.useState(false);
    const [typingTimeoutTime, setTypingTimeoutTime] = React.useState(100);
    const [dateRangeValue, setDateRangeValue] = React.useState( {created_at: [filter.created_at_from, filter.created_at_to] });

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const setFilterValue = (name, value ) => {
        let tmpFilter = filter;
        if ( value === '' ) {
            delete tmpFilter[name];
        } else {
            tmpFilter[name] = value;
        }
        setFilter(tmpFilter);
        clearTimeout(typingTimeout);
        setTypingTimeout(setTimeout(() => {
            filterChange(tmpFilter);
        }, typingTimeoutTime));
    }

    const filterChangeLocal = (event) => {
        setFilterValue(event.target.name, event.target.value);
    };

    const filterChangeLocalDateRange = (data, id) => {
        if (data && data[0] && data[1]) {
            data[0].setHours(0,0,0,0);
            data[1].setHours(23,59,59,999);
            let tmpFilter = { ...filter, [id + '_from']: data[0], [id + '_to']: data[1] };
            setFilter(tmpFilter);
            clearTimeout(typingTimeout);
            if ( tmpFilter[id + '_to'] ) {
                filterChange(tmpFilter);
            }
        }
    };

    const filterSelectChangeLocal = (event, id, value) => {
        setFilterValue(id, value);
    };

    const deleteFilter = () => {
        // setDateRangeValue({created_at:[ new Date(new Date().setDate(new Date().getDate() - 30)), new Date()]});
        setDateRangeValue({});
        clearTimeout(typingTimeout);
        filterChange({});
    }

    useEffect(() => {
        if (timeoutTime) {
            setTypingTimeoutTime(timeoutTime);
        }
    }, [timeoutTime]);

    useEffect(() => {
        if (state && state.cod_payment !== undefined) {
            setFilterValue('cod_payment', state.cod_payment);
        }
    }, [state]);

    const renderSearchField = (headCell) => {
        switch (headCell.searchType) {
            case 'select':
                return(
                    <Autocomplete
                        options={ headCell.selectChoices }
                        value={ filter[headCell.id] !== undefined ? headCell.selectChoices.find(choice => choice.id === filter[headCell.id]) : null }
                        onChange={ (event, choice) => choice ? filterSelectChangeLocal(event, headCell.id, choice.id) : filterSelectChangeLocal(event, headCell.id, '') }
                        getOptionLabel={(option) => option.shortName ? option.shortName : option.name}
                        renderInput={(params) => <TextField 
                            {...params} 
                            label={headCell.filterName ? headCell.filterName : headCell.label}
                        />}
                    />
                )
            case 'dateRange':
                if (!dateRangeValue[headCell.id]) {
                    setDateRangeValue({
                        ...dateRangeValue,
                        [headCell.id]: [null, null]
                    });
                }
                return (
                    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={sk}>
                        <DateRangePicker
                            value={dateRangeValue[headCell.id]}
                            onChange={(newValues) => {
                                setDateRangeValue({
                                    ...dateRangeValue,
                                    [headCell.id]: newValues
                                })
                                filterChangeLocalDateRange(newValues, headCell.id)
                            }}
                            disableFuture={true}
                            renderInput={(startProps, endProps) => {
                                startProps.inputProps.value = dateRangeValue[headCell.id][0] ? dateRangeValue[headCell.id][0].toLocaleDateString() + ' - \n' : '';
                                startProps.inputProps.value += dateRangeValue[headCell.id][1] ? dateRangeValue[headCell.id][1].toLocaleDateString() : '';
                                return (
                                    <React.Fragment>
                                        <TextField
                                            {...startProps}
                                            label={headCell.label}
                                            name={headCell.id}
                                            variant={'standard'}
                                            type="text"
                                            helperText=""
                                            multiline
                                        />
                                    </React.Fragment>
                                )
                            }}
                        />
                    </LocalizationProvider>
                );
            default:
                return (
                    <TextField
                        label={headCell.label}
                        name={headCell.id}
                        value={filter[headCell.id] || ''}
                        onChange={filterChangeLocal}
                    />
                );
        }
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    colSpan="100"
                    className={classes.searchCellButton}
                    key="table-filter-cell"
                >
                    <Grid container spacing={3} justify="space-between">
                        <Grid item xs={'auto'} style={{ textAlign: 'right' }}>
                            {
                                topLeftButtons && topLeftButtons()
                            }
                        </Grid>
                        <Grid item xs={'auto'} style={{ textAlign: 'right' }}>
                            {
                                exportData && <ExportButtons topRightButtons={topRightButtons} exportData={exportData} filter={filter} />
                            }
                            {
                                topRightButtons && !exportData && topRightButtons()
                            }
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justify="flex-end" alignItems='flex-end'>
                        {
                            !addFilter ? null :
                            <Grid item xs={true} className={classes.tableFilter}>
                                <TableFilter enabled={addFilter} setFilterValue={setFilterValue} filter={filter} />
                            </Grid>
                        }
                        <Grid item xs={'auto'} style={{textAlign: 'right'}}>
                            {pagination}
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            {superHeader &&
                <TableRow className={"filterRow"}>
                    {superHeader.map((h, index) => <TableCell align={h.align} colSpan={h.colSpan} key={'super-header-cell'+index}>{h.label}</TableCell>)}
                </TableRow>
            }
            <TableRow className={"filterRow"}>
                {hasSubRows && <TableCell key="hasSubRows-table-filters"/> }
                {headCells.map((headCell, index) => (
                    headCell.hide ? null :
                        <TableCell
                            key={index}
                            className={classes.searchCell}
                        >
                            {
                                index !== headCells.length - 1 ? (headCell.search ? renderSearchField(headCell) : null) :
                                    <Box display="flex" justifyContent="end">
                                        {headCell.search && renderSearchField(headCell) }
                                        <Tooltip title={t('button.delete_filter')}>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={deleteFilter}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                            }
                        </TableCell>
                ))}
            </TableRow>
            <TableRow>
                {hasSubRows && <TableCell key='hasSubRows-table-header'/>}
                {headCells.map((headCell, index) => (
                    headCell.hide ? null :
                        <TableCell
                            key={index}
                            align={headCell.numeric ? 'right' : 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {
                                headCell.disableOrder ? (headCell.renderLabel ? headCell.renderLabel() : headCell.label) :
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                            }
                        </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

ClientTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    headCells: PropTypes.array
};

export default ClientTableHead;