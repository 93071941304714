import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Box,
    Tooltip,
    makeStyles, CardHeader
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AddIcon from '@material-ui/icons/Add';
import { NavLink as RouterLink } from 'react-router-dom';
import DeleteIconDialog from 'components/DeleteIconDialog';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import NewIbanForm from "./NewIbanForm";
import EditIbanForm from "./EditIbanForm";
import AppContext from "../../context/AppContext";

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const IbansTable = ({ className, user, loadUser, ...rest }) => {
    const classes = useStyles();
    const { appValues } = useContext(AppContext);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openEditIbanDialog, setOpenEditIbanDialog] = React.useState(false);
    const [editIbanData, setEditIbanData] = useState({});

    const load = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('admin_iban', [user.id]) ).then(json => {
            setRows(json);
            setLoading(false);
        });
    }, [secureFetch])

    const handleDialogOpenClose = () => {
        setOpenCreateDialog(!openCreateDialog);
    };

    const handleEditDialogOpenClose = (data) => {
        if(openEditIbanDialog) {
            setOpenEditIbanDialog(false);
        } else {
            setEditIbanData(data);
            setOpenEditIbanDialog(true);
        }
    };

    useEffect(() => {
        load();
    }, [load]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'id'
        },
        headCells: [
            { id: 'id', numeric: true, label: t('label.id'), search: false, hide: true },
            { id: 'state_to', numeric: false, label: t('label.state_to'), search: true },
            { id: 'shipper', numeric: false, label: t('label.shipper'),render: (data) => appValues.shippers.find(s => s.id === data.shipper).shortcut},
            { id: 'iban', numeric: false, label: t('label.iban'), search: false, render: (data) => data.iban },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                    return (
                        <Box display="flex">
                            <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')} onClick={() => {handleEditDialogOpenClose(data)}}>
                                <IconButton
                                    size="small"
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <DeleteIconDialog
                                text={
                                    <React.Fragment>
                                        {t('messages.really_delete')}
                                        <br/>
                                        <pre>
                                        {JSON.stringify(data, null, 2)}
                                    </pre>
                                    </React.Fragment>
                                }
                                route={getApiRoute('admin_iban', [data.id])}
                                onDelete={load}
                            />
                        </Box>
                    );
                } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleDialogOpenClose}
                    >
                        {t('button.create_iban')}
                    </Button>
                </React.Fragment>
            );
        }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            {/*<CardHeader*/}
            {/*    title={t('title.iban')}*/}
            {/*    subheader={t('messages.iban')}*/}
            {/*/>*/}
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
            <Dialog
                fullWidth
                open={openCreateDialog}
                onClose={handleDialogOpenClose}
                maxWidth="sm"
            >
                <DialogContent>
                    <NewIbanForm handleDialogOpenClose={handleDialogOpenClose} load={load} user={user} />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openEditIbanDialog}
                onClose={handleEditDialogOpenClose}
                maxWidth="md"
            >
                <DialogContent>
                    <EditIbanForm iban={editIbanData} handleEditDialogOpenClose={handleEditDialogOpenClose} load={load} />
                </DialogContent>
            </Dialog>
        </Card>
    );
}

export default IbansTable;