import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import {Button, Box, makeStyles} from '@material-ui/core';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PrintIcon from '@material-ui/icons/Print';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useNavigate } from "react-router-dom";
import AppContext from 'context/AppContext';
import {isUser} from "../../../helpers";
import DailyClosingDialog from "./DailyClosingDialog";

const useStyles = makeStyles(() => ({
    button: {
        marginRight: "10px"
    }
}));

const PackageTableActions = ({ className, selected, setSelected, loadPackages, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [successMessage, setSuccessMessage] = React.useState(null);
    const navigate = useNavigate();
    const [errors, setErrors] = React.useState(null);
    const { appValues } = useContext(AppContext);

    const removeAction = () => {
        let ids = Object.keys(selected)
        if (ids.length === 0) {
            return;
        }
        let values = {
            action: 'delete',
            packages: ids,
            source: 'WEB'.toUpperCase() // MUST BE UPPERCASE
        }
        secureFetch(getApiRoute('package_bulk'), values, 'POST').then(json => {
            setSuccessMessage(t('messages.packages_removed'));
            loadPackages();
            setSelected({});
        });
    };

    const printSticker = () => {
        navigate('/app/package/printsticker', { state: selected });
    }

    return (
        <Box>
            {
                isUser(appValues.user) &&
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={printSticker}
                    className={classes.button}
                >
                    {t('button.print_sticker')} ({Object.keys(selected).length})
                </Button>
            }
            <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<DeleteForeverIcon />}
                onClick={removeAction}
                className={classes.button}
            >
                {t('button.delete_packages')} ({Object.keys(selected).length})
            </Button>
            {
                isUser(appValues.user) &&
                <DailyClosingDialog
                    setErrors={setErrors}
                    loadPackages={loadPackages}
                />
            }
            <Box textAlign="left" display="inline">
                <Snackbar open={successMessage !== null} autoHideDuration={5000} onClose={() => setSuccessMessage(null)}>
                    <Alert onClose={() => setSuccessMessage(null)} variant="filled" severity="success">
                        {successMessage}
                    </Alert>
                </Snackbar>

                <Snackbar open={errors !== null} autoHideDuration={50000} onClose={() => setErrors(null)}>
                    <Alert onClose={() => setErrors(null)} variant="filled" severity="error">
                        {errors}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
}

export default PackageTableActions;