import React from 'react';
import {Grid, TextField} from "@material-ui/core";
import {getAllPickups} from "../../../helpers";

export default function ShipperPickupAddressForm({ user, shipper, label, name, onChange, values, ...rest }) {

    const handleChange = (addr, event) => {
        const change = {
            target: {
                name: event.target.name,
                value: {
                    ...(values[name]),
                    [addr.id]: event.target.value
                }
            }
        }
        console.log(change);
        onChange(change);
    }

    return (
        <React.Fragment>
            {
                getAllPickups(user).map((addr, idx) => (
                    <Grid container spacing={3} alignItems="center" key={idx}>
                        <Grid item lg={4} md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={`${label} - ${addr.address.city}, ${addr.address.street}`}
                                name={name}
                                onChange={(event) => handleChange(addr, event)}
                                value={typeof values[name] === 'object' ? (values[name][addr.id] || '') : '' }
                            />
                        </Grid>
                    </Grid>
                ))
            }
        </React.Fragment>
    )
}