import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Tooltip,
    Box,
    makeStyles,
    IconButton
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AddIcon from '@material-ui/icons/Add';
import { NavLink as RouterLink } from 'react-router-dom';
import DeleteIconDialog from 'components/DeleteIconDialog';
import AppContext from 'context/AppContext';
import InfoIcon from '@material-ui/icons/Info';
import { formatDate } from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const MessageListTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);

    const loadMessages = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute(appValues.user.roles.includes('ROLE_USER') ? 'message' : 'admin_message') ).then(json => {
            setRows(json);
            setLoading(false);
        });
    }, [secureFetch, appValues.user.roles])

    useEffect(() => {
        loadMessages();
    }, [loadMessages]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'desc',
            orderBy: 'created_at'
        },
        headCells: [
            { id: 'created_at', numeric: false, label: t('label.created_at'), search: true, searchType: 'dateRange', render: (data) => formatDate(data.created_at, true) },
            { id: 'title', numeric: false, label: t('label.title'), search: true },
            { id: 'type', numeric: false, label: t('label.type'), search: true },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                return (
                    <Box display="flex">
                        <Tooltip title={t('tooltip.detail')} aria-label={t('tooltip.detail')}>
                            <IconButton
                                aria-label={t('tooltip.detail')}
                                className={classes.rowButtons}
                                component={RouterLink}
                                size="small"
                                to={"/app/messages/" + data.id}
                            >
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                        {
                            appValues.user.roles.includes('ROLE_USER') ? null :
                                <DeleteIconDialog
                                    text={
                                        <React.Fragment>
                                            {t('messages.really_delete')}
                                            <br />
                                            <pre>
                                                {JSON.stringify(data, null, 2)}
                                            </pre>
                                        </React.Fragment>
                                    }
                                    route={getApiRoute('admin_message', [data.id])}
                                    onDelete={loadMessages}
                                />
                        }
                    </Box>
                );
            } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    {
                        appValues.user.roles.includes('ROLE_USER') ? null :
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            size="small"
                            startIcon={<AddIcon />}
                            component={RouterLink}
                            to={'/app/admin/message/new'}
                        >
                            {t('button.new_message')}
                        </Button>
                    }
                </React.Fragment>
            );
        }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default MessageListTable;