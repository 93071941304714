import React, { useEffect, useState, useContext } from 'react';
import {
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    Box,
    Button,
    makeStyles,
    Chip,
    CircularProgress
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { isNumeric } from "helpers";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';
import Alert from "@material-ui/lab/Alert";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    root: {
    },
    grid: {
        marginBottom: "20px"
    }
}));

export default function MessageNewEditView() {
    let navigate = useNavigate();
    const { id } = useParams();
    const classes = useStyles();
    const { t } = useTranslation();
    const [values, setValues] = useState({});
    const [editorState, setEditorState] = useState({});
    const [users, setUsers] = useState([]);
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [files, setFiles] = useState([]);
    const [sending, setSending] = useState(false);

    const onFilesChange = (event) => {
        setFiles(Array.from(event.target.files));
    };

    const onReceiverChange = (event, pValues) => {
        setValues({
            ...values,
            users: pValues.filter(val => !val.hasOwnProperty('shipper_id')).map(val => val.id),
            shippers: pValues.filter(val => val.hasOwnProperty('shipper_id')).map(val => val.shipper_id),
        })
    };

    const handleChangeContent = (state) => {
        setEditorState(state);
    };

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value })
    };

    useEffect(() => {
        if (id && !isNumeric(id)) {
            navigate('/404');
        }

        if (id && id !== 0) {
            secureFetch(getApiRoute('admin_message', [id])).then(json => {
                setValues(json);
            });
        }

        if (users.length === 0) {
            secureFetch(getApiRoute('admin_user')).then(json => {
                let shipper = appValues.shippers.map(shipper => { return { id: shipper.shortcut, shipper_id: shipper.id, name: shipper.name+' - Prémium' } });
                let shipper_service = appValues.shippers.map(shipper => { return { id: shipper.shortcut+'_service', shipper_id: shipper.id+'_service', name: shipper.name+' - Základ' } });
                json = json.map(user => { return { id: user.id, name: user.username } });
                json = shipper.concat(shipper_service, json);
                setUsers(json);
            });
        }
    }, [id, navigate, appValues.user.roles, secureFetch, appValues.shippers, users.length]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setSending(true);

        const formData = new FormData();
        for (const file of files) {
            formData.append('files[]', file);
        }
        formData.append('filenames', files.map(f => f.name));
        for (const [k, val] of Object.entries(values)) {
            formData.append(k, val);
        }
        formData.append('content', JSON.stringify(convertToRaw(editorState.getCurrentContent())));

        secureFetch(getApiRoute('admin_message'), formData, 'POST', true, false).then(json => {
            setSending(null);
            navigate('/app/messages');
        });
    }

    return (
        <Page
            className={`${classes.root}`}
            title={id ? t('title.edit_message') : t('title.new_message')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {id ? t('title.edit_message') : t('title.new_message')}
                </Typography>
                <form onSubmit={handleSubmit}>

                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        options={users}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={onReceiverChange}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('label.message_to')}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label={t('label.title')}
                                        value={values.title || ''}
                                        name='title'
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="messagetype-select-label">{t('label.type')}</InputLabel>
                                        <Select
                                            required
                                            labelId="messagetype-select-label"
                                            id="messagetype-select"
                                            name='type'
                                            value={values.type || ''}
                                            fullWidth
                                            onChange={handleChange}
                                        >
                                            <MenuItem value='Oznam'>Oznam</MenuItem>
                                            <MenuItem value='Upozornenie'>Upozornenie</MenuItem>
                                            <MenuItem value='Príplatok'>Príplatok</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className={classes.grid}>
                                    <MUIRichTextEditor
                                        label="Text..."
                                        onChange={handleChangeContent}
                                        defaultValue={values.content || ''}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        component="label"
                                    >
                                        {t('button.add_attachment')}
                                        <input
                                            type="file"
                                            name="files"
                                            onChange={onFilesChange}
                                            multiple
                                            hidden
                                        />
                                    </Button>
                                    {
                                        files.map((file) => {
                                            return(
                                                <Box padding={1} display="inline">
                                                    <Chip label={file.name} />
                                                </Box>
                                            )
                                        })
                                    }
                                </Grid>
                                <Grid item xs={3} >
                                    <Box display="flex" justifyContent="flex-end">
                                        {
                                            sending === null ?
                                                <Alert variant="filled" severity="success">
                                                    {t('messages.sended')}
                                                </Alert> :
                                                <Button color="primary" variant="contained" type="submit" disabled={sending}>
                                                    {t('button.send')}
                                                    {
                                                        sending && <CircularProgress size={24} color="primary" />
                                                    }
                                                </Button>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </form>
            </Container>
        </Page>
    );
}