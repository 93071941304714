import React from 'react';
import {
    Divider,
    Grid,
    TextField,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { getErrorMessage, isInputError } from 'helpers';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    input: {
        maxWidth: '226px'
    },
}));

const PackagesStep = ({ packages, setPackages, shipper, ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const editableFields = ['cod_reference', 'cod_currency_code', 'cod_price', 'insurance', 'receiver_name', 'receiver_company', 'receiver_street', 'receiver_house_number', 'receiver_zip', 'receiver_city', 'receiver_state_code', 'receiver_phone', 'receiver_email'];
    const disabledFieldsForParcelshops = ['receiver_company', 'receiver_street', 'receiver_house_number', 'receiver_zip', 'receiver_city', 'receiver_state_code', 'count_of_packages']

    const getOtherErrors = (pErrors, getArray = false) => {
        let errors = [];
        for (const key in pErrors) {
            if (key.includes('receiver') || ['insurance', 'cod_reference', 'cod_price', 'count_of_packages'].includes(key) || pErrors[key].length === 0 ) {
                continue;
            }
            if (typeof pErrors[key] === 'object') {
                let tmpRes = getOtherErrors(pErrors[key], true);
                if (tmpRes.length !== 0)
                    errors = errors.concat(t('label.'+key) + " : " + tmpRes.join(','));
            } else {
                errors.push(pErrors[key]);
            }
        }
        if (getArray) {
            return errors;
        }
        return errors.length ? errors.join(', ') : null;
    };

    const onChange = (e, index) => {
        packages[index].package[e.target.name] = e.target.value;
        setPackages([...packages]);
    };

    const isDisabled = (p, field) => {
        if (shipper.toUpperCase() === 'PACKETA' && field === 'count_of_packages'){
            return disabledFieldsForParcelshops.includes(field);
        } else {
            return !!p.parcelshop && disabledFieldsForParcelshops.includes(field);
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {
                    packages.map((pack, index) => 
                        <Grid container spacing={2} key={index}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {index + 1}. {pack.package['reference_number']}
                                </Typography>
                            </Grid>
                            {
                                editableFields.concat(shipper.toUpperCase() === 'PACKETA' ? ['weight'] : []).map((field, i) =>
                                    <Grid item xs={'auto'} key={i}>
                                        <TextField
                                            label={t('label.' + (field.includes('receiver') ? field.substr(9) : field))}
                                            value={pack.package[field] || ''}
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => onChange(e, index)}
                                            name={field}
                                            error={isInputError(pack.errors, field)}
                                            helperText={getErrorMessage(pack.errors, field)}
                                            className={classes.input}
                                            disabled={isDisabled(pack.package, field)}
                                        />
                                    </Grid>
                                )
                            }
                            {
                                <Grid item xs={'auto'}>
                                    <TextField
                                        label={t('label.count_of_packages')}
                                        value={pack.package['count_of_packages'] || 1}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => onChange(e, index)}
                                        name={'count_of_packages'}
                                        error={isInputError(pack.errors, 'count_of_packages')}
                                        helperText={getErrorMessage(pack.errors, 'count_of_packages')}
                                        className={classes.input}
                                        disabled={isDisabled(pack.package, 'count_of_packages')}
                                    />
                                </Grid>
                            }
                            {
                                getOtherErrors(pack.errors) &&
                                <Grid item xs={12}>
                                    <Alert variant="filled" severity="error">
                                        {getOtherErrors(pack.errors) }
                                    </Alert>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    );
};

export default PackagesStep;
