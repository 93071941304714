import React, { useContext } from 'react';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AppContext from 'context/AppContext';

const CodPrice = (props) => {
    const { appValues } = useContext(AppContext);

    return (
        <React.Fragment>
            {
                props.data.cod_price &&
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {props.data.cod_payment === "1" && <CreditCardIcon fontSize="small" />}
                    <div style={{ paddingLeft: '4px', whiteSpace: 'nowrap' }}>
                        {props.data.cod_price > 0 ? parseFloat(props.data.cod_price).toFixed(2) + ' ' + appValues.currencies.find(el => el.code === props.data.cod_currency_code).symbol : null}
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

export default CodPrice;
