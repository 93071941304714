import React, {useContext} from 'react';
import {
    Container,
    Grid,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import PackagePaidTable from './PackagePaidTable';
import {hasService, isAdmin} from "../../../helpers";
import Alert from "@material-ui/lab/Alert";
import AppContext from "../../../context/AppContext";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    yMargin: {
        margin: '15px 0',
    }
}));

export default function PackagePaidListView() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appValues } = useContext(AppContext);

    return (
        <Page
            className={classes.root}
            title={t('navbar.package_paid')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.package_paid')}
                </Typography>
                {
                    (isAdmin(appValues.user) || hasService(appValues.user) ) &&
                    <Alert severity="info" className={classes.yMargin}>
                        <strong>Upozornenie:</strong> Zobrazujú sa informácie iba o balíkoch z verzie <strong>Prémium</strong>.
                    </Alert>
                }
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <PackagePaidTable/>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}