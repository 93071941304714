import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Typography,
    TextField,
    Grid,
    makeStyles, Chip
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import AppContext from 'context/AppContext';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { filterStates } from "../../../helpers";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
    root: {}
}));

const EditServicePriceForm = ({ className, data, pricelist, handleDialogOpenClose, loadPrices, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appValues } = useContext(AppContext);
    const secureFetch = useContext(SecureFetchContext);
    const [states, setStates] = useState({states_to: filterStates(appValues.states, data['state_to'])});
    const [services, setServices] = useState([]);
    const [values, setValues] = useState({
        [data.id]: {
            percentage: data.percentage,
            price: data.price,
        }
    });
    const [alertMessage, setAlertMessage] = useState('');

    const handleChange = (event, index) => {
        let tmpValues = values;
        tmpValues[index] = { ...tmpValues[index], [event.target.name]: event.target.value };
        setValues({ ...tmpValues });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if ( !states.states_to || states.states_to.length === 0 ) {
            setAlertMessage(t('messages.states_not_blank'));
            return;
        }
        for (const key in values) {
            values[key].state_to = states.states_to.map(state => state.id);
            values[key].price_list = pricelist.id;
        }
        secureFetch(getApiRoute('admin_serviceprice'), { prices: values }, 'PUT').then(json => {
            setAlertMessage('');
            handleDialogOpenClose();
            loadPrices();
        });
    };

    useEffect(() => {
        if (pricelist.id) {
            secureFetch(getApiRoute('admin_service_list', [ pricelist.id ])).then(json => {
                setServices(json);
                handleChange({
                    target: {
                        name: 'service',
                        value: json.find(service => service.code === data.code).id
                    }}, data.id);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureFetch, pricelist, data]);

    const rows = [];
    rows.push(
        <React.Fragment key={data.id} >
            <Grid item xs={4}>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>{t('label.service')}</InputLabel>
                    <Select
                        value={values[data.id] && values[data.id].service ? values[data.id].service : ''}
                        name="service"
                        onChange={ (event) => { handleChange(event, data.id) } }
                        required
                    >
                        {services.map((option) => (
                            <MenuItem
                                key={option.id}
                                value={option.id}
                            >
                                {option.code + ' ' + option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label={t('label.percentage')}
                    name="percentage"
                    onChange={ (event) => { handleChange(event, data.id) } }
                    value={values[data.id] && values[data.id].percentage ? values[data.id].percentage : ''}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label={t('label.price')}
                    name="price"
                    onChange={ (event) => { handleChange(event, data.id) } }
                    value={values[data.id] && values[data.id].price ? values[data.id].price : ''}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                    required
                />
            </Grid>
        </React.Fragment>
    );

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.edit_price')}
            </Typography>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        options={appValues.states}
                        onChange={ (event, pStates) => setStates({ ...states, states_to: pStates }) }
                        disableCloseOnSelect
                        value={states.states_to}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.name}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label={t('label.states_to')} placeholder={t('label.state')} />
                        )}
                        />
                </Grid>
                {rows}
            </Grid>
            { 
                alertMessage === '' ? null : 
                <Box>
                    <Alert variant="filled" severity="error">
                        {alertMessage}
                    </Alert>
                </Box>
            }
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Button color="secondary" variant="text" onClick={handleDialogOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.edit')}
                </Button>
            </Box>
        </form>
    );
};

EditServicePriceForm.propTypes = {
    className: PropTypes.string
};

export default EditServicePriceForm;
