import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    Grid,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
    root: {}
}));

const EditShipperForm = ({ className, shipper, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        if ( shipper ) {
            setValues({
                name: shipper.name,
                shortcut: shipper.shortcut,
            });
        }
    }, [shipper]);

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch(getApiRoute('admin_shipper', [shipper.id]), values, 'PUT').then(json => {
            setSuccessMessage(t('messages.data_saved'));
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.name')}
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.shortcut')}
                                        name="shortcut"
                                        onChange={handleChange}
                                        value={values.shortcut || ''}
                                        required
                                    />
                                </Grid>
                            </Grid>

                        </CardContent>
                        { 
                            successMessage === '' ? null : 
                            <Box px={2}>
                                <Alert variant="filled" severity="success">
                                    {successMessage}
                                </Alert>
                            </Box>
                        }
                        <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.save')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
};

EditShipperForm.propTypes = {
    className: PropTypes.string
};

export default EditShipperForm;
