import React, { useEffect } from 'react';
import {  makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ClientTableHead from 'components/ClientTableHead';
import LinearProgress from '@material-ui/core/LinearProgress';

function descendingComparator(a, b, orderBy, headCell) {
    if (headCell.numeric) {
        return parseFloat(a[orderBy]) > parseFloat(b[orderBy]) ? 1 : -1;
    }
    return String(b[orderBy]).localeCompare(String(a[orderBy]));
}

function getComparator(order, orderBy, headCell) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, headCell)
        : (a, b) => -descendingComparator(a, b, orderBy, headCell);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        overflow: 'hidden'
    },
    rowclick: {
        cursor: 'pointer'
    }
}));

export default function ClientTable({ settings, renderedDataRef, ...rest }) {
    const { headCells, loading, rows, colOrder, topRightButtons, rowClick, rowClass, addFilter, topLeftButtons, superHeader } = settings;
    const rowsPerPageInitial = [15, 50, 200];
    const classes = useStyles();
    const [order, setOrder] = React.useState( colOrder.order );
    const [orderBy, setOrderBy] = React.useState( colOrder.orderBy );
    const [page, setPage] = React.useState(0);
    const [filter, setFilter] = React.useState( {} );
    const [rowsPerPage, setRowsPerPage] = React.useState(settings.rowsPerPage ? settings.rowsPerPage : rowsPerPageInitial[1]);
    const [rowsRendered, setRowsRendered] = React.useState( [] );
    
    if (renderedDataRef) {
        renderedDataRef.current = rowsRendered;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, data) => {
        if (rowClick) {
            rowClick(event, data);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filterChange = ( filter ) => {
        let normalizedFilter = {};
        for (const key in filter) {
            if ( typeof filter[key] === 'string' ) {
                normalizedFilter[key] = filter[key].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            }
            else{
                normalizedFilter[key] = filter[key];
            }
        }
        setFilter(normalizedFilter);
    };

    useEffect(() => {
        const search = () => {
            let tmpRows = [];
            for (const row of rows) {
                let find = true;
                for ( const value in filter) {
                    if ( typeof filter[value] === 'string' ) {
                        if ( String(row[ value ]).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf( filter[value] ) === -1 ) {
                            find = false;
                            break;
                        }
                    }
                    else if (typeof filter[value] === 'boolean') {
                        if (row[value] !== filter[value]) {
                            find = false;
                            break;
                        }
                    }
                    else if ( Array.isArray(filter[value]) && filter[value].length > 0 ) {
                        if ( Array.isArray(row[value]) ) {
                            const intersection = filter[value].filter(val => row[value].includes(val));
                            if (intersection.length === 0) {
                                find = false;
                                break;
                            }
                        }
                        else if (!filter[value].includes(row[value])) {
                            find = false;
                            break;
                        }
                    }
                    else{
                        let isTo = value.includes( '_to' );
                        if (isTo) {
                            let tmpIndex = value.substr(0, value.indexOf('_to'));
                            let tmpDate = new Date( row[ tmpIndex ] );
                            if ( tmpDate > filter[ tmpIndex + '_to' ] || tmpDate < filter[ tmpIndex + '_from' ] ) {
                                find = false;
                            }
                        }
                    }
                    
                }
                if (find) {
                    tmpRows.push( row );
                }
            }
            setRowsRendered(tmpRows);
        };

        search(rows);
    }, [rows, filter]);

    return (
        <div className={classes.root}>
            { loading && <LinearProgress style={{ marginBottom: '1rem' }} />}
            <TableContainer>
                <Table
                    className={classes.table}
                    size={'small'}
                    aria-label="enhanced table"
                >
                    <ClientTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                        filterChange={filterChange}
                        topRightButtons={topRightButtons}
                        topLeftButtons={topLeftButtons}
                        addFilter={addFilter}
                        filter={filter}
                        setFilter={setFilter}
                        superHeader={superHeader}
                        pagination={
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageInitial}
                                component="div"
                                count={rowsRendered.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        }
                    />
                    <TableBody>
                        {stableSort(rowsRendered, getComparator(order, orderBy, headCells.find(v => v.id === orderBy )))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        onClick={(event) => handleClick(event, row)}
                                        role="checkbox"
                                        tabIndex={-1}
                                        className={`${classes.row} ${index % 2 && 'rowbg'} ${rowClass && rowClass(row)} ${rowClick && classes.rowclick} `}
                                        key={row.id}
                                    >
                                        {headCells.map((cell, index) => {
                                            return (
                                                cell.hide ? null :
                                                <TableCell key={index} align={cell.numeric ? 'right' : 'left'} >
                                                    { cell.render ? cell.render(row) : row[ cell.id ] }
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                            {
                                settings.totalDataRow && 
                                <TableRow
                                    role="checkbox"
                                    tabIndex={-1}
                                >
                                    {
                                        headCells.map((cell, index) => {
                                            return (
                                                <TableCell key={index} align={cell.numeric ? 'right' : 'left'} >
                                                    {settings.totalDataRow(cell, rowsRendered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))}
                                                </TableCell>
                                            )})
                                    }
                                </TableRow>
                            }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={ rowsPerPageInitial }
                component="div"
                count={rowsRendered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    );
}