import React, { useEffect, useState, useCallback, useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Tooltip,
    Box,
    makeStyles,
} from '@material-ui/core';
import ServerTable from 'components/ServerTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import {createUrlQuery, formatDate, getShipperIcon, isUser, isAdmin, openNewTab} from 'helpers';
import AddInvoiceNumberDialog from './AddInvoiceNumberDialog';
import SetCodReturnedDialog from './SetCodReturnedDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        '& table .MuiTableCell-sizeSmall': {
            padding: '6px 4px'
        }
    },
    checkbox: {
        padding: 0
    }
}));

const PackageUnpaidTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState({ rows: [], count: 0 });
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);

    const loadPackages = useCallback((pConditions = null) => {
        setLoading(true);
        secureFetch( getApiRoute( isUser(appValues.user) ? 'package_unpaid' : 'admin_package_unpaid' ) + '?' + createUrlQuery(pConditions) ).then(json => {
            setLoading(false);
            setRowsData(json);
        })
    }, [secureFetch, appValues.user])

    const exportData = [
        {
            'name': t('button.export_csv'),
            'url': getApiRoute('package_unpaid', ['export'], isAdmin(appValues.user)),
        },
    ];

    const settings = {
        rows: rowsData.rows,
        rowsLength: rowsData.count,
        loading: loading,
        colOrder: {
            order: 'desc',
            order_by: 'created_at'
        },
        handleTableUpdate: loadPackages,
        headCells: [
            { id: 'id', numeric: false, label: t('label.id'), search: false, hide: true, disableOrder: true },
            { id: 'shipper', numeric: false, label: t('label.shipper'), search: true, searchType: 'select', selectChoices: appValues.shippers, render: (data) => getShipperIcon(data) },
            { id: 'created_at', numeric: false, label: t('label.created_at'), search: true, searchType: 'dateRange', render: (data) => formatDate(data.created_at, true) },
            { id: 'reference_number', numeric: false, label: t('label.reference_number'), search: true },
            { id: 'cod_reference', numeric: false, label: t('label.cod_reference'), search: true },
            { id: 'tracking_number', numeric: false, label: t('label.tracking_number'), search: true },
            { id: 'receiver', numeric: false, label: t('label.receiver'), search: true, disableOrder: true },
            { id: 'cod_price', numeric: true, label: t('label.cod_price'), search: false, render: (data) => {
                return data.cod_price ? parseFloat(data.cod_price).toFixed(2) + ' ' + appValues.currencies.find(el => el.code === data.cod_currency_code).symbol : null;
            } },
            { id: 'actions', numeric: false, label: t('label.actions'), disableOrder: true, search: false, render: (data) => {
                return (
                    <Box display="flex">
                        <Tooltip title={t('tooltip.detail')} aria-label={t('tooltip.detail')}>
                            <IconButton
                                aria-label={t('tooltip.detail')}
                                className={classes.rowButtons}
                                size="small"
                                onClick={() => openNewTab("/app/package/detail/" + data.id)}
                            >
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                        {
                            isAdmin(appValues.user) &&
                            <React.Fragment>
                                <AddInvoiceNumberDialog data={data} loadPackages={loadPackages} />
                                <SetCodReturnedDialog data={data} loadPackages={loadPackages} />
                            </React.Fragment>
                        }
                    </Box>
                );
            } },
        ],
        addFilter: {
            states: true,
            cod_currency_code: true,
        },
        exportData: exportData
    };

    if ( isAdmin(appValues.user) ) {
        settings.headCells.splice(2, 0, { id: 'user', numeric: false, label: t('label.eshop_name'), search: true, searchType: 'select', selectChoices: users, render: (data) => data.eshop});
    }

    useEffect(() => {
        if ( isAdmin(appValues.user) ) {
            secureFetch( getApiRoute( 'admin_user' ) ).then(json => {
                setUsers(json.map(user => {
                    return {
                        id: user.id,
                        name: user.eshop || ''
                    }
                }));
            })
        }
    }, [appValues.user, secureFetch]);


    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ServerTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default PackageUnpaidTable;