import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            backgroundColor: '#f4f6f8',
            height: '100%',
            width: '100%'
        },
        a: {
            textDecoration: 'none'
        },
        '#root': {
            height: '100%',
            width: '100%'
        },
        '.uppercase': {
            textTransform: 'uppercase'
        },
        '.cardHover': {
            cursor: "pointer",
            '&:hover': {
                boxShadow: 'rgb(0, 0, 0) 0px 6px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;'
            }
        },
        '.MuiInputLabel-animated': {
            whiteSpace: 'nowrap'
        }
    },
}));

const GlobalStyles = () => {
    useStyles();

    return null;
};

export default GlobalStyles;