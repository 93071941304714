import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AppContext from 'context/AppContext';

const useStyles = makeStyles(() => ({
    root: {}
}));

const EditAddressForm = ({ className, address, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const { appValues } = useContext(AppContext);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        if ( address.name ) {
            setValues({
                name: address.name,
                company: address.company,
                email: address.email,
                phone: address.phone,
                street: address.street,
                house_number: address.house_number,
                city: address.city,
                zip: address.zip,
                state: address.state ? address.state.id : appValues.states[ appValues.states.findIndex(i => i.code === 'SK') ].id || null,
                user: address.user.id
            });
        }
    }, [address, appValues.states]);

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch(getApiRoute('address', [address.id]), values, 'PUT').then(json => {
            setSuccessMessage(t('messages.data_saved'));
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.name')}
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name || ''}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.company')}
                                                name="company"
                                                onChange={handleChange}
                                                value={values.company || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.email')}
                                                name="email"
                                                onChange={handleChange}
                                                value={values.email || ''}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.phone')}
                                                name="phone"
                                                onChange={handleChange}
                                                value={values.phone || ''}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={7} xs={8}>
                                            <TextField
                                                fullWidth
                                                label={t('label.street')}
                                                name="street"
                                                onChange={handleChange}
                                                value={values.street || ''}
                                                required
                                            />
                                        </Grid>
                                        <Grid item lg={5} xs={4}>
                                            <TextField
                                                fullWidth
                                                label={t('label.house_number')}
                                                name="house_number"
                                                onChange={handleChange}
                                                value={values.house_number || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.city')}
                                                name="city"
                                                onChange={handleChange}
                                                value={values.city || ''}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.zip')}
                                                name="zip"
                                                onChange={handleChange}
                                                value={values.zip || ''}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl className={classes.formControl} fullWidth>
                                                <InputLabel id="address_state">{t('label.state')}</InputLabel>
                                                <Select
                                                    labelId="address_state"
                                                    value={ values.state || '' }
                                                    name="state"
                                                    onChange={handleChange}
                                                >
                                                    {appValues.states.map((option) => (
                                                        <MenuItem
                                                            key={option.id}
                                                            value={option.id}
                                                        >
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        { 
                            successMessage === '' ? null : 
                            <Box px={2}>
                                <Alert variant="filled" severity="success">
                                    {successMessage}
                                </Alert>
                            </Box>
                        }
                        <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.save')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
};

EditAddressForm.propTypes = {
    className: PropTypes.string,
    states: PropTypes.array
};

export default EditAddressForm;
