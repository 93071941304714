import React, { useEffect, useState, useContext } from 'react';
import {
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    Box,
    GridList,
    GridListTile, makeStyles, GridListTileBar, IconButton, Link,
} from '@material-ui/core';
import {GetApp} from '@material-ui/icons';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import {useParams, useNavigate} from "react-router-dom";
import {isNumeric} from "helpers";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute, {getUploadPath} from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import MUIRichTextEditor from 'mui-rte';
import UnreadMessageUsersTable from './UnreadMessageUsersTable';
import GetAppIcon from "@material-ui/icons/GetApp";

const imageExtensions = [
    'png', 'jpeg', 'jpg', 'svg', 'gip'
]

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: '100%',
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        '&>li': {
            width: 'auto !important',
            maxWidth: '40%',
            minWidth: '30%'
        }
    },
    gridListTile: {
        cursor: 'pointer'
    },
    gridListImg: {
        height: '100%',
        width: 'auto',
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    linkList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignContent: 'space-between',
        marginTop: "10px"
    },
    linkItem: {
        cursor: 'pointer'
    },
    linkIcon: {
        marginBottom: "-5px",
        marginRight: "10px"
    }
}));

export default function MessageDetailView() {
    const classes = useStyles();
    let navigate = useNavigate();
    const { id } = useParams();
    const { t } = useTranslation();
    const [values, setValues] = useState({});
    const secureFetch = useContext(SecureFetchContext);
    const { appValues, setAppValues } = useContext(AppContext);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const downloadData = (file) => {
        const params = new URLSearchParams({
            file: file.filename,
        });

        secureFetch(getApiRoute('message', ['attachment']) + '?' + params.toString()).then(json => {
            if (json['document'] && json['document'] !== '') {
                let a = document.createElement('a');
                a.href = 'data:;base64,' + json['document'];
                a.download = file.original_filename;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
            }
        });
    }

    useEffect(() => {
        if (id && !isNumeric(id)) {
            navigate('/404');
        }

        if (id && id !== 0 && parseInt(id) !== values.id ) {

            secureFetch(getApiRoute( appValues.user.roles.includes('ROLE_ADMIN') ? 'admin_message' : 'message', [id])).then(json => {
                setValues(json);
                secureFetch(getApiRoute('message_unread')).then(json => {
                    setAppValues({ ...appValues, messages: json });
                });
            });
          
        }

    }, [id, navigate, secureFetch, values.id, appValues, setAppValues]);

    return (
        <Page
            title={t('title.message')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('title.message')}
                </Typography>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={'auto'}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {t('label.created_at')}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {new Date(values.created_at).toLocaleString('sk-SK')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={'auto'}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {t('label.type')}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {values.type}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={'auto'}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {t('label.title')}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {values.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {t('label.message')}
                                </Typography>
                                <Typography variant="body1" component="div" gutterBottom>
                                    <MUIRichTextEditor
                                        defaultValue={values.content || ''}
                                        readOnly={true}
                                        inheritFontSize={true}
                                        toolbar={false}
                                    />
                                </Typography>
                            </Grid>
                            {values.message_attachments &&
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {t('label.attachments')}
                                </Typography>
                                <div className={classes.root}>
                                    <GridList className={classes.gridList} cols={2.5}>
                                        { values.message_attachments.filter((att) => imageExtensions.includes(att.filename.split('.')[att.filename.split('.').length -1])).map((att, index) => (
                                            <GridListTile key={index} className={classes.gridListTile}>
                                                <img
                                                    src={getUploadPath('message_attachments/') + att.filename}
                                                    onClick={() => openInNewTab(getUploadPath('message_attachments/') + att.filename)}
                                                    className={classes.gridListImg}
                                                />
                                                <GridListTileBar
                                                    title={att.original_filename}
                                                    classes={{
                                                        root: classes.titleBar,
                                                        title: classes.title,
                                                    }}
                                                    actionIcon={
                                                        <IconButton onClick={() => downloadData(att)}>
                                                            <GetApp className={classes.title} />
                                                        </IconButton>
                                                    }
                                                />
                                            </GridListTile>
                                        ))}
                                    </GridList>
                                </div>
                                <div className={classes.linkList}>
                                    { values.message_attachments.filter((att) => !imageExtensions.includes(att.filename.split('.')[att.filename.split('.').length -1])).map((att, index) => (
                                        <Typography variant="body1" className={classes.linkItem} onClick={() => downloadData(att)} color="primary" gutterBottom>
                                            <GetAppIcon className={classes.linkIcon} />
                                            {att.original_filename}
                                        </Typography>
                                    ))}
                                </div>
                            </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Card>
                {
                    appValues.user.roles.includes('ROLE_ADMIN') &&
                    <React.Fragment>
                        <Box pt={3} />
                        <UnreadMessageUsersTable messageId={id} />
                    </React.Fragment>
                }
            </Container>
        </Page>
    );
}