import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import { createUrlQuery } from 'helpers';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ExportButtons({ exportData, filter, topRightButtons, ...rest }) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [sepaOpen, setSepaOpen] = React.useState(false);
    const [params, setParams] = React.useState({});

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const exportFile = (url) => {
        let query = {...filter, ...params};
        secureFetch(url + '?' + createUrlQuery(query)).then(([data, response]) => {
            let contentType = response.headers.get("content-type");
            contentType = contentType.includes('csv') ? 'csv' : contentType;
            contentType = contentType.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet') ? 'xlsx' : (contentType.includes('xml') ? 'xml' : contentType);
            contentType = contentType.includes('zip') ? 'zip' : contentType;
            let objectUrl = window.URL.createObjectURL(data);
            let anchor = document.createElement("a");
            document.body.appendChild(anchor);
            anchor.href = objectUrl;
            anchor.download = 'export.' + contentType;
            anchor.click();
            window.URL.revokeObjectURL(objectUrl);
            anchor.remove();
        })
        setParams({});
    };

    const sepa = exportData.find((el) => el.name.toLowerCase().includes('sepa'));
    return (
        <React.Fragment>

            <Grid container direction="column" alignItems="flex-end">
                <Grid item xs={12}>
                    <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                        <Button
                            color="primary"
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            { t('button.export') }&nbsp;&nbsp;<GetAppIcon fontSize="small" />
                        </Button>
                        {topRightButtons && topRightButtons()}
                    </ButtonGroup>
                    <Popper style={{zIndex: 1}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu">
                                            {exportData.map((option, index) => (
                                                <MenuItem
                                                    key={index}
                                                    //disabled={index === 2}
                                                    onClick={() => {
                                                        if (option.name.toLowerCase().includes('sepa')) {
                                                            setSepaOpen(true);
                                                            setOpen(false);
                                                        } else {
                                                            exportFile(option.url);
                                                            handleToggle();
                                                        }
                                                    }}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
            {
                sepa === undefined ? null :
                <Dialog open={sepaOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{t('title.sepa_set_date')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('messages.date_of_payment')}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="date_of_payment"
                            label={t('label.date')}
                            type="date"
                            fullWidth
                            onChange={(e) => setParams({...params, [e.target.name]: e.target.value})}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setSepaOpen(false)} color="primary">
                            {t('button.close')}
                        </Button>
                            <Button onClick={() => {
                                setSepaOpen(false);
                                exportFile(sepa.url);
                                handleToggle();
                            }}
                            color="primary"
                        >
                            {t('button.export_sepa')}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </React.Fragment>
    );
}
