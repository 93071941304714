import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    TextField,
    InputAdornment,
    Button,
    Box,
    makeStyles, IconButton, Tooltip
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PriceForm from './PriceForm';
import EditPriceForm from './EditPriceForm';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import SaveIcon from '@material-ui/icons/Save';
import Alert from '@material-ui/lab/Alert';
import DeleteIconDialog from 'components/DeleteIconDialog';
import AppContext from 'context/AppContext';
import ManagePriceDialog from './ManagePriceDialog';
import {fuelSign} from 'shippers';
import { getCarrierTypes } from "../../../helpers";

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const PriceTable = ({ className, pricelist, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [openCreatePriceDialog, setOpenCreatePriceDialog] = React.useState(false);
    const [openEditPriceDialog, setOpenEditPriceDialog] = React.useState(false);
    const secureFetch = useContext(SecureFetchContext);
    const [editMode, setEditMode] = useState(false);
    const [editPriceData, setEditPriceData] = useState({});
    const [editedValues, setEditedValues] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const { appValues } = useContext(AppContext);
    const renderedData = useRef(null);

    const handleDialogOpenClose = () => {
        setOpenCreatePriceDialog(!openCreatePriceDialog);
    };

    const handleEditDialogOpenClose = (data) => {
        if(openEditPriceDialog) {
            setOpenEditPriceDialog(false);
        } else {
            setEditPriceData(data);
            setOpenEditPriceDialog(true);
        }
    };

    const editModeChange = () => {
        if (editMode) {
            setEditedValues({});
        }
        setEditMode(!editMode);
    };

    const onEditChange = (event, index) => {
        let tmpValues = editedValues;
        tmpValues[index] = { ...tmpValues[index], [event.target.name]: event.target.value };
        setEditedValues({ ...tmpValues });
    };

    const saveChanges = () => {
        secureFetch(getApiRoute('admin_price'), { prices: editedValues }, 'PUT').then(json => {
            loadPrices();
        });
    };

    const loadPrices = useCallback(() => {
        if (pricelist.id) {
            setLoading(true);
            secureFetch( getApiRoute('admin_price_list', [pricelist.id]) ).then(json => {
                for (let price of json) {
                    price.delivery_type = appValues.deliveryTypes.find((el) => el.value === price.delivery_type).name;
                }
                setRows(json);
                setLoading(false);
                setEditedValues({});
                setEditMode(false);
                setSuccessMessage('');
            })
        }
    }, [secureFetch, pricelist, appValues.deliveryTypes])

    useEffect(() => {
        loadPrices();
    }, [loadPrices]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'desc',
            orderBy: 'price'
        }, 
        headCells: [
            { id: 'state_from', numeric: false, label: t('label.state_from'), search: true },
            { id: 'state_to', numeric: false, label: t('label.state_to'), search: true },
            { id: 'weight_from', numeric: true, label: t('label.weight_from'), search: true, render: (data) => data.weight_from + ' kg' },
            { id: 'weight_to', numeric: true, label: t('label.weight_to'), search: true, render: (data) => data.weight_to + ' kg' },
            { id: 'price', numeric: true, label: t('label.price'), search: true, render: (data) => {
                return (
                    editMode ? 
                    <TextField
                        fullWidth
                        name="price"
                        onChange={ (event) => { onEditChange(event, data.id) } }
                        value={  editedValues[data.id] && editedValues[data.id].price ? editedValues[data.id].price : data.price }
                        type="number"
                        inputProps={{ step: "0.001" }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        required
                    /> : data.price + ' €'
                )
            } },
            { id: 'percentage', numeric: true, label: t('label.percentage'), search: true, render: (data) => {
                return (
                    editMode ? 
                        <TextField
                            fullWidth
                            name="percentage"
                            onChange={(event) => { onEditChange(event, data.id) }}
                            value={editedValues[data.id] && editedValues[data.id].percentage ? editedValues[data.id].percentage : data.percentage}
                            type="number"
                            inputProps={{ step: "0.001" }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            required
                        />
                    : `${parseFloat(data.percentage ? data.percentage : 0).toFixed(2)} %`
                )
            } },
            { id: 'toll', numeric: true, label: t('label.toll'), search: true, render: (data) => data.toll + ' €' },
            { id: 'fuel', numeric: true, label: t('label.fuel'), search: true, render: (data) => data.fuel + ' ' + fuelSign(pricelist.shipper) },
            { id: 'delivery_type', numeric: false, label: t('label.delivery_type'), search: true },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                return (
                    <Box display="flex">
                        <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')} onClick={() => {handleEditDialogOpenClose(data)}}>
                            <IconButton
                                size="small"
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <DeleteIconDialog
                            text={
                                <React.Fragment>
                                    {t('messages.delete_price')}
                                    <br/>
                                    <pre>
                                        {JSON.stringify(data, null, 2)}
                                    </pre>
                                </React.Fragment>
                            }
                            route={getApiRoute('admin_price', [data.id])}
                            onDelete={loadPrices}
                        />
                    </Box>
                )
            } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleDialogOpenClose}
                    >
                        {t('button.create_price')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        size="small"
                        startIcon={ editMode ? <ClearIcon/> : <EditIcon /> }
                        onClick={editModeChange}
                    >
                        {t('button.edit_mode')}
                    </Button>
                    {
                        !editMode ? null :
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            size="small"
                            startIcon={ <SaveIcon /> }
                            onClick={ saveChanges }
                        >
                            {t('button.save_changes')}
                        </Button>
                    }
                    <ManagePriceDialog 
                        prices={renderedData}
                        route={getApiRoute('admin_price')}
                        loadPrices={loadPrices}
                    />
                </React.Fragment>
            );
        }
    };

    if ( pricelist.shipper.shortcut == 'Packeta')  {
        settings.headCells.splice(9, 0, { id: 'carrier_type', numeric: false, label: t('label.carrier_type'), search: true, render: (data) => {
                return (
                    <React.Fragment>
                        { getCarrierTypes().find(option => option.value === data.carrier_type).name }<span style={{fontSize:'small', paddingLeft: '10px'}}>{data.carrier_type}</span>
                    </React.Fragment>
                )}},);
    }

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                { 
                    successMessage === '' ? null : 
                    <Box px={2} pb={1} >
                        <Alert variant="filled" severity="success">
                            {successMessage}
                        </Alert>
                    </Box>
                }
                <ClientTable settings={settings} renderedDataRef={renderedData} />
            </CardContent>
            <Dialog
                open={openCreatePriceDialog}
                onClose={handleDialogOpenClose}
                maxWidth="md"
            >
                <DialogContent>
                    <PriceForm handleDialogOpenClose={handleDialogOpenClose} pricelist={pricelist} loadPrices={loadPrices} />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openEditPriceDialog}
                onClose={handleEditDialogOpenClose}
                maxWidth="md"
            >
                <DialogContent>
                    <EditPriceForm data={editPriceData} handleDialogOpenClose={handleEditDialogOpenClose} pricelist={pricelist} loadPrices={loadPrices} />
                </DialogContent>
            </Dialog>
        </Card>
    );
}

export default PriceTable;