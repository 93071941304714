import React, { useEffect } from 'react';
import {
    Container,
    makeStyles,
    Typography,
    Card,
    Grid,
    CardContent,
    Link,
    AppBar,
    Tabs,
    Tab,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import Person from './Person';
import Papa from 'papaparse';
import ClientTable from 'components/ClientTable';
import TabPanel from 'components/TabPanel';
import { getShipperLogoPath } from 'helpers';

const useStyles = makeStyles((theme) => ({
    list: {
        maxWidth: '400px'
    },
    appBar: {
        backgroundColor: 'white'
    },
    icon: {
        minWidth: theme.spacing(4),
        color: 'rgba(0,0,0,0.8)'
    }
}));

const ContactsView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [csvSps, setCsvSps] = React.useState([]);
    const [csvGls, setCsvGls] = React.useState([]);
    const [csvPacketa, setCsvPacketa] = React.useState([]);
    const [csv123, setCsv123] = React.useState([]);
    const [tab, setTab] = React.useState(0);
    const csvFiles = {
        sps: {
            file: '/documents/contact-sps.csv',
            config: {
                complete: function (results) {
                    results.data.map(d => {
                        d.id = d.city;
                        return d;
                    })
                    setCsvSps(results.data);
                }
            }
        },
        gls: {
            file: '/documents/contact-gls.csv',
            config: {
                complete: function (results) {
                    results.data.map((d, index) => {
                        d.id = index;
                        return d;
                    })
                    setCsvGls(results.data);
                }
            }
        },
        packeta: {
            file: '/documents/contact-packeta.csv',
            config: {
                complete: function (results) {
                    results.data.map((d, index) => {
                        d.id = index;
                        return d;
                    })
                    setCsvPacketa(results.data);
                }
            }
        },
        '123': {
            file: '/documents/contact-123kurier.csv',
            config: {
                complete: function (results) {
                    results.data.map((d, index) => {
                        d.id = index;
                        return d;
                    })
                    setCsv123(results.data);
                }
            }
        }
    }

    useEffect(() => { // load all services for user
        for (const key in csvFiles) {
            fetch(csvFiles[key].file).then(v => v.text()).then(text => {
                Papa.parse(text, {
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    ...csvFiles[key].config
                });
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const persons = [
        {
            name: "Mgr. Tomáš Záškvara",
            description: "Konateľ NEOSHIP s.r.o.",
            image: "/images/people/tomas.png",
            phone: "0911 658 913",
            email: "zaskvara@neoship.sk",
        },
        {
            name: "Mgr. Janka Bakaľová",
            description: "Zákaznícky servis, kredit a faktúry",
            image: "/images/people/janka.png",
            phone: "0901 714 003",
            email: "klientservis@neoship.sk",
        },
        {
            name: "Ing. Iveta Reháková",
            description: "Zákaznícky servis a obchod",
            image: "/images/people/ivetka.png",
            phone: "0901 714 003",
            email: "klientservis@neoship.sk",
        },
        // {
        //     name: "Jakub Prielomek",
        //     description: "Obchodný zástupca",
        //     image: "/images/people/jakub.png",
        //     phone: "0911 767 404",
        //     email: "prielomek@neoship.sk",
        // },
        {
            name: "Ing. Ladislav Briš",
            description: "Vývoj a technická podpora",
            image: "/images/people/laci.png",
            email: "it@neoship.sk",
        },
        // {
        //     name: "Ing. Lukáš Haninčík",
        //     description: "Vývoj a technická podpora",
        //     image: "/images/people/lukas.png",
        //     email: "it@neoship.sk",
        // },
        {
            image: "/images/neoship.png",
            name: "Fakturačná a poštová adresa",
            description: <React.Fragment>
                NEOSHIP s.r.o.<br/>
                Miestneho priemyslu 1247<br />
                029 01 Námestovo<br />
                IČO: 50 286 820<br />
                IČ DPH: SK2120258679<br />
                <Link href="tel:0901 714 003">
                    Tel: 0901 714 003
                </Link><br/>
                <Link href="mailto:info@neoship.sk">
                    Email: info@neoship.sk
                </Link>
            </React.Fragment>,
        },
    ];

    const getRows = (shipper) => {
        let sources = {SPS: csvSps, GLS: csvGls, Packeta: csvPacketa, '123': csv123};
        return sources[shipper];
    }

    const getTableSettingsByShipper = (shipper) => {
        return {
            rows: getRows(shipper),
            colOrder: {
                orderBy: 'city',
                order: 'asc'
            },
            headCells: [
                { id: 'id', numeric: true, label: t('label.id'), search: false, hide: true },
                { id: 'city', numeric: false, label: t('label.city'), search: true },
                { id: 'address', numeric: false, label: t('label.address'), search: true },
                { id: 'zip', numeric: false, label: t('label.zip'), search: true },
                {
                    id: 'phone', numeric: false, label: t('label.phone'), search: true, render: (data) => {
                        return (
                            <Link href={"tel:" + data.phone}>
                                {data.phone}
                            </Link>
                        );
                    }
                },
                {
                    id: 'email', numeric: false, label: t('label.email'), search: true, render: (data) => {
                        return (
                            <Link href={"mailto:" + data.email}>
                                {data.email}
                            </Link>
                        );
                    }
                },
                { id: 'center', numeric: false, label: t('label.center'), search: true },
            ],
        }
    }

    return (
        <Page
            title={t('navbar.contacts')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.contacts')}
                </Typography>
                <Grid container spacing={3}>
                    {
                        persons.map((p, index) => 
                            <Grid key={index} item xs={'auto'}>
                                <Person
                                    name={p.name}
                                    description={p.description}
                                    phone={p.phone}
                                    email={p.email}
                                    image={p.image}
                                />
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <Typography variant="h1" gutterBottom >
                            {t('title.centers')}
                        </Typography>
                        <AppBar className={classes.appBar} position="static">
                            <Tabs
                                value={tab}
                                onChange={(event, newValue) => setTab(newValue)}
                                aria-label="scrollable auto tabs"
                                indicatorColor="primary"
                                textColor="primary"
                                scrollButtons="auto"
                                variant="scrollable"
                            >
                                <Tab value={0} label={
                                    <img height="30" alt="SPS" src={getShipperLogoPath("SPS")} />
                                } />
                                <Tab value={1} label={
                                    <img height="30" alt="GLS" src={getShipperLogoPath("GLS")} />
                                } />
                                <Tab value={2} label={
                                    <img height="30" alt="Packeta" src={getShipperLogoPath("PACKETA")} />
                                } />
                                <Tab value={3} label={
                                    <img height="30" alt="123kurier" src={getShipperLogoPath("123")} />
                                } />
                            </Tabs>
                        </AppBar>
                    </Grid>
                    <Grid item xs={12}>
                       
                        <Card>
                            <CardContent>
                                <TabPanel className={classes.tabPanel} value={tab} index={0}>
                                    <ClientTable settings={getTableSettingsByShipper('SPS')} />
                                </TabPanel>
                                <TabPanel className={classes.tabPanel} value={tab} index={1}>
                                    <ClientTable settings={getTableSettingsByShipper('GLS')} />
                                </TabPanel>
                                <TabPanel className={classes.tabPanel} value={tab} index={2}>
                                    <ClientTable settings={getTableSettingsByShipper('Packeta')} />
                                </TabPanel>
                                <TabPanel className={classes.tabPanel} value={tab} index={3}>
                                    <ClientTable settings={{...getTableSettingsByShipper('123'), colOrder: {orderBy: 'id', order: 'desc'}}} />
                                </TabPanel>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </Container>
        </Page>
    );
};

export default ContactsView;