import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent, CardHeader, Checkbox,
    Divider,
    FormControlLabel,
    Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import getApiRoute from "../../routes/apiRoutes";
import AppContext from "../../context/AppContext";
import SecureFetchContext from "../../context/SecureFetchContext";

const UserConnectorSettings = ({user, ...rest}) => {
    const {t} = useTranslation();
    const { appValues } = useContext(AppContext);
    const secureFetch = useContext(SecureFetchContext);
    const [successMessage, setSuccessMessage] = useState('');
    const [values, setValues] = React.useState({});


    const handleCheckBoxChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.checked
        });
    };

    useEffect(() => {
        if (user) {
            secureFetch( getApiRoute('connector_settings', [user.id], true) ).then(json => {
                setValues(json);
            });
        }
    }, [secureFetch, appValues.states, user]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (user) {
            secureFetch(getApiRoute('connector_settings', [user.id], true) , values, 'POST').then(json => {
                setSuccessMessage(t('messages.data_saved'));
            });
        }
    }

    return (
        <Card
            {...rest}
        >
            <CardHeader
                subheader={t('messages.connector_settings')}
                title={t('title.connector_settings')}
            />
            <Divider />
            <CardContent>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <Grid style={{maxWidth: '900px'}} container spacing={3}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values['ENABLED'] || false}
                                        onChange={handleCheckBoxChange}
                                        name="ENABLED"
                                        value="1"
                                        color="primary"
                                    />
                                }
                                label={t('label.enabled')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {
                                successMessage === '' ? null :
                                    <Box pb={2}>
                                        <Alert variant="filled" severity="success">
                                            {successMessage}
                                        </Alert>
                                    </Box>
                            }
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
}

export default UserConnectorSettings;