import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import AddressPreview from './AddressPreview';

const AddressesPreview = ({ className, values, ...rest }) => {
    const { t } = useTranslation();

    return (
        <Card style={{ height: "100%"}}>
            <CardHeader
                title={ t('title.addresses') }
                className={className}
            />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                       <AddressPreview title={t('title.sender_address')} prefix="sender_" values={values} rightCorner={values.pickup_address ? `ID: ${values.pickup_address.id}` : null}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AddressPreview title={t('title.receiver_address')} prefix="receiver_" values={values} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AddressesPreview;
