import React from 'react';
import {
    Container,
    Grid,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import ServiceNeoshipTable from './ServiceNeoshipTable';

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

export default function NeoshipServiceView() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Page
            className={classes.root}
            title={t('navbar.services_neoship')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.services_neoship')}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ServiceNeoshipTable/>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}