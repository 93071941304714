import React, { useState, useContext, useEffect } from 'react';
import {
    Container,
    Grid,
    Box,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import CalculatedPrices from './CalculatedPrices';
import CalculationMainForm from './CalculationMainForm';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import ServicesForm from '../PackageNewEditView/ServicesForm';
import LoadingOverlay from "react-loading-overlay";

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

export default function CalculationView() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appValues } = useContext(AppContext);
    const [values, setValues] = useState({
        sender_state_code: appValues.states.find(state => state.code === 'SK').code,
        receiver_state_code: appValues.states.find(state => state.code === 'SK').code,
        services: {},
        payment_type: 0,
    });
    const secureFetch = useContext(SecureFetchContext);
    const [notAllowedServices, setNotAllowedServices] = useState([]);
    const [services, setServices] = useState([]);
    const [defServices, setDefServices] = useState(null);
    const [isDefServicesSet, setIsDefServicesSet] = useState(false);
    const [prices, setPrices] = useState(null);
    const [shipper, setShipper] = useState(appValues.user.roles.includes('ROLE_USER') && appValues.user.user_shipper_price_lists ? appValues.user.user_shipper_price_lists[0].shipper.id : null);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [loader, setLoader] = useState(false);
    const SERVICES_TO_HIDE = ['IP'];

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleCheckboxChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.checked });
    };

    const handleServiceChange = (event) => {
        let tmpServices = { ...values.services, [event.target.name]: event.target.value };
        setValues({
            ...values,
            services: tmpServices
        });
    };

    const handleServiceCheckboxChange = (event) => {
        let tmpServices = { ...values.services, [event.target.name]: event.target.checked ? true : false };
        setValues({
            ...values,
            services: tmpServices
        });
    };

    useEffect(() => {
        let userId = appValues.user.id;
        if (appValues.user.roles.includes('ROLE_ADMIN')) {
            userId = user;
        }
        
        if (shipper && userId) {
            let urlSettings;
            if (appValues.user.roles.includes('ROLE_USER')) {
                urlSettings = getApiRoute('usersettings', [shipper]);
            }
            else if (userId) {
                urlSettings = getApiRoute('usersettings', [shipper, userId], true);
            }

            setLoader(true);
            secureFetch(urlSettings).then(json => {
                let tmpDefServices = null;
                for (const key in json) {
                    if (key.includes('DEFAULT_SERVICES')) {
                        tmpDefServices = json[key];
                    }
                }
                if (tmpDefServices) {
                    setDefServices(tmpDefServices);
                    setIsDefServicesSet(false);
                }
                else {
                    setDefServices({});
                }
                setLoader(false);
            });
        }
    }, [secureFetch, shipper, appValues.user, user]);

    useEffect(() => {
        if (!isDefServicesSet) {
            let tmpValues = values;
            let changed = false;

            for (const serviceCode in tmpValues.services) {
                console.log(serviceCode, " ", tmpValues.services[serviceCode], "&&", !services.find(s => s.code === serviceCode));
                if (tmpValues.services[serviceCode] && !services.find(s => s.code === serviceCode)) {
                    delete tmpValues.services[serviceCode];
                    changed = true;
                }
            }

            for (const serviceCode in defServices) {
                if (!tmpValues.services[serviceCode] && defServices[serviceCode] === true && !notAllowedServices.includes(serviceCode) && services.find(s => s.code === serviceCode)) {
                    tmpValues.services[serviceCode] = true;
                    changed = true;
                }
            }
            if (changed) {
                setValues({ ...values, ...tmpValues });
                setIsDefServicesSet(true);
            }
        }
    }, [services, defServices, notAllowedServices, values, isDefServicesSet]);

    useEffect(() => {
        if (users.length === 0 && appValues.user.roles.includes('ROLE_ADMIN')) {
            setLoader(true);
            secureFetch(getApiRoute('admin_user')).then(json => {
                setLoader(false);
                setUsers(json);
            })
        }
    }, [appValues.user, secureFetch, users.length]);

    useEffect(() => { // load all services for user
        if (values.receiver_state_code && shipper) {
            let url = '';
            if (appValues.user.roles.includes('ROLE_USER')) {
                url = getApiRoute('package_services', [shipper, appValues.states.find(state => state.code === values.receiver_state_code).id])
            }
            else if (user){
                url = getApiRoute('admin_package_services', [shipper, appValues.states.find(state => state.code === values.receiver_state_code).id, user]);
            }

            if (url !== '') {
                setLoader(true);
                secureFetch(url+'?all=true').then(json => {
                    setLoader(false);
                    setServices(json.filter(s => !SERVICES_TO_HIDE.includes(s.code)));
                });
            }
        } else {
            setServices([]);
        }
    }, [secureFetch, shipper, appValues.states, values.receiver_state_code, user, appValues.user.roles]);

    useEffect(() => { // get not allowed services combination
        if (values.services && Object.keys(services).length > 0 && shipper && defServices !== null) {
            let tmpVal = {
                sender_state_code: values.sender_state_code,
                receiver_state_code: values.receiver_state_code,
                insurance: values.insurance,
                reverse: values.reverse,
                parcelshop: values.parcelshop,
                services: values.services,
            };
            setLoader(true);
            secureFetch(getApiRoute('package_services', [shipper]), tmpVal, 'POST').then(json => {
                setLoader(false);
                setNotAllowedServices(json)
            });
        }
    }, [secureFetch, values.services, shipper, services, defServices, values.sender_state_code, values.receiver_state_code, values.insurance, values.reverse, values.parcelshop]);

    const calculate = (e) => {
        e.preventDefault();
        let url = '';
        if (appValues.user.roles.includes('ROLE_USER')) {
            url = getApiRoute('package_calculate', [shipper])
        }
        else {
            url = getApiRoute('admin_package_calculate', [shipper, user])
        }
        if (url !== '') {
            for (const serviceCode in values.services) {// sanitize remove services from different shipper
                if (values.services[serviceCode] && !services.find(s => s.code === serviceCode)) {
                    delete values.services[serviceCode];
                }
            }
            setLoader(true);
            secureFetch(url, values, 'POST').then(json => {
                setLoader(false);
                setPrices(json);
            });
        }
    };

    return (
        <Page
            className={classes.root}
            title={t('navbar.calculation')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.calculation')}
                </Typography>
                <form onSubmit={calculate}>
                    <Grid container spacing={3}>
                        <Grid item md={4}>
                            <CalculationMainForm
                                values={values}
                                setValues={setValues}
                                handleChange={handleChange}
                                handleCheckboxChange={handleCheckboxChange}
                                notAllowedServices={notAllowedServices}
                                handleServiceCheckboxChange={handleServiceCheckboxChange}
                                shipper={shipper}
                                users={users}
                                user={user}
                                setUser={setUser}
                                setShipper={setShipper}
                                setIsDefServicesSet={setIsDefServicesSet}
                                setPrices={setPrices}
                            />
                        </Grid>
                        <Grid item md={8}>
                            <ServicesForm
                                title={t('title.services')}
                                services={services}
                                values={values.services}
                                handleChange={handleServiceChange}
                                notAllowedServices={notAllowedServices}
                                handleCheckBoxChange={handleServiceCheckboxChange}
                                onlyCheck={true}
                            />
                            <Box pt={3}/>
                            {
                                prices && <CalculatedPrices prices={prices} shipperId={shipper} />
                            }
                        </Grid>
                    </Grid>
                </form>

                <LoadingOverlay
                    active={loader}
                    spinner
                    styles={{
                        wrapper: {position: 'static'}
                    }}
                    classNamePrefix='ServiceFormLoader_'
                    text={<Typography variant='subtitle1'>{t('messages.loading_content')}</Typography> }
                >
                </LoadingOverlay>
            </Container>
        </Page>
    );
}