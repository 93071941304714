import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    makeStyles, Avatar
} from '@material-ui/core';
import { formatNumber } from 'helpers';
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.secondary.main,
        height: '100%',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.5)',
    },
    content: {
        padding: "8px 16px 10px !important",
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    button: {
        marginLeft: "-0.5em"
    }
}));

const TotalCreditSmall = ({ count, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    let navigate = useNavigate();
    return (
        <Card
            className={clsx(classes.root, "cardHover")}
            onClick={() => navigate('/app/credit')}
        >
            <CardContent
                className={clsx(classes.content)}
            >
                <Grid
                    container
                    wrap="nowrap"
                    justify="space-between"
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                            style={{ color: 'rgba(255,255,255,0.8)' }}
                        >
                            { t('title.total_credit') }
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="subtitle2"
                            style={{ color: 'rgba(255,255,255,1)' }}
                        >
                            { `${formatNumber(parseFloat(count).toFixed(2))} €` }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <AddIcon />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

TotalCreditSmall.propTypes = {
    count: PropTypes.number
};

export default TotalCreditSmall;
