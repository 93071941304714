import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';

const UserWithoutPackageStatisticsTable = ({ params, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        secureFetch(getApiRoute('admin_statistics', ['nouserpackage']) + '?' + params).then(json => {
            for (const val of json) {
                val.id = val.eshop;
            }
            setRows(json);
        });
    }, [secureFetch, params]);

    const settings = {
        rows: rows,
        loading: false,
        colOrder: {
            order: 'asc',
            orderBy: 'eshop'
        },
        headCells: [
            { id: 'eshop', numeric: false, label: t('label.eshop_name'), search: true },
            { id: '', numeric: false, label: '', search: false }, //for filter alignment
        ],
        totalDataRow: (cell, data) => {
            if (cell.id === 'username') {
                return (
                    <b>{t('label.total') + ' ' + data.length}</b>
                )
            }
        }
    };
    
    return (
        <Card
            {...rest}
        >
            <CardContent>
                <Typography variant="h4" align="center">
                    {t('title.user_without_package')}
                </Typography>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default UserWithoutPackageStatisticsTable;