import React, {useContext} from 'react';
import {
    Grid,
    Container,
    Typography,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Page from 'components/Page';
import ReturnedPackageTable from './ReturnedPackageTable';
import Alert from "@material-ui/lab/Alert";
import {hasService, isAdmin} from "../../../helpers";
import AppContext from "../../../context/AppContext";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    yMargin: {
        margin: '15px 0',
    }
}));

export default function ReturnedPackageStatistics({ ...rest }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appValues } = useContext(AppContext);

    return (
        <Page
            title={t('navbar.package_returned')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.package_returned')}
                </Typography>
                <Grid container spacing={3} alignItems="center" >
                    <Grid item xs={12}>
                        {
                            (isAdmin(appValues.user) || hasService(appValues.user) ) &&
                            <Alert severity="info" className={classes.yMargin}>
                                <strong>Upozornenie:</strong> Zobrazujú sa informácie iba o balíkoch z verzie <strong>Prémium</strong>.
                            </Alert>
                        }
                        <ReturnedPackageTable />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}