import React, { useState, useContext } from 'react';
import {
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    TextField,
    Divider,
    Box,
    CardHeader,
    Radio,
    InputAdornment,
    FormControl,
    RadioGroup,
    Button, makeStyles,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import getApiRoute from 'routes/apiRoutes';
import SecureFetchContext from 'context/SecureFetchContext';
import AppContext from 'context/AppContext';
import UserUpdatableContext from "../../context/UserUpdatableContext";
import LoadingOverlay from "react-loading-overlay";
import CardMedia from "@material-ui/core/CardMedia";

export default function CreditView() {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues, setAppValues } = useContext(AppContext);
    const { userUpdatable, setUserUpdatable } = useContext(UserUpdatableContext);
    const [values, setValues] = useState({ bank: 'SPOROPAY'});
    const [comgateValues, setComgateValues] = useState({ });
    const [besteron2Values, setBesteron2Values] = useState({});
    const [creditReloaded, setCreditReloaded] = useState(false);
    const [loader, setLoader] = useState(false);

    const useStyles = makeStyles((theme) => ({
        media: {
            height: '50px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        }
    }));

    const banks = [
        {
            code: 'SPOROPAY',
            name: 'Slovenská sporiteľňa, a. s.',
            img: 'slovenska.svg',
        },
        {
            code: 'TATRAPAY',
            name: 'Tatra banka, a. s.',
            img: 'tatra.svg',
        },
        {
            code: 'VUBPAY',
            name: 'Všeobecná úverová banka, a. s.',
            img: 'vub.svg',
        },
        {
            code: 'VIAMO',
            name: 'Viamo',
            img: 'viamo.svg',
        },
        {
            code: 'POSTAPAY',
            name: 'Poštová banka, a. s.',
            img: 'postova.svg',
        },
    ];

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    };

    const handleComgateChange = (e) => {
        setComgateValues({...comgateValues, [e.target.name]: e.target.value});
    };

    const handleBesteron2Change = (e) => {
        setBesteron2Values({...besteron2Values, [e.target.name]: e.target.value});
    };

    const onBesteronSubmit = (e) => {
        e.preventDefault();
        secureFetch( getApiRoute('credit_charge'), values, 'POST' ).then(json => {
            window.location.href = json.redirect;
        });
    }

    const onComgateSubmit = (e) => {
        e.preventDefault();
        secureFetch( getApiRoute('credit_charge_comgate'), comgateValues, 'POST' ).then(json => {
            window.location.href = json.redirect;
        });
    }

    const onBesteron2Submit = (e) => {
        e.preventDefault();
        setLoader(true);
        secureFetch( getApiRoute('credit_charge_besteron2'), besteron2Values, 'POST' ).then(json => {
            setLoader(false);
            window.location.href = json.redirect;
        });
    }

    const status = (new URLSearchParams(window.location.search)).get('status'); //get payment status true = paid, false = unpaid
    if (status && status === 'true' && !creditReloaded) {
        setCreditReloaded(true);
        secureFetch( getApiRoute('user') ).then(json => {
            setAppValues({...appValues, user: json});
            setUserUpdatable({statistics: json.others, credit: json.credit, last_update: new Date()});
        });
    }

    const classes = useStyles();

    return (
        <Page
            title={t('title.charge_credit')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('title.charge_credit')}
                </Typography>
                {
                    status === null ? null : 
                    <Box pb={3}>
                        {
                            status === 'true' ? 
                            <Alert variant="filled" severity="success">
                                Vaša platba bola úspešná
                            </Alert> :
                                status === 'pending' ?
                                <Alert variant="filled" severity="warning">
                                    Vaša platba čaká na schválenie, aktuálny stav viete skontrolovať v záznamoch kreditu.
                                </Alert> :
                                    <Alert variant="filled" severity="error">
                                        Vaša platba bola neúspešná.
                                    </Alert>
                        }
                    </Box>
                }

                    <Card>
                        <CardHeader
                            title={'1. Rýchle dobitie kreditu'}
                        />
                        <Divider />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} >
                                        <Typography variant="subtitle1">
                                            Dobitie kreditu prostredníctvom služby <strong>Besteron</strong> aj <strong>Comgate</strong> je štandardne realizované do niekoľkých minút od odoslania platby.<br/>
                                            S vašimi údajmi neprichádzame do kontaktu, sme informovaní iba o úspechu alebo neúspechu transakcie.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <Typography variant="h4">
                                            Besteron - bankové tlačidlo
                                        </Typography>
                                        <Box mb={3}/>
                                        <form onSubmit={onBesteron2Submit}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <TextField
                                                        fullWidth
                                                        label={t('label.credit')}
                                                        name={"credit_besteron2"}
                                                        onChange={handleBesteron2Change}
                                                        type="number"
                                                        value={besteron2Values["credit_besteron2"] || ''}
                                                        inputProps={{step: "10"}}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment
                                                                position="start">€</InputAdornment>,
                                                        }}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Box mb={3}>
                                                        <Button
                                                            color="primary"
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                        >
                                                            {t('button.pay')}
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </form>
                                        <Alert variant="filled" severity="warning">
                                            UPOZORNENIE: Pri platbách za kredit v hodnote 0,01 € - 99,99 € prostredníctvom tlačidiel Besteronu je transakcia spoplatnená sumou 1,99 € s DPH. Pri dobití kreditu 100,00 € a viac je platba bez poplatku.
                                        </Alert>
                                    </Grid>

                                    <Grid item xs={12} md={6} >
                                        <Typography variant="h4">
                                            Comgate - platobná karta
                                        </Typography>
                                        <Box mb={3}/>
                                        <form onSubmit={onComgateSubmit}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <TextField
                                                        fullWidth
                                                        label={t('label.credit')}
                                                        name={"credit_comgate"}
                                                        onChange={handleComgateChange}
                                                        type="number"
                                                        value={comgateValues["credit_comgate"] || ''}
                                                        inputProps={{step: "10"}}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment
                                                                position="start">€</InputAdornment>,
                                                        }}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Box mb={3}>
                                                        <Button
                                                            color="primary"
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                        >
                                                            {t('button.pay')}
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image="/images/card.svg"
                                                        title="Contemplative Reptile"
                                                    />
                                                </Grid>
                                                <Grid container spacing={3}>
                                                </Grid>
                                            </Grid>
                                        </form>
                                        <Alert variant="filled" severity="warning">
                                            UPOZORNENIE: Pri platbách prostredníctvom platobnej karty
                                            cez <strong>Comgate</strong> je transakcia spoplatnená sumou
                                            zodpovedajúcou <strong>1,00% z výšky platby</strong>.
                                        </Alert>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                <Box mb={3} />
                <Card>
                    <CardHeader
                        title={'2. Bankový prevod'}
                    />
                    <Divider />
                    <CardContent>
                        <Typography variant="subtitle1">
                            Kredit vám bude navýšený až po pripísaní vašej platby na náš účet. V závislosti od vašej banky to môže trvať niekoľko dní.
                            <br/>
                            Vaše platobné údaje pre dobitie kreditu sú:
                        </Typography>
                        <Box mb={2} />
                        <TableContainer>
                            <Table style={{width: 'auto'}} size="small" aria-label="a dense table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell variant="head">Číslo účtu</TableCell>
                                        <TableCell >SK29 1100 0000 0029 4802 2430</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">Variabilný symbol</TableCell>
                                        <TableCell >{appValues.user.variable_number}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">Banka</TableCell>
                                        <TableCell >Tatra Banka</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">SWIFT / BIC</TableCell>
                                        <TableCell >TATRSKBX</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">Konštantný symbol</TableCell>
                                        <TableCell >0308</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
                <LoadingOverlay
                    active={loader}
                    spinner
                    styles={{
                        wrapper: {position: 'static'}
                    }}
                    classNamePrefix='ServiceFormLoader_'
                    text={<Typography variant='subtitle1'>{t('messages.loading_content')}</Typography> }
                >
                </LoadingOverlay>
            </Container>
        </Page>
    );
}