import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Grid,
    Card,
    CardContent,
    Typography,
    Divider,
    TextField,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ServicesForm from 'views/Package/PackageNewEditView/ServicesForm';
import {isAdmin, isUser} from "../../helpers";
import UserConnectorSettings from "./UserConnectorSettings";
import UserMicrocampaignSettings from "./UserMicrocapaignSettings";

const OtherSettingsForm = ({ className, user, setUser, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = React.useState({ GLS_DEFAULT_SERVICES: {} });
    const { appValues } = useContext(AppContext);
    const [successMessage, setSuccessMessage] = useState('');
    const [services, setServices] = useState([]);
    const [notAllowedServices, setNotAllowedServices] = useState([]);
    const [selectedShipper, setSelectedShipper] = React.useState(user.user_shipper_price_lists.length ? user.user_shipper_price_lists[0] : null);
    const [depotList, setDepotList] = useState([]);

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let urlSettings;
        if (isUser(appValues.user)) {
            urlSettings = getApiRoute('usersettings', [selectedShipper.shipper.id]);
        }
        else if (user) {
            urlSettings = getApiRoute('usersettings', [selectedShipper.shipper.id, user.id], true);
        }
        secureFetch(urlSettings , values, 'POST').then(json => {
            setSuccessMessage(t('messages.data_saved'));
        });
    }

    useEffect(() => { // load all services for user
        if (selectedShipper) {
            let url, urlSettings;
            if (isUser(appValues.user)) {
                url = getApiRoute('package_services', [selectedShipper.shipper.id, appValues.states.find(state => state.code === 'SK').id])
                urlSettings = getApiRoute('usersettings', [selectedShipper.shipper.id]);
            }
            else if (user) {
                url = getApiRoute('admin_package_services', [selectedShipper.shipper.id, appValues.states.find(state => state.code === 'SK').id, user.id]);
                urlSettings = getApiRoute('usersettings', [selectedShipper.shipper.id, user.id], true);
            }

            if (url !== '') {
                secureFetch(url).then(json => {
                    setServices(json);
                });
            }
            secureFetch( urlSettings ).then(json => {
                if( isAdmin(appValues.user) && selectedShipper.shipper.shortcut.toUpperCase() === 'SPS'){
                    let urlDepotContacts = getApiRoute('depot_contacts', [selectedShipper.shipper.id], true);
                    secureFetch(urlDepotContacts).then(depotContacts => {
                        setDepotList(depotContacts);
                    })
                }
                setValues(json);
            });
        }
    }, [secureFetch, appValues.states, user, selectedShipper, appValues.user]);

    useEffect(() => { // get not allowed services combination
        if (selectedShipper) {
            let services = values[selectedShipper.shipper.shortcut.toUpperCase() + '_DEFAULT_SERVICES'];
            if (services && Object.entries(services).length !== 0) {
                let tmpVal = {
                    sender_state_code: 'SK',
                    receiver_state_code: 'SK',
                    services: services,
                };
                secureFetch(getApiRoute('package_services', [selectedShipper.shipper.id]), tmpVal, 'POST').then(json => {
                    setNotAllowedServices(json)
                });
            } else if (services === undefined) {
                setNotAllowedServices([]);
            }
        }
    }, [secureFetch, services, selectedShipper, values]);

    const handleServiceCheckboxChange = (event) => {
        let serviceName = selectedShipper.shipper.shortcut.toUpperCase() + '_DEFAULT_SERVICES';
        let tmpServices = { ...values[serviceName], [event.target.name]: event.target.checked ? true : false };
        setValues({
            ...values,
            [serviceName]: tmpServices
        });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card
                    {...rest}
                >
                    <CardContent>
                        <form
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <Grid style={{maxWidth: '900px'}} container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="shipper">{t('label.shipper')}</InputLabel>
                                        <Select
                                            labelId="shipper"
                                            value={selectedShipper ? user.user_shipper_price_lists.indexOf(selectedShipper) : ''}
                                            onChange={(event) => setSelectedShipper(user.user_shipper_price_lists[event.target.value])}
                                            name="shipper"
                                        >
                                            {user.user_shipper_price_lists.map((option, index) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={index}
                                                >
                                                    {option.shipper.shortcut}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {
                                    selectedShipper && isAdmin(appValues.user) && selectedShipper.shipper.shortcut.toUpperCase() === 'SPS' &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <Typography variant="h4">
                                                {t('label.data_depot')}
                                            </Typography>
                                            <FormControl fullWidth>
                                                <InputLabel id={selectedShipper.shipper.shortcut.toUpperCase() + "_DATA_DEPOT"}>{t('label.depot')}</InputLabel>
                                                <Select
                                                    labelId={selectedShipper.shipper.shortcut.toUpperCase() + "_DATA_DEPOT"}
                                                    value={ values[selectedShipper.shipper.shortcut.toUpperCase() + "_DATA_DEPOT"] || '' }
                                                    onChange={handleChange}
                                                    name={selectedShipper.shipper.shortcut.toUpperCase() + "_DATA_DEPOT"}
                                                >
                                                    {Object.keys(depotList).map((key, index) => (
                                                        <MenuItem key={key} value={key}>
                                                            {depotList[key]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    selectedShipper && isAdmin(appValues.user) &&
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                label={t('label.xml_transform')}
                                                fullWidth
                                                rows={4}
                                                variant="outlined"
                                                name={selectedShipper.shipper.shortcut.toUpperCase() + "_XML_TRANSFORM"}
                                                value={values[selectedShipper.shipper.shortcut.toUpperCase() + "_XML_TRANSFORM"] || ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    selectedShipper &&
                                    <Grid item xs={12}>
                                        <ServicesForm
                                            title={t('title.services')}
                                            services={services}
                                            values={values[selectedShipper.shipper.shortcut.toUpperCase() + '_DEFAULT_SERVICES']}
                                            notAllowedServices={notAllowedServices}
                                            handleCheckBoxChange={handleServiceCheckboxChange}
                                            onlyCheck={true}
                                        />
                                    </Grid>
                                }
                                {
                                    selectedShipper && selectedShipper.shipper.shortcut.toUpperCase() === 'GLS' &&
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">
                                                SMS Service
                                            </Typography>
                                            <Typography>
                                                Text SMS správy max. 160 znakov. Je možné vložiť nasledujúce premenné:
                                            </Typography>
                                            <Box pt={1}/>
                                            <Table style={{width: 'auto'}} size="small" aria-label="a dense table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>číslo balíka: 11 znakov</TableCell>
                                                        <TableCell>#ParcelNr#</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Dobierka: 7 znakov</TableCell>
                                                        <TableCell>#COD#</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Dátum nakládky: 10 znakov</TableCell>
                                                        <TableCell>#PickupDate#</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Meno odosielateľa: 20 znakov</TableCell>
                                                        <TableCell>#From_Name#</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Referencia 20 znakov</TableCell>
                                                        <TableCell>#ClientRef#</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <Box pt={1} />
                                            <TextField
                                                fullWidth
                                                label={t('label.service') + ' SMS Text'}
                                                name="GLS_SMS_TEXT"
                                                value={values.GLS_SMS_TEXT || ''}
                                                onChange={handleChange}
                                                rows={4}
                                                variant="outlined"
                                                multiline
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                                <Grid item xs={12}>
                                    {
                                        successMessage === '' ? null :
                                            <Box pb={2}>
                                                <Alert variant="filled" severity="success">
                                                    {successMessage}
                                                </Alert>
                                            </Box>
                                    }
                                    <Button color="primary" variant="contained" type="submit">
                                        {t('button.save')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
            {
                isAdmin(appValues.user) &&
                <Grid item xs={12}>
                    <UserConnectorSettings user={user} />
                </Grid>
            }
            {
                isAdmin(appValues.user) &&
                <Grid item xs={12}>
                    <UserMicrocampaignSettings user={user} />
                </Grid>
            }
        </Grid>
    );
};

export default OtherSettingsForm;
