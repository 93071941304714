import React from 'react';
import {
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(() => ({
    root: {}
}));

const PickupAddressDetail = ({ className, values, title, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Table className={classes.table} size="small" aria-label="a dense table">
            <TableBody>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.name')}
                    </TableCell>
                    <TableCell>
                        {values['name']}
                    </TableCell>
                    <TableCell component="th" variant="head">
                        {t('label.company')}
                    </TableCell>
                    <TableCell>
                        {values['company']}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.city')}
                    </TableCell>
                    <TableCell>
                        {values['city']}
                    </TableCell>
                    <TableCell component="th" variant="head">
                        {t('label.street')}
                    </TableCell>
                    <TableCell>
                        {values['street']}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.house_number')}
                    </TableCell>
                    <TableCell>
                        {values['house_number']}
                    </TableCell>
                    <TableCell component="th" variant="head">
                        {t('label.zip')}
                    </TableCell>
                    <TableCell>
                        {values['zip']}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.state')}
                    </TableCell>
                    <TableCell>
                        {values.state.code}
                    </TableCell>
                    <TableCell component="th" variant="head" />
                    <TableCell />
                </TableRow>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.email')}
                    </TableCell>
                    <TableCell>
                        {values['email']}
                    </TableCell>
                    <TableCell component="th" variant="head">
                        {t('label.phone')}
                    </TableCell>
                    <TableCell>
                        {values['phone']}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default PickupAddressDetail;
