import React, {useContext, useEffect, useState} from 'react';
import {
    Grid,
    TextField,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import getApiRoute from "../../../routes/apiRoutes";
import SecureFetchContext from "../../../context/SecureFetchContext";

const SelectCsvFields = ({ csv, csvMap, setCsvMap, shipper, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [stateTo, setStateTo] = useState(null);
    const [statesTo, setStatesTo] = useState([]);

    useEffect(() => {
        secureFetch(getApiRoute('price_list_states', ['to', shipper])).then(json => {
            if (csvMap['receiver_state_code_fix'] !== undefined) {
                const tmpState = json.find(s => s.code === csvMap['receiver_state_code_fix']) || null;
                setStateTo(tmpState);
            }
            setStatesTo(json);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureFetch, shipper]);

    const fields = [
        'reference_number', 'receiver_name', 'receiver_company', 'receiver_street', 'receiver_house_number',
        'receiver_zip', 'receiver_city', 'receiver_state_code', 'receiver_phone', 'receiver_email'
    ];

    const additonalFields = [
        'cod_price',
        'cod_currency_code',
        'cod_reference',
        'insurance',
        'count_of_packages'
    ];

    const required = [
        'reference_number', 'receiver_name', 'receiver_street',
        'receiver_zip', 'receiver_city', 'receiver_phone', 'receiver_email'
    ];

    // eslint-disable-next-line no-unused-expressions
    if (rest.type === 'gls-parcelshop') {
        additonalFields.pop();
        additonalFields.push('parcelshop');
        fields.splice(2, 6);
    }

    const onChange = (field, val) => {
        if (val !== -1) {
            setCsvMap({...csvMap, [field]: val});
        } else {
            delete csvMap[field];
            setCsvMap({...csvMap});
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                    {
                        fields.map((field, index) =>
                            <React.Fragment key={index}>
                                <Grid key={index} item xs={12} md={field === 'receiver_state_code' ? 6 : null}>
                                    <Autocomplete
                                        options={csv.meta.fields}
                                        value={csvMap[field] !== undefined ? csv.meta.fields[csvMap[field]] : null}
                                        onChange={(event, val) => onChange(field, csv.meta.fields.indexOf(val)) }
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            label={t('label.' + (field === 'reference_number' ? field : field.substr(9)))}
                                            required={required.includes(field)}
                                        />}
                                    />
                                </Grid>
                                {
                                    field === 'receiver_state_code' && 
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            options={statesTo}
                                            value={stateTo}
                                            onChange={(event, val) => {
                                                setStateTo(val);
                                                onChange('receiver_state_code_fix', val.code);
                                            }}
                                            getOptionLabel={(option) => option.code}
                                            getOptionSelected={(option, value) => option.code === value.code}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label={t('label.state_code')}
                                            />}
                                        />
                                    </Grid>
                                }
                            </React.Fragment>
                        )
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                    {
                        additonalFields.map((field, index) =>
                            <Grid key= { index } item xs={12}>
                                <Autocomplete
                                    options={csv.meta.fields}
                                    value={csvMap[field] !== undefined ? csv.meta.fields[csvMap[field]] : null}
                                    onChange={(event, val) => onChange(field, csv.meta.fields.indexOf(val))}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={t('label.' + field)}
                                    />}
                                />
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SelectCsvFields;
