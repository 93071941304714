import React, { useContext } from 'react';
import {
    Container,
    Grid,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import InvoiceAddressForm from './InvoiceAddressForm';
import DeliveryAddressForm from './DeliveryAddressForm';
import UserLogo from './UserLogo';
import PasswordForm from './PasswordForm';
import TabPanel from 'components/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppContext from 'context/AppContext';
import OtherSettingsForm from './OtherSettingsForm';
import EmailTemplateSelector from "./EmailTemplateSelector";
import ShipperCredentialsForm from "./ShipperCredentialsForm";
import {hasService, isAdmin} from "../../helpers";
import NotificationEmailsForm from "./NotificationEmailsForm";
import IbansTable from "./IbansTable";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    appBar: {
        background: 'white'
    }
}));

export default function AccountView() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);
    const { appValues } = useContext(AppContext);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Page
            className={classes.root}
            title={t('navbar.account')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.account')}
                </Typography>
                <div>
                    <AppBar className={classes.appBar} position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="scrollable auto tabs"
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            variant="scrollable"
                        >
                            <Tab value={0} label={t('title.basic_settings')} />
                            <Tab value={1} label={t('title.emails')} />
                            <Tab value={2} label={t('title.other_settings')} />
                        </Tabs>
                    </AppBar>
                    <TabPanel className={classes.tabPanel} value={value} index={0}>
                        <Grid container spacing={3}>
                            {
                                !isAdmin(appValues.user) &&
                                <Grid item lg={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <DeliveryAddressForm />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InvoiceAddressForm />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <IbansTable
                                                user={appValues.user}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item lg={4} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} >
                                        <UserLogo user={appValues.user} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordForm />
                                    </Grid>
                                    {
                                        !appValues.user.roles.includes('ROLE_ADMIN') &&
                                        <Grid item xs={12}>
                                            <NotificationEmailsForm />
                                        </Grid>
                                    }
                                    {
                                        hasService(appValues.user) &&
                                        <Grid item xs={12}>
                                            <ShipperCredentialsForm user={appValues.user} />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={value} index={1}>
                        <EmailTemplateSelector user={appValues.user} />
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={value} index={2}>
                        <OtherSettingsForm user={appValues.user}/>
                    </TabPanel>
                </div>
            </Container>
        </Page>
    );
}