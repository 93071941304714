import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { formatErrors, getShipperLogoPath } from 'helpers';
import Page from 'components/Page';
import { useLocation, useNavigate } from 'react-router-dom';
import AppContext from 'context/AppContext';
import ProgressButton from 'components/ProgressButton';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';

const ClaimAssistant = ({ className, ...rest }) => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const { appValues } = useContext(AppContext);
    const [values, setValues] = useState({
        insurance_currency: 'EUR'
    });
    const secureFetch = useContext(SecureFetchContext);
    let navigate = useNavigate();
    const [alert, setAlert] = React.useState('');

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const createClaimAssistant = () => {
        values.disable_default_services = true;
        values.claim_assistant = true;
        values.source = 'WEB'.toUpperCase(); // must be uppercase
        let promise = secureFetch(getApiRoute('package_new', [state.id]), values, 'POST', false);
        promise.then(json => {
            navigate('/app/package');
        }).catch((err) => {
            let output = formatErrors(err, t);
            setAlert(output);
        });
        return promise;
    }

    return (
        <Page
            title={t('title.post_claim_assistant')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    <img src={getShipperLogoPath(state.shortcut)} alt={state.shortcut} height={30} />
                    &nbsp;&nbsp;
                    {t('title.post_claim_assistant')}
                </Typography>
                <Card>
                    <CardHeader />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Alert variant="filled" severity="info">
                                    {t('messages.claim_assistant_information')}
                                </Alert>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} >
                                <TextField
                                    fullWidth
                                    label={t('label.reference_number')}
                                    name={"reference_number"}
                                    onChange={handleChange}
                                    value={values["reference_number"] || ''}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} >
                                <TextField
                                    fullWidth
                                    label={t('label.insurance')}
                                    name={"insurance"}
                                    onChange={handleChange}
                                    value={values["insurance"] || ''}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} >
                                <Autocomplete
                                    options={appValues.currencies.filter(v => ['EUR', 'CZK', 'HUF', 'RON'].includes(v.code))}
                                    value={appValues.currencies.find(currency => currency.code === values['insurance_currency']) || null}
                                    onChange={(event, currency) => currency ? setValues({ ...values, 'insurance_currency': currency.code }) : null}
                                    getOptionLabel={(option) => option.code}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={t('label.insurance_currency')}
                                        required
                                    />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} >
                                <TextField
                                    fullWidth
                                    label={t('label.sender_email')}
                                    name={"sender_email"}
                                    onChange={handleChange}
                                    value={values["sender_email"] || ''}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} >
                                <TextField
                                    fullWidth
                                    label={t('label.sender_phone')}
                                    name={"sender_phone"}
                                    onChange={handleChange}
                                    value={values["sender_phone"] || ''}
                                    required
                                />
                            </Grid>
                            {
                                alert === '' ? null :
                                <Grid item xs={12}>
                                    <Alert variant="filled" severity="error">
                                        {alert}
                                    </Alert>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <ProgressButton
                                    type="submit"
                                    name={t('button.claim_assistant')}
                                    color="primary"
                                    variant="contained"
                                    onClick={createClaimAssistant}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
};

ClaimAssistant.propTypes = {
    className: PropTypes.string,
    states: PropTypes.array
};

export default ClaimAssistant;
