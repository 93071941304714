import React, {useState, useEffect, useContext} from 'react';
import {
    Card,
    CardHeader,
    Divider,
    CardContent,
    TextField,
    Button,
    Grid,
    InputAdornment,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';

export default function CalculatedPrices({ className, prices, packageId, shipperId, ...rest }) {
    const { t } = useTranslation();
    const [values, setValues] = useState({});
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [ creditLogValues, setCreditLogValues ] = useState({});
    const [ services, setServices ] = useState([]);
    const shipper = appValues.shippers.find(s => s.id === shipperId);

    const formatPrice = (price, vat = false) => {
        if (vat) {
            price = price * 1.2;
        }
        return parseFloat(price).toFixed(2) + ' €';
    };

    useEffect(() => {
        if (packageId) {
            secureFetch(getApiRoute(appValues.user.roles.includes('ROLE_USER') ? 'package_prices' : 'admin_package_prices', [packageId])).then(json => {
                setValues(json);
            });
        }
    }, [secureFetch, packageId, appValues.user.roles]);

    useEffect(() => {
        if (!services.length) {
            secureFetch(getApiRoute('services')).then(json => {
                setServices(json);
            });
        }
    }, [secureFetch, services]);

    useEffect(() => {
        setValues(prices);
    }, [prices]);

    if (!values || Object.keys(values).length === 0) {
        return (null);
    }

    const getServiceNameByCode = (code) => {
        let service = services.find(s => s.code === code && s.shipper === shipper.shortcut);
        return service ? service.name : code;
    }

    const onAddCreditLog = (e) => {
        
        if (!creditLogValues.message || !creditLogValues.sale || !creditLogValues.purchase) {
            return;
        }

        e.preventDefault();
        let btn = e.target.closest('button');
        if (btn.name === 'return') {
            creditLogValues.sale *= -1;
            creditLogValues.purchase *= -1;
        }

        secureFetch( getApiRoute('admin_package', ['creditlog', packageId]), creditLogValues, 'POST' ).then(json => {
            window.location.reload();
        });
    }

    const onCreditLogValuesChange = (e) => {
        setCreditLogValues({...creditLogValues, [e.target.name]: e.target.value});
    }

    return (
        <Card>
            <CardHeader
                title={t('title.price')}
                className={className}
            />
            <Divider />
            <CardContent>
                <TableContainer>
                    <Table aria-label="a dense table" size="small" className={'table'} style={{overflow: 'hidden'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell rowSpan={2} >{t('label.service')}</TableCell>
                                <TableCell colSpan={2}>{t('label.price')}</TableCell>
                                {
                                    !values.purchase ? null :
                                    <TableCell colSpan={2}>{t('label.purchase')}</TableCell>
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">{t('label.price')}</TableCell>
                                <TableCell align="right">{t('label.price_vat')}</TableCell>
                                {
                                    !values.purchase ? null :
                                        <React.Fragment>
                                            <TableCell align="right">{t('label.price')}</TableCell>
                                            <TableCell align="right">{t('label.price_vat')}</TableCell>
                                        </React.Fragment>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" variant="head">{t('label.delivery_base_price')}</TableCell>
                                <TableCell align="right">{formatPrice(values.sale.shipping)}</TableCell>
                                <TableCell align="right">{formatPrice(values.sale.shipping, true)}</TableCell>
                                {
                                    !values.purchase ? null :
                                        <React.Fragment>
                                            <TableCell align="right">{formatPrice(values.purchase.shipping)}</TableCell>
                                            <TableCell align="right">{formatPrice(values.purchase.shipping, true)}</TableCell>
                                        </React.Fragment>
                                }
                            </TableRow>
                            {
                                values.sale.fuel > 0 &&
                                <TableRow>
                                    <TableCell component="th" scope="row" variant="head">{t('label.delivery_fuel')}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.fuel)}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.fuel, true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(values.purchase.fuel)}</TableCell>
                                                <TableCell align="right">{formatPrice(values.purchase.fuel, true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            }
                            {
                                values.sale.toll > 0 &&
                                <TableRow>
                                    <TableCell component="th" scope="row" variant="head">{t('label.delivery_toll')}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.toll)}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.toll, true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(values.purchase.toll)}</TableCell>
                                                <TableCell align="right">{formatPrice(values.purchase.toll, true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            }
                            {
                                values.sale.fuel > 0 && values.sale.toll > 0 &&
                                <TableRow>
                                    <TableCell component="th" scope="row" variant="head">{t('label.delivery_total')}</TableCell>
                                    <TableCell align="right">{formatPrice(+values.sale.shipping+ + values.sale.fuel + + values.sale.toll)}</TableCell>
                                    <TableCell align="right">{formatPrice(+values.sale.shipping + + values.sale.fuel + + values.sale.toll, true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(+values.purchase.shipping+ + values.purchase.fuel + + values.purchase.toll)}</TableCell>
                                                <TableCell align="right">{formatPrice(+values.purchase.shipping+ + values.purchase.fuel + + values.purchase.toll, true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            }
                            {Object.keys(values.sale.services).map((key, index) => (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row" variant="head">{getServiceNameByCode(key)}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.services[key])}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.services[key], true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(values.purchase.services[key])}</TableCell>
                                                <TableCell align="right">{formatPrice(values.purchase.services[key], true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            ))}
                            {
                                values.sale.cod && parseFloat(values.sale.cod) > 0 &&
                                    <TableRow>
                                        <TableCell component="th" scope="row" variant="head">{t('label.cod')}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.cod)}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.cod, true)}</TableCell>
                                        {
                                            !values.purchase ? null :
                                                <React.Fragment>
                                                    <TableCell align="right">{formatPrice(values.purchase.cod)}</TableCell>
                                                    <TableCell align="right">{formatPrice(values.purchase.cod, true)}</TableCell>
                                                </React.Fragment>
                                        }
                                    </TableRow>
                            }
                            {
                                values.sale.cod && parseFloat(values.sale.cod_card) > 0 &&
                                    <TableRow>
                                        <TableCell component="th" scope="row" variant="head">{t('label.cod_card')}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.cod_card)}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.cod_card, true)}</TableCell>
                                        {
                                            !values.purchase ? null :
                                                <React.Fragment>
                                                    <TableCell align="right">{formatPrice(values.purchase.cod_card)}</TableCell>
                                                    <TableCell align="right">{formatPrice(values.purchase.cod_card, true)}</TableCell>
                                                </React.Fragment>
                                        }
                                    </TableRow>
                            }
                            {
                                values.sale.insurance && parseFloat(values.sale.insurance) > 0 &&
                                    <TableRow>
                                        <TableCell component="th" scope="row" variant="head">{t('label.insurance')}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.insurance)}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.insurance, true)}</TableCell>
                                        {
                                            !values.purchase ? null :
                                                <React.Fragment>
                                                    <TableCell align="right">{formatPrice(values.purchase.insurance)}</TableCell>
                                                    <TableCell align="right">{formatPrice(values.purchase.insurance, true)}</TableCell>
                                                </React.Fragment>
                                        }
                                    </TableRow>
                            }
                            {values.sale.other && Object.keys(values.sale.other).map((key, index) => (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row" variant="head">{key}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.other[key])}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.other[key], true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(values.purchase.other[key])}</TableCell>
                                                <TableCell align="right">{formatPrice(values.purchase.other[key], true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            ))}
                            {Object.keys(values.sale).map((key, index) => key.includes('returned') ? (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row" variant="head">{t('label.returned_calculation')} {key.replace('returned', '')}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale[key])}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale[key], true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(values.purchase[key])}</TableCell>
                                                <TableCell align="right">{formatPrice(values.purchase[key], true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            ) : null)}
                            <TableRow>
                                <TableCell component="th" scope="row" variant="head">{t('label.total')}</TableCell>
                                <TableCell align="right">{formatPrice(values.sale.total >= 0.01 ? values.sale.total : 0)}</TableCell>
                                <TableCell align="right">{formatPrice(values.sale.total >= 0.01 ? values.sale.total : 0, true)}</TableCell>
                                {
                                    !values.purchase ? null :
                                        <React.Fragment>
                                            <TableCell align="right">{formatPrice(values.purchase.total >= 0.01 ? values.purchase.total : 0)}</TableCell>
                                            <TableCell align="right">{formatPrice(values.purchase.total >= 0.01 ? values.purchase.total : 0, true)}</TableCell>
                                        </React.Fragment>
                                }
                            </TableRow>
                            {
                                packageId && appValues.user.roles.includes('ROLE_ADMIN') &&
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label={t('label.message')}
                                                    inputProps={{ form: 'createLogPrice'}}
                                                    value={creditLogValues['message'] || ''}
                                                    name="message"
                                                    onChange={onCreditLogValuesChange}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label={t('label.sale_no_vat')}
                                                    inputProps={{ form: 'createLogPrice', step: "0.001" }}
                                                    value={creditLogValues['sale'] || ''}
                                                    name="sale"
                                                    onChange={onCreditLogValuesChange}
                                                    required
                                                    type="number"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label={t('label.purchase_no_vat')}
                                                    inputProps={{ form: 'createLogPrice', step: "0.001" }}
                                                    value={creditLogValues['purchase'] || ''}
                                                    name="purchase"
                                                    onChange={onCreditLogValuesChange}
                                                    required
                                                    type="number"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} justify="flex-end">
                                            <Grid item xs={'auto'}>
                                                <form id="createLogPrice">
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary"
                                                        name="return"
                                                        type="submit"
                                                        onClick={onAddCreditLog}
                                                    >
                                                        {t('button.return')}
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        name="add"
                                                        type="submit"
                                                        onClick={onAddCreditLog}
                                                    >
                                                        {t('button.addcalculate')}
                                                    </Button>
                                                </form>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
}