import React, { useContext } from 'react';
import {
    Grid,
    Container,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Page from 'components/Page';
import PartnerProfitStatisticsTable from './PartnerProfitStatisticsTable';
import PartnerProfitByMonthStatistics from './PartnerProfitByMonthStatistics';
import TableFilter from 'components/TableFilter';
import AppContext from 'context/AppContext';
import { createUrlQuery } from 'helpers';

export default function PartnerProfitStatistics({ ...rest }) {
    const { t } = useTranslation();
    const [filter, setFilter] = React.useState({});
    const [params, setParams] = React.useState('');
    const { appValues } = useContext(AppContext);

    const setFilterValue = (name, value) => {
        let tmpFilter = { ...filter, [name]: value };
        setParams(createUrlQuery(tmpFilter));
        setFilter({ ...filter, [name]: value });
    }

    return (
        <Page
            title={t('navbar.partner_profit')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.partner_profit')}
                </Typography>
                <Grid container spacing={3} alignItems="center" >
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <TableFilter
                                    filter={filter}
                                    enabled={{
                                        created_at: true,
                                        partner: appValues.user.roles.includes('ROLE_ADMIN'),
                                    }}
                                    setFilterValue={setFilterValue}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <PartnerProfitStatisticsTable params={params} />
                    </Grid>
                    <Grid item xs={12}>
                        <PartnerProfitByMonthStatistics params={params} />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}