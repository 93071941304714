import React, {forwardRef, useContext} from 'react';
import AppContext from "../context/AppContext";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import {getAllPickups} from "../helpers";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const PickupAddressSelector = forwardRef(({children, asSelect, onSelect, pickup, ...rest}, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { appValues } = useContext(AppContext);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getProps = (child) => {
        let pProps = { ref: ref, ...rest};
        pProps.onClick = (event) => setAnchorEl(event.currentTarget) ;
        return pProps;
    }

    const childrenWithProps = React.Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a typescript error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, getProps(child));
        }
        return child;
    });

    return (
        <React.Fragment>
            {asSelect ?
                <FormControl fullWidth>
                    <Select
                        value={pickup.id}
                        name="pickup"
                        onChange={(event) => {
                            const pickup = getAllPickups(appValues.user).find(addr => addr.id === event.target.value);
                            onSelect(pickup);
                        }}
                    >
                        {getAllPickups(appValues.user).map((option) => (
                            <MenuItem
                                key={option.id}
                                value={option.id}
                            >
                                {option.address.city}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                : <div>
                    {[childrenWithProps].map(el => el)}
                    <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                        transformOrigin={{
                            horizontal: 'center',
                            vertical: 'top',
                        }}
                    >
                        {
                            getAllPickups(appValues.user).map((addr, idx) => (
                                <MenuItem onClick={() => {
                                    onSelect(addr);
                                    handleClose();
                                }} key={idx}>{addr.address.city}
                                </MenuItem>
                            ))
                        }
                    </Menu>
                </div>
            }
        </React.Fragment>
    );
});

PickupAddressSelector.propTypes = {
    asSelect: PropTypes.bool
};

export default PickupAddressSelector;