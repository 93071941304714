import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import SecureFetchContext from 'context/SecureFetchContext';
import Alert from '@material-ui/lab/Alert';
import AppContext from 'context/AppContext';
import getApiRoute from 'routes/apiRoutes';
import Snackbar from '@material-ui/core/Snackbar';
import { useTranslation } from "react-i18next";
import { formatErrors } from 'helpers';
import UserUpdatableContext, {DefaultUserUpdatable} from "../../context/UserUpdatableContext";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        backgroundImage: 'url(/images/map.png)',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

const DashboardLayout = () => {
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const { appValues, setAppValues } = useContext(AppContext);
    const { userUpdatable, setUserUpdatable } = useContext(UserUpdatableContext);
    const { t } = useTranslation();

    const secureFetchFun = useCallback((url, data, method = 'GET', handleError = true, isJson = true, handleImpersonate = true, reject500 = false) => {

        const refreshToken = async () => {
            const response = await fetch(getApiRoute('refresh_token'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refresh_token: appValues.refresh_token
                }),
            });

            if (response.ok && response.status === 200) {
                let tokenData = await response.json();
                return tokenData;
            }
            else {
                setAppValues({ user: null });
                setUserUpdatable(DefaultUserUpdatable);
            }
            return null;
        };

        return new Promise((resolve, reject) => {
            if (data && method === 'GET') {
                data = Object.entries(data)
                    .map(pair => pair.map(encodeURIComponent).join('='))
                    .join('&');
            }

            let headers = {
                'Authorization': 'BEARER ' + appValues.token
            };

            if (isJson) {
                headers['Content-Type'] = 'application/json ';
            }

            if (appValues.isImpersonate && handleImpersonate) {
                headers['x-switch-user'] = appValues.user.username;
            }
            
            setAlertMessage('');

            if (process.env.REACT_APP_ALLOW_XDEBUG){
                url += url.includes('?') ? (url.endsWith('&')? '' : '&') : '?';
                url += 'XDEBUG_SESSION_START=PHPSTORM';
            }

            fetch(url, {
                method: method + (data && method === 'GET' ? data : ''),
                headers: headers,
                body: (data && ['PUT', 'POST'].includes(method) ? (isJson ? JSON.stringify(data) : data) : null),
            }).then(async response => {
                // response only can be ok in range of 2XX
                if (response.ok) {
                    // you can call response.json() here too if you want to return json
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        resolve(response.json());
                    } else {
                        resolve([await response.blob(), response]);
                    }
                } else {
                    //handle errors in the way you want to
                    switch (response.status) {
                        case 401:
                            const tokenData = await refreshToken();
                            if (tokenData) {
                                appValues.token = tokenData.token;
                                appValues.refresh_token = tokenData.refresh_token;
                                setAppValues({ ...appValues });
                                secureFetchFun(url, data, method, handleError).then(json => resolve(json)).catch((json) => reject(json));
                            }
                            break;

                        case 500:
                            setAlertMessage('500: Na serveri sa niečo pokazilo.');
                            if(reject500){
                                reject({status: 500, message: '500: Na serveri sa niečo pokazilo.'});
                            }
                            break;

                        case 503:
                            let data = await response.text();
                            setAlertMessage(<div className="content" dangerouslySetInnerHTML={{ __html: data }}></div>);
                            break;

                        default:
                            let json = await response.json();
                            if (json.error) {
                                setAlertMessage(json.error);
                                return;
                            }
                            if (handleError) {
                                if (typeof json === 'string') {
                                    setAlertMessage(json);
                                } else {
                                    let output = formatErrors(json, t);
                                    setAlertMessage(output);
                                }
                            } else {
                                reject(json);
                            }
                            break;
                    }
                }
            }).catch(error => {
                //it will be invoked mostly for network errors
                //do what ever you want to do with error here
                setAlertMessage(String(error));
            });
        });
    }, [appValues, setAppValues, userUpdatable, setUserUpdatable])

    const [secureFetch, setSecureFetch] = useState(() => secureFetchFun);

    useEffect(() => {
        setSecureFetch(() => secureFetchFun);
    }, [secureFetchFun]);

    useEffect(() => {
        const fetchAll = async () => {
            //console.log('I AM BEFORE STATES, SHIPPERS, CURRENCIES AND MESSAGE FETCHING', appValues);
            if (!appValues.states.length && !appValues.shippers.length && !appValues.currencies.length && !appValues.messages.length) {
                //console.log('I AM IN!!');
                let json = {};
                json.states = await secureFetch(getApiRoute('states'));
                json.shippers = await secureFetch(getApiRoute('shippers'));
                json.currencies = await secureFetch(getApiRoute('currencies'));
                json.messages = await secureFetch(getApiRoute('message_unread'));
                //console.log('AFTER FETCH', json);
                setAppValues({ ...appValues, ...json });
            }
        }
        fetchAll();
    }, [secureFetch, appValues, setAppValues]);

    return (
        <div className={classes.root}>
            <SecureFetchContext.Provider value={secureFetch}>
                <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
                <NavBar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                />
                <div className={classes.wrapper}>
                    <div className={classes.contentContainer}>
                        <div className={classes.content}>
                            <Snackbar open={alertMessage !== ''} >
                                <Alert onClose={() => setAlertMessage('')} variant="filled" severity="error">
                                    {alertMessage}
                                </Alert>
                            </Snackbar>
                            <Outlet secureFetch={secureFetch} />
                        </div>
                    </div>
                </div>
            </SecureFetchContext.Provider>
        </div>
    );
};

export default DashboardLayout;
