import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { Bar } from 'react-chartjs-2';
import AppContext from 'context/AppContext';

const PackageCountByMonthStatistics = ({ params, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const { appValues } = useContext(AppContext);

    useEffect(() => {
        let routeName = 'statistics';
        routeName = appValues.user.roles.includes('ROLE_ADMIN') ? 'admin_statistics' : routeName;
        routeName = appValues.user.roles.includes('ROLE_PARTNER') ? 'partner_statistics' : routeName;
        secureFetch(getApiRoute(routeName, ['monthpackagecount']) + '?' + params).then(json => {
            let tmpData = {
                domestic_count: [],
                foreign_count: [],
                parcelshop_local_count: [],
                parcelshop_international_count: [],
            };
            let tmpLabels = [];
            for (const obj of json) { //sort by year, month
                tmpLabels.push(obj.month);
                tmpData.domestic_count.push(obj.domestic_count);
                tmpData.foreign_count.push(obj.foreign_count);
                tmpData.parcelshop_local_count.push(obj.parcelshop_local_count);
                tmpData.parcelshop_international_count.push(obj.parcelshop_international_count);
            }

            setData(tmpData);
            setLabels(tmpLabels);
        });
    }, [secureFetch, params, appValues.user.roles]);
  
    const chartData = {
        labels: labels.map((val) => {
            let date = new Date(val);
            return (date.getMonth() + 1) + '/' + date.getFullYear();
        }),
        datasets: data.length === 0 ? null : [
            {
                label: t('label.domestic_packages'),
                data: data.domestic_count,
                backgroundColor: 'rgb(32, 87, 144)',
            },
            {
                label: t('label.foreign_packages'),
                data: data.foreign_count,
                backgroundColor: 'rgb(88, 168, 60)',
            },
            {
                label: t('label.parcelshop_local'),
                data: data.parcelshop_local_count,
                backgroundColor: 'rgb(230, 96, 34)',
            },
            {
                label: t('label.parcelshop_international'),
                data: data.parcelshop_international_count,
                backgroundColor: 'rgb(240, 252, 47)',
            },
        ],
    }

    const options = {
        scales: {
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                },
            ],
        },
        tooltips: {
            // enabled: false,
            callbacks: {
                label: (tooltipItem, data) => {
                    const name = data.datasets[ tooltipItem.datasetIndex ].label;
                    const value = data.datasets[ tooltipItem.datasetIndex ].data[ tooltipItem.index ];
                    let total = 0;
                    for (const v of data.datasets) {
                        total += v.data[ tooltipItem.index ];
                    }
                    return `${name}: ${value} ks / ${total} ks`;
                }
            }
        }
    }

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <Typography gutterBottom variant="h4" component="h2" align="center">
                    {t('title.count_of_packages_month')}
                </Typography>
                <Bar height={80} data={chartData} options={options} />
            </CardContent>
        </Card>
    );
}

export default PackageCountByMonthStatistics;