import React, {useState, useEffect, useContext} from 'react';
import {
    Card,
    CardHeader,
    Divider,
    CardContent,
    TextField,
    Button,
    Grid,
    InputAdornment,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';

export default function CalculatedPrices({ className, prices, packageId, shipperId, ...rest }) {
    const { t } = useTranslation();
    const [values, setValues] = useState({"sale":{"services":{"Notifikácia SMS":"-","Notifikácia EMAIL":"-"},"total":"-","shipping":"-","toll":"-","fuel":"-"}});
    const { appValues } = useContext(AppContext);
    const [ services, setServices ] = useState([]);
    const shipper = appValues.shippers.find(s => s.id === shipperId);

    const formatPrice = (price, vat = false) => {
        return '- €';
    };

    if (!values || Object.keys(values).length === 0) {
        return (null);
    }

    const getServiceNameByCode = (code) => {
        let service = services.find(s => s.code === code && s.shipper === shipper.shortcut);
        return service ? service.name : code;
    }

    return (
        <Card>
            <CardHeader
                title={t('title.price')}
                className={className}
            />
            <Divider />
            <CardContent>
                <TableContainer>
                    <Table aria-label="a dense table" size="small" className={'table'} style={{overflow: 'hidden', opacity: '0.4'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell rowSpan={2} >{t('label.service')}</TableCell>
                                <TableCell colSpan={2}>{t('label.price')}</TableCell>
                                {
                                    !values.purchase ? null :
                                    <TableCell colSpan={2}>{t('label.purchase')}</TableCell>
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">{t('label.price')}</TableCell>
                                <TableCell align="right">{t('label.price_vat')}</TableCell>
                                {
                                    !values.purchase ? null :
                                        <React.Fragment>
                                            <TableCell align="right">{t('label.price')}</TableCell>
                                            <TableCell align="right">{t('label.price_vat')}</TableCell>
                                        </React.Fragment>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" variant="head">{t('label.delivery_base_price')}</TableCell>
                                <TableCell align="right">{formatPrice(values.sale.shipping)}</TableCell>
                                <TableCell align="right">{formatPrice(values.sale.shipping, true)}</TableCell>
                                {
                                    !values.purchase ? null :
                                        <React.Fragment>
                                            <TableCell align="right">{formatPrice(values.purchase.shipping)}</TableCell>
                                            <TableCell align="right">{formatPrice(values.purchase.shipping, true)}</TableCell>
                                        </React.Fragment>
                                }
                            </TableRow>
                            {
                                values.sale.fuel &&
                                <TableRow>
                                    <TableCell component="th" scope="row" variant="head">{t('label.delivery_fuel')}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.fuel)}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.fuel, true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(values.purchase.fuel)}</TableCell>
                                                <TableCell align="right">{formatPrice(values.purchase.fuel, true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            }
                            {
                                values.sale.toll &&
                                <TableRow>
                                    <TableCell component="th" scope="row" variant="head">{t('label.delivery_toll')}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.toll)}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.toll, true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(values.purchase.toll)}</TableCell>
                                                <TableCell align="right">{formatPrice(values.purchase.toll, true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            }
                            {
                                values.sale.fuel && values.sale.toll &&
                                <TableRow>
                                    <TableCell component="th" scope="row" variant="head">{t('label.delivery_total')}</TableCell>
                                    <TableCell align="right">{formatPrice(+values.sale.shipping+ + values.sale.fuel + + values.sale.toll)}</TableCell>
                                    <TableCell align="right">{formatPrice(+values.sale.shipping + + values.sale.fuel + + values.sale.toll, true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(+values.purchase.shipping+ + values.purchase.fuel + + values.purchase.toll)}</TableCell>
                                                <TableCell align="right">{formatPrice(+values.purchase.shipping+ + values.purchase.fuel + + values.purchase.toll, true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            }
                            {Object.keys(values.sale.services).map((key, index) => (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row" variant="head">{getServiceNameByCode(key)}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.services[key])}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.services[key], true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(values.purchase.services[key])}</TableCell>
                                                <TableCell align="right">{formatPrice(values.purchase.services[key], true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            ))}
                            {
                                values.sale.cod && parseFloat(values.sale.cod) > 0 &&
                                    <TableRow>
                                        <TableCell component="th" scope="row" variant="head">{t('label.cod')}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.cod)}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.cod, true)}</TableCell>
                                        {
                                            !values.purchase ? null :
                                                <React.Fragment>
                                                    <TableCell align="right">{formatPrice(values.purchase.cod)}</TableCell>
                                                    <TableCell align="right">{formatPrice(values.purchase.cod, true)}</TableCell>
                                                </React.Fragment>
                                        }
                                    </TableRow>
                            }
                            {
                                values.sale.cod && parseFloat(values.sale.cod_card) > 0 &&
                                    <TableRow>
                                        <TableCell component="th" scope="row" variant="head">{t('label.cod_card')}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.cod_card)}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.cod_card, true)}</TableCell>
                                        {
                                            !values.purchase ? null :
                                                <React.Fragment>
                                                    <TableCell align="right">{formatPrice(values.purchase.cod_card)}</TableCell>
                                                    <TableCell align="right">{formatPrice(values.purchase.cod_card, true)}</TableCell>
                                                </React.Fragment>
                                        }
                                    </TableRow>
                            }
                            {
                                values.sale.insurance && parseFloat(values.sale.insurance) > 0 &&
                                    <TableRow>
                                        <TableCell component="th" scope="row" variant="head">{t('label.insurance')}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.insurance)}</TableCell>
                                        <TableCell align="right">{formatPrice(values.sale.insurance, true)}</TableCell>
                                        {
                                            !values.purchase ? null :
                                                <React.Fragment>
                                                    <TableCell align="right">{formatPrice(values.purchase.insurance)}</TableCell>
                                                    <TableCell align="right">{formatPrice(values.purchase.insurance, true)}</TableCell>
                                                </React.Fragment>
                                        }
                                    </TableRow>
                            }
                            {values.sale.other && Object.keys(values.sale.other).map((key, index) => (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row" variant="head">{key}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.other[key])}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale.other[key], true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(values.purchase.other[key])}</TableCell>
                                                <TableCell align="right">{formatPrice(values.purchase.other[key], true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            ))}
                            {Object.keys(values.sale).map((key, index) => key.includes('returned') ? (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row" variant="head">{t('label.returned_calculation')} {key.replace('returned', '')}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale[key])}</TableCell>
                                    <TableCell align="right">{formatPrice(values.sale[key], true)}</TableCell>
                                    {
                                        !values.purchase ? null :
                                            <React.Fragment>
                                                <TableCell align="right">{formatPrice(values.purchase[key])}</TableCell>
                                                <TableCell align="right">{formatPrice(values.purchase[key], true)}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>
                            ) : null)}
                            <TableRow>
                                <TableCell component="th" scope="row" variant="head">{t('label.total')}</TableCell>
                                <TableCell align="right">{formatPrice(values.sale.total >= 0.01 ? values.sale.total : 0)}</TableCell>
                                <TableCell align="right">{formatPrice(values.sale.total >= 0.01 ? values.sale.total : 0, true)}</TableCell>
                                {
                                    !values.purchase ? null :
                                        <React.Fragment>
                                            <TableCell align="right">{formatPrice(values.purchase.total >= 0.01 ? values.purchase.total : 0)}</TableCell>
                                            <TableCell align="right">{formatPrice(values.purchase.total >= 0.01 ? values.purchase.total : 0, true)}</TableCell>
                                        </React.Fragment>
                                }
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
}