import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    Grid,
    Checkbox,
    FormControlLabel,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { useNavigate } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AppContext from 'context/AppContext';

const useStyles = makeStyles(() => ({
    root: {}
}));

const NewAddressForm = ({ className, states, edit, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [values, setValues] = useState({
        state: appValues.states.find(state => state.code === 'SK').id,
        user: appValues.user.id
    });
    let navigate = useNavigate();

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch(getApiRoute('address_new'), values, 'POST').then(json => {
            navigate('/app/address');
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.name')}
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name || ''}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.company')}
                                                name="company"
                                                onChange={handleChange}
                                                value={values.company || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.email')}
                                                name="email"
                                                onChange={handleChange}
                                                value={values.email || ''}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.phone')}
                                                name="phone"
                                                onChange={handleChange}
                                                value={values.phone || ''}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={7} xs={8}>
                                            <TextField
                                                fullWidth
                                                label={t('label.street')}
                                                name="street"
                                                onChange={handleChange}
                                                value={values.street || ''}
                                                required
                                            />
                                        </Grid>
                                        <Grid item lg={5} xs={4}>
                                            <TextField
                                                fullWidth
                                                label={t('label.house_number')}
                                                name="house_number"
                                                onChange={handleChange}
                                                value={values.house_number || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.city')}
                                                name="city"
                                                onChange={handleChange}
                                                value={values.city || ''}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('label.zip')}
                                                name="zip"
                                                onChange={handleChange}
                                                value={values.zip || ''}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl className={classes.formControl} fullWidth>
                                                <InputLabel id="deliverystates">{t('label.state')}</InputLabel>
                                                <Select
                                                    labelId="deliverystates"
                                                    id="demo-simple-select"
                                                    value={ values.state || '' }
                                                    name="state"
                                                    onChange={handleChange}
                                                >
                                                    {appValues.states.map((option) => (
                                                        <MenuItem
                                                            key={option.id}
                                                            value={option.id}
                                                        >
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.create')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
};

NewAddressForm.propTypes = {
    className: PropTypes.string
};

export default NewAddressForm;
