import React, { useEffect, useContext, useCallback, useState } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    makeStyles,
    Box
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import getApiRoute from 'routes/apiRoutes';
import SecureFetchContext from 'context/SecureFetchContext';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ShipperCreateDialog from './ShipperCreateDialog';
import DeleteIconDialog from 'components/DeleteIconDialog';
import PricesTabs from 'views/PriceList/PriceListEditView/PricesTabs';
import TabPanel from 'components/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ShipperOthersForm from './ShipperOthersForm';
import EditPriceListForm from './EditPriceListForm';
import GeneratePricelist from './GeneratePricelist';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    tabPanel: {
        '& > .MuiBox-root': {
            paddingTop: 0
        }
    }
}));

export default function Shipper({ user, ...rest }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [priceLists, setPriceLists] = React.useState([]);
    const [selectedShipper, setSelectedShipper] = React.useState(0);
    const [activePriceList, setActivePriceList] = useState('sale_price_list');

    const loadPriceLists = useCallback(() => {
        if (user.id) {
            secureFetch(getApiRoute('admin_user_pricelists', [user.id])).then(json => {
                setPriceLists(json);
                if ( json.length ) {
                    setSelectedShipper(json[0]);
                }
            });
        }
    }, [secureFetch, user])

    const handlePriceListChange = (event, newValue) => {
        setActivePriceList(newValue);
    };

    useEffect(() => {
        loadPriceLists();
    }, [user, secureFetch, loadPriceLists]);

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel id="shipper">{t('label.shipper')}</InputLabel>
                                <Select
                                    labelId="shipper"
                                    value={selectedShipper ? priceLists.indexOf(selectedShipper) : ''}
                                    onChange={(event) => setSelectedShipper(priceLists[event.target.value])}
                                    name="shipper"
                                >
                                    {priceLists.map((option, index) => (
                                        <MenuItem
                                            key={option.shipper_id}
                                            value={index}
                                        >
                                            {option.shortcut}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            priceLists.length > 0 && selectedShipper &&
                                <Grid item className={'p0'}>
                                    <DeleteIconDialog
                                        text={
                                            <React.Fragment>
                                                {t('messages.really_delete')}
                                                <br />
                                                <pre>
                                                    {JSON.stringify(selectedShipper, null, 2)}
                                                </pre>
                                            </React.Fragment>
                                        }
                                        route={getApiRoute('admin_user_pricelists', [user.id, selectedShipper.id])}
                                        onDelete={loadPriceLists}
                                    />
                                </Grid>
                        }
                        <Grid item className={'p0'}>
                            <ShipperCreateDialog loadedPriceLists={priceLists} user={user} onCreate={loadPriceLists} />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneratePricelist pricelist={selectedShipper} />
                        </Grid>
                    </Grid>
                    {
                        priceLists.length > 0 && selectedShipper && <ShipperOthersForm user={user} selectedShipper={selectedShipper} />
                    }
                </CardContent>
            </Card>
            {
                priceLists.length > 0 && selectedShipper && selectedShipper.purchase_price_list_id &&
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={12}>
                            <Box p={2} />
                            <Typography variant="h2" gutterBottom >
                                {t('title.pricelists')}
                            </Typography>
                            <Box p={1} />
                            <AppBar className={classes.appBar} position="static">
                                <Tabs
                                    value={activePriceList}
                                    onChange={handlePriceListChange}
                                    aria-label="scrollable auto tabs"
                                    scrollButtons="auto"
                                    variant="scrollable"
                                >
                                    <Tab 
                                        value="sale_price_list" 
                                        label={
                                            <div>
                                                <Typography variant="button">
                                                    {t('label.sale_price_list')}
                                                </Typography>
                                                <br/>
                                                <Typography variant="caption">
                                                    {selectedShipper.sale_price_list_name}
                                                </Typography>
                                            </div>
                                        }
                                    />
                                    <Tab 
                                        value="purchase_price_list"
                                        label={
                                            <div>
                                                <Typography variant="button">
                                                    {t('label.purchase_price_list')}
                                                </Typography>
                                                <br />
                                                <Typography variant="caption">
                                                    {selectedShipper.purchase_price_list_name}
                                                </Typography>
                                            </div>
                                        }
                                    />
                                </Tabs>
                            </AppBar>
                            <TabPanel className={classes.tabPanel} value={activePriceList} index={"sale_price_list"}>
                                <PricesTabs localNavigation={true} pricelist={{ id: selectedShipper.sale_price_list_id, shipper: selectedShipper }} tab={'shipping_price'} />
                            </TabPanel>
                            <TabPanel className={classes.tabPanel} value={activePriceList} index={"purchase_price_list"}>
                                <PricesTabs localNavigation={true} pricelist={{ id: selectedShipper.purchase_price_list_id, shipper: selectedShipper }} tab={'shipping_price'} />
                            </TabPanel>
                        </Grid>
                    </Grid>
            }
            {
                priceLists.length > 0 && selectedShipper && !selectedShipper.purchase_price_list_id &&
                <Grid container spacing={3} alignItems="center" >
                    <Grid item xs={12}>
                        <Box p={2} />
                        <Typography variant="h2" gutterBottom >
                            {t('title.pricelists')}
                        </Typography>
                        <Box p={1} />
                        <EditPriceListForm id={selectedShipper.sale_price_list_id} />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}