import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {Button, makeStyles, Tooltip, Typography, withStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    root_shaded: {
        opacity: 0.5,
        cursor: 'not-allowed'
    },
    tooltipButton: {
        display: 'block',
        visibility: 'hidden',
        width: '0',
        minWidth: '0',
        height: '0'
    }
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const Shader = forwardRef(({children, shaded = false, index, ...rest}, ref) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const getProps = (child) => {
        let pProps = { ref: ref, className: shaded ? classes.root_shaded : classes.root, ...rest};
        if(shaded){
            pProps.onClick = () => {};
            pProps.onMouseEnter = handleTooltipOpen
            pProps.onMouseLeave = handleTooltipClose
        }
        return pProps;
    }

    const childrenWithProps = React.Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a typescript error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, getProps(child));
        }
        return child;
    });

    const tooltip = <HtmlTooltip
        title={
            <React.Fragment>
                <Typography color="inherit">{t('messages.premium_only_title')}</Typography>
                {t('messages.premium_only_message') + ' '}<a href="www.neoship.sk/premium">www.neoship.sk/premium</a>{'.'}
            </React.Fragment>
        }
        PopperProps={{
            disablePortal: true,
        }}
        key={index+'tooltip'}
        onClose={handleTooltipClose}
        open={open}
        placement='top'
        disableFocusListener
        disableHoverListener
        disableTouchListener
    >
        <Button className={classes.tooltipButton}>HTML</Button>
    </HtmlTooltip>

    //return ([tooltip, childrenWithProps].map(el => el));
    return ([childrenWithProps].map(el => el));
});

Shader.propTypes = {
    children: PropTypes.node.isRequired,
    shaded: PropTypes.bool
};

export default Shader;
