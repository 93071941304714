import React, { useState, useContext } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Paper from '@material-ui/core/Paper';
import AppContext from 'context/AppContext';
import { useNavigate } from "react-router-dom";
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import UserUpdatableContext from "../../context/UserUpdatableContext";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '500px',
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(4),
    }
}));

const LoginForm = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appValues, setAppValues } = useContext(AppContext);
    const { userUpdatable, setUserUpdatable } = useContext(UserUpdatableContext);
    const [alertMessage, setAlertMessage] = useState('');
    const [values, setValues] = useState({
        username: '',
        password: '',
    });
    let navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        fetch( getApiRoute('login') , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then(response => response.json())
            .then(data => {
                if ( data.code === 401 ) {
                    setAlertMessage(data.message);
                }
                else{
                    setAlertMessage('');
                    let tmpAppValues = {...appValues};
                    tmpAppValues.token = data.token;
                    tmpAppValues.refresh_token = data.refresh_token;
                    tmpAppValues.openMessageDialog = true;
                    getUser(tmpAppValues);
                }
            })
            .catch((error) => {
                setAlertMessage( String(error) );
            });
    };

    const getUser = (pAppValues) => {
        fetch( getApiRoute('user') , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'BEARER ' + pAppValues.token
            },
        })
            .then(response => response.json())
            .then(data => {
                if ( data.code === 401 ) {
                    setAlertMessage(data.message);
                }
                else{
                    setAppValues({ 
                        ...pAppValues, 
                        user: data
                    });
                    setUserUpdatable({statistics: data.others, credit: data.credit, last_update: new Date()});
                    navigate('/app/dashboard', {state: {user: data}});
                }
            })
            .catch((error) => {
                setAlertMessage( String(error) );
            });
    }


    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    return (
        <form onSubmit={handleSubmit} className={classes.root}>
            <Paper elevation={15} className={classes.paper}>
                <Box mb={3}>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        {t('title.signin')}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                    >
                        {t('messages.signin_desc')}
                    </Typography>
                </Box>
                <TextField
                    fullWidth
                    label={t('label.username')}
                    margin="normal"
                    name="username"
                    value={values['username']}
                    onChange={handleChange}
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    label={t('label.password')}
                    margin="normal"
                    name="password"
                    type="password"
                    value={values['password']}
                    onChange={handleChange}
                    variant="outlined"
                />
                { 
                    alertMessage === '' ? null : 
                    <Box pt={1}>
                        <Alert variant="filled" severity="error">
                            {alertMessage}
                        </Alert>
                    </Box>
                }
                <Box my={2}>
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        {t('button.login')}
                    </Button>
                </Box>
            </Paper>
        </form>
    );
};

export default LoginForm;