import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    TextField,
    Grid,
    makeStyles, Typography, Checkbox, Chip
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {filterStates, getNeoshipServiceTypes} from "../../helpers";
import InputAdornment from "@material-ui/core/InputAdornment";
import AppContext from "../../context/AppContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControl from "@material-ui/core/FormControl";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
    root: {}
}));

const EditIbanForm = ({ className, iban, handleEditDialogOpenClose, load, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [states, setStates] = useState({states_to: filterStates(appValues.states, iban.state_to)});
    const [values, setValues] = useState({});
    const [alertMessage, setAlertMessage] = useState('');

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        if ( iban.iban ) {
            setValues({
                iban: iban.iban,
                states_to: iban.states_to,
                shipper: iban.shipper,
                user: iban.user_id,
            });
        }
    }, [iban]);

    const handleSubmit = (event) => {
        event.preventDefault();
        values.state_to = states.states_to.map(state => state.id);
        secureFetch(getApiRoute('admin_iban', [iban.id]), values, 'PUT').then(json => {
            handleEditDialogOpenClose();
            load();
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.edit_iban')}
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <InputLabel >{t('label.states_to')}</InputLabel>
                    <Autocomplete
                        multiple
                        options={appValues.states}
                        onChange={ (event, pStates) => setStates({ ...states, states_to: pStates }) }
                        disableCloseOnSelect
                        value={states.states_to}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.name}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label={t('label.states_to')} placeholder={t('label.state')} />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="shipper">{t('label.shipper')}</InputLabel>
                        <Select
                            labelId="shipper"
                            value={ values.shipper || '' }
                            name="shipper"
                            onChange={handleChange}
                            required
                        >
                            {appValues.shippers.map((option) => (
                                <MenuItem
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.shortcut}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('label.iban')}
                        name="iban"
                        onChange={handleChange}
                        value={values.iban || ''}
                        type="text"
                        required
                    />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Button color="secondary" variant="text" onClick={handleEditDialogOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.save')}
                </Button>
            </Box>

        </form>
    );
};

EditIbanForm.propTypes = {
    className: PropTypes.string
};

export default EditIbanForm;
