import React, { useEffect, useContext } from 'react';
import {
    Container,
    Typography,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Box,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import Papa from 'papaparse';
import SelectCsvFields from './SelectCsvFields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import PackagesStep from './PackagesStep';
import { useNavigate, useLocation } from "react-router-dom";
import { formatErrors, getShipperLogoPath } from 'helpers';
import ProgressButton from 'components/ProgressButton';
import AppContext from "../../../context/AppContext";

export default function ImportCsv() {
    const { t } = useTranslation();
    const [csv, setCsv] = React.useState(null);
    const [delimiter, setDelimiter] = React.useState("");
    const [quoteChar, setQuoteChar] = React.useState("\"");
    const [file, setFile] = React.useState(null);
    const [skip, setSkip] = React.useState(false);
    const [csvMap, setCsvMap] = React.useState({});
    const secureFetch = useContext(SecureFetchContext);
    const [alert, setAlert] = React.useState('');
    const [packages, setPackages] = React.useState([]);
    const { appValues } = useContext(AppContext);
    let navigate = useNavigate();
    const { state } = useLocation();

    const inputChange = (e) => {
        setAlert('');
        let files = e.target.files[0];

        let promise = secureFetch(getApiRoute('usersettings', ['byname', state.shipper.shortcut.toUpperCase() + '_CSV_FIELDS']));

        promise.then((json) => {
            if (json) {
                Papa.parse(files, {
                    header: true,
                    delimiter: json.value.delimiter ? json.value.delimiter : delimiter,
                    quoteChar: json.value.quote_char ? json.value.quote_char : quoteChar,
                    dynamicTyping: true,
                    complete: function (results) {
                        setCsv(results);
                        setDelimiter(results.meta.delimiter);

                        let mapping = {};
                        for (const key in json.value) {
                            if ( !['delimiter', 'quote_char', 'skip'].includes(key) ) {
                                if (json.value[key] <= results.meta.fields.length){
                                    mapping[key] = json.value[key];
                                }
                            }
                        }
                        setCsvMap(mapping);

                        setSkip(json.value.skip);

                        if (json.value.quote_char) {
                            setQuoteChar(json.value.quote_char);
                        }
                    }
                });
            } 
            else {
                Papa.parse(files, {
                    header: true,
                    delimiter: delimiter,
                    quoteChar: quoteChar,
                    dynamicTyping: true,
                    complete: function (results) {
                        setCsv(results);
                        setDelimiter(results.meta.delimiter);
                    }
                });
            }
        });
        
        setFile(files);

        return promise;
    };

    useEffect(() => { // load all services for user
        if (file && delimiter) {
            Papa.parse(file, {
                header: true,
                delimiter: delimiter,
                quoteChar: quoteChar,
                dynamicTyping: true,
                complete: function (results) {
                    setCsv(results);
                    setDelimiter(results.meta.delimiter);
                }
            });
        }
    }, [file, delimiter, quoteChar]);

    const createCSV = () => {
        const formData = new FormData();
        formData.append('csv', file);
        formData.append('delimiter', delimiter);
        formData.append('quote_char', quoteChar);

        if (skip) {
            formData.append('skip', skip);
        }

        if (packages.length > 0) {
            formData.append('fields', JSON.stringify(getPackageFields()));
        }

        for (const key in csvMap) {
            formData.append(key, csvMap[key]);
        }

        let promise = secureFetch(getApiRoute('import', [state.shipper.id, 'csv']), formData, 'POST', false, false);
        promise.then((json) => {
            if (Object.keys(json).length === 0) {
                const uspl = appValues.user.user_shipper_price_lists.find(uspl => uspl.shipper.id === parseInt(state.shipper.id));
                navigate(uspl.sale_price_list.type === 'fix' ? '/app/package/service' : '/app/package');
                return;
            }
            setPackages(json);
            setAlert('');
        }).catch((err) => {
            if (typeof err === 'string') {
                setAlert(err);
            } else {
                let output = formatErrors(err, t);
                setAlert(output);
            }
        });

        return promise;
    };

    const getPackageFields = () => {
        let fields = [];
        for (const pack of packages) {
            let tmpFields = {};
            for (const key in pack.package) {
                tmpFields[key] = pack.package[key];
            }
            tmpFields.pickup_address = state.pickup.id;
            fields.push(tmpFields);
        }
        return fields;
    }

    return (
        <Page
            title={t('title.import_packages') + ' - ' + state.shipper.shortcut}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    <img src={getShipperLogoPath(state.shipper.shortcut)} alt={state.shipper.shortcut} height={30} />
                    &nbsp;&nbsp;
                    {t('title.import_packages')}
                </Typography>
                <Card>
                    <CardHeader
                        title={"CSV"}
                    />
                    <Divider />
                    <CardContent>
                        {
                            alert === '' ? null :
                                <Box pb={2}>
                                    <Alert variant="filled" severity="error">
                                        {alert}
                                    </Alert>
                                </Box>
                        }
                        {
                            packages.length > 0  ?
                            <form onSubmit={(e) => e.preventDefault()}>
                                <PackagesStep packages={packages} setPackages={setPackages}  shipper={state.shipper.shortcut} />
                                <Box pb={2}/>
                                <ProgressButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    name={t('button.save')}
                                    onClick={createCSV}
                                />
                            </form>
                            :
                            <React.Fragment>
                                <ProgressButton
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    name={t('button.choose_file')}
                                    acceptFile=".csv"
                                    file={true}
                                    onClick={inputChange}
                                />
                                {
                                    delimiter &&
                                    <form onSubmit={(e) => e.preventDefault()} >

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Box pb={3}/>
                                            </Grid>
                                            <Grid item xs={'auto'} md={1}>
                                                <FormControl>
                                                    <InputLabel id="field-select-label">{t('label.delimiter')}</InputLabel>
                                                    <Select
                                                        labelId="field-select-label"
                                                        id="field-select"
                                                        value={delimiter}
                                                        name="delimiter"
                                                        onChange={(e) => setDelimiter(e.target.value)}
                                                    >
                                                        <MenuItem value={';'}>;</MenuItem>
                                                        <MenuItem value={','}>,</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={'auto'} md={1}>
                                                <FormControl>
                                                    <InputLabel id="field-quote-select-label">{t('label.quote_char')}</InputLabel>
                                                    <Select
                                                        labelId="field-quote-select-label"
                                                        id="field-quote-select"
                                                        value={quoteChar}
                                                        name="quoteChar"
                                                        onChange={(e) => setQuoteChar(e.target.value)}
                                                    >
                                                        <MenuItem value={'"'}>"</MenuItem>
                                                        <MenuItem value={"'"}>'</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={'auto'}>
                                                <FormControl component="fieldset">
                                                    <FormGroup aria-label="position" row>
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Checkbox color="primary" checked={skip} />}
                                                            label={t('label.skip_first_row')}
                                                            onChange={(e) => setSkip(e.target.checked)}
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {csv && <SelectCsvFields csv={csv} csvMap={csvMap} setCsvMap={setCsvMap} shipper={state.shipper.id} /> }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ProgressButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    name={t('button.upload')}
                                                    onClick={createCSV}
                                                />
                                            </Grid>
                                        </Grid>
                                    </form>
                                }
                            </React.Fragment>
                        }
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
}