import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Box,
    Tooltip,
    makeStyles
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import NewDiscountForm from "./NewDiscountForm";
import EditDiscountForm from "./EditDiscountForm";
import DeleteIconDialog from "../../../components/DeleteIconDialog";

const useStyles = makeStyles((theme) => ({
    root: {},
}));

const DiscountTable = ({ className, user, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openEditDiscountDialog, setOpenEditDiscountDialog] = React.useState(false);
    const [editDiscountData, setEditDiscountData] = useState({});

    const load = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('admin_user_discount', [user.id]) ).then(json => {
            setRows(json);
            setLoading(false);
        });
    }, [secureFetch])

    const handleDialogOpenClose = () => {
        setOpenCreateDialog(!openCreateDialog);
    };

    const handleEditDialogOpenClose = (data) => {
        if(openEditDiscountDialog) {
            setOpenEditDiscountDialog(false);
        } else {
            setEditDiscountData(data);
            setOpenEditDiscountDialog(true);
        }
    };

    useEffect(() => {
            load();
    }, [load]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'start_date'
        },
        headCells: [
            { id: 'start_date', numeric: false, label: t('label.start_date'), search: false, render: data => new Date(data.start_date).toLocaleDateString("fr-FR").slice(3) },
            { id: 'end_date', numeric: false, label: t('label.end_date'), search: false, render: data => new Date(data.end_date).toLocaleDateString("fr-FR").slice(3) },
            { id: 'discount', numeric: false, label: t('label.discount'), search: false, render: (data) => data.discount  + ' %' },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                    return (
                        (new Date(new Date(data.end_date).setDate(1)).setHours(0,0,0,0) >= new Date(new Date().setDate(1)).setHours(0,0,0,0) ) &&
                        <Box display="flex">
                            <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')} onClick={() => {handleEditDialogOpenClose(data)}}>
                                <IconButton
                                    size="small"
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            {/*<DeleteIconDialog*/}
                            {/*    text={*/}
                            {/*        <React.Fragment>*/}
                            {/*            {t('messages.really_delete')}*/}
                            {/*            <br/>*/}
                            {/*            <pre>*/}
                            {/*            {JSON.stringify(data, null, 2)}*/}
                            {/*        </pre>*/}
                            {/*        </React.Fragment>*/}
                            {/*    }*/}
                            {/*    route={getApiRoute('admin_user_discount', [data.id])}*/}
                            {/*    onDelete={load}*/}
                            {/*/>*/}
                        </Box>
                    );
                } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleDialogOpenClose}
                    >
                        {t('button.create_discount')}
                    </Button>
                </React.Fragment>
            );
        },
        rowClass: (data) => {
            if (new Date(new Date(data.start_date).setDate(1)).setHours(0,0,0,0) <= new Date(new Date().setDate(1)).setHours(0,0,0,0) &&
                new Date(new Date().setDate(1)).setHours(0,0,0,0) <= new Date(new Date(data.end_date).setDate(1)).setHours(0,0,0,0)) {
                return 'activeDiscount';
            }
        },
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
            <Dialog
                fullWidth
                open={openCreateDialog}
                onClose={handleDialogOpenClose}
                maxWidth="sm"
            >
                <DialogContent>
                    <NewDiscountForm handleDialogOpenClose={handleDialogOpenClose} load={load} user={user}/>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openEditDiscountDialog}
                onClose={handleEditDialogOpenClose}
                maxWidth="sm"
            >
                <DialogContent>
                    <EditDiscountForm discount={editDiscountData} handleEditDialogOpenClose={handleEditDialogOpenClose} load={load} user={user} />
                </DialogContent>
            </Dialog>
        </Card>
    );
}

export default DiscountTable;