import React, {useContext, useEffect, useRef, useState} from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';

const UserProfitStatisticsTable = ({ params, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [rows, setRows] = useState([]);
    const renderedData = useRef([]);


    useEffect(() => {
        secureFetch(getApiRoute('admin_statistics', ['profit']) + '?' + params).then(json => {
            secureFetch(getApiRoute('admin_statistics', ['serviceprofit']) + '?' + params).then(serviceProfits => {
                var hashTable = {};
                for (const val of json) {
                    val.id = val.username;
                    val.profit = parseFloat(val.price - val.purchase_price).toFixed(2);
                    val.average_profit = parseFloat((val.price - val.purchase_price) / val.count).toFixed(2);
                    val.together = val.profit;
                    hashTable[val.id] = val;
                }
                for (const sp of serviceProfits) {
                    let serviceProfit = {};
                    let fromHashTable = hashTable[sp.username];
                    if(fromHashTable === undefined){
                        fromHashTable = {
                            id: sp.username,
                            username: sp.username,
                            eshop: sp.eshop,
                            price: 0,
                            purchase_price: 0,
                            count: 0,
                            profit: 0,
                            average_profit: 0
                        }
                    }
                    serviceProfit.service_fees = parseFloat(sp.service_fees).toFixed(2);
                    serviceProfit.other_fees = parseFloat(sp.other_fees).toFixed(2);
                    serviceProfit.together = (parseFloat(fromHashTable.profit) + parseFloat(sp.service_fees) + parseFloat(sp.other_fees)).toFixed(2);
                    hashTable[sp.username] = {...fromHashTable, ...serviceProfit};
                }
                setRows(Object.values(hashTable));
            });
        });
    }, [secureFetch, params]);

    const settings = {
        rows: rows,
        loading: false,
        rowsPerPage: 200,
        colOrder: {
            order: 'asc',
            orderBy: 'count'
        },
        headCells: [
            { id: 'eshop', numeric: false, label: t('label.eshop_name'), search: true },
            { id: 'count', numeric: true, label: t('label.count'), search: false },
            { id: 'purchase_price', numeric: true, label: t('label.purchase_price'), search: false, render: (data) => parseFloat(data.purchase_price).toFixed(2) + ' €' },
            { id: 'price', numeric: true, label: t('label.sale_price'), search: false, render: (data) => parseFloat(data.price).toFixed(2) + ' €' },
            { id: 'profit', numeric: true, label: t('label.profit'), search: false, render: (data) => parseFloat(data.profit).toFixed(2) + ' €' },
            { id: 'average_profit', numeric: true, label: t('label.average_profit'), search: false, render: (data) => parseFloat(data.average_profit).toFixed(2) + ' €' },
            { id: 'service_fees', numeric: true, label: t('label.service_fees'), search: false, render: (data) => parseFloat(data.service_fees || 0).toFixed(2) + ' €' },
            { id: 'other_fees', numeric: true, label: t('label.other_fees'), search: false, render: (data) => parseFloat(data.other_fees || 0).toFixed(2) + ' €' },
            { id: 'together', numeric: true, label: t('label.together'), search: false, render: (data) => parseFloat(data.together || 0).toFixed(2) + ' €' },
        ],
        totalDataRow: (cell, data) => {
            if (cell.id === 'average_profit') {
                let sumProfit = 0;
                let count = 0;
                for (const row of rows) {
                    sumProfit += parseFloat(row['profit']);
                    count += parseFloat(row['count']);
                }
                return count == 0 ?
                    <b>{(0).toFixed(2) + (cell.id !== 'count' ? ' €' : '')}</b> :
                    <b>{(sumProfit / count).toFixed(2) + (cell.id !== 'count' ? ' €' : '')}</b>;

            }
            else if (cell.id !== 'eshop') {
                let sum = 0;
                for (const row of renderedData.current) {
                    sum += parseFloat(row[cell.id] || 0);
                }
                return (<b>{sum.toFixed(2) + (cell.id !== 'count' ? ' €' : '')}</b>);
            }
            else {
                return(
                    <b>{t('label.total') + ' ' + data.length}</b>
                )
            }
        },
        superHeader: [
            {align: 'center', colSpan: 1, label: ''},
            {align: 'center', colSpan: 5, label: t('navbar.pricelist_transit')},
            {align: 'center', colSpan: 2, label: t('title.services')},
            {align: 'right', colSpan: 1, label: t('label.together_profit_service_fees_and_other_fees')},
        ]

    };

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} renderedDataRef={renderedData} />
            </CardContent>
        </Card>
    );
}

export default UserProfitStatisticsTable;