import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Tooltip,
    Box,
    IconButton,
    makeStyles
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AddIcon from '@material-ui/icons/Add';
import { NavLink as RouterLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIconDialog from 'components/DeleteIconDialog';
import {formatDate, isAdmin, isPartner} from 'helpers';
import AppContext from 'context/AppContext';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const UserTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);

    const loadUsers = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute( isAdmin(appValues.user) ? 'admin_user' : 'partner_user') ).then(json => {
            json.map( (val) => {
                if (val.user_shipper_price_lists) {
                    val.shipper = val.user_shipper_price_lists.map((v) => v.shipper.id+'_'+v.type);
                }
                val.company = val.invoice_address ? val.invoice_address.company : '';
                return val;
            } )
            setRows(json);
            setLoading(false);
        });
    }, [secureFetch, appValues.user])

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'eshop'
        },
        headCells: [
            { id: 'enabled', numeric: false, label: t('label.enabled'), search: true, disableOrder: true, searchType: 'select', hide: isPartner(appValues.user), selectChoices: [
                {id: true, name: t('label.enabled')},
                {id: false, name: t('label.disabled')},
            ], render: (row) => {
                return (
                    row.enabled ? <CheckIcon/> : <ClearIcon/>
                );
            } },
            { id: 'eshop', numeric: false, label: t('label.eshop_name'), search: true },
            { id: 'username', numeric: false, label: t('label.username'), search: true },
            { id: 'company', numeric: false, label: t('label.company'), search: true },
            { id: 'variable_number', numeric: true, label: t('label.variable_number'), hide: isPartner(appValues.user), search: true },
            { id: 'credit', numeric: true, label: t('label.credit'), search: false, disableOrder: true, hide: isPartner(appValues.user), render: (data) => {
                return data.credit ? parseFloat(data.credit).toFixed(2) + ' €' : '0 €';
            } },
            { id: 'created_at', numeric: false, label: t('label.created_at'), search: true, searchType: 'dateRange', render: (row) => formatDate(row.created_at)  },
            { id: 'start_of_shipment', numeric: false, label: t('label.start_of_shipment'), hide: !isPartner(appValues.user), search: true, searchType: 'dateRange', render: (row) => formatDate(row.start_of_shipment)  },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    {
                        isAdmin(appValues.user) &&
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            size="small"
                            startIcon={<AddIcon />}
                            component={RouterLink}
                            to={'/app/admin/user/new'}
                        >
                            {t('button.create_user')}
                        </Button>
                    }
                </React.Fragment>
            );
        },
        addFilter: {
            shipper_multi_user_list: true,
            roles: isAdmin(appValues.user),
        },
    };

    if ( isAdmin(appValues.user) ) {
        settings.headCells.push(
            {
                id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                    return (
                        <Box display="flex">
                            <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')}>
                                <IconButton
                                    aria-label={t('tooltip.edit')}
                                    className={classes.rowButtons}
                                    component={RouterLink}
                                    size="small"
                                    to={"/app/admin/user/edit/" + data.id}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <DeleteIconDialog
                                text={
                                    <React.Fragment>
                                        {t('messages.really_delete')}
                                        <br />
                                        <pre>
                                            {JSON.stringify(data, null, 2)}
                                        </pre>
                                    </React.Fragment>
                                }
                                route={getApiRoute('admin_user', [data.id])}
                                onDelete={loadUsers}
                            />
                        </Box>
                    );
                }
            },
        )
    } else {
        settings.headCells.push(
            { id: '', numeric: false, label: '', search: false },
        )
    }

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default UserTable;