import React, { useState, useEffect } from "react";
import { ThemeProvider } from '@material-ui/core';
import theme from 'theme';
import GlobalStyles from 'components/GlobalStyles';
import roleAllRoutes from 'routes/roleAllRoutes';
import roleUserRoutes from 'routes/roleUserRoutes';
import roleAdminRoutes from 'routes/roleAdminRoutes';
import rolePartnerRoutes from 'routes/rolePartnerRoutes';
import { useRoutes } from 'react-router-dom';
import AppContext, { defAppValues } from 'context/AppContext';
import UserUpdatableContext, {DefaultUserUpdatable} from "./context/UserUpdatableContext";
import LoadedValuesContext from 'context/LoadedValuesContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import tawkTo from "tawkto-react";
import {isAdmin, isPartner, isUser} from "./helpers";

library.add(fab, faMoneyBillWave);

const App = () => {
    //let tmpAppValues = sessionStorage.getItem("appValues");
    //console.log('localStorage.appValues' ,localStorage.getItem("appValues"));
    let tmpAppValues = JSON.parse(localStorage.getItem("appValues"));
    if (tmpAppValues !== null) {
        if (tmpAppValues.user !== undefined && tmpAppValues.user !== null) {
            tmpAppValues = ('additional_pickup_addresses' in tmpAppValues.user) && ('messages' in tmpAppValues) ? tmpAppValues : defAppValues;
        }
    }
    else
        tmpAppValues = defAppValues;
    const [appValues, setAppValues] = useState(tmpAppValues);
    const value = { appValues, setAppValues };

    let tmpUserUpdatable = JSON.parse(localStorage.getItem("userUpdatable"));
    tmpUserUpdatable = tmpUserUpdatable !== null && tmpUserUpdatable.statistics ? tmpUserUpdatable : DefaultUserUpdatable;
    const [userUpdatable, setUserUpdatable] = useState(tmpUserUpdatable);
    const userUpdatableValue = { userUpdatable, setUserUpdatable };

    const [loadedValues, setLoadedValues] = useState({
        statusesByType: null
    });
    const loadedValuesValue = { loadedValues, setLoadedValues };

    useEffect(() => {
        //sessionStorage.setItem( "appValues", JSON.stringify(appValues) );
        //console.log('appValues useEffect', appValues);
        localStorage.setItem( "appValues", JSON.stringify(appValues) );
    }, [appValues]);

    useEffect(() => {
        localStorage.setItem( "userUpdatable", JSON.stringify(userUpdatable) );
    }, [userUpdatable])

    // useEffect(() => {
    //     if ( appValues.user && window.Tawk_API && typeof window.Tawk_API.showWidget === 'function') {
    //         // user is logged in and Tawk is initialized
    //         window.Tawk_API.setAttributes({
    //             name: appValues.user.eshop || '',
    //             email: appValues.user.username
    //         }, function(error){});
    //         window.Tawk_API.showWidget();
    //     } else if ( appValues.user && !window.Tawk_API ){
    //         // user is logged in and Tawk is not initialized
    //         tawkTo(process.env.REACT_APP_TAWK_TO_PROPERTY_ID, process.env.REACT_APP_TAWK_TO_KEY);
    //         window.Tawk_API.onLoad = () => {
    //             window.Tawk_API.setAttributes({
    //                 name: appValues.user.eshop || '',
    //                 email: appValues.user.username
    //             }, function(error){});
    //             window.Tawk_API.showWidget();
    //         };
    //     } else if (!appValues.user && window.Tawk_API) {
    //         // user is not logged in and Tawk is initialized
    //         if (window.Tawk_API.isChatMaximized()) {
    //             window.Tawk_API.minimize()
    //         }
    //         window.Tawk_API.hideWidget();
    //     }
    // }, [appValues.user]);

    const getRouting = () => {
        let routes = roleAllRoutes;
        if ( appValues.user ) {
            if ( isUser(appValues.user) ) {
                routes = roleUserRoutes.concat(routes);
            }
            else if( isAdmin(appValues.user) ){
                routes = roleAdminRoutes.concat( roleUserRoutes.concat( routes ) );
            }
            else if( isPartner(appValues.user) ){
                routes = rolePartnerRoutes.concat( roleUserRoutes.concat( routes ) );
            }
        }
        return routes;
    };
    const routing = useRoutes( getRouting() );

    return (
        <ThemeProvider theme={theme}>
            <AppContext.Provider value={value}>
                <UserUpdatableContext.Provider value={userUpdatableValue}>
                    <LoadedValuesContext.Provider value={loadedValuesValue}>
                        <GlobalStyles />
                        {routing}
                    </LoadedValuesContext.Provider>
                </UserUpdatableContext.Provider>
            </AppContext.Provider>
        </ThemeProvider>
    );
};

export default App;