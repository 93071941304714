import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from "react-i18next";
import {
    Typography,
    TextField,
    Grid,
    Box, FormControlLabel,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AppContext from 'context/AppContext';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShipperCreateDialog({ text, user, onCreate, loadedPriceLists, ...rest }) {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [values, setValues] = useState({});
    const [priceLists, setPriceLists] = useState([]);
    const [fix, setFix] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        if ( event.target.name === 'shipper' && values.shipper !== event.target.value ) {
            secureFetch( getApiRoute('admin_shipper_pricelists', [event.target.value]) ).then(json => {
                setPriceLists(json);
            })
        }
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch( getApiRoute('admin_user_pricelists', [user.id]), values, 'POST' ).then(json => {
            onCreate();
            setOpen(false);
        })
    };

    const onFixChange = (event) => {
        setFix(event.target.checked);
        setValues({ ...values, sale_price_list: null });
    };

    const shippersIdsToRemove = [];
    for (const priceList of loadedPriceLists) {
        shippersIdsToRemove.push( priceList.shipper_id )
    }

    return (
        <div>
            <Button color="primary" variant="text" onClick={handleClickOpen}>
                {t('button.add_shipper_pricelist')}
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                fullWidth
            >
                <DialogContent>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        {...rest}
                    >
                        <Typography variant="h3" gutterBottom >
                            {t('title.add_shipper_pricelist')}
                        </Typography>
                        <Grid container spacing={3} alignItems="flex-end">
                            <Grid item xs={12} md={1}>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        value="top"
                                        control={<Checkbox
                                            style={{padding: '0px'}}
                                            checked={fix}
                                            onChange={onFixChange}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Fix"
                                        labelPlacement="top"
                                    />

                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="shipper">{t('label.shipper')}</InputLabel>
                                    <Select
                                        labelId="shipper"
                                        value={values.shipper || ''}
                                        name="shipper"
                                        onChange={handleChange}
                                        required
                                    >
                                        {appValues.shippers.filter( shipper => !shippersIdsToRemove.includes(shipper.id) ).map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                fix ? null :
                                <Grid item xs={12} md={4}>
                                    <Autocomplete
                                        options={priceLists.filter(priceList => priceList.type === 'purchase' )}
                                        onChange={ (event, priceList) => setValues({ ...values, purchase_price_list: priceList.id }) }
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label={t('label.purchase_price_list')} required/>}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={priceLists.filter(priceList => (!fix && priceList.type === 'sale') || (fix && priceList.type === 'fix') )}
                                    onChange={ (event, priceList) => setValues({ ...values, sale_price_list: priceList.id }) }
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label={t('label.sale_price_list')} required/>}
                                />
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" py={2}>
                            <Button color="secondary" variant="text" onClick={handleClose} >
                                {t('button.close')}
                            </Button>
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.create')}
                            </Button>
                        </Box>
                    </form>

                </DialogContent>
            </Dialog>
        </div>
    );
}
