import React, {useContext} from 'react';
import {
    Container,
    makeStyles,
    Typography,
    Card,
    Grid,
    CardContent,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import SecureFetchContext from "../../../context/SecureFetchContext";
import AppContext from "../../../context/AppContext";
import {ConnectorList} from "neoship_connector";

const useStyles = makeStyles((theme) => ({

}));

const ConnectorTableView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);

    return (
        <Page
            title={t('navbar.connector')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <ConnectorList AppValues={appValues} secureFetch={secureFetch}/>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </Container>
        </Page>
    );
};

export default ConnectorTableView;