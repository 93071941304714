import React, { useEffect, useContext } from 'react';
import {
    Container,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import EditShipperForm from './EditShipperForm';
import { isNumeric } from "helpers";
import { useParams, useNavigate } from "react-router-dom";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

export default function ShipperEditView() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [shipper, setShipper] = React.useState({name: ''});
    let { id } = useParams();
    let navigate = useNavigate();
    const secureFetch = useContext(SecureFetchContext);

    useEffect(() => {
        if ( !isNumeric(id) ) {
            navigate('/404');
        }
        if ( shipper.id !== parseInt(id) ) {
            secureFetch( getApiRoute('admin_shipper', [id]) ).then(json => {
                setShipper(json);
            });
        }
    }, [id, navigate, secureFetch, shipper.id]);

    return (
        <Page
            className={classes.root}
            title={t('title.edit_shipper') + ' - ' + shipper.name}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('title.edit_shipper') + ' - ' + shipper.name}
                </Typography>
                <EditShipperForm shipper={shipper} />
            </Container>
        </Page>
    );
}