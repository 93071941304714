import React, {useState, useContext, useEffect} from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    TextField,
    Grid,
    makeStyles,
    Chip,
    CircularProgress,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import AppContext from 'context/AppContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
    root: {
    }
}));

const ComplaintForm = ({ className, data, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [values, setValues] = useState({
        emails: appValues.user.address ? appValues.user.address.email : ""
    });
    const [files, setFiles] = useState([]);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        secureFetch(getApiRoute('usersettings', ['byname', 'NOTIFICATION_EMAILS'])).then(json => {
            if (json) {
                setValues({
                    emails: json.value
                })
            }
        });

    },[secureFetch]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const onFilesChange = (event) => {
        setFiles(Array.from(event.target.files));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSending(true);

        const formData = new FormData();
        formData.append('message', values.message);
        for (const file of files) {
            formData.append('files[]', file);
        }
        for(const email of values.emails.split(',')) {
            formData.append('emails[]', email);
        }
        secureFetch(getApiRoute('package', ['complaint', data.type, data.package.id]), formData, 'POST', true, false).then(json => {
            setSending(null);
        });
    };

    return (
            <form
                autoComplete="off"
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t('label.message')}
                            name="message"
                            onChange={handleChange}
                            value={values.message || ''}
                            required
                            multiline
                            rows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            component="label"
                        >
                            {t('button.add_attachment')}
                            <input
                                type="file"
                                name="files"
                                onChange={onFilesChange}
                                multiple
                                hidden
                            />
                        </Button>
                        {
                            files.map((file) => {
                                return(
                                    <Box padding={1} display="inline">
                                        <Chip label={file.name} />
                                    </Box>
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t('label.complaint_emails')}
                            name="emails"
                            onChange={handleChange}
                            value={values.emails || ''}
                            required
                        />
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-start" py={2}>
                    {
                        sending === null ?
                        <Alert variant="filled" severity="success">
                            {t('messages.sended')}
                        </Alert> :
                        <Button color="primary" variant="contained" type="submit" disabled={sending}>
                            {t('button.send')}
                            {
                                sending && <CircularProgress size={24} color="primary" />
                            }
                        </Button>
                    }
                </Box>
            </form>
    );
};

export default ComplaintForm;
