import React from 'react';
import DashboardLayout from 'layouts/DashboardLayout';
import { Navigate } from 'react-router-dom';
import UserListView from 'views/User/UserListView';
import PartnerProfitStatistics from 'views/Statistics/PartnerProfitStatistics';
import PackageCountStatistics from 'views/Statistics/PackageCountStatistics';
import ReturnedPackageStatistics from 'views/Statistics/ReturnedPackageStatistics';

const roleAdminRoutes = [
    {
        path: 'app/partner',
        element: <DashboardLayout />,
        children: [
            { path: '/user/list', element: <UserListView /> },
            { path: '/statistics/partner', element: <PartnerProfitStatistics /> },
            { path: '/statistics/packagecount', element: <PackageCountStatistics /> },
            { path: '/statistics/returnedpackage', element: <ReturnedPackageStatistics /> },
            { path: '*', element: <Navigate to="/404" replace={true} /> }
        ]
    }
];

export default roleAdminRoutes;