import React from 'react';
import {
    Container,
    Grid,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import LoginForm from './LoginForm';
import NeoshipInfoPreview from './NeoshipInfoPreview';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        width: '100%',
    },
    [theme.breakpoints.up('md')]: {
        containerRoot: {
            height: '100%',
            maxWidth: 'initial',
            padding: '0px'
        },
        container: {
            height: '100%',
            maxWidth: 'initial',
        },
        h100: {
            height: '100%',
        }
    },
    spacing: {
        padding: theme.spacing(2),
    },
    leftBox: {
        background: '#ffffff',
    },
    rightBox: {
        background: 'url(/images/map.png) no-repeat center 35% / 95% ',
    },
}));

const LoginView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return (
        <Page
            className={classes.root}
            title={t('title.signin')}
        >
            <Container className={classes.containerRoot}>
                <Grid container className={classes.container} >
                    <Grid item xs={12} md={6} className={`${classes.spacing} ${classes.leftBox}`} >
                        <Grid container direction="column" className={classes.h100} justify="center">
                            <Grid item>
                                <NeoshipInfoPreview />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className={`${classes.spacing} ${classes.rightBox}`}>
                        <Grid container direction="column" className={classes.h100} justify="center">
                            <Grid item>
                                <LoginForm />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default LoginView;