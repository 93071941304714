import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    Grid,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AppContext from 'context/AppContext';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(() => ({
    root: {}
}));

const EditPartnerCommissionForm = ({ className, partnerCommission, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const { appValues } = useContext(AppContext);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const commissions = appValues.commissions.map((com) => {
        let type = com.name.includes('PREMIUM') ? 'PREMIUM' : 'ZAKLAD';
        return {
            type: type,
            ...com,
        }
    });

    const handleCommissionChange = (event, com) =>
        com ? setValues({ ...values, 'type': com.value }) : null;
    ;

    useEffect(() => {
        if ( partnerCommission ) {
            setValues({
                value: parseFloat(partnerCommission.value).toFixed(2),
                type: partnerCommission.type,
            });
        }
    }, [partnerCommission]);

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch(getApiRoute('admin_partnercommission', [partnerCommission.id]), values, 'PUT').then(json => {
            setSuccessMessage(t('messages.data_saved'));
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.value')}
                                        name="value"
                                        onChange={handleChange}
                                        value={values.value || ''}
                                        type="number"
                                        inputProps={{ step: "0.001" }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth required>
                                        {/*<InputLabel >{t('label.type')}</InputLabel>*/}
                                        {/*<Select*/}
                                        {/*    value={values.type || ''}*/}
                                        {/*    name="type"*/}
                                        {/*    onChange={handleChange}*/}
                                        {/*    required*/}
                                        {/*>*/}
                                        {/*    {appValues.commissions.map((val) => (*/}
                                        {/*        <MenuItem*/}
                                        {/*            key={val.value}*/}
                                        {/*            value={val.value}*/}
                                        {/*        >*/}
                                        {/*            {val.name}*/}
                                        {/*        </MenuItem>*/}
                                        {/*    ))}*/}
                                        {/*</Select>*/}
                                        <Autocomplete
                                            id="type"
                                            name="type"
                                            value={commissions.find(commission => commission.value === parseInt(values.type)) || null}
                                            onChange={handleCommissionChange}
                                            options={commissions}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            groupBy={(option) => option.type}
                                            getOptionLabel={(option) => option.name}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="Typ *" />}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </CardContent>
                        {
                            successMessage === '' ? null :
                                <Box px={2}>
                                    <Alert variant="filled" severity="success">
                                        {successMessage}
                                    </Alert>
                                </Box>
                        }
                        <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.create')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
};

EditPartnerCommissionForm.propTypes = {
    className: PropTypes.string
};

export default EditPartnerCommissionForm;
