import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import {
    makeStyles,
    Tooltip,
    Box
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import Fade from '@material-ui/core/Fade';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AppContext from 'context/AppContext';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useNavigate } from "react-router-dom";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import EmailIcon from '@material-ui/icons/Email';
import {isAdmin, isRevExp, isExp, isUser, openNewTab} from "../../../helpers";
import PackageListActionsDialog from "./PackageListActionsDialog";

const useStyles = makeStyles((theme) => ({
    root: {},
    rowButtons: {
        padding: '5px'
    }
}));

const PackageListActions = ({ className, data, loadPackages, statusesByType, ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [anchorElComplaint, setAnchorElComplaint] = React.useState(null);
    const openComplaint = Boolean(anchorElComplaint);
    const { appValues } = useContext(AppContext);
    let navigate = useNavigate();
    const secureFetch = useContext(SecureFetchContext);

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorElComplaint(null);
    };

    const handleCopy = () => {
        secureFetch(getApiRoute(isUser(appValues.user) ? 'package' : 'admin_package', [data.id])).then(json => {
            delete json.id;
            delete json.reference_number;
            delete json.statuses;
            delete json.user;
            delete json.last_status;
            delete json.cod_payment;
            delete json.cod_price;
            delete json.cod_reference;
            delete json.weight;
            delete json.tracking_number;
            delete json.shipper;
            delete json.last_status;
            navigate('/app/package/new/' + data.shipper_id, { state: {receiver: json} });
        });
    }

    const onClickComplaint = (type) => {
        navigate('/app/package/complaint', { state: {
            package: data,
            type: type
        } });
    }

    const handleCancelClosePackage = (type) => {
        const payload = {source: 'WEB'.toUpperCase()};
        secureFetch(getApiRoute(isUser(appValues.user) ? 'package' : 'admin_package', [type, data.id]), payload, 'POST').then(json => {
                loadPackages();
                handleClose();
        });
    };

    const packetaInShipping = (p) => {
        return p.shipper.toUpperCase() === 'PACKETA' && p.price_vat !== null;
    };

    const K123InShipping = (p) => {
        return p.shipper === '123' && p.statuses.some(s => s.code == 19);
    };

    const otherInShipping = (p) => {
        return p.shipper.toUpperCase() !== 'PACKETA' && p.weight && p.weight > 0;
    };

    const inShipping = (p) => {
        return packetaInShipping(p) || K123InShipping(p) || otherInShipping(p);
    };

    return (
        <Box display="flex">
            {
                !data.isSubPackage && !data.tracking_number && !isRevExp(data) &&
                    <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')}>
                        <IconButton
                            aria-label={t('tooltip.edit')}
                            className={classes.rowButtons}
                            size="small"
                            onClick={() => openNewTab("/app/package/edit/" + data.id + "/" + data.shipper_id)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
            }
            {
                (data.tracking_number || isRevExp(data)) && data.shipper !== 'DPD' &&
                <Tooltip title={t('tooltip.tracking')} aria-label={t('tooltip.tracking')}>
                    <IconButton
                        aria-label={t('tooltip.tracking')}
                        className={classes.rowButtons}
                        size="small"
                        onClick={() => openNewTab("/tracking/" + data.tracking_number + "?lang=sk")}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
            }
            {
                (data.tracking_number || isRevExp(data)) && data.shipper === 'DPD' &&
                <Tooltip title={t('tooltip.tracking')} aria-label={t('tooltip.tracking')}>
                    <IconButton
                        aria-label={t('tooltip.tracking')}
                        className={classes.rowButtons}
                        size="small"
                        onClick={() => openNewTab('https://www.dpdgroup.com/sk/mydpd/my-parcels/track?lang=sk&parcelNumber='+ data.tracking_number)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
            }
            {
                (!data.isSubPackage || data.shipper !== 'SPS') &&
                    <Tooltip title={t('tooltip.detail')} aria-label={t('tooltip.detail')}>
                        <IconButton
                            aria-label={t('tooltip.detail')}
                            className={classes.rowButtons}
                            size="small"
                            onClick={() => openNewTab("/app/package/detail/" + data.id)}
                        >
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
            }
            {

                <React.Fragment>
                    <Tooltip title={t('tooltip.more')} aria-label={t('tooltip.more')}>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            className={classes.rowButtons}
                            size="small"
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                        transformOrigin={{
                            horizontal: 'center',
                            vertical: 'top',
                        }}
                    >
                        {
                            // IS NOT DELIVERED YET                                                                       EXPORTED                IN SHIPPING
                            statusesByType && !statusesByType[data.shipper_id].delivered.includes(data.last_status_id) && data.tracking_number && inShipping(data) && !(data.shipper === '123' && !data.weight) && !data.is_service &&
                            <PackageListActionsDialog
                                title={t('title.closing')}
                                message={t('messages.close_package')}
                                buttonName={t('button.close_package')}
                                onDelete={() => handleCancelClosePackage('close')}
                            />
                        }
                        {
                            // IS NOT CANCELED YET                                                                     EXPORTED               NOT EXPORTED REVERSE NOT IN SHIPPING YET    OR IS SERVICE
                            (statusesByType && !statusesByType[data.shipper_id].cancel.includes(data.last_status_id) && data.tracking_number && ((!isRevExp(data) && !inShipping(data)) || data.is_service)) &&
                            <PackageListActionsDialog
                                title={t('title.canceling')}
                                message={t('messages.cancel_package')}
                                buttonName={t('button.cancel_package')}
                                onDelete={() => handleCancelClosePackage('cancel')}
                            />
                        }
                        {
                            // IS NOT CANCELED YET                                                                     NOT EXPORTED YET                          NOT IN SHIPPING YET
                            statusesByType && !statusesByType[data.shipper_id].cancel.includes(data.last_status_id) && !data.tracking_number && !isExp(data) && !inShipping(data) &&
                            <PackageListActionsDialog
                                title={t('title.deleting')}
                                message={t('messages.delete_package')}
                                buttonName={t('button.delete_package')}
                                onDelete={() => handleCancelClosePackage('delete')}
                            />
                        }
                        {
                            isUser(appValues.user) &&
                            <MenuItem
                                onClick={handleCopy}
                            >{t('button.copy_receiver')}</MenuItem>
                        }
                        {
                            (isAdmin(appValues.user) && data.tracking_number) && !data.is_service &&
                            <MenuItem
                                onClick={() => navigate(`/app/admin/package/price/${data.id}/${data.reference_number}`, { state: {isSubPackage: data.isSubPackage} })}
                            >{t('button.price')}</MenuItem>
                        }
                        {
                            // IS ADMIN AND IS 123K PACKAGE admin can cancel package - create status, remove from reservation, update stickers                                                                     NOT EXPORTED YET                          NOT IN SHIPPING YET
                            // isAdmin(appValues.user) && data.shipper === '123' && !statusesByType[data.shipper_id].cancel.includes(data.last_status_id) && inShipping(data) &&
                            isAdmin(appValues.user) && data.shipper === '123' && inShipping(data) &&
                            <PackageListActionsDialog
                                title={t('title.canceling')}
                                message={t('messages.cancel_package')}
                                buttonName={t('button.cancel_package')}
                                onDelete={() => handleCancelClosePackage('cancel')}
                            />
                        }
                    </Menu>
                </React.Fragment>
            }
            {
                isUser(appValues.user) && (data.tracking_number || isRevExp(data)) &&
                <React.Fragment>
                    <Tooltip title={t('tooltip.complaint')} aria-label={t('tooltip.complaint')}>
                        <IconButton
                            aria-label="complaint"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            className={classes.rowButtons}
                            size="small"
                            onClick={(event) => setAnchorElComplaint(event.currentTarget)}
                        >
                            <EmailIcon />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        id="fade-menu"
                        anchorEl={anchorElComplaint}
                        keepMounted
                        open={openComplaint}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                        transformOrigin={{
                            horizontal: 'center',
                            vertical: 'top',
                        }}
                    >
                        {
                            <MenuItem
                                onClick={() => onClickComplaint('damaged_shipment')}
                            >{t('button.damaged_shipment')}</MenuItem>
                        }
                        {
                            ['SPS', '123'].includes(data.shipper) &&
                            <MenuItem
                                onClick={() => onClickComplaint('edit_cod')}
                            >{t('button.edit_cod')}</MenuItem>
                        }
                        {
                            <MenuItem
                                onClick={() => onClickComplaint('customer_service')}
                            >{t('button.customer_service')}</MenuItem>
                        }
                    </Menu>
                </React.Fragment>
            }
        </Box>
    );
}

export default PackageListActions;