import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import {  Grid, IconButton, Tooltip } from '@material-ui/core';
import SecureFetchContext from 'context/SecureFetchContext';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import getApiRoute from 'routes/apiRoutes';

export default function AddInvoiceNumberDialog({ data, loadPackages, ...rest}) {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const [values, setValues] = React.useState({
        invoice_date: (new Date()).toISOString().slice(0, 10)
    });
    const secureFetch = useContext(SecureFetchContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        secureFetch(getApiRoute('admin_package', ['invoice', data.id]), values, 'PUT').then(json => {
            handleClose();
            loadPackages();
        });
    }

    const onValuesChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <React.Fragment>
            <Tooltip title={t('tooltip.add_invoice')} aria-label={t('tooltip.add_invoice')}>
                <IconButton
                    size="small"
                    aria-label={t('tooltip.add_invoice')}
                    onClick={handleClickOpen}
                >
                    <PlaylistAddCheckIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit}>
                    <DialogTitle>{t('tooltip.add_invoice')}</DialogTitle>
                    <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        name="invoice_date"
                                        value={values.invoice_date || ''}
                                        label={t('label.invoice_date')}
                                        type="date"
                                        onChange={onValuesChange}
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        margin="dense"
                                        name="invoice_number"
                                        value={values.invoice_number || ''}
                                        label={t('label.invoice_number')}
                                        type="invoice_number"
                                        onChange={onValuesChange}
                                        required
                                    />
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" variant="text" onClick={handleClose} >
                            {t('button.close')}
                        </Button>
                        <Button color="primary" variant="contained" type="submit">
                            {t('button.save')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
}
