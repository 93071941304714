import React from 'react';
import {
    Box,
    Button,
    Typography,
    makeStyles
} from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '500px',
        margin: 'auto'
    }
}));

const NeoshipInfoPreview = () => {
    const classes = useStyles();

    return (
        <Box mb={3} textAlign="center">
            <img src="/images/logo-neoship.svg" width="200" height="auto" alt="Neoship logo" />
            <Typography
                color="textPrimary"
                variant="h2"
            >
                Informačná web stránka
            </Typography>
            <Box mb={3} />
            <img src="/images/notebook_new.png" width="350" height="auto" alt="Neoship preview" />
            <Box mb={3} />
            <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                endIcon={<DoubleArrowIcon/>}
                onClick={() => window.location.href = 'https://www.neoship.sk'}
            >
                Prejsť na www.neoship.sk
            </Button>
        </Box>
    );
};

export default NeoshipInfoPreview;