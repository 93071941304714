import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    TextField,
    Grid,
    makeStyles, Typography
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import InputAdornment from "@material-ui/core/InputAdornment";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import sk from "date-fns/locale/sk";
import {DatePicker, LocalizationProvider} from "@material-ui/pickers";

const useStyles = makeStyles(() => ({
    root: {}
}));

const NewDiscountForm = ({ className, user, edit, handleDialogOpenClose, load, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const [startDate, setStartDate] = useState((new Date()));
    const [endDate, setEndDate] = useState((new Date()));

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let tmpValues = { ...values, start_date : new Date(startDate).toLocaleDateString("fr-FR"), end_date : new Date(endDate).toLocaleDateString("fr-FR")};
        secureFetch(getApiRoute('admin_user_discount', [user.id]), tmpValues, 'POST').then(json => {
            handleDialogOpenClose();
            load();
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.create_discount')}
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={sk}>
                        <DatePicker
                            ampm={false}
                            views={["year", "month"]}
                            openTo="month"
                            minDate={new Date()}
                            onChange={(val) => setStartDate(val)}
                            value={startDate || ''}
                            label={t('label.start_date')}
                            name="start_date"
                            inputFormat="MM/yyyy"
                            renderInput={(params) => <TextField
                                {...params}
                                fullWidth
                                required
                            />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={sk}>
                        <DatePicker
                            autoOk
                            ampm={false}
                            views={["year", "month"]}
                            openTo="month"
                            minDate={new Date()}
                            onChange={(val) => setEndDate(val)}
                            value={endDate || ''}
                            label={t('label.end_date')}
                            name="end_date"
                            inputFormat="MM/yyyy"
                            renderInput={(params) => <TextField
                                {...params}
                                fullWidth
                                required
                            />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t('label.discount')}
                            name="discount"
                            onChange={handleChange}
                            value={values.discount || ''}
                            type="number"
                            inputProps={{ step: "10" }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            required
                        />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Button color="secondary" variant="text" onClick={handleDialogOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.create')}
                </Button>
            </Box>

        </form>
    );
};

NewDiscountForm.propTypes = {
    className: PropTypes.string
};

export default NewDiscountForm;
