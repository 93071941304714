import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from "react-i18next";
import DeleteIcon from '@material-ui/icons/Delete';
import SecureFetchContext from 'context/SecureFetchContext';
import {
    Tooltip,
    IconButton,
    Typography,
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteIconDialog({ text, route, onDelete, customDelete, ...rest }) {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        if (customDelete) {
            await customDelete();
            onDelete();
            setOpen(false);
        } else {
            secureFetch(route, null, 'DELETE').then(json => {
                onDelete();
                setOpen(false);
            });
        }
    };

    return (
        <div>
            <Tooltip title={t('tooltip.delete')} aria-label={t('tooltip.delete')} onClick={handleClickOpen}>
                <IconButton
                    aria-label={t('tooltip.delete')}
                    size="small"
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle>{t('title.deleting')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" component="div">
                        { text }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {t('button.close')}
                    </Button>
                    <Button onClick={handleDelete} color="primary">
                        {t('button.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
