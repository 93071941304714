import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Typography,
    TextField,
    Grid,
    IconButton,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import AppContext from 'context/AppContext';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
    root: {
    }
}));

const ServicePriceForm = ({ className, pricelist, handleDialogOpenClose, loadPrices, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const [states, setStates] = useState({});
    const [services, setServices] = useState([]);
    const [rowCount, setRowCount] = useState(1);
    const { appValues } = useContext(AppContext);
    const [alertMessage, setAlertMessage] = useState('');

    const handleChange = (event, index) => {
        let tmpValues = values;
        tmpValues[index] = { ...tmpValues[index], [event.target.name]: event.target.value };
        setValues({ ...tmpValues });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if ( !states.states_to || states.states_to.length === 0 ) {
            setAlertMessage(t('messages.states_not_blank'));
            return;
        }
        for (const key in values) {
            values[key].state_to = states.states_to.map(state => state.id);
            values[key].price_list = pricelist.id;
        }
        secureFetch(getApiRoute('admin_serviceprice'), { prices: values }, 'POST').then(json => {
            setAlertMessage('');
            handleDialogOpenClose();
            loadPrices();
        });
    };

    useEffect(() => {
        if (pricelist.id) {
            secureFetch(getApiRoute('admin_service_list', [ pricelist.id ])).then(json => {
                setServices(json);
            });
        }
    }, [secureFetch, pricelist]);

    const addRow = () => {
        setRowCount( rowCount + 1 );
    };

    const removeRow = (index) => {
        let tmpValues = values;
        while(index + 1 < rowCount){
            tmpValues[index] = tmpValues[index + 1];
            index++;
        }
        delete tmpValues[rowCount - 1];
        setValues({ ...tmpValues });
        setRowCount( rowCount - 1 );
    };

    const rows = [];
    for (let i = 0; i < rowCount; i++) {
        rows.push(
            <React.Fragment key={i} >
                <Grid item xs={3}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>{t('label.service')}</InputLabel>
                        <Select
                            value={values[i] && values[i].service ? values[i].service : ''}
                            name="service"
                            onChange={ (event) => { handleChange(event, i) } }
                            required
                        >
                            {services.map((option) => (
                                <MenuItem 
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.code + ' ' + option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('label.percentage')}
                        name="percentage"
                        onChange={ (event) => { handleChange(event, i) } }
                        value={values[i] && values[i].percentage ? values[i].percentage : ''}
                        type="number"
                        inputProps={{ step: "0.001" }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('label.price')}
                        name="price"
                        onChange={ (event) => { handleChange(event, i) } }
                        value={values[i] && values[i].price ? values[i].price : ''}
                        type="number"
                        inputProps={{ step: "0.001" }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={2}>
                    <IconButton onClick={ () => removeRow(i)} size="small" >
                        <ClearIcon />
                    </IconButton>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.create_price')}
            </Typography>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={6}>
                    <Autocomplete
                        multiple
                        options={appValues.states}
                        onChange={ (event, pStates) => setStates({ ...states, states_to: pStates }) }
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label={t('label.states_to')} placeholder={t('label.state')} />
                        )}
                        />
                </Grid>
                <Grid item xs={6}>
                </Grid>
                {rows}
            </Grid>
            <Box display="flex" py={2}>
                <Button color="secondary" variant="text" onClick={addRow} >
                    {t('button.add_price')}
                </Button>
            </Box>
            { 
                alertMessage === '' ? null : 
                <Box>
                    <Alert variant="filled" severity="error">
                        {alertMessage}
                    </Alert>
                </Box>
            }
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Button color="secondary" variant="text" onClick={handleDialogOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.create')}
                </Button>
            </Box>
        </form>
    );
};

ServicePriceForm.propTypes = {
    className: PropTypes.string
};

export default ServicePriceForm;
