import React from 'react';
import DashboardLayout from 'layouts/DashboardLayout';
import { Navigate } from 'react-router-dom';
import UserListView from 'views/User/UserListView';
import UserNewView from 'views/User/UserNewView';
import UserEditView from 'views/User/UserEditView';
import ShipperListView from 'views/Shipper/ShipperListView';
import ShipperNewView from 'views/Shipper/ShipperNewView';
import ShipperEditView from 'views/Shipper/ShipperEditView';
import PartnerCommissionListView from 'views/PartnerCommission/PartnerCommissionListView';
import PartnerCommissionNewView from 'views/PartnerCommission/PartnerCommissionNewView';
import PartnerCommissionEditView from 'views/PartnerCommission/PartnerCommissionEditView';
import PriceListListView from 'views/PriceList/PriceListListView';
import PriceListNewView from 'views/PriceList/PriceListNewView';
import PriceListEditView from 'views/PriceList/PriceListEditView';
import ServiceListView from 'views/Service/ServiceListView';
import ServiceNewView from 'views/Service/ServiceNewView';
import ServiceEditView from 'views/Service/ServiceEditView';
import MessageNewEditView from 'views/Message/MessageNewEditView';
import ProfitStatisticsView from 'views/Statistics/ProfitStatistics';
import PartnerProfitStatistics from 'views/Statistics/PartnerProfitStatistics';
import PackageCountStatistics from 'views/Statistics/PackageCountStatistics';
import ReturnedPackageStatistics from 'views/Statistics/ReturnedPackageStatistics';
import NotPricedPackageStatistics from 'views/Statistics/NotPricedPackageStatistics';
import PackagePriceView from 'views/Package/PackagePriceView';
import CodLimitView from 'views/CodLimit';
import ServiceNeoshipView from 'views/ServiceNeoshipView';

const roleAdminRoutes = [
    {
        path: 'app/admin',
        element: <DashboardLayout />,
        children: [
            { path: '/user/list', element: <UserListView /> },
            { path: '/user/new', element: <UserNewView /> },
            { path: '/user/edit/:id', element: <UserEditView /> },
            { path: '/user/edit/:id/:tab', element: <UserEditView /> },
            { path: '/shipper/list', element: <ShipperListView /> },
            { path: '/shipper/new', element: <ShipperNewView /> },
            { path: '/shipper/:id', element: <ShipperEditView /> },
            { path: '/partnercommission/list', element: <PartnerCommissionListView /> },
            { path: '/partnercommission/new', element: <PartnerCommissionNewView /> },
            { path: '/partnercommission/:id', element: <PartnerCommissionEditView /> },
            { path: '/pricelist/list', element: <PriceListListView /> },
            { path: '/pricelist/new', element: <PriceListNewView /> },
            { path: '/pricelist/:id', element: <PriceListEditView /> },
            { path: '/pricelist/:id/:tab', element: <PriceListEditView /> },
            { path: '/service/neoship', element: <ServiceNeoshipView /> },
            { path: '/service/list', element: <ServiceListView /> },
            { path: '/service/new', element: <ServiceNewView /> },
            { path: '/service/:id', element: <ServiceEditView /> },
            { path: '/message/new', element: <MessageNewEditView /> },
            { path: '/statistics/profit', element: <ProfitStatisticsView /> },
            { path: '/statistics/notpriced', element: <NotPricedPackageStatistics /> },
            { path: '/statistics/partner', element: <PartnerProfitStatistics /> },
            { path: '/statistics/packagecount', element: <PackageCountStatistics /> },
            { path: '/statistics/returnedpackage', element: <ReturnedPackageStatistics /> },
            { path: '/package/price/:id/:reference_number', element: <PackagePriceView /> },
            { path: '/codlimit', element: <CodLimitView /> },
            { path: '*', element: <Navigate to="/404" replace={true} /> }
        ]
    }
];

export default roleAdminRoutes;