import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Tooltip,
    Box,
    makeStyles,
    IconButton
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AddIcon from '@material-ui/icons/Add';
import { NavLink as RouterLink } from 'react-router-dom';
import DeleteIconDialog from 'components/DeleteIconDialog';
import EditIcon from '@material-ui/icons/Edit';
import CopyIconDialog from './CopyIconDialog';
import UsersIconDialog from './UsersIconDialog';
import AppContext from 'context/AppContext';
import UpdateFuelDialog from "./UpdateFuelDialog";
import { getShipperIcon } from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const PriceListTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);

    const loadPriceList = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('admin_pricelist_list') ).then(json => {
            setRows(json.map(d => {
                d.shipper = d.shipper.shortcut;
                return d;
            }));
            setLoading(false);
        });
    }, [secureFetch])

    useEffect(() => {
        loadPriceList();
    }, [loadPriceList]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'name'
        },
        headCells: [
            { id: 'shipper', numeric: false, label: t('label.shipper'), search: true, render: (data) => getShipperIcon(data), searchType: 'select', selectChoices: appValues.shippers.map(s => {
                return {
                    id: s.shortcut.toLowerCase(),
                    name: s.shortcut,
                };
            }) },
            { id: 'name', numeric: false, label: t('label.name'), search: true },
            { id: 'type', numeric: false, label: t('label.type'), search: true, render: (data) => t('label.' + data.type), searchType: 'select', selectChoices: appValues.pricelistTypes.map((el) => {
                return { id: el, name: t('label.' + el)}
            } )  },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                return (
                    <Box display="flex">
                        <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')}>
                            <IconButton
                                aria-label={t('tooltip.edit')}
                                className={classes.rowButtons}
                                component={RouterLink}
                                size="small"
                                to={"/app/admin/pricelist/" + data.id}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <CopyIconDialog data={data} loadPriceList={loadPriceList} />
                        <UsersIconDialog data={data} />
                        <DeleteIconDialog
                            text={
                                <React.Fragment>
                                    {t('messages.really_delete')}
                                    <br />
                                    <pre>
                                        {JSON.stringify(data, null, 2)}
                                    </pre>
                                </React.Fragment>
                            }
                            route={getApiRoute('admin_pricelist', [data.id])}
                            onDelete={loadPriceList}
                        />
                    </Box>
                );
            } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        size="small"
                        startIcon={<AddIcon />}
                        component={RouterLink}
                        to={'/app/admin/pricelist/new'}
                    >
                        {t('button.create_pricelist')}
                    </Button>
                </React.Fragment>
            );
        },
        topLeftButtons: () => {
            return (
                <UpdateFuelDialog />
            );
        },
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default PriceListTable;