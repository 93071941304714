import React from 'react';
import {
    Box,
    Button,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        width: '100%',
        position: 'relative',
        zIndex: '0',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 560
    },
    text404: {
        fontSize: '10vw'
    },
    textNotFound: {
        fontSize: '5vw'
    },
    textDesc: {
        maxWidth: '800px',
        margin: '1rem auto',
        padding: '1rem'
    }
}));

const NotFoundView = () => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title="404"
        >
            <div id="clouds">
                <div className="cloud x1"></div>
                <div className="cloud x2"></div>
                <div className="cloud x4"></div>
            </div>
            <div className="notfound">
                <Typography className={classes.text404} variant="h1" component="h1" align="center" color="secondary">
                    404
                </Typography>
                <Typography className={classes.textNotFound} variant="h1" align="center" color="secondary">
                    NOT FOUND
                </Typography>
                <Typography className={classes.textDesc} variant="h4" align="center" color="secondary">
                    Mrzí nás to, ale Vami zadaný objekt sa v aplikácii nenachádza. Prekontrolujte prosím Vašu požiadavku. Je možné, že problém bol medzičasom odstránený alebo premenovaný. V prípade akýchkoľvek otázok nás neváhajte kontaktovať.
                </Typography>
                <Box display="flex" justifyContent="center" p={2}>
                    <Button variant="contained" color="primary" to="/" component={RouterLink} >
                        Prejsť na NEOSHIP
                    </Button>
                    <Box p={2}/>
                    <Button variant="contained" color="primary" href="https://info.neoship.sk/sk/kontakt">
                        Kontaktovať nás
                    </Button>
                </Box>
            </div>
        </Page>
    );
};

export default NotFoundView;
