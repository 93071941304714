import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    Typography,
    CardContent,
} from '@material-ui/core';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { Bar } from 'react-chartjs-2';
import AppContext from 'context/AppContext';

const PartnerProfitByMonthStatistics = ({ params, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const { appValues } = useContext(AppContext);

    useEffect(() => {
        secureFetch(getApiRoute( appValues.user.roles.includes('ROLE_ADMIN') ? 'admin_statistics' : 'partner_statistics', ['partnerprofitcounttype']) + '?' + params).then(json => {
            let tmpData = [];
            let tmpLabels = [];
            for (const obj of json) { //sort by year, month
                let key = obj.month + '/' + obj.year;
                if ( !tmpData[key] ) {
                    tmpData[key] = [];
                    tmpLabels.push(key);
                }
                tmpData[key].push(obj);
            }

            //clone
            let commissions = [{'name': 'Akvizičná','type':['acquisition', 'acquisition_basic', 'acquisition_premium'], 'values':[]}, {'name':'Servisná','type':['service', 'service_basic', 'service_premium'], 'values': []}];
            for (const key in tmpData) { //each year/month
                for (const comm of commissions) {
                    let find = false;
                    for (const val of tmpData[key]) {
                        if ( comm.type.includes(val.commission_type) ) {
                            comm.values.push(val.count);
                            find = true;
                        }
                    }
                    if (!find) {
                        comm.values.push(0);
                    }
                }
            }

            setData(commissions);
            setLabels(tmpLabels);
        });
    }, [secureFetch, appValues.commissions, appValues.user.roles, params]);

    const chartData = {
        labels: labels,
        datasets: data.length === 0 ? null : [
            {
                label: data[0].name,
                data: data[0].values,
                backgroundColor: 'rgb(32, 87, 144)',
            },
            {
                label: data[1].name,
                data: data[1].values,
                backgroundColor: 'rgb(88, 168, 60)',
            },
        ],
    }

    const options = {
        scales: {
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => {
                    const name = data.datasets[ tooltipItem.datasetIndex ].label;
                    const value = data.datasets[ tooltipItem.datasetIndex ].data[ tooltipItem.index ];
                    let total = 0;
                    for (const v of data.datasets) {
                        total += v.data[ tooltipItem.index ];
                    }
                    return `${name}: ${value} / ${total} zásielok`;
                }
            }
        }
    }

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <Typography variant="h4" component="h2" align="center">
                    {t('title.partner_profit_count_month')}
                </Typography>
                <Bar height={80} data={chartData} options={options} />
            </CardContent>
        </Card>
    );
}

export default PartnerProfitByMonthStatistics;