import React from 'react';
import clsx from 'clsx';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
    makeStyles
} from '@material-ui/core';
import { formatNumber } from 'helpers';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    avatar: {
        height: 56,
        width: 56,
        '& svg': {
            fontSize: '2.5em'
        }
    },
    card: {
        height: '100%',
        '& *': {
            color: 'white'
        }
    }
}));

const UserStatusCard = ({ iconBg, location, icon, title, count, ...rest }) => {
    const classes = useStyles();
    let navigate = useNavigate();

    return (
        <React.Fragment>
            <Card
                className={clsx(classes.root, "cardHover")}
                onClick={() => navigate(location)}
            >
                <CardContent className={classes.card} style={{ backgroundColor: iconBg }}>
                    <Grid
                        container
                        justify="space-between"
                        spacing={3}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="h6"
                                className="uppercase"
                            >
                                {title}
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h3"
                            >
                                {formatNumber(count)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Avatar className={classes.avatar} style={{ backgroundColor: iconBg }}>
                                {icon}
                            </Avatar>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment >
    );
};

export default UserStatusCard;
