import React, { useEffect, useState, useContext } from 'react';
import {
    Container,
    Grid,
    Typography,
    Card,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { isNumeric } from "helpers";
import AddressesPreview from './AddressesPreview';
import AdditionalInfoPreview from './AdditionalInfoPreview';
import TrackingPreview from './TrackingPreview';
import StatusTable from './StatusTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import LoadedValuesContext from 'context/LoadedValuesContext';
import CalculatedPrices from 'views/Package/CalculationView/CalculatedPrices';
import CreditLogTable from 'views/CreditLogView/CreditLogTable';
import CalculatedPricesService from 'views/Package/CalculationView/CalculatedPricesService';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
    }
}));

export default function PackageDetailView() {
    let navigate = useNavigate();
    const { id, reference_number } = useParams();
    const classes = useStyles();
    const { t } = useTranslation();
    const [values, setValues] = useState({});
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const { loadedValues, setLoadedValues } = useContext(LoadedValuesContext);

    useEffect(() => {
        if (!loadedValues.statusesByType) {
            secureFetch(getApiRoute('statustype', ['bytype'])).then((json) => {
                setLoadedValues({ ...loadedValues, statusesByType: json });
            });
        }
    }, [secureFetch, loadedValues, setLoadedValues]);

    const loadPackage = () => {
        secureFetch(getApiRoute(appValues.user.roles.includes('ROLE_USER') ? 'package' : 'admin_package', [id])).then(json => {
            setValues(json);
        });
    }

    useEffect(() => {
        if (!isNumeric(id) || (reference_number && reference_number === '') ) {
            navigate('/404');
        }

        if (id && id !== '0') {
            loadPackage();
        }
        else if (reference_number) {
            secureFetch(getApiRoute( appValues.user.roles.includes('ROLE_USER') ? 'package_reference_number' : 'admin_package_reference_number', [reference_number])).then(json => {
                setValues(json);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, reference_number, navigate, appValues.user.roles, secureFetch]);

    const getStatusClass = () => {
        if (loadedValues.statusesByType && values.shipper) {
            if (loadedValues.statusesByType[values.shipper.id].transit.includes(values.last_status.id)) {
                return 'transit';
            }

            if (loadedValues.statusesByType[values.shipper.id].delivered.includes(values.last_status.id)) {
                return 'delivered';
            }

            if (loadedValues.statusesByType[values.shipper.id].notdelivered.includes(values.last_status.id)) {
                return 'notdelivered';
            }
        }
        return '';

    };

    return (
        <Page
            className={`${classes.root}`}
            title={t('title.package_detail')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('title.package_detail')}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <AddressesPreview values={values} className={`${getStatusClass()}`} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AdditionalInfoPreview values={values} className={`${getStatusClass()}`} loadPackage={loadPackage} />
                    </Grid>
                    {
                        values.tracking_number &&
                        <Grid item xs={12} md={6}>
                            <TrackingPreview tracking_number={values.tracking_number} className={`${getStatusClass()}`} language='SK' />
                        </Grid>
                    }
                    {
                        values.shipper &&
                        <Grid item xs={12} md={6}>
                            {!values.is_service &&
                                <CalculatedPrices packageId={values.id} className={`${getStatusClass()}`}
                                                  shipperId={values.shipper.id}/>
                            }
                            {values.is_service &&
                                <CalculatedPricesService packageId={values.id} className={`${getStatusClass()}`}
                                                  shipperId={values.shipper.id}/>
                            }
                        </Grid>
                    }
                    {
                        values.reference_number &&
                        <Grid item xs={12}>
                            <Card>
                                <CreditLogTable packageId={id} />
                            </Card>
                        </Grid>
                    }
                    {
                        appValues.user.roles.includes('ROLE_ADMIN') && values.id &&
                        <Grid item xs={12}>
                            <StatusTable id={id !== '0' ? id : values.id} />
                        </Grid>
                    }
                </Grid>
            </Container>
        </Page>
    );
}