import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles,
    IconButton,
    Tooltip, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import AppContext from 'context/AppContext';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import NewDeliveryAddressDialog from "./NewDeliveryAddressDialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import {getAllAdminPickups, getAllPickups, getDefaultAddress} from "../../../helpers";
import DeleteIconDialog from "../../../components/DeleteIconDialog";
import PickupAddressDetail from "./PickupAddressDetail";

const useStyles = makeStyles(() => ({
    root: {}
}));

const DeliveryAddressForm = ({ className, user, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const { appValues } = useContext(AppContext);

    useEffect(() => {
        user.address = user.address ? user.address : {};
        setValues({
            name: user.address.name,
            company: user.address.company,
            email: user.address.email,
            phone: user.address.phone,
            street: user.address.street,
            city: user.address.city,
            zip: user.address.zip,
            house_number: user.address.house_number,
            state: user.address.state ? user.address.state.id : appValues.states[ appValues.states.findIndex(i => i.code === 'SK') ].id || null,
        })
    }, [user, appValues.states]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const {id: _, ...tmpAddress} = values;
        secureFetch( getApiRoute('admin_user', [user.id]), { 'address': tmpAddress}, 'PUT' ).then(json => {
            setSuccessMessage(t('messages.data_saved'));
        });
    }

    const copyInvoiceAddress = () => {
        if (user.invoice_address) {
            setValues({
                name: user.invoice_address.name,
                company: user.invoice_address.company,
                email: user.invoice_address.email,
                phone: user.invoice_address.phone,
                street: user.invoice_address.street,
                city: user.invoice_address.city,
                zip: user.invoice_address.zip,
                house_number: user.invoice_address.house_number,
                state: user.invoice_address.state ? user.invoice_address.state.id : appValues.states[appValues.states.findIndex(i => i.code === 'SK')].id || null,
            })
        }
    }

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Card>
                <CardHeader
                    subheader={t('messages.delivery_data_desc')}
                    title={t('title.delivery_data')}
                    action={
                        <Tooltip title={t('messages.copy_invoice_address')}>
                            <IconButton color="inherit" onClick={copyInvoiceAddress}>
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                    }
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.name')}
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.company')}
                                        name="company"
                                        onChange={handleChange}
                                        value={values.company || ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.email')}
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.phone')}
                                        name="phone"
                                        onChange={handleChange}
                                        value={values.phone || ''}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item lg={7} xs={8}>
                                    <TextField
                                        fullWidth
                                        label={t('label.street')}
                                        name="street"
                                        onChange={handleChange}
                                        value={values.street || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item lg={5} xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('label.house_number')}
                                        name="house_number"
                                        onChange={handleChange}
                                        value={values.house_number || ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.city')}
                                        name="city"
                                        onChange={handleChange}
                                        value={values.city || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.zip')}
                                        name="zip"
                                        onChange={handleChange}
                                        value={values.zip || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="deliverystates">{t('label.state')}</InputLabel>
                                        <Select
                                            labelId="deliverystates"
                                            id="demo-simple-select"
                                            value={ values.state || '' }
                                            name="state"
                                            onChange={handleChange}
                                        >
                                            {appValues.states.map((option) => (
                                                <MenuItem 
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box my={2}>
                        {getAllAdminPickups(user, false).map((addr, idx) => (
                            <Accordion key={idx}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={addr.id + '-content'}
                                    id={addr.id + '-header'}
                                >
                                    {/*<FormControlLabel*/}
                                    {/*    aria-label="active"*/}
                                    {/*    onClick={(event) => event.stopPropagation()}*/}
                                    {/*    onFocus={(event) => event.stopPropagation()}*/}
                                    {/*    control={<Checkbox*/}
                                    {/*        checked={!!addr.active}*/}
                                    {/*        name="checkboxInfo"*/}
                                    {/*        color="primary"*/}
                                    {/*        disabled*/}
                                    {/*    />}*/}
                                    {/*    label={addr.id + '. ' + addr.address.city}*/}
                                    {/*/>*/}
                                    <Typography style={{color: addr.active ? "green" : "red"}}>{addr.id + '. ' + addr.address.city}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <PickupAddressDetail values={addr.address} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="flex-end" p={2}>
                                                <DeleteIconDialog
                                                    text={
                                                        <React.Fragment>
                                                            {t('messages.delete_address')}
                                                            <br/>
                                                            <pre>
                                                            {JSON.stringify(addr.address, null, 2)}
                                                        </pre>
                                                        </React.Fragment>
                                                    }
                                                    route={getApiRoute('admin_user', ['pickup', addr.id])}
                                                    onDelete={() => window.location.reload()}
                                                />
                                                <NewDeliveryAddressDialog user={user} address={addr}/>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))
                        }
                    </Box>
                </CardContent>
                { 
                    successMessage === '' ? null : 
                    <Box px={2}>
                        <Alert variant="filled" severity="success">
                            {successMessage}
                        </Alert>
                    </Box>
                }
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <NewDeliveryAddressDialog user={user} />
                    <Button color="primary" variant="contained" type="submit">
                        { t('button.save') }
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

DeliveryAddressForm.propTypes = {
    className: PropTypes.string,
};

export default DeliveryAddressForm;
