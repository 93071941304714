import React, { useEffect, useState, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Box,
    makeStyles
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CodPriceForm from './CodPriceForm';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import DeleteIconDialog from 'components/DeleteIconDialog';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const CodLimitTable = ({ className, pricelist, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = React.useState(false);
    const secureFetch = useContext(SecureFetchContext);
    const [successMessage, setSuccessMessage] = useState('');

    const handleDialogkOpenClose = () => {
        setOpenDialog(!openDialog);
    };

    const loadCodLimits = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('admin_codlimit') ).then(json => {
            setRows(json);
            setLoading(false);
            setSuccessMessage('');
        })
    }, [secureFetch])

    useEffect(() => {
        loadCodLimits();
    }, [loadCodLimits]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'state'
        }, 
        headCells: [
            { id: 'shipper', label: t('label.shipper'), search: true },
            { id: 'currency', label: t('label.currency'), search: true },
            { id: 'state', label: t('label.state'), search: true },
            { id: 'limit_cod', numeric: true, label: t('label.limit_cod'), search: false, render: (data) => parseFloat(data.limit_cod).toFixed(2)},
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                return (
                    <DeleteIconDialog
                        text={
                            <React.Fragment>
                                {t('messages.delete_price')}
                                <br/>
                                <pre>
                                    {JSON.stringify(data, null, 2)}
                                </pre>
                            </React.Fragment>
                        }
                        route={getApiRoute('admin_codlimit', [data.id])}
                        onDelete={loadCodLimits}
                    />
                )
            } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleDialogkOpenClose}
                    >
                        {t('button.create_cod_limit')}
                    </Button>
                </React.Fragment>
            );
        }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                { 
                    successMessage === '' ? null : 
                    <Box px={2} pb={1} >
                        <Alert variant="filled" severity="success">
                            {successMessage}
                        </Alert>
                    </Box>
                }
                <ClientTable settings={settings} />
            </CardContent>
            <Dialog
                open={openDialog}
                onClose={handleDialogkOpenClose}
                maxWidth="md"
            >
                <DialogContent>
                    <CodPriceForm handleDialogkOpenClose={handleDialogkOpenClose} loadCodLimits={loadCodLimits} />
                </DialogContent>
            </Dialog>
        </Card>
    );
}

export default CodLimitTable;