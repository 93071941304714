import React from 'react';
import {
    makeStyles,
    Typography,
    Card,
    CardContent,
    Link,
} from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '260px',
        maxWidth: '100%',
        height: '100%',
    },
    media: {
        height: '250px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
}));

const Person = ({name, description, phone, email, image, ...rest }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={6}>
            {
                image &&
                <CardMedia
                    className={classes.media}
                    image={image}
                    title="Contemplative Reptile"
                />
            }
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {description}
                </Typography>
                {
                    phone && 
                    <Link href={"tel:" + phone} variant="body2" component="p">
                        Tel: {phone}
                    </Link>
                }
                {
                    email &&
                    <Link href={"mailto:" + email} variant="body2" component="p">
                        Email: {email}
                    </Link>
                }
            </CardContent>
        </Card>
    );
};

export default Person;