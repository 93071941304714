import React, { useContext, useState, useEffect } from 'react';
import {
    Container,
    Grid,
    Typography,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import AddressForm from './AddressForm';
import AdditionalnfoForm from './AdditionalnfoForm';
import ServicesForm from './ServicesForm';
import AppContext from 'context/AppContext';
import { useParams } from "react-router-dom";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import {getDefaultAddress} from "../../../helpers";

export default function PackageNewEditView() {
    const { t } = useTranslation();
    const { id, shipper, parcelshop_id , address_id} = useParams();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    let navigate = useNavigate();
    const { state } = useLocation();

    const defaultPickup = state && state.pickup ? state.pickup : (
        state && state.receiver && state.receiver.pickup_address ? state.receiver.pickup_address : getDefaultAddress(appValues.user)
    );
    const [senderValues, setSenderValues] = useState({
        sender_name: defaultPickup ? defaultPickup.address.name : null,
        sender_company: defaultPickup ? defaultPickup.address.company : null,
        sender_street: defaultPickup ? defaultPickup.address.street : null,
        sender_house_number: defaultPickup ? defaultPickup.address.house_number : null,
        sender_city: defaultPickup ? defaultPickup.address.city : null,
        sender_zip: defaultPickup ? defaultPickup.address.zip : null,
        sender_email: defaultPickup ? defaultPickup.address.email : null,
        sender_phone: defaultPickup ? defaultPickup.address.phone : null,
        sender_state_code: defaultPickup ? defaultPickup.address.state.code : 'SK',
    });

    const [receiverValues, setReceiverValues] = useState( state && state.receiver ? {
            receiver_city: state.receiver.receiver_city,
            receiver_company: state.receiver.receiver_company,
            receiver_email: state.receiver.receiver_email,
            receiver_house_number: state.receiver.receiver_house_number,
            receiver_name: state.receiver.receiver_name,
            receiver_phone: state.receiver.receiver_phone,
            receiver_state_code: state.receiver.receiver_state_code,
            receiver_street: state.receiver.receiver_street,
            receiver_zip: state.receiver.receiver_zip,
            parcelshop: state.receiver.parcelshop,
            address: state.receiver.address,
        }:{
            parcelshop: null,
            address: null,
            receiver_state_code: 'SK',
            carrier_type: null
    });

    const [servicesValues, setServicesValues] = useState({ //actual services
        services: {}
    });

    const [additionalValues, setAdditionalValues] = useState({
        insurance: null,
        reverse: false,
        save_address: false
    });

    const [currencies, setCurrencies] = useState([]);
    const [valuesErrors, setValuesErrors] = useState({
        services: {}
    });
    const [notAllowedServices, setNotAllowedServices] = useState(null);
    const [services, setServices] = useState([]); //all services
    const shipperObject = appValues.shippers.find((s) => s.id === parseInt(shipper));

    const [stateFrom, setStateFrom] = useState(appValues.states.find(state => state.code === 'SK'));
    const [statesFrom, setStatesFrom] = useState([appValues.states.find(state => state.code === 'SK')]);
    const [stateTo, setStateTo] = useState(null);
    const [statesTo, setStatesTo] = useState([]);

    const [loader, setLoader] = useState(false);
    const [firstRenderFinished, setFirstRenderFinished] = useState(false);
    const [ reverse, setReverse ] = useState('no_reverse');
    const [carrierTypes, setCarrierTypes] = useState([]);

    const handleChangeSender = (event) => {
        setSenderValues({
            ...senderValues,
            [event.target.name]: event.target.value
        });
    };
    const handleChangeReceiver = (event) => {
        setReceiverValues({
            ...receiverValues,
            [event.target.name]: event.target.value
        });
    };
    const handleChangeAdditional = (event) => {
        setAdditionalValues({
            ...additionalValues,
            [event.target.name]: event.target.value
        });
    };

    const handleServiceChange = (event) => {
        let tmpServices = { ...servicesValues.services, [event.target.name]: event.target.value };
        setServicesValues({
            ...servicesValues,
            services: tmpServices
        });
    };

    const handleCheckboxChangeAdditional = (event) => {
        setAdditionalValues({ ...additionalValues, [event.target.name]: event.target.checked});
    };

    const handleServiceCheckboxChange = (event) => {
        let tmpServices = { ...servicesValues.services, [event.target.name]: !!event.target.checked };
        setServicesValues({
            ...servicesValues,
            services: tmpServices
        });
    };

    const onStateChange = async (e, state, name) => {
        if (state) {
            if (name === 'receiver_state_code') {
                let json = await secureFetch(getApiRoute('codlimit', [shipper, state.id]));
                let curr = json.map((val) => val.currency);
                setCurrencies(curr);
                setStateTo(state);
                setAdditionalValues({ ...additionalValues, 'cod_currency_code': curr[0] ? curr[0].code : additionalValues['cod_currency_code'] });
                if (shipper === '3' && !parcelshop_id) {
                    let carrierType = await secureFetch(getApiRoute('price_list_carrier', [shipper, state.id]));
                    setCarrierTypes(carrierType);
                    setReceiverValues({ ...receiverValues, [name]: state.code, carrier_type: null});
                } else {
                    setReceiverValues({ ...receiverValues, [name]: state.code});
                }
            } else {
                if (name === 'sender_state_code') {
                    setStateFrom(state);
                }
                setSenderValues({ ...senderValues, [name]: state.code });
            }
        }
    };

    const onCarrierTypesChange = async (e, pCarrierType) => {
        if (pCarrierType) {
            setReceiverValues({
                ...receiverValues,
                carrier_type: pCarrierType.value,
            })
        }
    }

    useEffect(() => { //if edit load package data
        if (receiverValues.receiver_state_code === 'SK' && !additionalValues.cod_currency_code && currencies.length === 0 && firstRenderFinished) {
            secureFetch(getApiRoute('codlimit', [shipper, appValues.states.find(state => state.code === receiverValues.receiver_state_code).id])).then((json) => {
                let curr = json.map((val) => val.currency);
                setCurrencies(curr);
                setAdditionalValues({ ...additionalValues, 'cod_currency_code': curr[0].code });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureFetch, shipper, additionalValues, currencies.length]);

    useEffect(() => { // load all services for user and selected country
        if (parcelshop_id && receiverValues.parcelshop === null) {
            return;
        }
        if (shipper && receiverValues.receiver_state_code && !id && firstRenderFinished) {
            let url ='';
            if ( appValues.user.roles.includes('ROLE_USER') ) {
                url = getApiRoute('package_services', [shipper, appValues.states.find(state => state.code === receiverValues.receiver_state_code).id])
            }

            if ( url !== '' ) {
                secureFetch(url).then(json => {
                    setServices(json);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureFetch, shipper, appValues.states, receiverValues.receiver_state_code, appValues.user.roles, id, receiverValues.parcelshop, parcelshop_id]);

    useEffect(() => { // get not allowed services combination
        if (servicesValues.services && Object.entries(services).length !== 0 && senderValues.sender_state_code && receiverValues.receiver_state_code && firstRenderFinished) {
            let tmpVal = {
                sender_state_code: senderValues.sender_state_code,
                receiver_state_code: receiverValues.receiver_state_code,
                insurance: additionalValues.insurance,
                reverse: additionalValues.reverse,
                parcelshop: receiverValues.parcelshop,
                services: servicesValues.services,
            };
            secureFetch(getApiRoute('package_services', [shipper]), tmpVal, 'POST' ).then(json => {
                setNotAllowedServices(json);
            });
        } else {
            setNotAllowedServices(services.map(s => s.code));
        }
    }, [secureFetch, services, servicesValues.services, shipper, senderValues.sender_state_code, receiverValues.receiver_state_code, additionalValues.insurance, additionalValues.reverse, receiverValues.parcelshop, firstRenderFinished]);

    useEffect(() => {
        const firstRender = async () => {
            setLoader(true);
            if (shipper !== 3 || !parcelshop_id || !address_id) { //parcelshop/address not needed actually this call
                //STATES TO
                await secureFetch(getApiRoute('price_list_states', ['to', shipper])).then(json => {
                    const tmpState = json.find(s => s.code === receiverValues.receiver_state_code);
                    setStatesTo(json);
                    onStateChange(null, tmpState, 'receiver_state_code');
                })
            }
            if (shipper === '3' && !parcelshop_id){
                let tmpState = appValues.states.find(s => s.code === 'SK');
                await secureFetch(getApiRoute('price_list_carrier', [shipper, tmpState.id])).then(json => {
                    setCarrierTypes(json);
                })
            }
            //STATES FROM NOT NEEDED ACTUALLY
            // await secureFetch(getApiRoute('price_list_states', ['from', shipper])).then(json => {
            //     const tmpState = json.find(s => s.code === senderValues.sender_state_code);
            //     setStatesFrom(json);
            //     onStateChange(null, tmpState, 'sender_state_code');
            // })
            //CURRENCIES
            await secureFetch(getApiRoute('codlimit', [shipper, appValues.states.find(state => state.code === receiverValues.receiver_state_code).id])).then((json) => {
                let curr = json.map((val) => val.currency);
                setCurrencies(curr);
                setAdditionalValues({ ...additionalValues, 'cod_currency_code': curr[0] ? curr[0].code : additionalValues['cod_currency_code'] });
            });

            //ALL SERVICES
            let url ='';
            if ( appValues.user.roles.includes('ROLE_USER') ) {
                url = getApiRoute('package_services', [shipper, appValues.states.find(state => state.code === receiverValues.receiver_state_code).id])
            }
            if ( url !== '' ) {
                await secureFetch(url).then(json => {
                    setServices(json);
                });
            }

            //DEFAULT SERVICES
            let urlSettings;
            if (appValues.user.roles.includes('ROLE_USER')) {
                urlSettings = getApiRoute('usersettings', [shipper]);
            }
            await secureFetch(urlSettings).then(json => {
                for (const key in json) {
                    if (key.includes('DEFAULT_SERVICES')) {
                        let tmpValues = servicesValues;
                        for (const serviceCode in json[key]){
                            if (json[key][serviceCode] === true) {
                                tmpValues.services[serviceCode] = true;
                            }
                        }
                        setServicesValues({...servicesValues, ...tmpValues});
                        break;
                    }
                }
            });

            //IF PARCELSHOP
            if (parcelshop_id && !receiverValues.parcelshop ) {
                // let tmpStateTo ='';
                await secureFetch(getApiRoute('parcelshop', [parcelshop_id])).then(json => {
                    // const tmpStateFrom = appValues.states.find(s => s.code === senderValues.sender_state_code);
                    let tmpStateTo = appValues.states.find(s => s.code === json.state_code);

                    setStateTo(tmpStateTo);
                    // setStateFrom(tmpStateFrom);

                    setReceiverValues({
                        ...receiverValues,
                        parcelshop: json.parcelshop_id,
                        receiver_state_code: json.state_code,
                        receiver_street: json.street,
                        receiver_city: json.city,
                        receiver_company: json.name,
                        receiver_zip: json.zip,
                    });
                    secureFetch(getApiRoute('codlimit', [shipper, tmpStateTo.id])).then(response => {
                        let curr = response.map((val) => val.currency);
                        setCurrencies(curr);
                        setAdditionalValues({
                            ...additionalValues,
                            cod_currency_code: curr[0].code
                        })
                    });

                });
                // onStateChange(null, tmpStateTo, 'receiver_state_code');
            }
            //IF ADDRESS
            if (address_id && !receiverValues.address ) {
                secureFetch(getApiRoute('address', [address_id])).then(json => {
                    // const tmpStateFrom = appValues.states.find(s => s.code === senderValues.sender_state_code);
                    const tmpStateTo = appValues.states.find(s => s.code === json.state.code);

                    setStateTo(tmpStateTo);
                    // setStateFrom(tmpStateFrom);

                    setReceiverValues({
                        ...receiverValues,
                        address: json.id,
                        receiver_state_code: json.state.code,
                        receiver_street: json.street,
                        receiver_house_number: json.house_number,
                        receiver_city: json.city,
                        receiver_name: json.name,
                        receiver_company: json.company,
                        receiver_zip: json.zip,
                        receiver_email: json.email,
                        receiver_phone: json.phone,
                    });
                });
            }

            //HIDE LOADER
            setLoader(false);
            //FIRST RENDER FINISHED
            setFirstRenderFinished(true);
        }
        firstRender()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const removeRedundantFields = (data) => {
        delete data.id;
        delete data.statuses;
        delete data.user;
        delete data.last_status;
        delete data.cod_payment;
        delete data.tracking_number;
        delete data.shipper;
        delete data.last_status;
        delete data.total_weight;
        delete data.packages;
        delete data.others;
        if ( shipperObject.shortcut && shipperObject.shortcut.toUpperCase() !== 'PACKETA' ) {
            delete data.weight;
            delete data.carrier_type;
        }
        // if (shipperObject.shortcut && shipperObject.shortcut.toUpperCase() === 'PACKETA' && data.parcelshop !== null) {
        //     data.receiver_carrier_type = ''
        //     console.log(data)
        // }

        for (const key in data.services) {
            const serviceDefinition = services.find(s => s.code === key.replace('value', ''));
            if( !serviceDefinition ) {
                delete data.services[key];
                delete data.services[key + 'value'];
            } else if ( !serviceDefinition.input_type ){
                delete data.services[key + 'value'];
            }
        }

        return data;
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        let values = {...senderValues, ...receiverValues, ...additionalValues, ...servicesValues};
        values.disable_default_services = true;
        values.source = 'WEB'.toUpperCase(); // must be uppercase
        values.pickup_address = defaultPickup ? defaultPickup.id : undefined;
        secureFetch(getApiRoute('package_new', [shipper]), removeRedundantFields(values), 'POST', false).then(json => {
            const uspl = appValues.user.user_shipper_price_lists.find(uspl => uspl.shipper.id === parseInt(shipper));
            navigate(uspl.sale_price_list.type === 'fix' ? '/app/package/service' : '/app/package');
        }).catch( (json) => {
            setValuesErrors(json);
        } );

    }

    return (
        <Page
            title={id ? t('title.edit_package') : t('title.create_package')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {id ? t('title.edit_package') : t('title.create_package')}
                </Typography>
                <form onSubmit={handleFormSubmit} noValidate>
                    <Grid container spacing={3}>
                        {
                            shipperObject.shortcut && shipperObject.shortcut.toUpperCase() !== 'PACKETA' &&
                            <Grid item xs={12} sm={6} md={4} >
                                <AddressForm
                                    onStateChange={onStateChange}
                                    states={statesFrom}
                                    state={stateFrom}
                                    prefix="sender_"
                                    title={t('title.sender_address')}
                                    values={senderValues}
                                    setValues={setSenderValues}
                                    handleChange={handleChangeSender}
                                    errors={ valuesErrors }
                                    pickupAddress={defaultPickup ? `ID: ${defaultPickup.id}` : null}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} sm={6} md={4}>
                            <AddressForm
                                onStateChange={onStateChange}
                                states={statesTo}
                                state={stateTo}
                                prefix="receiver_"
                                title={t('title.receiver_address')}
                                values={receiverValues}
                                setValues={setReceiverValues}
                                handleChange={handleChangeReceiver}
                                errors={ valuesErrors }
                                parcelshop={ receiverValues.parcelshop }
                                carrierType={ receiverValues.carrier_type }
                                carrierTypes={carrierTypes}
                                onCarrierTypesChange={onCarrierTypesChange}
                                shipper={shipperObject}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AdditionalnfoForm
                                currencies={currencies}
                                title={t('title.additiona_info')}
                                values={additionalValues}
                                setValues={setAdditionalValues}
                                handleChange={handleChangeAdditional}
                                handleCheckboxChange={handleCheckboxChangeAdditional}
                                errors={ valuesErrors }
                                notAllowedServices={notAllowedServices !== null ? notAllowedServices : []}
                                shipper={shipperObject}
                                id={id}
                                senderValues={senderValues}
                                setSenderValues={setSenderValues}
                                receiverValues={receiverValues}
                                setReceiverValues={setReceiverValues}
                                statesTo={statesTo}
                                setStatesTo={setStatesTo}
                                statesFrom={statesFrom}
                                setStatesFrom={setStatesFrom}
                                stateTo={stateTo}
                                setStateTo={setStateTo}
                                stateFrom={stateFrom}
                                setStateFrom={setStateFrom}
                                setCurrencies={setCurrencies}
                                reverse={reverse}
                                setReverse={setReverse}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ServicesForm
                                title={t('title.services')}
                                services={services}
                                values={servicesValues.services}
                                handleChange={handleServiceChange}
                                notAllowedServices={notAllowedServices !== null ? notAllowedServices : []}
                                handleCheckBoxChange={handleServiceCheckboxChange}
                                errors={ valuesErrors.services }
                            />
                        </Grid>
                    </Grid>
                </form>
                <LoadingOverlay
                    active={loader}
                    spinner
                    styles={{
                        wrapper: {position: 'static'}
                    }}
                    classNamePrefix='ServiceFormLoader_'
                    text={<Typography variant='subtitle1'>{t('messages.loading_content')}</Typography> }
                >
                </LoadingOverlay>
            </Container>
        </Page>
    );
}