import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
} from '@material-ui/core';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import ClientTable from "../../../components/ClientTable";


const NotPricedPackageTable = ({ params, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        secureFetch(getApiRoute('admin_statistics', ['notpricedpackages']) + '?' + params).then(json => {
            for (const val of json) {
                val.id = val.eshop;
            }
            setRows(json);
        });
    }, [secureFetch, params]);

    const settings = {
        rows: rows,
        loading: false,
        rowsPerPage: 200,
        colOrder: {
            order: 'asc',
            orderBy: 'count'
        },
        headCells: [
            { id: 'eshop', numeric: false, label: t('label.eshop_name'), search: true },
            { id: 'count', numeric: true, label: t('label.count'), search: false },
        ],
        totalDataRow: (cell, data) => {
            if (cell.id !== 'eshop') {
                let sum = 0;
                for (const row of data) {
                    sum += parseFloat(row[cell.id]);
                }
                return (<b>{sum}</b>);
            }
            else {
                return(
                    <b>{t('label.total') + ' ' + data.length}</b>
                )
            }
        }
    };

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default NotPricedPackageTable;