import React, { useContext, useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Button,
    Grid,
    Box,
    FormControlLabel,
    Checkbox,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AppContext from 'context/AppContext';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import {getCarrierTypes, getFilteredCarrierTypes} from "../../../helpers";

const CalculationMainForm = ({ values, setValues, handleChange, notAllowedServices, handleCheckboxChange, handleServiceCheckboxChange, shipper, setShipper, users, user, setUser, setIsDefServicesSet, setPrices, ...rest }) => {
    const { t } = useTranslation();
    const { appValues } = useContext(AppContext);
    const [currencies, setCurrencies] = useState([]);
    const secureFetch = useContext(SecureFetchContext);
    const [priceLists, setPriceLists] = useState(appValues.user.roles.includes('ROLE_USER') ? appValues.user.user_shipper_price_lists : []);
    const [statesFrom, setStatesFrom] = useState([]);
    const [statesTo, setStatesTo] = useState([]);
    const [carrierTypes, setCarrierTypes] = useState([]);

    let selectedShipper = appValues.shippers.find((option) => option.id === shipper);
    selectedShipper = selectedShipper ? selectedShipper : null;

    useEffect(() => { // get not allowed services combination
        if (shipper) {
            secureFetch(getApiRoute('codlimit', [shipper, appValues.states.find(state => state.code === values.receiver_state_code).id])).then((json) => {
                let curr = json.map((val) => val.currency);
                setCurrencies(curr);
            });
        }
    }, [shipper, values.receiver_state_code, appValues.states, secureFetch])

    useEffect(() => { // get not allowed services combination
        if (currencies[0] && !currencies.find(c => c.code === values.cod_currency_code)) {
            setValues({ ...values, cod_currency_code: currencies[0] ? currencies[0].code : null });
        }
    }, [currencies, setValues, values])

    useEffect(() => { // download allowed states from and states to on shipper change
        if (shipper) {
            secureFetch(getApiRoute('price_list_states', ['from', shipper, user])).then(json => {
                setStatesFrom(json);
                setValues({ ...values, sender_state_code: 'SK' });
            })
            secureFetch(getApiRoute('price_list_states', ['to', shipper, user])).then(json => {
                setStatesTo(json);
                setValues({ ...values, receiver_state_code: 'SK' });
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipper, user])

    const onUserChange = (event, usr) => {
        setUser(usr ? usr.id : null);

        if (usr) {
            setPrices(null);
            setShipper(null);
            setValues({ ...values, services: {} });
            setIsDefServicesSet(false);
            secureFetch(getApiRoute('admin_user', [usr.id])).then(json => {
                setPriceLists(json.user_shipper_price_lists);
            });
        }
    }

    const onShipperChange = (event) => {
        setShipper(event.target.value);
        setValues({ ...values, services: {} });
        setIsDefServicesSet(false);
        if (event.target.value == 3) {
            secureFetch(getApiRoute('price_list_carrier', [event.target.value, appValues.states.find(state => state.code === 'SK').id, appValues.user.roles.includes('ROLE_ADMIN') ? user : null])).then(json => {
                setCarrierTypes(json);
            });

        }
    }

    const onStateToChange = async (e, cStateTo) => {
        setValues({ ...values, receiver_state_code: cStateTo.code , others: {carrier_type: null} });
        if (selectedShipper.shortcut == 'Packeta') {
            secureFetch(getApiRoute('price_list_carrier', [selectedShipper.id,  cStateTo.id, appValues.user.roles.includes('ROLE_ADMIN') ? user : null])).then(json => {
                setCarrierTypes(json);
            });
        }
    }

    const onCarrierTypesChange = async (e, pCarrierType) => {
        if (pCarrierType) {
            setValues({
                ...values,
                others: { carrier_type: pCarrierType.value }
            })
        }
    }

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    {
                        users.length ?
                        <Grid item xs={12}>
                            <Autocomplete
                                options={users}
                                value={users.find(usr => usr.id === user) || null}
                                onChange={onUserChange}
                                getOptionLabel={(option) => option.eshop || ''}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t('label.user')}
                                    className='requiredbg'
                                    required
                                />}
                            />
                        </Grid> : null
                    }
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel >{t('label.shipper')}</InputLabel>
                            <Select
                                value={shipper || ''}
                                name="shipper"
                                className={appValues.user.roles.includes('ROLE_ADMIN') ? 'requiredbg' : ''}
                                onChange={onShipperChange}
                                required
                            >
                                {
                                    priceLists.map((option) => (
                                        <MenuItem
                                            disabled={option.sale_price_list.type === 'fix' ? true : false}
                                            key={option.shipper.id}
                                            value={option.shipper.id}
                                        >
                                            {option.shipper.shortcut}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t('label.weight')}
                            name={"weight"}
                            onChange={handleChange}
                            type="number"
                            value={values["weight"] || ''}
                            inputProps={{ step: "0.001" }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Kg</InputAdornment>, 
                                className: 'requiredbg'
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={statesFrom}
                            value={statesFrom.find(state => state.code === values["sender_state_code"]) || null}
                            onChange={(event, state) => state ? setValues({ ...values, sender_state_code: state.code }) : null}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t('label.state_from')}
                                required
                            />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={statesTo}
                            value={ statesTo.find(state => state.code === values["receiver_state_code"]) || null}
                            onChange={onStateToChange}
                            onChange={(event, cStateTo) => onStateToChange(event, cStateTo)}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField 
                                {...params} 
                                label={t('label.state_to')} 
                                required
                            />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t('label.cod_price')}
                            name={"cod_price"}
                            type="number"
                            onChange={handleChange}
                            value={values["cod_price"] || ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={currencies}
                            value={currencies.find(currency => currency.code === values['cod_currency_code']) || null}
                            onChange={(event, currency) => currency ? setValues({ ...values, 'cod_currency_code': currency.code }) : null}
                            getOptionLabel={(option) => option.symbol}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t('label.currency')}
                            />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel >{t('label.payment_type')}</InputLabel>
                            <Select
                                value={values.payment_type != null ? values.payment_type : ''}
                                name="payment_type"
                                onChange={handleChange}
                            >
                                {appValues.paymentTypes.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t('label.insurance')}
                            name={"insurance"}
                            inputProps={{ step: "0.001" }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            type="number"
                            onChange={handleChange}
                            value={values["insurance"] || ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            {
                                selectedShipper && selectedShipper.shortcut.toUpperCase() === 'PACKETA' ?
                                <React.Fragment>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={values.parcelshop ? true : false}
                                            options={getFilteredCarrierTypes(carrierTypes)}
                                            value={(values.others ) ? getCarrierTypes().find(ct => ct.value === values.others.carrier_type) :''}
                                            onChange={ (event, cCarrierType) => onCarrierTypesChange(event, cCarrierType) }
                                            getOptionLabel={(option) => option ? option.name : ''}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label={t('label.carrier_type')}
                                                    required={true}
                                                />}
                                        />
                                    </FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.parcelshop ? true : false}
                                                onChange={handleCheckboxChange}
                                                name="parcelshop"
                                                value="1"
                                                color="primary"
                                                disabled={notAllowedServices.includes('parcelshop') || (values.others && values.others.claim_assistant)}
                                            />
                                        }
                                        label={t('label.parcelshop')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={values.parcelshop}
                                                checked={(values.others && values.others.claim_assistant) || false}
                                                onChange={() => setValues({ ...values, others: { claim_assistant: !((values.others && values.others.claim_assistant)) } })}
                                                name="claim_assistant"
                                                color="primary"
                                            />
                                        }
                                        label={t('label.return_package')}
                                    />
                                </React.Fragment> :
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.parcelshop ? true : false}
                                            onChange={handleCheckboxChange}
                                            name="parcelshop"
                                            value="1"
                                            color="primary"
                                            disabled={notAllowedServices.includes('parcelshop')}
                                        />
                                    }
                                    label={t('label.parcelshop')}
                                />
                            }
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Box textAlign="right">
                            <Button type="submit" variant="contained" color="primary">
                                {t('button.calculate')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CalculationMainForm;
