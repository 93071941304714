import React, { useEffect, useContext } from 'react';
import {
    Container,
    Typography,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import EditPartnerCommissionForm from './EditPartnerCommissionForm';
import { isNumeric } from "helpers";
import { useParams, useNavigate } from "react-router-dom";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';

export default function PartnerCommissionEditView() {
    const { t } = useTranslation();
    const [partnerCommission, setPartnerCommission] = React.useState({user: {}});
    let { id } = useParams();
    let navigate = useNavigate();
    const secureFetch = useContext(SecureFetchContext);

    useEffect(() => {
        if ( !isNumeric(id) ) {
            navigate('/404');
        }
        if ( partnerCommission.id !== parseInt(id) ) {
            secureFetch( getApiRoute('admin_partnercommission', [id]) ).then(json => {
                setPartnerCommission(json);
            });
        }
    }, [id, navigate, secureFetch, partnerCommission.id]);

    return (
        <Page
            title={t('title.edit_partnercommission') + ' - ' + partnerCommission.user.username}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('title.edit_partnercommission') + ' - ' + partnerCommission.user.username}
                </Typography>
                <EditPartnerCommissionForm partnerCommission={partnerCommission} />
            </Container>
        </Page>
    );
}