import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import PartnerProfitTableActions from './PartnerProfitTableActions';
import AppContext from 'context/AppContext';
import {isAdmin, isPartner} from "../../../helpers";

const PartnerProfitStatisticsTable = ({ params, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [rows, setRows] = useState([]);
    const { appValues } = useContext(AppContext);

    useEffect(() => {
        secureFetch(getApiRoute( isAdmin(appValues.user) ? 'admin_statistics' : 'partner_statistics', ['partnerprofit']) + '?' + params).then(json => {
            let index = 0;
            for (const val of json) {
                val.id = index++;
            }
            setRows(json);
        });
    }, [secureFetch, appValues.user, params]);

    const settings = {
        rows: rows,
        loading: false,
        colOrder: {
            order: 'asc',
            orderBy: 'actions'
        },
        headCells: [
            { id: 'year', numeric: true, label: t('label.year'), search: false },
            { id: 'month', numeric: true, label: t('label.month'), search: false },
            { id: 'eshop', numeric: false, label: t('label.eshop_name'), search: false, hide: isPartner(appValues.user) },
            { id: 'profit', numeric: false, label: t('label.commission'), search: false, render: (data) => parseFloat(data.profit).toFixed(2) + ' €' },
            { id: 'actions', numeric: false, label: t('label.actions'), disableOrder: true, search: false, render: (data) => {
                return (
                    <PartnerProfitTableActions data={data} />
                );
            } },
        ],
    };

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default PartnerProfitStatisticsTable;