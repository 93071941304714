import React from 'react';
import {
    Container,
    Grid,
    Box,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import TrackingPreview from 'views/Package/PackageDetailView/TrackingPreview';
import { useParams, useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.secondary.main,
        width: '100vw',
        position: 'relative',
        '& > div': {
            position: 'relative',
        },
        '&::before': {
            backgroundImage: 'url(/images/map.png)',
            backgroundSize: '80%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            content: '""',
            position: 'absolute',
            height: '100%',
            width: '100%',
            opacity: '0.2',
        }
    }
}));

const TrackingView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { tracking_number } = useParams();
    const [searchParams] = useSearchParams();

    return (
        <Page
            className={classes.root}
            title={t('title.tracking')}
        >
            <Container>
                <Grid container justify="center">
                    <Grid item xs={12} md={8}>
                        <Box pt={3}/>
                        <TrackingPreview tracking_number={tracking_number} showAll={true} language={searchParams.get("lang")} />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default TrackingView;