import React, { useEffect, useContext, useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Box,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SendIcon from '@material-ui/icons/Send';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CheckIcon from '@material-ui/icons/Check';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import {useLocation} from "react-router-dom";
import {createUrlQuery, formatDate} from "../../../helpers";
import i18n from "i18next";

const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        color: 'white',
        background: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '50%',
        position: 'relative',
        width: '4.6em',
        height: '4.6em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        "& svg": {
            fontSize: '2.5em',
            position: 'relative'
        }
    },
    spacer: {
        height: '0.6rem',
        width: 'calc(100% - 1rem)',
        marginTop: '2em',
        background: 'rgba(0, 0, 0, 0.3)',
        position: 'relative'
    },
    iconDesc: {
        width: '75px',
    },
    [theme.breakpoints.down('xs')]: {
        icon: {
            fontSize: '1em',
        },
        spacer: {
            marginTop: '1.3em',
        },
        iconDesc: {
            width: '70px',
        },
        mainGrid: {
            padding: '0px !important',
        },
        logoGrid: {
            order: 1,
            width: '100%'
        },
        tableGrid: {
            order: 2,
        },
        spaceGrid: {
            order: 3,
        },
    },
}));

const TrackingPreview = ({ className, tracking_number, showAll, language, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const { state } = useLocation();

    useEffect(() => {
        fetch(getApiRoute('tracking', [tracking_number, state]) + '?' + createUrlQuery({lang: language})).then((data) => {
            return data.json();
        }).then((json) => {
            if (json.redirect && showAll) {
                window.location.href = json.redirect;
            } else {
                i18n.changeLanguage(String(json['language']).toLowerCase());
                setValues(json);
            }
        }).catch(() => {
            console.log('error');
        })
    }, [tracking_number, secureFetch, state, language]);

    const lastState = values.step ? 
        (values.step.lastIndexOf(true) !== -1 ? values.step.lastIndexOf(true) : 0) : 0;

    const getPhoneNumber = () => {
        switch (values.shipper) {
            case "GLS":
                return 'GLS: 18 585';
            case "SPS":
                return 'SPS: 02 16877';
            case "PACKETA":
                return 'Packeta: +421 221 201 135';
            case "123":
                return '123Kuriér: 18 555';
            default:
                return "";
        }
    }

        return (
        <Card style={{ height: '100%' }}>
            <CardHeader
                title={t('title.tracking') + (values.receiver_state_code === "SK" ? ' - Tel. kontakt na prepravcu ' + getPhoneNumber() : "")}
                className={className}
            />
            <Divider />
            <CardContent style={values.shipper==='DPD' ? {opacity: 0.4} : null}>
                {
                    showAll && 
                    <Grid container alignItems="center">
                        <Grid className={classes.tableGrid} item sm={8}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableBody>
                                    {Object.keys(values).map((k, index) => ['statuses', 'step', 'logo', 'language', 'shipper', 'receiver_state_code'].includes(k) ? null :
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {t('label.'+k)}
                                                </TableCell>
                                                <TableCell variant="head">
                                                    {values[k]}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </Grid>
                        {
                            values.logo &&
                                <Grid className={classes.logoGrid} item sm={4}>
                                    <Box textAlign="center">
                                        <img alt="logo" style={{maxWidth: '100%', width: '130px'}} src={values.logo} />
                                    </Box>
                                </Grid>
                        }
                        <Grid className={classes.spaceGrid} item xs={12}>
                            <Box pb={3} />
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={2} justify="space-between" wrap="nowrap">
                    <Grid item className={`${classes.mainGrid} ${lastState >= 0 ? 'animtrack' : ''} ${lastState === 0 ? 'last' : ''}`}>
                        <Box textAlign="center">
                            <Box className={`${classes.icon} icon`}>
                                <ShoppingCartIcon/>
                            </Box>
                            <Typography variant="subtitle2" align="center" className={classes.iconDesc}>
                                {t('label.order')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={true} className={`${classes.mainGrid} ${lastState >= 1 ? 'animtrack' : ''} ${lastState === 1 ? 'last' : ''}`}>
                        <Grid container>
                            <Grid item xs={true}>
                                <div className={`${classes.spacer} spacer`}/>
                            </Grid>
                            <Grid item>
                                <Box textAlign="center" className={`${classes.icon} icon`}>
                                    <SendIcon/>
                                </Box>
                                <Typography variant="subtitle2" align="center" className={classes.iconDesc}>
                                    {t('label.send_to_shipper')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={true} className={`${classes.mainGrid} ${lastState >= 2 ? 'animtrack' : ''} ${lastState === 2 ? 'last' : ''}`}>
                        <Grid container>
                            <Grid item xs={true} className={classes.thirdSpacer}>
                                <div className={`${classes.spacer} spacer`} />
                            </Grid>
                            <Grid item>
                                <Box textAlign="center" className={`${classes.icon} icon`}>
                                    <LocalShippingIcon />
                                </Box>
                                <Typography variant="subtitle2" align="center" className={classes.iconDesc}>
                                    {t('label.in_shipping')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={true} className={`${classes.mainGrid} ${lastState >= 3 ? 'animtrack' : ''} ${lastState === 3 ? 'last' : ''}`}>
                        <Grid container>
                            <Grid item xs={true}>
                                <div className={`${classes.spacer} spacer`} />
                            </Grid>
                            <Grid item>
                                <Box textAlign="center" className={`${classes.icon} icon`}>
                                    <CheckIcon />
                                </Box>
                                <Typography variant="subtitle2" align="center" className={classes.iconDesc}>
                                    {t('label.delivered')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box pb={2}/>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t('label.created_at')}
                            </TableCell>
                            <TableCell>
                                {t('label.name')}
                            </TableCell>
                            <TableCell>
                                {t('label.center_info')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        Object.keys(values).length > 0 && values.statuses && values.statuses.map((status) => {
                            return (
                                <TableRow key={status.name}>
                                    <TableCell>
                                        { status.created_at && status.created_at.split(' ').length === 2?
                                            formatDate(status.created_at, true) : formatDate(status.created_at)
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {status.name}
                                    </TableCell>
                                    <TableCell>
                                        {status.center}
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </CardContent>
        </Card>

    );
};

export default TrackingPreview;
