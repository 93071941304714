import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import {  DialogContentText, IconButton, Tooltip } from '@material-ui/core';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import ClearIcon from '@material-ui/icons/Clear';

export default function SetCodReturnedDialog({ data, loadPackages, ...rest}) {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteCod = (e) => {
        e.preventDefault();
        secureFetch(getApiRoute('admin_package', ['deletecod', data.id]), [], 'POST').then(json => {
            handleClose();
            loadPackages();
        });
    }

    return (
        <React.Fragment>
            <Tooltip title={t('tooltip.delete_cod')} aria-label={t('tooltip.delete_cod')}>
                <IconButton
                    size="small"
                    aria-label={t('tooltip.delete_cod')}
                    onClick={handleClickOpen}
                >
                    <ClearIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle>{t('tooltip.delete_cod')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('messages.really_delete_cod')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="text" onClick={handleClose} >
                        {t('button.close')}
                    </Button>
                    <Button color="primary" variant="contained" type="submit" onClick={deleteCod}>
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
