import React, { useContext, useState, useEffect } from 'react';
import {
    Grid,
    Button,
    TextField,
    Box, Card, CardHeader, Divider, CardContent, makeStyles, FormControlLabel
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import getApiRoute from 'routes/apiRoutes';
import SecureFetchContext from 'context/SecureFetchContext';
import AppContext from 'context/AppContext';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {getErrorMessage, getService, isInputError} from "../../helpers";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    button: {
        marginLeft: '10px'
    }
}));

export default function ShipperCredentialsForm({user, ...rest }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const { appValues } = useContext(AppContext);
    const [selectedPricelist, setSelectedPricelist] = React.useState();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [priceLists, setPriceLists] = React.useState([]);
    const [errors, setErrors] = React.useState({});

    const handleSubmitForm = (event, test = false) => {
        event.preventDefault();
        const params = test ? ['test', selectedPricelist.shipper.id] : [selectedPricelist.shipper.id]
        secureFetch(getApiRoute('user_shippersettings', params), values, 'POST', false).then(json => {
            setSuccessMessage(test ? t('messages.test_successful') : t('messages.data_saved'));
            setErrors({});
            setErrorMessage('');
        }).catch( (json) => {
            if(test){
                setSuccessMessage('');
                setErrorMessage(t('messages.test_unsuccessful'));
            } else {
                setErrors(json);
            }
        });
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        setPriceLists(getService(appValues.user));
        setSelectedPricelist(getService(appValues.user)[0])
    }, [appValues.user])

    useEffect(() => {
        if(selectedPricelist){
            secureFetch(getApiRoute('user_shippersettings', [selectedPricelist.shipper.id])).then(json => {
                setValues(json)
            });
        }
    }, [secureFetch, user.id, selectedPricelist]);

    return (
        <form autoComplete="off" onSubmit={handleSubmitForm}{...rest} >
            <Card>
                <CardHeader
                    subheader={t('messages.service_shipper_credentials')}
                    title={t('title.settings')}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="shipper">{t('label.shipper')}</InputLabel>
                                <Select
                                    labelId="shipper"
                                    value={selectedPricelist && priceLists ? priceLists.indexOf(selectedPricelist) : ''}
                                    onChange={(event) => setSelectedPricelist(priceLists[event.target.value])}
                                    name="shipper"
                                >
                                    {priceLists.map((option, index) => (
                                        <MenuItem
                                            key={option.shipper.id}
                                            value={index}
                                        >
                                            {option.shipper.shortcut}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {selectedPricelist ? (
                            <React.Fragment>
                                {
                                    selectedPricelist.shipper.shortcut.toUpperCase() === '123' &&
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                error={ isInputError(errors, 'K123_API_USERNAME') }
                                                helperText={ getErrorMessage(errors, 'K123_API_USERNAME') }
                                                label={'API - ' + t('label.name')}
                                                name={'K123_API_USERNAME'}
                                                onChange={handleChange}
                                                value={values['K123_API_USERNAME'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                type={'password'}
                                                autoComplete="new-password"
                                                fullWidth
                                                error={ isInputError(errors, 'K123_API_PASSWORD') }
                                                helperText={ getErrorMessage(errors, 'K123_API_PASSWORD') }
                                                label={'API - ' + t('label.password')}
                                                name={'K123_API_PASSWORD'}
                                                onChange={handleChange}
                                                value={values['K123_API_PASSWORD'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                error={ isInputError(errors, 'K123_SENDER_ID') }
                                                helperText={ getErrorMessage(errors, 'K123_SENDER_ID') }
                                                label={t('label.sender_address_id')}
                                                name={'K123_SENDER_ID'}
                                                onChange={handleChange}
                                                value={values['K123_SENDER_ID'] || ''}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    selectedPricelist.shipper.shortcut.toUpperCase() === 'GLS' &&
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                error={ isInputError(errors, 'GLS_API_USERNAME') }
                                                helperText={ getErrorMessage(errors, 'GLS_API_USERNAME') }
                                                label={'API - ' + t('label.name')}
                                                name={'GLS_API_USERNAME'}
                                                onChange={handleChange}
                                                value={values['GLS_API_USERNAME'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                type={'password'}
                                                autoComplete="new-password"
                                                fullWidth
                                                error={ isInputError(errors, 'GLS_API_PASSWORD') }
                                                helperText={ getErrorMessage(errors, 'GLS_API_PASSWORD') }
                                                label={'API - ' + t('label.password')}
                                                name={'GLS_API_PASSWORD'}
                                                onChange={handleChange}
                                                value={values['GLS_API_PASSWORD'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                error={ isInputError(errors, 'GLS_CLIENT_NUMBER') }
                                                helperText={ getErrorMessage(errors, 'GLS_CLIENT_NUMBER') }
                                                label={'API - ' + t('label.client_number')}
                                                name={'GLS_CLIENT_NUMBER'}
                                                onChange={handleChange}
                                                value={values['GLS_CLIENT_NUMBER'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                multiple
                                                error={ isInputError(errors, 'GLS_COD_STATES') }
                                                helperText={ getErrorMessage(errors, 'GLS_COD_STATES') }
                                                options={appValues.states.filter((state) => ['SK', 'CZ', 'HU', 'HR', 'RO', 'SI'].includes(state.code) )}
                                                onChange={ (event, pStates) => setValues({ ...values, GLS_COD_STATES: pStates.map((state) => state.code) }) }
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.name}
                                                value={values['GLS_COD_STATES'] ? appValues.states.filter((state) => values['GLS_COD_STATES'].includes(state.code)) : [] }
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={t('label.states_cod_to')} placeholder={t('label.state')} />
                                                )}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    selectedPricelist.shipper.shortcut.toUpperCase() === 'PACKETA' &&
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                error={ isInputError(errors, 'PACKETA_API_PASSWORD') }
                                                helperText={ getErrorMessage(errors, 'PACKETA_API_PASSWORD') }
                                                label={'API - ' + t('label.password')}
                                                name={'PACKETA_API_PASSWORD'}
                                                onChange={handleChange}
                                                value={values['PACKETA_API_PASSWORD'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                error={ isInputError(errors, 'PACKETA_SENDER_ID') }
                                                helperText={ getErrorMessage(errors, 'PACKETA_SENDER_ID') }
                                                label={'API - ' + t('label.sender_id')}
                                                name={'PACKETA_SENDER_ID'}
                                                onChange={handleChange}
                                                value={values['PACKETA_SENDER_ID'] || ''}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    selectedPricelist.shipper.shortcut.toUpperCase() === 'SPS' &&
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete="off"
                                                error={ isInputError(errors, 'SPS_API_USERNAME') }
                                                helperText={ getErrorMessage(errors, 'SPS_API_USERNAME') }
                                                label={'API - ' + t('label.name')}
                                                name={'SPS_API_USERNAME'}
                                                onChange={handleChange}
                                                value={values['SPS_API_USERNAME'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                type={'password'}
                                                autoComplete="new-password"
                                                fullWidth
                                                error={ isInputError(errors, 'SPS_API_PASSWORD') }
                                                helperText={ getErrorMessage(errors, 'SPS_API_PASSWORD') }
                                                label={'API - ' + t('label.password')}
                                                name={'SPS_API_PASSWORD'}
                                                onChange={handleChange}
                                                value={values['SPS_API_PASSWORD'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete="off"
                                                error={ isInputError(errors, 'SPS_LOCAL_NUMBER') }
                                                helperText={ getErrorMessage(errors, 'SPS_LOCAL_NUMBER') }
                                                label={t('label.local_number')}
                                                name={'SPS_LOCAL_NUMBER'}
                                                onChange={handleChange}
                                                value={values['SPS_LOCAL_NUMBER'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete="off"
                                                error={ isInputError(errors, 'SPS_INTERNATIONAL_NUMBER') }
                                                helperText={ getErrorMessage(errors, 'SPS_INTERNATIONAL_NUMBER') }
                                                label={t('label.international_number')}
                                                name={'SPS_INTERNATIONAL_NUMBER'}
                                                onChange={handleChange}
                                                value={values['SPS_INTERNATIONAL_NUMBER'] || ''}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    selectedPricelist.shipper.shortcut.toUpperCase() === 'DPD' &&
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete="off"
                                                error={ isInputError(errors, 'DPD_API_DELIS_ID') }
                                                helperText={ getErrorMessage(errors, 'DPD_API_DELIS_ID') }
                                                label={'API - Delis ID'}
                                                name={'DPD_API_DELIS_ID'}
                                                onChange={handleChange}
                                                value={values['DPD_API_DELIS_ID'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete="off"
                                                error={ isInputError(errors, 'DPD_API_USERNAME') }
                                                helperText={ getErrorMessage(errors, 'DPD_API_USERNAME') }
                                                label={'API - ' + t('label.name')}
                                                name={'DPD_API_USERNAME'}
                                                onChange={handleChange}
                                                value={values['DPD_API_USERNAME'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                error={ isInputError(errors, 'DPD_API_PASSWORD') }
                                                helperText={ getErrorMessage(errors, 'DPD_API_PASSWORD') }
                                                label={'API - ' + t('label.key')}
                                                name={'DPD_API_PASSWORD'}
                                                onChange={handleChange}
                                                value={values['DPD_API_PASSWORD'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete="off"
                                                error={ isInputError(errors, 'DPD_SENDER_ID') }
                                                helperText={ getErrorMessage(errors, 'DPD_SENDER_ID') }
                                                label={t('label.sender_address_id')}
                                                name={'DPD_SENDER_ID'}
                                                onChange={handleChange}
                                                value={values['DPD_SENDER_ID'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete="off"
                                                error={ isInputError(errors, 'DPD_BANK_ACCOUNT_ID') }
                                                helperText={ getErrorMessage(errors, 'DPD_BANK_ACCOUNT_ID') }
                                                label={t('label.bank_account_id')}
                                                name={'DPD_BANK_ACCOUNT_ID'}
                                                onChange={handleChange}
                                                value={values['DPD_BANK_ACCOUNT_ID'] || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values['DPD_ALLOW_CARD_PAYMENT'] || false}
                                                        onChange={(e) => handleChange({target: {name: 'DPD_ALLOW_CARD_PAYMENT', value: e.target.checked}})}
                                                        name={"DPD_ALLOW_CARD_PAYMENT"}
                                                        color="primary"
                                                    />
                                                }
                                                label={t('label.allow_card_payment')}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        ): null}
                    </Grid>

                </CardContent>
                {
                    successMessage === '' ? null :
                        <Box px={2}>
                            <Alert variant="filled" severity="success">
                                {successMessage}
                            </Alert>
                        </Box>
                }
                {
                    errorMessage === '' ? null :
                        <Box px={2}>
                            <Alert variant="filled" severity="error">
                                {errorMessage}
                            </Alert>
                        </Box>
                }
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button color="secondary" variant="contained" className={classes.button} onClick={(event) => handleSubmitForm(event, true)}>
                        { t('button.test_connection') }
                    </Button>
                    <Button color="primary" variant="contained" className={classes.button} type="submit">
                        { t('button.save') }
                    </Button>
                </Box>
            </Card>
        </form>
    );
}