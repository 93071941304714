import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationSK from 'locales/sk/translation.json';
import translationSL from 'locales/sl/translation.json';
import translationCZ from 'locales/cs/translation.json';
import translationHU from 'locales/hu/translation.json';
import translationHR from 'locales/hr/translation.json';
import translationRO from 'locales/ro/translation.json';
import translationEN from 'locales/en/translation.json';

import {connectorTranslationSK} from 'neoship_connector'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    sk: {
        translation: translationSK
    },
    sl: {
        translation: translationSL
    },
    cs: {
        translation: translationCZ
    },
    hu: {
        translation: translationHU
    },
    hr: {
        translation: translationHR
    },
    ro: {
        translation: translationRO
    },
    en: {
        translation: translationEN
    },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "sk",
    fallbackLng: "sk",

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
i18n
    .addResourceBundle('sk', 'connector', connectorTranslationSK)

export default i18n;