import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import AppContext from 'context/AppContext';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import PickupAddressDetail from "../User/UserEditView/PickupAddressDetail";
import {getAllPickups} from "../../helpers";

const useStyles = makeStyles(() => ({
    root: {}
}));

const DeliveryAddressForm = ({ className, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const { appValues, setAppValues } = useContext(AppContext);

    useEffect(() => {
        appValues.user.address = appValues.user.address ? appValues.user.address : {};
        setValues({
            name: appValues.user.address.name,
            company: appValues.user.address.company,
            email: appValues.user.address.email,
            phone: appValues.user.address.phone,
            street: appValues.user.address.street,
            city: appValues.user.address.city,
            zip: appValues.user.address.zip,
            house_number: appValues.user.address.house_number,
            state: appValues.user.address.state ? appValues.user.address.state.id : appValues.states[ appValues.states.findIndex(i => i.code === 'SK') ].id || null,
        })
    }, [appValues.user, appValues.states]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch( getApiRoute('user'), { 'address': values}, 'PUT' ).then(json => {
            setSuccessMessage(t('messages.data_saved'));
            secureFetch(getApiRoute('user')).then(json => {
                setAppValues({...appValues, user: json});
            });
        });
    }

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Card>
                <CardHeader
                    subheader={t('messages.delivery_data_desc')}
                    title={t('title.delivery_data')}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.name')}
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.company')}
                                        name="company"
                                        onChange={handleChange}
                                        value={values.company || ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.email')}
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.phone')}
                                        name="phone"
                                        onChange={handleChange}
                                        value={values.phone || ''}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item lg={7} xs={8}>
                                    <TextField
                                        fullWidth
                                        label={t('label.street')}
                                        name="street"
                                        onChange={handleChange}
                                        value={values.street || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item lg={5} xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('label.house_number')}
                                        name="house_number"
                                        onChange={handleChange}
                                        value={values.house_number || ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.city')}
                                        name="city"
                                        onChange={handleChange}
                                        value={values.city || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.zip')}
                                        name="zip"
                                        onChange={handleChange}
                                        value={values.zip || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="deliverystates">{t('label.state')}</InputLabel>
                                        <Select
                                            labelId="deliverystates"
                                            id="demo-simple-select"
                                            value={ values.state || '' }
                                            name="state"
                                            onChange={handleChange}
                                        >
                                            {appValues.states.map((option) => (
                                                <MenuItem 
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box my={2}>
                        {getAllPickups(appValues.user, false).map(addr => (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={addr.id + '-content'}
                                    id={addr.id + '-header'}
                                >
                                    <Typography>{addr.id + '. ' + addr.address.city}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <PickupAddressDetail values={addr.address} />
                                </AccordionDetails>
                            </Accordion>
                        ))
                        }
                    </Box>
                </CardContent>
                { 
                    successMessage === '' ? null : 
                    <Box px={2}>
                        <Alert variant="filled" severity="success">
                            {successMessage}
                        </Alert>
                    </Box>
                }
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button color="primary" variant="contained" type="submit">
                        { t('button.save') }
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

DeliveryAddressForm.propTypes = {
    className: PropTypes.string,
};

export default DeliveryAddressForm;
