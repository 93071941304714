import React from 'react';
import {
    Typography,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import {getCarrierTypes} from "../../../helpers";

const useStyles = makeStyles(() => ({
    root: {}
}));

const AddressPreview = ({ className, prefix, values, title, rightCorner=null,...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>
                        <Grid container justify='space-between'>
                            <Grid item>
                                <Typography component="h3" color="primary">
                                    {title}
                                </Typography>
                            </Grid>
                            {
                                !!rightCorner &&
                                <Grid item>
                                    <Typography component="h4" color="primary">
                                        {rightCorner}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.name')}
                    </TableCell>
                    <TableCell>
                        {values[prefix + 'name']}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {values.parcelshop && prefix === 'receiver_' ? t('label.parcelshop') : t('label.company')}
                    </TableCell>
                    <TableCell>
                        {values.parcelshop && prefix === 'receiver_' ? `${values[prefix + 'company']} (ID: ${values.parcelshop})` : values[prefix + 'company']}
                    </TableCell>
                </TableRow>
                {values.others && values.others.carrier_type && prefix === 'receiver_' &&
                    <TableRow>
                        <TableCell component="th" variant="head">
                            {t('label.carrier_type')}
                        </TableCell>
                        <TableCell>
                            {getCarrierTypes().find(option => option.value === values.others.carrier_type).name}
                        </TableCell>
                    </TableRow>
                }
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.street')}
                    </TableCell>
                    <TableCell>
                        {values[prefix + 'street']}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.city')}
                    </TableCell>
                    <TableCell>
                        {values[prefix + 'city']}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.house_number')}
                    </TableCell>
                    <TableCell>
                        {values[prefix + 'house_number']}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.zip')}
                    </TableCell>
                    <TableCell>
                        {values[prefix + 'zip']}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.state')}
                    </TableCell>
                    <TableCell>
                        {values[prefix + 'state_code']}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.email')}
                    </TableCell>
                    <TableCell>
                        {values[prefix + 'email']}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" variant="head">
                        {t('label.phone')}
                    </TableCell>
                    <TableCell>
                        {values[prefix + 'phone']}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default AddressPreview;
