import React, {useContext} from 'react';
import {
    Box,
    Typography,
    Grid,
    makeStyles, Tooltip, IconButton
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import {
    Package as PackageIcon,
    List as ListIcon,
} from 'react-feather';
import Button from '@material-ui/core/Button';
import PlaceIcon from '@material-ui/icons/Place';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { getShipperLogoPath } from "helpers";
import ParcelshopIframe from 'modules/ParcelshopIframe'
import PickupAddressSelector from "../../../components/PickupAddressSelector";
import AppContext from "../../../context/AppContext";

const useStyles = makeStyles(() => ({
    root: {},
}));

const NewPackageShipper = ({ className, shipper, handlePackageClose, pickup, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { appValues } = useContext(AppContext);

    const onSelectParcelshop = (parcelshop) => {
        if (parcelshop) {
            handlePackageClose();
            navigate(`/app/package/newparcelshop/${shipper.id}/${parcelshop.id}`, {state: {pickup: pickup}});
        }
    };

    return (
        <Grid container spacing={3} alignItems="center" >
            <Grid item xs={12} md={6}>
                <Typography variant="h1" component="h2" color="primary" align="center">
                    <img width={150} src={getShipperLogoPath(shipper.shortcut)} alt={shipper.shortcut}/>
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                {
                    !['123'].includes(shipper.shortcut.toUpperCase()) &&
                    <React.Fragment>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<PackageIcon />}
                            size="small"
                            component={RouterLink}
                            state={{pickup: pickup}}
                            to={{
                                pathname: '/app/package/new/' + shipper.id,
                            }}
                            onClick={handlePackageClose}
                            fullWidth
                        >
                            {t('button.to_address')}
                        </Button>
                        <Box p={1} />
                    </React.Fragment>
                }
                {
                    ['123'].includes(shipper.shortcut.toUpperCase()) && appValues.user.additional_pickup_addresses.length > 1 &&
                    <React.Fragment>
                        <PickupAddressSelector isSelect={false} onSelect={(addr) => {
                            navigate("/app/package/new/" + shipper.id + '/', {state: {pickup: addr}});
                            handlePackageClose();
                        }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<PackageIcon />}
                                size="small"
                                state={{pickup: pickup}}
                                fullWidth
                            >
                                {t('button.to_address')}
                            </Button>
                        </PickupAddressSelector>

                        <Box p={1} />
                    </React.Fragment>
                }
                {
                    ['123'].includes(shipper.shortcut.toUpperCase()) && appValues.user.additional_pickup_addresses.length === 1 &&
                    <React.Fragment>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<PackageIcon />}
                            size="small"
                            component={RouterLink}
                            state={{pickup: pickup}}
                            to={{
                                pathname: '/app/package/new/' + shipper.id,
                            }}
                            onClick={handlePackageClose}
                            fullWidth
                        >
                            {t('button.to_address')}
                        </Button>
                        <Box p={1} />
                    </React.Fragment>
                }
                {
                    !['123', 'QDL'].includes(shipper.shortcut.toUpperCase()) &&
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<PlaceIcon />}
                        size="small"
                        /* component={RouterLink}
                        to={'/app/parcelshop'} */
                        onClick={() => ParcelshopIframe.Widget.open(onSelectParcelshop, { shipper_id: shipper.id })}
                        fullWidth
                    >
                        {t('button.parcelshop')}
                    </Button>
                }
                {
                    !['123'].includes(shipper.shortcut.toUpperCase()) &&
                    <React.Fragment>
                        <Box p={1} />
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<ListIcon />}
                            size="small"
                            onClick={() =>{
                                handlePackageClose();
                                navigate('/app/package/import', { state: {shipper: shipper, pickup: pickup} })}
                            }
                            fullWidth
                        >
                            {t('button.import')}
                        </Button>
                    </React.Fragment>
                }
                {
                    ['123'].includes(shipper.shortcut.toUpperCase()) && appValues.user.additional_pickup_addresses.length > 1 &&
                    <React.Fragment>
                        <PickupAddressSelector isSelect={false} onSelect={(addr) => {
                            navigate('/app/package/import', { state: {shipper: shipper, pickup: addr} });
                            handlePackageClose();
                        }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<ListIcon />}
                                size="small"
                                fullWidth
                            >
                                {t('button.import')}
                            </Button>
                        </PickupAddressSelector>

                        <Box p={1} />
                    </React.Fragment>
                }
                {
                    ['123'].includes(shipper.shortcut.toUpperCase()) && appValues.user.additional_pickup_addresses.length === 1 &&
                    <React.Fragment>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<ListIcon />}
                            size="small"
                            onClick={() =>{
                                handlePackageClose();
                                navigate('/app/package/import', { state: {shipper: shipper, pickup: pickup} })}
                            }
                            fullWidth
                        >
                            {t('button.import')}
                        </Button>
                        <Box p={1} />
                    </React.Fragment>
                }
                {
                    shipper.shortcut.toUpperCase() === 'PACKETA' && 
                    <React.Fragment>
                        <Box p={1} />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            size="small"
                            onClick={() =>{
                                handlePackageClose();
                                navigate('/app/package/claimassistant', { state: shipper })} 
                            }
                            fullWidth
                        >
                            {t('button.claim_assistant')}
                        </Button>
                    </React.Fragment>
                }
            </Grid>
        </Grid>
    );
};

export default NewPackageShipper;
