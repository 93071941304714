import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    AppBar,
    Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
    Button,
    TextField,
    Grid
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'components/Logo';
import MailIcon from '@material-ui/icons/Mail';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from "react-i18next";
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EuroIcon from '@material-ui/icons/Euro';
import WarningIcon from '@material-ui/icons/Warning';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { NavLink as RouterLink } from 'react-router-dom';
import NewPackageDialog from 'views/Package/PackageNewEditView/NewPackageDialog';
import AppContext, { defAppValues } from 'context/AppContext';
import { useNavigate } from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { formatDate } from 'helpers';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserUpdatableContext, {DefaultUserUpdatable} from "../../context/UserUpdatableContext";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.secondary.mainGradient
    },
    avatar: {
        width: 60,
        height: 60
    },
    impersonate: {
        //backgroundColor: 'white',
        //borderRadius: theme.spacing(1),
        '& input': {
            color: 'white',
        },
        '& label': {
            color: 'white',
        //    backgroundColor: 'white',
        },
        '& .MuiInput-underline::before': {
            borderBottom: "1px solid rgba(255, 255, 255, 0.42)"
        }
    },
    "@keyframes rotation": {
        /* Start changing by here */
        "0%": {
            transform: "rotate(0deg)"
        },
        /* Finish changes by here */
        "60%": {
            transform: "rotate(360deg)"
        },
        /* Between 60% and 100%, nothing changes */
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    messages: {
        animation: "$rotation 4s infinite ease-in-out;",
    }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [messageAnchorEl, setMessageAnchorEl] = React.useState(null);
    const [impersonateUsername, setImpersonateUsername] = React.useState('');
    const { appValues, setAppValues } = useContext(AppContext);
    const { userUpdatable, setUserUpdatable } = useContext(UserUpdatableContext);
    const secureFetch = useContext(SecureFetchContext);
    let navigate = useNavigate();

    const handleMessageOpen = (event) => {
        setMessageAnchorEl(event.currentTarget);
    };

    const handleMessageClose = () => {
        setMessageAnchorEl(null);
    };

    const handleMessageClick = (id) => {
        setMessageAnchorEl(null);
        navigate('/app/messages/' + id);
    };

    const logout = () => {
        setAppValues(defAppValues);
        setUserUpdatable(DefaultUserUpdatable);
        navigate('/');
    };

    const getIcon = (row) => {
        switch (row.type) {
            case 'Príplatok':
                return (<EuroIcon />);
            case 'Upozornenie':
                return (<WarningIcon />);
            default:
                return (<InfoIcon />);
        }
    }

    const onSubmitImpersonate = (e) => {
        e.preventDefault();
        secureFetch(getApiRoute('user') + `?x-switch-user=${impersonateUsername}`).then(json => {
            if (json.username === impersonateUsername) {
                navigate('/app/dashboard', {state: {user: json}});
                setAppValues({
                    ...appValues,
                    user: json,
                    isImpersonate: true
                });
                setUserUpdatable({statistics: json.others, credit: json.credit, last_update: new Date()});
            }
        });
    }

    const exitImpersonate = (e) => {
        e.preventDefault();
        secureFetch(getApiRoute('user'), null, 'GET', true, true, false).then(json => {
            navigate('/app/dashboard', {state: {user: json}});
            setAppValues({
                ...appValues,
                user: json,
                isImpersonate: false
            });
            setUserUpdatable({statistics: json.others, credit: json.credit, last_update: new Date()});
        });
    }

    return (
        <AppBar
            className={clsx(classes.root, className)}
            elevation={0}
            {...rest}
        >
            <Toolbar>
                <RouterLink to="/">
                    <Logo />
                </RouterLink>
                <Box pl={2}>
                    {
                        appValues.user.roles.includes('ROLE_ADMIN') && 
                        <form onSubmit={onSubmitImpersonate}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <TextField 
                                        label={t('label.user')} 
                                        variant="standard" 
                                        value={impersonateUsername}
                                        onChange={e => setImpersonateUsername(e.target.value)}
                                        className={classes.impersonate}
                                        size="small" 
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <Button type="submit" variant="contained" color="primary">
                                        {t('button.impersonate')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    }
                    {
                        appValues.isImpersonate &&
                        <Button variant="contained" color="primary" onClick={exitImpersonate}>
                            {t('button.exit_impersonate')}
                        </Button>
                    }
                </Box>

                <Box flexGrow={1} />

                    {
                        (appValues.user.connector_settings && appValues.user.connector_settings['ENABLED']) &&
                        <RouterLink to="/app/connector" style={{color: 'unset'}}>
                            <IconButton color="inherit">
                                <FontAwesomeIcon icon={['fab', 'hubspot']} />
                            </IconButton>
                        </RouterLink>
                    }
                    {
                        appValues.user.roles.includes('ROLE_USER') && <NewPackageDialog />
                    }

                    {
                        ['ROLE_ADMIN', 'ROLE_USER'].some(el => appValues.user.roles.includes(el)) &&
                        <React.Fragment>
                            <Tooltip title={t('tooltip.messages')} aria-label={t('tooltip.messages')}>
                                <IconButton color="inherit" onClick={ (event) => {appValues.messages.length ? handleMessageOpen(event) : navigate('/app/messages')}}>
                                    <Badge
                                        badgeContent={appValues.messages.length ? appValues.messages.length : null}
                                        color="primary"
                                    >
                                        <Box className={appValues.messages.length ? classes.messages : ''}>
                                            <MailIcon />
                                        </Box>
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <Popover
                                id={Boolean(messageAnchorEl) ? 'simple-popover' : undefined}
                                open={Boolean(messageAnchorEl)}
                                anchorEl={messageAnchorEl}
                                onClose={handleMessageClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <List>
                                    {
                                        appValues.messages.map((row, index) => {
                                            return (
                                                <ListItem
                                                    key={row.id}
                                                    button
                                                    onClick={() => handleMessageClick(row.id)}
                                                >
                                                    <ListItemIcon>
                                                        {getIcon(row)}
                                                    </ListItemIcon>
                                                    <ListItemText primary={row.title} secondary={formatDate(row.created_at, true)} />
                                                </ListItem>
                                            );
                                        })
                                    }
                                </List>
                            </Popover>
                        </React.Fragment>
                    }

                    <Tooltip title={t('tooltip.logout')} aria-label={t('tooltip.logout')} onClick={logout} >
                        <IconButton color="inherit">
                            <InputIcon />
                        </IconButton>
                    </Tooltip>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={onMobileNavOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;
