import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    makeStyles,
} from '@material-ui/core';
import ServerTable from 'components/ServerTable';
import PackageListActions from './PackageListActions';
import PackageTableActions from './PackageTableActions';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import LoadedValuesContext from 'context/LoadedValuesContext';
import NewPackageDialog from 'views/Package/PackageNewEditView/NewPackageDialog';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CodPrice from 'components/CodPrice';
import {createUrlQuery, formatDate, getShipperIcon, isAdmin, isUser} from 'helpers';

const useStyles = makeStyles(() => ({
    root: {
        '& table .MuiTableCell-sizeSmall': {
            padding: '6px 4px'
        }
    },
    checkbox: {
        padding: 0
    }
}));

const PackageTableService = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState({ rows: [], count: 0 });
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState({});
    const [selectedAll, setSelectedAll] = useState(false);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const { loadedValues, setLoadedValues } = useContext(LoadedValuesContext);
    const tableFilter = useRef(null);

    useEffect(() => {
        if (!loadedValues.statusesByType) {
            secureFetch(getApiRoute('statustype', ['bytype'])).then((json) => {
                setLoadedValues({ ...loadedValues, statusesByType: json });
            });
        }
    }, [secureFetch, loadedValues, setLoadedValues]);

    const loadPackages = useCallback((pConditions = null) => {
        if (!pConditions) {
            pConditions = tableFilter.current;
        }

        setLoading(true);
        secureFetch(getApiRoute(isUser(appValues.user) ? 'package_service' : 'admin_package_service') + '?' + createUrlQuery(pConditions) ).then(json => {
            setLoading(false);
            setRowsData(json);
        })
    }, [secureFetch, appValues.user])

    const loadSubPackages = (id) => {
        let pConditions = {
            main_package_id: id
        }
        secureFetch(getApiRoute(isUser(appValues.user) ? 'package_service' : 'admin_package_service') + '?' + createUrlQuery(pConditions)).then(json => {
            let index = rowsData.rows.findIndex(el => el.id === id);
            for (const row of json.rows) {
                row.isSubPackage = true;
                rowsData.rows.splice(index + 1, 0, row);
            }
            rowsData.rows[index].open = true;
            setRowsData({...rowsData})
        });
    }

    const handleSelectAll = ( event ) => {
        let tmpSelected = selected;
        for ( const row of rowsData.rows ) {
            if (!row.exported_at && !row.isSubPackage) {
                if (event.target.checked) {
                    tmpSelected[row.id] = row;
                }
                else{
                    delete tmpSelected[row.id];
                }
            }
        }
        setSelected({ ...tmpSelected });
        setSelectedAll(event.target.checked);
    };

    const handleSelectOne = ( event, data ) => {
        if (event.target.checked) {
            setSelected({ ...selected, [data.id]: data });
        }
        else{
            let tmpSelected = selected;
            delete tmpSelected[data.id];
            setSelected({ ...tmpSelected });
        }
    };

    const exportData = [
        {
            'name': t('button.export_csv'),
            'url': getApiRoute('package', ['service', 'export'], isAdmin(appValues.user)),
        },
        {
            'name': t('button.export_excel'),
            'url': getApiRoute('package', ['service', 'export', 'excel'], isAdmin(appValues.user)),
        },
    ];

    const settings = {
        rows: rowsData.rows,
        rowsLength: rowsData.count,
        loading: loading,
        colOrder: {
            order: 'desc',
            order_by: 'created_at'
        },
        refFilter: tableFilter,
        handleTableUpdate: loadPackages,
        headCells: [
            { id: 'id', numeric: false, label: t('label.id'), search: false, hide: true, disableOrder: true },
            { id: 'checkbox', numeric: false, label: '', search: false, subhide: true, disableOrder: true, render: (data) => {
                if (data.isSubPackage) {
                    return '-';
                }
                return (
                    (!data.exported_at || isAdmin(appValues.user)) &&
                    <Checkbox
                        checked={ !!selected[data.id] }
                        onChange={(event) => handleSelectOne( event, data )}
                        className={classes.checkbox}
                    />
                );
            }, renderLabel: () => {
                return (
                    <Tooltip title={ selectedAll ? t('label.unselect_all') : t('label.select_all') }>
                        <Checkbox
                            checked={selectedAll}
                            onChange={handleSelectAll}
                            className={classes.checkbox}
                        />
                    </Tooltip>
                );
            } },
            { id: 'shipper', numeric: false, label: t('label.shipper'), search: true, searchType: 'select', selectChoices: appValues.shippers.map((val) => {
                val.name = val.shortcut
                return val;
            }), render: (data) => getShipperIcon(data) },
            { id: 'created_at', numeric: false, label: t('label.created_at'), search: true, searchType: 'dateRange', render: (data) => formatDate(data.created_at, true) },
            { id: 'reference_number', numeric: false, label: t('label.reference_number'), search: true },
            { id: 'tracking_number', numeric: false, label: t('label.tracking_number'), search: true },
            { id: 'receiver', numeric: false, label: t('label.receiver'), search: true, disableOrder: true, render: (data) => {
                return (
                    <React.Fragment>
                        <div>
                            {
                                data.parcelshop && <Chip size="small" style={{ fontWeight: 'normal', margin: '1px', backgroundColor: '#3A6B35', color: 'white' }} label={t('label.ps')} />
                            }
                            {
                                data.reverse && <Chip size="small" style={{ fontWeight: 'normal', margin: '1px', backgroundColor: 'blue', color: 'white' }} label={t('label.reverse')} />
                            }
                            {
                                data.claim_assistant && <Chip size="small" style={{ fontWeight: 'normal', margin: '1px', backgroundColor: '#BF4071', color: 'white' }} label={t('label.returning_package')} />
                            }
                            {
                                data.statuses.map((s, index) => {
                                    switch (s.code) {
                                        case '99004': // DAMAGED_SHIPMENT
                                            return <Chip key={index} size="small" style={{ fontWeight: 'normal', margin: '1px', backgroundColor: 'red', color: 'white' }} label={s.name} />
                                        case '99005': // CUSTOMER_SERVICE
                                            return <Chip key={index} size="small" style={{fontWeight: 'normal', margin: '1px', backgroundColor: 'yellow', color: 'black'}} label={s.name} />
                                        case '99006': // EDIT_COD
                                            return <Chip key={index} size="small" style={{ fontWeight: 'normal', margin: '1px', backgroundColor: 'violet', color: 'white' }} label={s.name} />
                                        case '166': // ZM
                                            return <Chip key={index} size="small" style={{ fontWeight: 'normal', margin: '1px', backgroundColor: '#FF7900', color: 'white' }} label={s.name} />
                                        case '163': // TsN
                                            return <Chip key={index} size="small" style={{ fontWeight: 'normal', margin: '1px', backgroundColor: 'brown', color: 'white' }} label={s.name} />
                                        default:
                                            return null;
                                    }                             
                                })
                            }
                        </div>
                        {data.receiver}
                    </React.Fragment>
                );
            } },
            { id: 'weight', numeric: true, label: t('label.weight'), search: false, render: (data) => {
                let subWeight = 0;
                if (data.subpackages.weight) {
                    subWeight += data.weight ? parseFloat(data.weight) : 0;
                    subWeight += parseFloat(data.subpackages.weight);
                }
                return data.weight ? parseFloat(data.weight).toFixed(2) + (subWeight ? `(${parseFloat(subWeight).toFixed(2)})` : '') + ' kg' : '-';
            } },
            { id: 'cod_price', numeric: true, label: t('label.cod_price'), search: false, render: (data) => <CodPrice data={data} /> },
            { id: 'price_vat', numeric: true, label: t('label.price_vat'), search: false, render: (data) => {
                return data.price_vat ? parseFloat(data.price_vat >= 0.01 ? data.price_vat : 0).toFixed(2) + ' €' : null;
            } },
            { id: 'actions', numeric: false, label: t('label.actions'), subhide: true, disableOrder: true, search: false, render: (data) => {
                return (
                    <PackageListActions data={data} loadPackages={loadPackages} statusesByType={loadedValues.statusesByType} />
                );
            } },
        ],
        topRightButtons: () => isUser(appValues.user) && <NewPackageDialog buttonVariant='full' />,
        topLeftButtons: () => <PackageTableActions selected={selected} setSelected={setSelected} loadPackages={loadPackages} />,
        rowClass: (data) => {
            if (loadedValues.statusesByType) {
                if ( loadedValues.statusesByType[data.shipper_id].transit.includes( data.last_status_id ) ) {
                    return 'transit';
                }
    
                if (loadedValues.statusesByType[data.shipper_id].delivered.includes(data.last_status_id)) {
                    return 'delivered';
                }

                if (loadedValues.statusesByType[data.shipper_id].cancel && loadedValues.statusesByType[data.shipper_id].cancel.includes(data.last_status_id)) {
                    return 'cancel';
                }
    
                if (loadedValues.statusesByType[data.shipper_id].notdelivered.includes(data.last_status_id)) {
                    return 'notdelivered';
                }
            }
            return '';
        },
        subRow: (data, checkExist = true) => {
            if (checkExist) {
                return data.subpackages.count !== 0;
            }
            if (data.open) {
                rowsData.rows = rowsData.rows.filter(row => row.main_package_id !== data.id);
                data.open = false;
                setRowsData({ ...rowsData });
            } else {
                loadSubPackages(data.id);
            }
        },
        addFilter: {
            last_status: true,
            states: true,
            cod_payment: true,
            extended: true,
            //reverse: true,
            //composed: true,
            is_service: true,
        },
        exportData: exportData,
        loadFilterDate: {
            created_at_from : new Date(new Date().setDate(new Date().getDate() - 30)),
            created_at_to : new Date(),
        }
    };

    if ( isAdmin(appValues.user) ) {
        settings.headCells.splice(3, 0, { id: 'user', numeric: false, label: t('label.eshop_name'), search: true, searchType: 'select', selectChoices: users, render: (data) => data.eshop});
    }
    useEffect(() => {
        if ( isAdmin(appValues.user) ) {
            secureFetch( getApiRoute( 'admin_user' ) ).then(json => {
                setUsers(json.map(user => {
                    return {
                        id: user.id,
                        name: user.eshop || ''
                    }
                }));
            })
        }
    }, [appValues.user, secureFetch]);

    const SelectedPackages = () => {
        return Object.keys(selected).length ?
            <Box p={1}>
                {Object.keys(selected).map((item, i) => {
                    return (
                        <Chip
                            key={i}
                            label={selected[item].reference_number}
                        />
                    )
                })}
            </Box> : null
    }

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <SelectedPackages/>
                    <ServerTable settings={settings} />
                <SelectedPackages/>
            </CardContent>
        </Card>
    );
}

export default PackageTableService;