import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card, CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';

const ImportAddressForm = ({ className, user, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [successMessage, setSuccessMessage] = useState('');

    const onChange = (event) => {
        const files = Array.from(event.target.files);
        const formData = new FormData()

        files.forEach((file, i) => {
            formData.append('address_list', file)
        })

        secureFetch(getApiRoute('admin_address_list', [user.id]), formData, 'POST', true, false).then((json) => {
            setSuccessMessage(t('messages.address_list_saved', { count: json.count }));
        })

    };

    return (
            <Card>
                <CardHeader
                    title={t('title.address_list')}
                    subheader={t('messages.import_address_list')}
                />
                <Divider />
                {
                    successMessage === '' ? null :
                        <Box p={2}>
                            <Alert variant="filled" severity="success">
                                {successMessage}
                            </Alert>
                        </Box>
                }
                <CardContent>
                    <Grid container spacing={3}>
                        <CardActions>
                            <Button
                                color="primary"
                                variant="text"
                                component="label"
                                fullWidth
                            >
                                { t('button.choose_file') }
                                <input
                                    type="file"
                                        name="address_list"
                                    style={{ display: "none" }}
                                    onChange={onChange}
                                />
                            </Button>
                        </CardActions>
                    </Grid>
                </CardContent>
            </Card>

    );
};

ImportAddressForm.propTypes = {
    className: PropTypes.string
};

export default ImportAddressForm;
