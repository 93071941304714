import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    Grid,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {}
}));

const NewShipperForm = ({ className, states, edit, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    let navigate = useNavigate();

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch(getApiRoute('admin_shipper'), values, 'POST').then(json => {
            navigate('/app/admin/shipper/list');
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.name')}
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.shortcut')}
                                        name="shortcut"
                                        onChange={handleChange}
                                        value={values.shortcut || ''}
                                        required
                                    />
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.create')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
};

NewShipperForm.propTypes = {
    className: PropTypes.string
};

export default NewShipperForm;
