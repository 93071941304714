import React, { useEffect, useState, useCallback, useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    makeStyles,
} from '@material-ui/core';
import ServerTable from 'components/ServerTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import LogTableActions from './LogTableActions';
import {formatDate, normalizeDate} from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {},
    checkbox: {
        padding: 0
    }
}));

const LogTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState({ rows: [], count: 0 });
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);

    const loadPackages = useCallback((pConditions = null) => {
        setLoading(true);
        if (pConditions.created_at_from instanceof Date) {
            pConditions.created_at_from = normalizeDate(pConditions.created_at_from);
        }

        if (pConditions.created_at_to instanceof Date){
            pConditions.created_at_to = normalizeDate(pConditions.created_at_to);
        }

        const params = pConditions ? new URLSearchParams(pConditions) : '';

        secureFetch( getApiRoute( appValues.user.roles.includes('ROLE_USER') ? 'log' : 'admin_log' ) + '?' + params ).then(json => {
            setLoading(false);
            setRowsData(json);
        })
    }, [secureFetch, appValues.user.roles])

    const settings = {
        rows: rowsData.rows,
        rowsLength: rowsData.count,
        loading: loading,
        colOrder: {
            order: 'desc',
            order_by: 'created_at'
        },
        handleTableUpdate: loadPackages,
        headCells: [
            { id: 'id', numeric: false, label: t('label.id'), search: false, hide: true, disableOrder: true },
            { id: 'created_at', numeric: false, label: t('label.created_at'), search: true, searchType: 'dateRange', render: (data) => formatDate(data.created_at, true) },
            { id: 'type', numeric: false, label: t('label.type'), search: true },
            { id: 'status', numeric: false, label: t('label.status'), search: true },
            { id: 'message', numeric: false, label: t('label.message'), search: true },
            { id: 'package_reference_number', numeric: false, label: t('label.package_reference_number'), search: true },
            { id: 'actions', numeric: false, label: t('label.actions'), disableOrder: true, search: false, render: (data) => {
                return (
                    <LogTableActions data={data} />
                );
            } },
        ],
        addFilter: {
            exported: true,
        },
        loadFilterDate: {
            created_at_from : new Date(new Date().setDate(new Date().getDate() - 7)),
            created_at_to : new Date(),
        }
    };

    if ( appValues.user.roles.includes('ROLE_ADMIN') ) {
        settings.headCells.splice(2, 0, { id: 'user', numeric: false, label: t('label.eshop_name'), search: true, searchType: 'select', selectChoices: users, render: (data) => data.eshop});
    }
    useEffect(() => {
        if ( appValues.user.roles.includes('ROLE_ADMIN') ) {
            secureFetch( getApiRoute( 'admin_user' ) ).then(json => {
                setUsers(json.map(user => {
                    return {
                        id: user.id,
                        name: user.eshop || ''
                    }
                }));
            })
        }
    }, [appValues.user, secureFetch]);


    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ServerTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default LogTable;