import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import ProgressButton from 'components/ProgressButton';

const GeneratePricelist = ({ pricelist, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);

    const onGenerate = () => {
        return secureFetch(getApiRoute('price_list', ['generate', pricelist.id], true));
    }

    const onFile = (data) => {
        let filename = 'file';
        for (const header of data[1].headers) {
            if (header[0] === 'content-disposition') {
                filename = header[1].match(/filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i)[2];
            }
        }
        const url = window.URL.createObjectURL(data[0]);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    return (
        <ProgressButton
            type="submit"
            variant="contained"
            color="secondary"
            name={t('button.generate_pricelist')}
            onClick={onGenerate}
            onSuccess={onFile}
        />
    );
};

export default GeneratePricelist;
