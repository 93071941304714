import React, { useEffect, useContext } from 'react';
import {
    Container,
    Typography,
    Box,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import EditPriceListForm from './EditPriceListForm';
import PricesTabs from './PricesTabs';
import { isNumeric } from "helpers";
import { useParams, useNavigate } from "react-router-dom";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

export default function PriceListEditView() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [pricelist, setPriceList] = React.useState({name: ''});
    let { id, tab } = useParams();
    tab = tab ? tab : 'shipping_price';
    let navigate = useNavigate();
    const secureFetch = useContext(SecureFetchContext);

    useEffect(() => {
        if ( !isNumeric(id) ) {
            navigate('/404');
        }
        if ( pricelist.id !== parseInt(id) ) {
            secureFetch( getApiRoute('admin_pricelist', [id]) ).then(json => {
                if (json.price) {
                    json.price = parseFloat(json.price).toFixed(2);
                }
                setPriceList(json);
            });
        }
    }, [id, navigate, secureFetch, pricelist.id]);

    return (
        <Page
            className={classes.root}
            title={t('title.edit_pricelist') + ' - ' + pricelist.name}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('title.edit_pricelist') + ' - ' + pricelist.name}
                </Typography>
                <EditPriceListForm pricelist={pricelist} />
                {
                    !pricelist.shipper || pricelist.type === 'fix' ? null :
                    <React.Fragment>
                        <Box p={2} />
                        <PricesTabs pricelist={pricelist} tab={tab} />
                    </React.Fragment>
                }
            </Container>
        </Page>
    );
}