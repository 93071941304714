import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    MenuItem,
    Select,
    Checkbox,
    TextField,
    Grid,
    FormControlLabel,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import AppContext from 'context/AppContext';
import {isPartner} from "../../../helpers";

const useStyles = makeStyles(() => ({
    root: {}
}));

const NewUserForm = ({ className, user, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({ enabled: false });
    const [successMessage, setSuccessMessage] = useState('');
    const { appValues } = useContext(AppContext);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (user.username) {
            setValues({
                username: user.username,
                eshop: user.eshop,
                variable_number: user.variable_number,
                credit_limit: user.credit_limit,
                roles: user.roles[0],
                enabled: user.enabled,
                partner_user: user.partner_user ? user.partner_user.id : null,
                note: user.note,
                start_of_shipment: user.start_of_shipment ? user.start_of_shipment.slice(0, 10) : null
            })
        }
    }, [user]);

    useEffect(() => {
        if (users.length === 0) {
            secureFetch(getApiRoute('admin_user')).then(json => {
                setUsers(json.filter((user) => isPartner(user)).map(user => {
                    return {
                        id: user.id,
                        name: user.eshop || ''
                    }
                }));
            })
        }
    }, [secureFetch, users.length]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleCheckBoxChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.checked
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let tmpValues = values;
        if (tmpValues.roles) {
            tmpValues.roles = [ tmpValues.roles ];
        }
        secureFetch( getApiRoute('admin_user', [user.id] ), tmpValues, 'PUT').then(json => {
            setSuccessMessage(t('messages.data_saved'));
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.username')}
                                        name="username"
                                        type="email"
                                        onChange={handleChange}
                                        value={values.username || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.variable_number')}
                                        name="variable_number"
                                        onChange={handleChange}
                                        value={values.variable_number || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="roles-select-label">{t('label.roles')}</InputLabel>
                                        <Select
                                            id="roles-select"
                                            labelId="roles-select-label"
                                            value={values.roles || user.roles || ''}
                                            name="roles"
                                            onChange={handleChange}
                                        >
                                            {appValues.roles.map((role) => <MenuItem key={role} value={role}>{role}</MenuItem> )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.credit_limit')}
                                        name="credit_limit"
                                        onChange={handleChange}
                                        value={values.credit_limit || ''}
                                        type="number"
                                        inputProps={{ step: "0.01" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="start_of_shipment"
                                        label={t('label.start_of_shipment')}
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={values.start_of_shipment || ''}
                                        fullWidth
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.eshop_name')}
                                        name="eshop"
                                        onChange={handleChange}
                                        value={values.eshop || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="partner-select-label">{t('label.partner')}</InputLabel>
                                        <Select
                                            id="partner-select"
                                            labelId="partner-select-label"
                                            value={values.partner_user || ''}
                                            name="partner_user"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={null}>{t('label.none')}</MenuItem>
                                            {users.map((user) => <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.note')}
                                        name="note"
                                        onChange={handleChange}
                                        value={values.note || ''}
                                        rows={4}
                                        multiline
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.enabled}
                                                onChange={handleCheckBoxChange}
                                                name="enabled"
                                                value="1"
                                                color="primary"
                                            />
                                        }
                                        label={t('label.enabled')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </CardContent>
                { 
                    successMessage === '' ? null : 
                    <Box px={2}>
                        <Alert variant="filled" severity="success">
                            {successMessage}
                        </Alert>
                    </Box>
                }
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button color="primary" variant="contained" type="submit">
                        {t('button.save')}
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

NewUserForm.propTypes = {
    className: PropTypes.string
};

export default NewUserForm;
