import React, { useContext } from 'react';
import {
    Container,
    Typography,
    makeStyles,
    Card,
    CardContent, Box
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import AppContext from 'context/AppContext';
import ComplaintForm from './ComplaintForm';
import ComplaintFormSpsDamaged from './ComplaintFormSpsDamaged';
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

export default function PackageComplaint() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { state } = useLocation();
    const { appValues } = useContext(AppContext);

    return (
        <Page
            className={classes.root}
            title={t(`button.${state.type}`) + ` - ${state.package.reference_number}`}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t(`button.${state.type}`) + ` - ${state.package.reference_number}`}
                </Typography>
                <Card>
                    <CardContent>
                        {
                            (state.type === 'edit_cod' && state.package.shipper === '123') &&
                            <Box pt={3}>
                                <Alert variant="filled" severity="error">
                                    {t('messages.cod_change_warning')}
                                </Alert>
                            </Box>
                        }
                        {
                            appValues.shippers.find((s) => s.id === state.package.shipper_id && s.shortcut === 'SPS') && state.type === 'damaged_shipment' ? <ComplaintFormSpsDamaged data={state} /> : <ComplaintForm data={state} />
                        }
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
}