import React from 'react';
import {
    Container,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import NewServiceForm from './NewServiceForm';
const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

export default function ServiceNewView() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Page
            className={classes.root}
            title={t('title.create_service')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('title.create_service')}
                </Typography>
                <NewServiceForm/>
            </Container>
        </Page>
    );
}