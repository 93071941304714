import React from 'react';
import {
    Grid,
    Container,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Page from 'components/Page';
import UserProfitStatisticsTable from './UserProfitStatisticsTable';
import PackageCountStatistics from './PackageCountStatistics';
import PackageProfitStatistics from './PackageProfitStatistics';
import PackageCountryCountStatistics from './PackageCountryCountStatistics';
import PackageCountryProfitStatistics from './PackageCountryProfitStatistics';
import CountryProfitStatisticsTable from './CountryProfitStatisticsTable';
import UserWithoutPackageStatisticsTable from './UserWithoutPackageStatisticsTable';
import TableFilter from 'components/TableFilter';
import {createUrlQuery} from 'helpers';

export default function ProfitStatistics({ user, ...rest }) {
    const { t } = useTranslation();
    const [filter, setFilter] = React.useState({});
    const [params, setParams] = React.useState('');

    const setFilterValue = (name, value) => {
        let tmpFilter = { ...filter, [name]: value };
        setParams( createUrlQuery(tmpFilter) );
        setFilter({ ...filter, [name]: value });
    }

    return (
        <Page
            title={t('navbar.profit')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.profit')}
                </Typography>
                <Grid container spacing={3} alignItems="center" >
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <TableFilter
                                    filter={filter}
                                    enabled={{
                                        status_type: true,
                                        cod_payment: true,
                                        created_at: true,
                                        user: true,
                                        partner: true,
                                        delivery_type: true,
                                        reverse: true,
                                        shipper: true,
                                    }}
                                    setFilterValue={setFilterValue}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <UserProfitStatisticsTable params={params} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PackageCountStatistics params={params} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PackageProfitStatistics params={params} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PackageCountryCountStatistics params={params} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PackageCountryProfitStatistics params={params} />
                    </Grid>
                    <Grid item xs={12}>
                        <CountryProfitStatisticsTable params={params} />
                    </Grid>
                    <Grid item xs={12}>
                        <UserWithoutPackageStatisticsTable params={params} />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}