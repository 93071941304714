import React, {useCallback, useContext, useEffect, useState} from "react";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from "../../routes/apiRoutes";
import CardMedia from "@material-ui/core/CardMedia";
import {Card, Typography, makeStyles} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import AppContext from "../../context/AppContext";
import {CheckCircle} from "@material-ui/icons";
import EmailEdit from "./EmailEdit";
import {isAdmin} from "../../helpers";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '24px'
    },
    content: {
        flex: '0 1 auto',
        cursor: 'pointer'
    },
    media: {
        display: 'flex',
        height: 100,
        objectFit: 'contain',
        alignItems: 'left',
    },
    mediaBackground: {
        position: 'absolute',
        width:"100%",
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: '#000',
        opacity: 0.4
    },
    mediaText: {
        position: 'absolute',
        color: 'white',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))

const EmailTemplateSelector = ({ className, user, setUser, ...rest }) => {
    const classes = useStyles();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [templates, setTemplates] = useState([]);
    const [chosenTemplate, setChosenTemplate] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [successEmailMessage, setSuccessEmailMessage] = useState('');


    const getEmailRoute = useCallback(
        () => {
            let route;
            const stateId = appValues.states.find(s => s.code === 'SK').id;
            if (isAdmin(appValues.user)) {
                route = getApiRoute('admin_email_templates', [user.id]);
                route += stateId !== 'other' ? '/' + stateId : ''; // bug symfony route remove last slash
            } else {
                route = getApiRoute('email_templates');
                route = stateId !== 'other' ? route + stateId : route.slice(0, -1); // bug symfony route remove last slash
            }
            return route;
        },
        [appValues.user, appValues.states, user],
    );

    useEffect(() => {
        secureFetch(getEmailRoute())
            .then(function (json) {
                if (json) {
                    setTemplates(json);
                    setChosenTemplate(json.find(t => t.active))
                }
            }).catch(function (err) {
            alert('Something went wrong.' + err);
        });
    }, [secureFetch, getEmailRoute])

    const handleTemplateChange = (template) => {
        let tmpTemplates = templates;
        tmpTemplates = tmpTemplates.map((t) => {return {...t, active: template.id === t.id}})
        setTemplates(tmpTemplates);
        setChosenTemplate({...template, active: true});
        setSuccessMessage('');
        setSuccessEmailMessage('');
    }

    return (
        <React.Fragment>
            <Card {...rest} className={classes.root}>
                {
                    templates.map((template) => (
                        <CardContent
                            className={classes.content} key={template.id}
                            onClick={() => {handleTemplateChange(template)}}
                        >
                            <div style={{position: 'relative'}}>
                                <CardMedia
                                    component="img"
                                    className={classes.media}
                                    image={template.preview_img}
                                />
                                {
                                    template.active &&
                                    <React.Fragment>
                                        <div className={classes.mediaBackground}></div>
                                        <div className={classes.mediaText}>
                                            <Typography variant="body1" color="primary">
                                                <CheckCircle />
                                            </Typography>
                                        </div>
                                    </React.Fragment>

                                }
                            </div>
                            <Typography
                                variant="caption"
                                color="primary"
                                align="center"
                                paragraph={true}
                                style={{fontWeight: template.active? 'bold' : 'normal'}}
                            >
                                { template.name }
                            </Typography>
                        </CardContent>
                    ))
                }
            </Card>
            <EmailEdit
                user={user}
                setUser={setUser}
                template={chosenTemplate}
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                successEmailMessage={successEmailMessage}
                setSuccessEmailMessage={setSuccessEmailMessage}
            />
        </React.Fragment>
    )
}

export default EmailTemplateSelector;