import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import {
    Tooltip,
    IconButton,
    Box,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import getApiRoute from 'routes/apiRoutes';
import ClientTable from 'components/ClientTable';
import { NavLink as RouterLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UsersIconDialog({ data, loadPriceList, ...rest }) {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            secureFetch(getApiRoute('admin_pricelist_users', [data.id])).then(json => {
                setLoading(false);
                setRows(json);
            });
        }
    }, [data.id, open, secureFetch]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'eshop'
        },
        headCells: [
            { id: 'eshop', numeric: false, label: t('label.eshop_name'), search: true },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                return (
                    <Box display="flex">
                        <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')}>
                            <IconButton
                                aria-label={t('tooltip.edit')}
                                component={RouterLink}
                                size="small"
                                to={"/app/admin/user/edit/" + data.id}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            } },
        ],
    };

    return (
        <div>
            <Tooltip title={t('tooltip.users')} aria-label={t('tooltip.users')} onClick={handleClickOpen}>
                <IconButton
                    aria-label={t('tooltip.users')}
                    size="small"
                >
                    <PeopleIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle>{t('title.users') + ' - ' + data.name}</DialogTitle>
                <DialogContent>
                    <ClientTable settings={settings} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {t('button.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
