import React from 'react';
import {
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import MessageListTable from './MessageListTable';

export default function MessageListView() {
    const { t } = useTranslation();

    return (
        <Page
            title={t('navbar.messages')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.messages')}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MessageListTable/>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}