import React, {useContext, useEffect} from 'react';
import {
    Container,
    Grid,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import PackageTable from './PackageTable';
import PackageTableService from './PackageTableService';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import TabPanel from "../../../components/TabPanel";
import {hasService, hasStandard, isAdmin, isUser} from "../../../helpers";
import AppContext from "../../../context/AppContext";
import getApiRoute from "../../../routes/apiRoutes";
import SecureFetchContext from "../../../context/SecureFetchContext";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTab-root': {
            minWidth: 'initial',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    appBar:{
        background: 'white'
    }
}));

export default function PackageListView(){
    const classes = useStyles();
    const { t } = useTranslation();
    const [tab, setTab] = React.useState("");
    const [balance, setBalance] = React.useState(0);
    const { appValues } = useContext(AppContext);
    let navigate = useNavigate();
    let location = useLocation();
    const secureFetch = useContext(SecureFetchContext);

    const [userType, setUserType] = React.useState("");

    useEffect(() => {
        if (location && location.pathname === '/app/package/service') {
            setTab('service');
            if (isUser(appValues.user)) {
                secureFetch(getApiRoute('sticker_balance', ['service'])).then((json) => {
                    setBalance(json.balance);
                });
            }
        }
        else {
            if (isUser(appValues.user)) {
                secureFetch(getApiRoute('sticker_balance', [tab])).then((json) => {
                    setBalance(json.balance);
                });
            }
        }
    }, [location]);

    useEffect(() => {
        if (userType === "" && isUser(appValues.user)){
            if (hasService(appValues.user) && !hasStandard(appValues.user)) {
                setUserType('service');
                setTab('service');
            }
            if (!hasService(appValues.user) && hasStandard(appValues.user)) {
                setUserType('standard');
            }
            if (hasService(appValues.user) && hasStandard(appValues.user)) {
                setUserType('combined');
            }
        } else{
            if (isAdmin(appValues.user)){
                setUserType('combined'); //admins have always both package tables
            }
        }
    }, [userType])

    const handleChange = (event, newValue) => {
        setTab(newValue);
        secureFetch(getApiRoute('sticker_balance', [newValue])).then((json) => {
            setBalance(json.balance);
        });
        navigate("/app/package/"+newValue);
    };

    return (
        <Page
            className={classes.root}
            title={t('navbar.packages')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.packages')}
                </Typography>
                <div className={classes.root}>
                    {
                        <AppBar className={classes.appBar} position="static" color="default" hidden>
                            <Tabs
                                value={tab}
                                onChange={handleChange}
                                aria-label="scrollable auto tabs"
                                indicatorColor="primary"
                                textColor="primary"
                                scrollButtons="auto"
                                variant="scrollable"
                            >
                                {
                                    (userType == 'standard' || userType == 'combined') &&
                                    <Tab value="" label={t('title.standard_shipments')} />
                                }
                                {
                                    (userType == 'service' || userType == 'combined') &&
                                    <Tab value="service" label={t('title.service_shipments')} />
                                }
                                {
                                    isUser(appValues.user) &&
                                    <Tab value="balance" label={" Počet zostávajúcich štítkov: " + balance } disabled wrapped/>
                                }
                            </Tabs>
                        </AppBar>
                    }
                    {
                        (userType == 'standard' || userType == 'combined') &&
                        <TabPanel className={classes.tabPanel} value={tab} index={""}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <PackageTable/>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    }
                    {
                        (userType == 'service' || userType == 'combined') &&
                        <TabPanel className={classes.tabPanel} value={tab} index={"service"}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <PackageTableService/>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    }
                </div>
            </Container>
        </Page>
    );
}