import React from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    makeStyles
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const InsurancePriceTable = ({ className, prices, shipper, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const settings = {
        rows: prices,
        loading: false,
        colOrder: {
            order: 'desc',
            orderBy: 'price'
        },
        headCells: [
            { id: 'state_to', numeric: false, label: t('label.state_to'), search: true },
            { id: 'amount_from', numeric: true, label: t('label.amount_from'), search: true, render: (data) => data.amount_from + ' ' + data.symbol },
            { id: 'amount_to', numeric: true, label: t('label.amount_to'), search: true, render: (data) => data.amount_to + ' ' + data.symbol },
            { id: 'price', numeric: true, label: t('label.price'), search: true, render: (data) => data.price + ' €' },
        ],
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                {
                    shipper.shortcut === 'SPS' &&
                    <Alert variant="filled" severity="info">
                        {t('messages.sps_insurance')}
                    </Alert>
                }
                {
                    shipper.shortcut === '123' &&
                    <Alert variant="filled" severity="info">
                        {t('messages.123_insurance')}
                    </Alert>
                }
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default InsurancePriceTable;