import React, { useEffect, useState, useCallback, useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Typography,
    makeStyles,
    Tooltip,
} from '@material-ui/core';
import ServerTable from 'components/ServerTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import CodPrice from 'components/CodPrice';
import {createUrlQuery, formatDate, isAdmin, isUser} from 'helpers';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => ({
    root: {},
    checkbox: {
        padding: 0
    }
}));

const ReturnedPackageTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState({ rows: [], count: 0 });
    const [returnedCounts, setReturnedCounts] = useState({ count: 0, total: 0 });
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);

    const loadPackages = useCallback((pConditions = null) => {
        setLoading(true);
        secureFetch(getApiRoute(isUser(appValues.user) ? 'statistics' : 'admin_statistics', ['returnedpackage']) + '?' + createUrlQuery(pConditions) ).then(json => {
            setLoading(false);
            setReturnedCounts({ count: json.totalReturned, total: json.total, totalPrice: json.totalPrice });
            setRowsData(json);
        })
    }, [secureFetch, appValues.user])

    const settings = {
        rows: rowsData.rows,
        rowsLength: rowsData.count,
        loading: loading,
        colOrder: {
            order: 'desc',
            order_by: 'created_at'
        },
        handleTableUpdate: loadPackages,
        headCells: [
            { id: 'created_at', numeric: false, label: t('label.created_at'), search: true, searchType: 'dateRange', render: (data) => formatDate(data.created_at, true) },
            { id: 'shipper', numeric: false, label: t('label.shipper'), search: true, searchType: 'select', selectChoices: isUser(appValues.user) ? appValues.user.user_shipper_price_lists.map(v => v.shipper) : appValues.shippers },
            { id: 'reference_number', numeric: false, label: t('label.reference_number'), search: true },
            { id: 'tracking_number', numeric: false, label: t('label.tracking_number'), search: true },
            { id: 'receiver', numeric: false, label: t('label.receiver'), search: true, disableOrder: true },
            { id: 'cod_price', numeric: true, label: t('label.cod_price'), search: false, render: (data) => <CodPrice data={data} /> },
            { id: 'price_vat', numeric: true, label: t('label.price_vat'), search: false, render: (data) => {
                    return data.price_vat ? parseFloat(data.price_vat >= 0.01 ? data.price_vat : 0).toFixed(2) + ' €' : null;
                } },
        ],
    };

    if ( isAdmin(appValues.user) ) {
        settings.headCells.splice(2, 0, { id: 'user', numeric: false, label: t('label.eshop_name'), search: true, searchType: 'select', selectChoices: users, render: (data) => data.eshop});
    }

    useEffect(() => {
        if ( isAdmin(appValues.user) ) {
            secureFetch( getApiRoute( 'admin_user' ) ).then(json => {
                setUsers(json.map(user => {
                    return {
                        id: user.id,
                        name: user.eshop || ''
                    }
                }));
            })
        }
    }, [appValues.user, secureFetch]);


    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <Typography variant="h5" gutterBottom >
                    {t('messages.returned_stat', { count: returnedCounts.count, total: returnedCounts.total, percentage: returnedCounts.count ? (returnedCounts.count / returnedCounts.total * 100).toFixed(2) : 0 , totalPrice: parseFloat(returnedCounts.totalPrice).toFixed(2)})}
                    <Tooltip title={isUser(appValues.user) ? t('help.returned_package') : t('help.returned_package_admin')}>
                        <HelpIcon fontSize="small" style={{marginLeft: '0.3em', marginBottom: '-0.2em'}} />
                    </Tooltip>
                </Typography>
                <ServerTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default ReturnedPackageTable;