import React, {useContext} from 'react';
import { useTranslation } from "react-i18next";
import {
    makeStyles,
    Tooltip,
    Typography,
    Button,
    Box,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import UpdateIcon from '@material-ui/icons/Update';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { Package as PackageIcon } from 'react-feather';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {isAdmin, openNewTab} from "../../helpers";
import AppContext from "../../context/AppContext";
import getApiRoute from "../../routes/apiRoutes";
import SecureFetchContext from "../../context/SecureFetchContext";
import MoneyOffIcon from '@material-ui/icons/MoneyOff';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {},
    rowButtons: {
        padding: '5px'
    }
}));

const CreditLogTableActions = ({ className, data, loadPackages, ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { appValues } = useContext(AppContext);
    const secureFetch = useContext(SecureFetchContext);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleComgateVerify = () => {
        secureFetch(getApiRoute('admin_comgate_verify', [data.id]), '', 'GET' ).then(json => {
            loadPackages();
        });
    };

    const handleBesteronVerify = () => {
        secureFetch(getApiRoute('admin_besteron_verify', [data.id]), '', 'GET' ).then(json => {
            loadPackages();
        });
    };

    const returnPayment = () => {
        secureFetch(getApiRoute('admin_return_payment', [data.id]), '', 'GET' ).then(json => {
            loadPackages();
            window.location.reload(false);
        });
    };

    return (
        <Box display="flex">
            <Tooltip title={t('tooltip.detail')} aria-label={t('tooltip.detail')}>
                <IconButton
                    aria-label={t('tooltip.detail')}
                    className={classes.rowButtons}
                    onClick={handleClickOpen}
                    size="small"
                >
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            {
                data.package_id &&
                <Tooltip title={t('tooltip.package')} aria-label={t('tooltip.package')}>
                    <IconButton
                        aria-label={t('tooltip.package')}
                        className={classes.rowButtons}
                        size="small"
                        onClick={() => openNewTab("/app/package/detail/" + data.package_id)}
                    >
                        <PackageIcon />
                    </IconButton>
                </Tooltip>
            }
            {
                data.type === 13 && isAdmin(appValues.user) && data.credit_from == data.credit_to &&
                <Tooltip title={t('tooltip.comgate_verify')} aria-label={t('tooltip.comgate_verify')}>
                    <IconButton
                        aria-label={t('tooltip.comgate_verify')}
                        className={classes.rowButtons}
                        size="small"
                        onClick={handleComgateVerify}
                    >
                        <UpdateIcon />
                    </IconButton>
                </Tooltip>
            }
            {
                data.type === 12 && isAdmin(appValues.user) && data.credit_from == data.credit_to &&
                <Tooltip title={t('tooltip.besteron_verify')} aria-label={t('tooltip.besteron_verify')}>
                    <IconButton
                        aria-label={t('tooltip.besteron_verify')}
                        className={classes.rowButtons}
                        size="small"
                        onClick={handleBesteronVerify}
                    >
                        <UpdateIcon />
                    </IconButton>
                </Tooltip>
            }
            {
                isAdmin(appValues.user) && [10, 11, 12, 13].includes(data.type) && data.credit_from !== data.credit_to && data.credit > 0 &&
                <Tooltip title={t('tooltip.return')} aria-label={t('tooltip.return')}>
                    <IconButton
                        aria-label={t('tooltip.return')}
                        className={classes.rowButtons}
                        size="small"
                        onClick={returnPayment}
                    >
                        <MoneyOffIcon />
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
            >
                <DialogContent>
                    <Typography variant="h3" component="h3">
                        {t('title.detail')}
                    </Typography>
                    <List className={classes.root}>
                        <ListItem dense disableGutters >
                            <ListItemText 
                                primary={t('label.created_at')} 
                                secondary={data.created_at}
                            />
                        </ListItem>
                        <ListItem dense disableGutters >
                            <ListItemText
                                primary={t('label.credit_from')}
                                secondary={data.credit_from}
                            />
                        </ListItem>
                        <ListItem dense disableGutters >
                            <ListItemText
                                primary={t('label.credit')}
                                secondary={data.credit}
                            />
                        </ListItem>
                        <ListItem dense disableGutters >
                            <ListItemText
                                primary={t('label.credit_to')}
                                secondary={data.credit_to}
                            />
                        </ListItem>
                        <ListItem dense disableGutters >
                            <ListItemText 
                                primary={t('label.message')} 
                                secondary={data.message}
                            />
                        </ListItem>
                        <ListItem dense disableGutters >
                            <ListItemText 
                                primary={t('label.reference_number')} 
                                secondary={data.reference_number}
                            />
                        </ListItem>
                        <ListItem dense disableGutters >
                            <ListItemText 
                                primary={t('label.data')} 
                                secondaryTypographyProps={ {component: 'div'} }
                                secondary={
                                    <pre>
                                        {JSON.stringify(JSON.parse(data.data), null, 2)}
                                    </pre>
                                }
                            />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {t('button.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default CreditLogTableActions;