import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    TextField,
    Grid,
    makeStyles, Typography
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AppContext from "../../context/AppContext";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
    root: {}
}));


const NewIbanForm = ({ className, handleDialogOpenClose, load, user, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const [states, setStates] = useState({});
    const { appValues } = useContext(AppContext);
    const [alertMessage, setAlertMessage] = useState('');

    const handleChange = (event, index) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if ( !states.states_to || states.states_to.length === 0 ) {
            setAlertMessage(t('messages.states_not_blank'));
            return;
        }
        values.state_to = states.states_to.map(state => state.id);
        values.user = user.id;
        secureFetch(getApiRoute('admin_iban'), values, 'POST').then(json => {
            setAlertMessage('');
            handleDialogOpenClose();
            load();
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.create_iban')}
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <InputLabel >{t('label.states_to')}</InputLabel>
                    <Autocomplete
                        multiple
                        options={appValues.states}
                        onChange={ (event, pStates) => setStates({ ...states, states_to: pStates }) }
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label={t('label.states_to')} placeholder={t('label.state')} />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="shipper">{t('label.shipper')}</InputLabel>
                        <Select
                            labelId="shipper"
                            value={ values.shipper || '' }
                            name="shipper"
                            onChange={handleChange}
                            required
                        >
                            {appValues.shippers.map((option) => (
                                <MenuItem
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.shortcut}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('label.iban')}
                        name="iban"
                        onChange={handleChange}
                        value={values.iban || ''}
                        type="text"
                        required
                    />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" py={2}>
                {
                    alertMessage === '' ? null :
                        <Box>
                            <Alert variant="filled" severity="error">
                                {alertMessage}
                            </Alert>
                        </Box>
                }
                <Button color="secondary" variant="text" onClick={handleDialogOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.create')}
                </Button>
            </Box>

        </form>
    );
};

NewIbanForm.propTypes = {
    className: PropTypes.string
};

export default NewIbanForm;
