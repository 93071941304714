import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    Grid,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';

const EditPriceListForm = ({ id, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({ type: 'sale' });
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (id) {
            secureFetch(getApiRoute('admin_pricelist', [id])).then(json => {
                json.price = parseFloat(json.price).toFixed(2);
                setValues({
                    name: json.name,
                    type: json.type,
                    price: json.price,
                    shipper: json.shipper.id,
                });
            });
        }
    }, [id, secureFetch]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch(getApiRoute('admin_pricelist', [id]), values, 'PUT').then(json => {
            setSuccessMessage(t('messages.data_saved'));
        });
    };

    return (
        <form
            autoComplete="off"
            onSubmit={handleSubmit}
            {...rest}
        >
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.name')}
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.price')}
                                        name={"price"}
                                        type="number"
                                        onChange={handleChange}
                                        value={values["price"] || ''}
                                        inputProps={{ step: "0.001" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert variant="filled" severity="error">
                                        {t('messages.pricelist_warning')}
                                    </Alert>
                                </Grid>
                            </Grid>

                        </CardContent>
                        { 
                            successMessage === '' ? null : 
                            <Box px={2}>
                                <Alert variant="filled" severity="success">
                                    {successMessage}
                                </Alert>
                            </Box>
                        }
                        <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.save')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
};

EditPriceListForm.propTypes = {
    className: PropTypes.string
};

export default EditPriceListForm;
