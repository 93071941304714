import React, { useContext } from 'react';
import {
    Container,
} from '@material-ui/core';
import Page from 'components/Page';
import AppContext from 'context/AppContext';
import UserDashboard from './UserDashboard';
import AdminDashboard from './AdminDashboard';
import PartnerDashboard from './PartnerDashboard';
import UnreadMessagesDialog from "../../layouts/DashboardLayout/UnreadMessagesDialog";

const Dashboard = () => {
    const { appValues } = useContext(AppContext);

    const renderDashboard = () => {
        if (appValues.user.roles.includes('ROLE_PARTNER')) {
            return <PartnerDashboard />;
        } else if (appValues.user.roles.includes('ROLE_ADMIN')) {
            return <AdminDashboard />;
        } else {
            return <UserDashboard />;
        }
    };

    return (
        <Page
            title="Neoship"
        >
            <Container maxWidth={false}>
                {renderDashboard()}
                <UnreadMessagesDialog />
            </Container>
        </Page>
    );
};

export default Dashboard;
