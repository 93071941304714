import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import {
    Tooltip,makeStyles,
} from '@material-ui/core';
import getApiRoute from 'routes/apiRoutes';
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AppContext from 'context/AppContext';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {}
}));

export default function UpdateFuelDialog({  ...rest }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState({fuel: ''});
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch(getApiRoute('admin_pricelist_fuel'), values, 'POST').then(json => {
            handleClose();
        });
    };

    return (
        <div>
            <Tooltip title={t('tooltip.update_fuel')} aria-label={t('tooltip.update_fuel')} onClick={handleClickOpen}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="small"
                    startIcon={<EditIcon />}
                >
                    {t('button.update_fuel')}
                </Button>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle>{t('title.update_fuel')}</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="shipper">{t('label.shipper')}</InputLabel>
                        <Select
                            labelId="shipper"
                            value={ values.shipper || '' }
                            name="shipper"
                            onChange={handleChange}
                            required
                        >
                            {appValues.shippers.map((option) => (
                                <MenuItem
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.shortcut}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label={t('label.value')}
                        name={"fuel"}
                        type="number"
                        onChange={handleChange}
                        value={values["fuel"] || ''}
                        inputProps={{ step: "1" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {t('button.close')}
                    </Button>
                    <Button onClick={handleSubmit} color="primary" variant='contained'>
                        {t('button.edit')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
