import React, { useEffect, useContext } from 'react';
import {
    Container,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import { isNumeric } from "helpers";
import { useParams, useNavigate } from "react-router-dom";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import EditAddressForm from "./EditAddressForm";

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

export default function AddressEditView() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [address, setAddress] = React.useState({name: ''});
    let { id } = useParams();
    let navigate = useNavigate();
    const secureFetch = useContext(SecureFetchContext);

    useEffect(() => {
        if ( !isNumeric(id) ) {
            navigate('/404');
        }
        if ( address.id !== parseInt(id) ) {
            secureFetch( getApiRoute('address', [id]) ).then(json => {
                setAddress(json);
            });
        }
    }, [id, navigate, secureFetch, address.id]);

    return (
        <Page
            className={classes.root}
            title={t('title.edit_addresss') + ' - ' + address.name}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('title.edit_address') + ' - ' + address.name}
                </Typography>
                <EditAddressForm address={address} />
            </Container>
        </Page>
    );
}