import React, { useState, useContext, useEffect } from 'react';
import {
    Container,
    Typography,
    Card,
    CardContent,
    Box,
    Grid,
    TextField,
    InputAdornment,
    Button,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import CreditLogTable from 'views/CreditLogView/CreditLogTable';
import { useParams, useNavigate } from "react-router-dom";
import AppContext from 'context/AppContext';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { isNumeric } from "helpers";
import { useLocation } from "react-router-dom";

export default function PackagePriceView() {
    const { t } = useTranslation();
    const [values, setValues] = useState({});
    const [refreshLog, setRefreshLog] = useState(0);
    const [packageValues, setPackageValues] = useState({});
    const { id, reference_number } = useParams();
    const { appValues } = useContext(AppContext);
    const secureFetch = useContext(SecureFetchContext);
    const { state } = useLocation();

    let navigate = useNavigate();

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const calculatePrice = (e) => {
        e.preventDefault();
        secureFetch( getApiRoute('admin_package_calculateprice', [id]), values, 'POST' ).then(json => {
            setRefreshLog(refreshLog + 1);
            if (state.isSubPackage && !(packageValues.shipper && packageValues.shipper.shortcut.toUpperCase() === 'GLS')) {
                navigate('/app/package');
            }
        });
    };

    useEffect(() => {
        if (!isNumeric(id) || (reference_number && reference_number === '')) {
            navigate('/404');
        }

        secureFetch( getApiRoute('admin_package', [id]) ).then(json => {
            setPackageValues(json);
            setValues({ weight: json.weight, cod_payment: json.cod_payment });
        });

    }, [id, reference_number, navigate, appValues.user.roles, secureFetch]);

    return (
        <Page
            title={t('title.price')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('title.price')}
                </Typography>
                <Card>
                    <CardContent>
                        <form onSubmit={calculatePrice}>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField
                                        fullWidth
                                        label={t('label.weight')}
                                        name={"weight"}
                                        onChange={handleChange}
                                        type="number"
                                        value={values["weight"] || ''}
                                        inputProps={{ step: "0.001" }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                                        }}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            {
                                !state.isSubPackage &&
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormControl fullWidth>
                                            <InputLabel >{t('label.payment_type')}</InputLabel>
                                            <Select
                                                value={values.cod_payment != null ? values.cod_payment : ''}
                                                name="cod_payment"
                                                onChange={handleChange}
                                            >
                                                {appValues.paymentTypes.map((option) => (
                                                    <MenuItem
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Button color="primary" variant="contained" type="submit">
                                        {t('button.save')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
                {
                    (!state.isSubPackage || (packageValues.shipper && packageValues.shipper.shortcut.toUpperCase() === 'GLS')) &&
                    <React.Fragment>
                        <Box pt={3}/>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {packageValues.user && <CreditLogTable packageId={id} user={packageValues.user} refresh={refreshLog} />}
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Container>
        </Page>
    );
}