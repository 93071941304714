import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';

const CountryProfitStatisticsTable = ({ params, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [rows, setRows] = useState([]);
    const [sumCount, setSumCount] = useState(1);
    const [sumProfit, setSumProfit] = useState(1);

    useEffect(() => {
        secureFetch(getApiRoute('admin_statistics', ['countryprofit']) + '?' + params).then(json => {
            let sumCount = 0;
            let sumProfit = 0;
            for (const val of json) {
                val.id = val.receiver_state_code;
                sumCount += +val.count;
                sumProfit += +val.profit;
            }
            setSumCount(sumCount);
            setSumProfit(sumProfit);
            setRows(json);
        });
    }, [secureFetch, params]);

    const settings = {
        rows: rows,
        loading: false,
        colOrder: {
            order: 'asc',
            orderBy: 'count'
        },
        headCells: [
            { id: 'receiver_state_code', numeric: false, label: t('label.state'), search: true },
            { id: 'count', numeric: true, label: t('label.count'), search: false, render: (data) => parseFloat(data.count).toFixed(2) + ' (' + parseFloat(data.count / sumCount * 100).toFixed(2) + '%)' },
            { id: 'profit', numeric: true, label: t('label.profit'), search: false, render: (data) => parseFloat(data.profit).toFixed(2) + '€ (' + parseFloat(data.profit / sumProfit * 100).toFixed(2) + '%)' },
        ],
        totalDataRow: (cell, data) => {
            if (cell.id !== 'receiver_state_code') {
                let sum = 0;
                for (const row of rows) {
                    sum += parseFloat(row[cell.id]);
                }
                return (<b>{sum.toFixed(2) + (cell.id !== 'count' ? ' €' : '')}</b>);
            }
            else {
                return (
                    <b>{t('label.total') + ' ' + data.length}</b>
                )
            }
        }
    };

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default CountryProfitStatisticsTable;