import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import AppContext from 'context/AppContext';

const useStyles = makeStyles(() => ({
    root: {}
}));

const InvoiceAddressForm = ({ className, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appValues } = useContext(AppContext);

    const address = appValues.user.invoice_address ? appValues.user.invoice_address : {};

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Card>
                <CardHeader
                    subheader={t('messages.invoice_data_desc')}
                    title={t('title.invoice_data')}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.name')}
                                        name="name"
                                        value={address.name || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.company')}
                                        name="company"
                                        value={address.company || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.email')}
                                        name="email"
                                        value={address.email || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.phone')}
                                        name="phone"
                                        value={address.phone || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item lg={7} xs={8}>
                                    <TextField
                                        fullWidth
                                        label={t('label.street')}
                                        name="street"
                                        value={address.street || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item lg={5} xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('label.house_number')}
                                        name="house_number"
                                        value={address.house_number || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.city')}
                                        name="city"
                                        value={address.city || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.zip')}
                                        name="zip"
                                        value={address.zip || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth disabled>
                                        <InputLabel id="deliverystates">{ t('label.state') }</InputLabel>
                                        <Select
                                            labelId="deliverystates"
                                            id="demo-simple-select"
                                            value={ address.state ? address.state.id : appValues.states[ appValues.states.findIndex(i => i.code === 'SK') ].id || null }
                                        >
                                            {appValues.states.map((option) => (
                                                <MenuItem 
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('label.oin')}
                                        name="oin"
                                        value={address.oin || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('label.tin')}
                                        name="tin"
                                        value={address.tin || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('label.vatin')}
                                        name="vatin"
                                        value={address.vatin || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('label.bank_account')}
                                name="bank_account"
                                value={address.bank_account || ''}
                                required
                                disabled
                            />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </form>
    );
};

InvoiceAddressForm.propTypes = {
    className: PropTypes.string,
    states: PropTypes.array
};

export default InvoiceAddressForm;
