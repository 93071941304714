import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AppContext from 'context/AppContext';

const useStyles = makeStyles(() => ({
    root: {}
}));

const EditServiceForm = ({ className, service, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const { appValues } = useContext(AppContext);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        if ( service.name ) {
            setValues({
                code: service.code,
                name: service.name,
                input_name: service.input_name,
                shipper: service.shipper.id,
                input_type: service.input_type,
                description: service.description,
                visible: service.visible,
                enabled_from: service.enabled_from ? service.enabled_from.slice(0, 10) : null,
                enabled_to: service.enabled_to ? service.enabled_to.slice(0, 10) : null,
            });
        }
    }, [service]);

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch(getApiRoute('admin_service', [service.id]), values, 'PUT').then(json => {
            setSuccessMessage(t('messages.data_saved'));
        });
    };

    const handleCheckboxChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.checked });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                            <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.code')}
                                        name="code"
                                        onChange={handleChange}
                                        value={values.code || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.name')}
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="shipper">{t('label.shipper')}</InputLabel>
                                        <Select
                                            labelId="shipper"
                                            value={ values.shipper || '' }
                                            name="shipper"
                                            onChange={handleChange}
                                            required
                                        >
                                            {appValues.shippers.map((option) => (
                                                <MenuItem 
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.shortcut}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.input_name')}
                                        name="input_name"
                                        onChange={handleChange}
                                        value={values.input_name || ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="input_type">{t('label.input_type')}</InputLabel>
                                        <Select
                                            labelId="input_type"
                                            value={ values.input_type !== undefined && values.input_type !== null ? values.input_type : '' }
                                            name="input_type"
                                            onChange={handleChange}
                                        >
                                            {appValues.inputTypes.map((option) => (
                                                <MenuItem 
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.description')}
                                        name="description"
                                        onChange={handleChange}
                                        value={values.description || ''}
                                        rows={4}
                                        multiline
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="enabled_from"
                                        label={t('label.enabled_from')}
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={values.enabled_from || ''}
                                        fullWidth
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="enabled_to"
                                        label={t('label.enabled_to')}
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={values.enabled_to || ''}
                                        fullWidth
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.visible ? true : false}
                                                onChange={handleCheckboxChange}
                                                name="visible"
                                                color="primary"
                                            />
                                        }
                                        label={t('label.visible')}
                                    />
                                </Grid>
                            </Grid>

                        </CardContent>
                        { 
                            successMessage === '' ? null : 
                            <Box px={2}>
                                <Alert variant="filled" severity="success">
                                    {successMessage}
                                </Alert>
                            </Box>
                        }
                        <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.save')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
};

EditServiceForm.propTypes = {
    className: PropTypes.string
};

export default EditServiceForm;
