import React, {useContext, useEffect} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Typography, List, ListItem, ListItemText
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Slide from '@material-ui/core/Slide';
import AppContext from 'context/AppContext';
import CheckIcon from "@material-ui/icons/Check";
import {formatDate, isNumeric} from "../../helpers";
import {useNavigate} from "react-router-dom";
import MailIcon from "@material-ui/icons/Mail";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EuroIcon from "@material-ui/icons/Euro";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import ClearIcon from "@material-ui/icons/Clear";
import MUIRichTextEditor from "mui-rte";
import getApiRoute from "../../routes/apiRoutes";
import SecureFetchContext from "../../context/SecureFetchContext";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getIcon = (row) => {
    switch (row.type) {
        case 'Príplatok':
            return (<EuroIcon />);
        case 'Upozornenie':
            return (<WarningIcon />);
        default:
            return (<InfoIcon />);
    }
}

const UnreadMessagesDialog = ({ ...rest }) => {
    const { t } = useTranslation();
    const { appValues, setAppValues } = useContext(AppContext);
    const [open, setOpen] = React.useState(false);
    const secureFetch = useContext(SecureFetchContext);
    let navigate = useNavigate();

    const handleDialogClose = () => {
        setOpen(false);
        setAppValues({...appValues, openMessageDialog: false});
    };

    const handleUnderstand = (id) => {
        if (id && !isNumeric(id)) {
            navigate('/404');
        }

        secureFetch(getApiRoute( appValues.user.roles.includes('ROLE_ADMIN') ? 'admin_message' : 'message', [id])).then(json => {
            secureFetch(getApiRoute('message_unread')).then(json => {
                setAppValues({ ...appValues, messages: json });
            });
        });
    }

    useEffect(() => {
        if (appValues.openMessageDialog && appValues.messages.length) {
            setOpen(true);
        }
    }, [appValues.messages, appValues.openMessageDialog]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleDialogClose}
            aria-describedby="unread-messages-dialog-slide-description"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>{t('title.unread_messages')}</DialogTitle>
            <DialogContent>
                { appValues.messages.length === 1 &&
                appValues.messages.map((m) => {
                    return (
                        <React.Fragment key='single-message'>
                            <Box
                                display="flex"
                                mb={2}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    mr={2}
                                >
                                    {getIcon(m)}
                                </Box>
                                <Box>
                                    <Typography variant='h6'>
                                        {m.title}
                                    </Typography>
                                    <Typography variant='subtitle1'>
                                        {formatDate(m.created_at, true)}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography variant="body1" component="div">
                                <MUIRichTextEditor
                                    defaultValue={(m.content || '')}
                                    readOnly={true}
                                    inheritFontSize={true}
                                    toolbar={false}
                                />
                            </Typography>
                        </React.Fragment>
                    )
                })
                }
                { appValues.messages.length > 1 &&
                <List>
                    {
                        appValues.messages.map((m) => {
                            return (
                                <ListItem
                                    key={m.id}
                                    button
                                    onClick={() => {
                                        handleDialogClose();
                                        navigate('/app/messages/'+m.id);
                                    }}
                                >
                                    <ListItemIcon>
                                        {getIcon(m)}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={m.title}
                                        secondary={formatDate(m.created_at, true)}
                                        primaryTypographyProps={{
                                            variant: 'h6'
                                        }}
                                    />
                                </ListItem>
                            )
                        })
                    }
                </List>
                }
            </DialogContent>
            <DialogActions>
                {appValues.messages.length === 1 &&
                <Button
                    onClick={() => {
                        handleDialogClose();
                        handleUnderstand(appValues.messages[0].id)
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<CheckIcon />}
                >{t('button.understand')}
                </Button>
                }
                { appValues.messages.length > 1 &&
                <Button
                    onClick={() => {
                        handleDialogClose();
                        navigate('/app/messages');
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<MailIcon />}
                >{t('button.open_messages')}
                </Button>
                }
                <Button
                    onClick={handleDialogClose}
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<ClearIcon />}
                >{t('button.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnreadMessagesDialog;
