import React, { useState, useContext, useEffect } from 'react';
import {
    Container,
    Typography,
    Card,
    CardContent,
    Grid, Box, Tooltip,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {getShipperLogoPath} from "helpers";
import Alert from '@material-ui/lab/Alert';
import ClientTable from "../../../components/ClientTable";
import IconButton from "@material-ui/core/IconButton";
import {Download} from "react-feather";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 600,
    },
});

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default function PackagePrintedDocuments() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [values, setValues] = useState(null);
    const secureFetch = useContext(SecureFetchContext);
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = React.useState('');

    useEffect(() => {
        setLoading(true);
        secureFetch(getApiRoute('package', ['printeddocuments'])).then(json => {
            setValues(json);
            // setExpanded(Object.keys(json)[0]);
            setLoading(false);
        });
    }, [secureFetch]);

    const handleRowClick = (shipper, file) => {
        const params = new URLSearchParams({
            shipper: shipper,
            file: file,
        });

        secureFetch(getApiRoute('package', ['printeddocuments']) + '?' + params.toString()).then(json => {
            if (json['document'] && json['document'] !== '') {
                let a = document.createElement('a');
                a.href = 'data:application/pdf;base64,' + json['document'];
                a.download = file;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
            }
        });
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getTableSettingsByShipper = (shipper) => {
        return {
            rows: values[shipper],
            rowsPerPage: 15,
            loading: loading,
            colOrder: {
                order: 'desc',
                orderBy: 'name'
            },
            headCells: [
                { id: 'id', numeric: true, label: t('label.id'), search: false, hide: true },
                {
                    id: 'name', numeric: false, label: t('label.file'), search: true, render: (data) => {
                        return (
                            <React.Fragment>
                                <Grid container spacing={1} alignItems={"center"}>
                                    <PictureAsPdfIcon/><Typography variant="body1" >{data.name}</Typography>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                },
                { id: 'time', numeric: false, label: t('label.time'), search: true, render: (data) => data.time},
                {
                    id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                        return (
                            <Box display="flex">
                                <Tooltip title={t('tooltip.download')} aria-label={t('tooltip.download')}
                                         onClick={() => {
                                             handleRowClick(shipper, data.name)
                                         }}>
                                    <IconButton
                                        size="small"
                                    >
                                        <Download/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        );
                    }
                },
            ]
        }
    };

    return (
        <Page
            title={t('navbar.printed_documents')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.printed_documents')}
                </Typography>
                <Card>
                    <CardContent>
                        {
                            values && Object.keys(values).length === 0 &&
                            <Alert variant="filled" severity="info">
                                {t('messages.no_printed_documents')}
                            </Alert>
                        }
                        {
                            values && Object.keys(values).map( (shipper, index) => {
                                return (
                                    <Accordion key={shipper+'-'+index} expanded={expanded === shipper} onChange={handleChange(shipper)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={shipper}
                                            id={index}
                                        >
                                            <Typography className={classes.heading}><img height={18} src={getShipperLogoPath(shipper)} alt={shipper} /></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ClientTable settings={getTableSettingsByShipper(shipper)}/>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }
                    </CardContent>
                </Card>

            </Container>
        </Page>
    );
}