import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import MainLayout from 'layouts/MainLayout';
import DashboardView from 'views/DashboardView';
import AccountView from 'views/AccountView';
import ParcelshopView from 'views/ParcelshopView';
import PackageNewEditView from 'views/Package/PackageNewEditView';
import PackageEditView from 'views/Package/PackageEditView';
import PackageImportView from 'views/Package/PackageImportView';
import PackageListView from 'views/Package/PackageListView';
import PackagePrintSticker from 'views/Package/PackageListView/PackagePrintSticker';
import PackagePaidListView from 'views/Package/PackagePaidListView';
import PackagePrintedDocuments from 'views/Package/PackagePrintedDocuments';
import PackageUnpaidListView from 'views/Package/PackageUnpaidListView';
import CalculationView from 'views/Package/CalculationView';
import PackageDetailView from 'views/Package/PackageDetailView';
import ClaimAssistant from 'views/Package/PackageNewEditView/ClaimAssistant';
import UserPrices from 'views/UserPrices';
import LogView from 'views/LogView';
import CreditLogView from 'views/CreditLogView';
import CreditView from 'views/CreditView';
import MessageListView from 'views/Message/MessageListView';
import MessageDetailView from 'views/Message/MessageDetailView';
import PackageCountStatistics from 'views/Statistics/PackageCountStatistics';
import ReturnedPackageStatistics from 'views/Statistics/ReturnedPackageStatistics';
import ImportCsv from 'views/Package/PackageImportView/ImportCsv';
import ImportXml from 'views/Package/PackageImportView/ImportXml';
import ImportOmega from 'views/Package/PackageImportView/ImportOmega';
import ImportPohoda from 'views/Package/PackageImportView/ImportPohoda';
import ImportBiznisweb from 'views/Package/PackageImportView/ImportBiznisweb';
import PackageComplaint from 'views/Package/PackageComplaint';
import DocumentsView from 'views/DocumentsView';
import ContactsView from 'views/ContactsView';
import AddressNewView from "../views/Address/AddressNewView";
import AddressesListView from "../views/Address/AddressListView";
import AddressEditView from "../views/Address/AddressEditView";
import ImportPacketa from "../views/Package/PackageImportView/ImportPacketa";
import DebugCallCommand from "../views/Debug/DebugCallCommand";
import ServiceNeoshipTableList from "../views/ServiceNeoshipView/ServiceNeoshipTableList";
import ConnectorTableView from "../views/Connector/ConnectorTableView";
import ConnectorNewEditView from "../views/Connector/ConnectorNewEditView";
import ConnectorPackageListView from "../views/Connector/ConnectorPackageListView";
import ConnectorPackageEditView from "../views/Connector/ConnectorPackageEditView";
import ImportGlsCsvParcelshop from "../views/Package/PackageImportView/ImportGlsCsvParcelshop";

const roleUserRoutes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { path: '/', element: <Navigate to="/app/dashboard" /> },
        ]
    },
    {
        path: 'app',
        element: <DashboardLayout />,
        children: [
            { path: 'dashboard', element: <DashboardView /> },
            { path: 'package', element: <PackageListView /> },
            { path: 'package/service', element: <PackageListView /> },
            { path: 'package/:paramfilter', element: <PackageListView /> },
            { path: 'package/import', element: <PackageImportView /> },
            { path: 'package/import/csv', element: <ImportCsv /> },
            { path: 'package/import/xml', element: <ImportXml /> },
            { path: 'package/import/omega', element: <ImportOmega /> },
            { path: 'package/import/pohoda', element: <ImportPohoda /> },
            { path: 'package/import/biznisweb', element: <ImportBiznisweb /> },
            { path: 'package/import/packeta', element: <ImportPacketa /> },
            { path: 'package/import/csv-gls-parcelshop', element: <ImportGlsCsvParcelshop /> },
            { path: 'package/claimassistant', element: <ClaimAssistant /> },
            { path: 'cod/paid', element: <PackagePaidListView /> },
            { path: 'cod/unpaid', element: <PackageUnpaidListView /> },
            { path: 'cod', element: null },
            { path: 'calculation', element: <CalculationView/> },
            { path: 'parcelshop', element: <ParcelshopView /> },
            { path: 'pricelist', element: <UserPrices /> },
            { path: 'account', element: <AccountView /> },
            { path: 'log', element: <LogView/> },
            { path: 'creditlog', element: <CreditLogView/> },
            { path: 'package/new/:shipper', element: <PackageNewEditView /> },
            { path: 'package/newparcelshop/:shipper/:parcelshop_id', element: <PackageNewEditView /> },
            { path: 'package/newaddress/:shipper/:address_id', element: <PackageNewEditView /> },
            { path: 'package/edit/:id/:shipper', element: <PackageEditView /> },
            { path: 'package/detail/:id', element: <PackageDetailView /> },
            { path: 'package/printsticker', element: <PackagePrintSticker /> },
            { path: 'package/printeddocuments', element: <PackagePrintedDocuments /> },
            { path: 'package/complaint', element: <PackageComplaint /> },
            { path: '/messages', element: <MessageListView /> },
            { path: '/messages/:id', element: <MessageDetailView /> },
            { path: '/statistics/packagecount', element: <PackageCountStatistics /> },
            { path: '/statistics/returnedpackage', element: <ReturnedPackageStatistics /> },
            { path: '/credit', element: <CreditView /> },
            { path: '/documents', element: <DocumentsView /> },
            { path: '/contacts', element: <ContactsView /> },
            { path: '/address', element: <AddressesListView /> },
            { path: '/address/new', element: <AddressNewView /> },
            { path: '/address/edit/:id', element: <AddressEditView /> },
            { path: '/connector', element: <ConnectorTableView /> },
            { path: '/connector/new', element: <ConnectorNewEditView /> },
            { path: '/connector/packages', element: <ConnectorPackageListView /> },
            { path: '/connector/packages/bulk', element: <ConnectorPackageEditView /> },
            { path: '/service/neoship/list', element: <ServiceNeoshipTableList /> },
            { path: '/run/command', element: <DebugCallCommand /> },
            { path: '*', element: <Navigate to="/404" replace={true} /> }
        ]
    }
];

export default roleUserRoutes;
