import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import EditIcon from '@material-ui/icons/Edit';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import SecureFetchContext from 'context/SecureFetchContext';

export default function ManagePriceDialog({ prices, route, loadPrices, field = 'price', ...rest}) {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const [operation, setOperation] = React.useState("+");
    const [value, setValue] = React.useState(0);
    const secureFetch = useContext(SecureFetchContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setOperation(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let tmpValues = {};
        for (const p of prices.current) {
            let tmpVal = parseFloat(p[field]);
            switch (operation) {
                case '+':
                    tmpVal += parseFloat(value);
                    break;
                case '-':
                    tmpVal -= parseFloat(value);
                    break;
                case '*':
                    tmpVal *= parseFloat(value);
                    break;
                case '/':
                    tmpVal /= parseFloat(value);
                    break;
                case '=':
                    tmpVal = parseFloat(value);
                    break;
            
                default:
                    break;
            }
            tmpValues[p.id] = { [field]: tmpVal };
        }

        secureFetch(route, { prices: tmpValues }, 'PUT').then(json => {
            loadPrices();
            setOpen(false);
        });
    }

    const onValueChange = (e) => {
        setValue(e.target.value);
    }

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ backgroundColor: '#5f3f25' }}
                startIcon={<EditIcon />}
                onClick={handleClickOpen}
            >
                {t('button.manage')}
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit}>
                    <DialogTitle>{t('title.manage_price')}</DialogTitle>
                    <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel id="choose-operation">{t('label.operation')}</InputLabel>
                                        <Select
                                            labelId="choose-operation"
                                            value={operation}
                                            onChange={handleChange}
                                            required
                                        >
                                            <MenuItem value="+">+</MenuItem>
                                            <MenuItem value="-">-</MenuItem>
                                            <MenuItem value="*">*</MenuItem>
                                            <MenuItem value="/">/</MenuItem>
                                            <MenuItem value="=">=</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={t('label.value')}
                                        name="value"
                                        onChange={onValueChange}
                                        value={value}
                                        type="number"
                                        inputProps={{ step: "0.001" }}
                                        required
                                    />
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" variant="text" onClick={handleClose} >
                            {t('button.close')}
                        </Button>
                        <Button color="primary" variant="contained" type="submit">
                            {t('button.manage')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
}
