import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { Line } from 'react-chartjs-2';
import { formatDate } from 'helpers';
import AppContext from 'context/AppContext';

const PackageCountChartStatistics = ({ params, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [data, setData] = useState([]);
    const { appValues } = useContext(AppContext);

    useEffect(() => {
        let routeName = 'statistics';
        routeName = appValues.user.roles.includes('ROLE_ADMIN') ? 'admin_statistics' : routeName;
        routeName = appValues.user.roles.includes('ROLE_PARTNER') ? 'partner_statistics' : routeName;
        secureFetch(getApiRoute(routeName, ['daypackagecount']) + '?' + params).then(json => {
            
            let tmpData = [];
            if (json.length) {
                const urlParams = new URLSearchParams(params);
                const createdAtFrom = urlParams.get('created_at_from');
                const createdAtTo = urlParams.get('created_at_to');

                if (createdAtFrom) {
                    let dataDay = new Date(json[0].day);
                    dataDay.setHours(0, 0, 0, 0);
                    let filterDay = new Date(createdAtFrom.replace('Z', '')); // Z at the end of datetime adds 2 hours and it leads to shifting date to next day
                    filterDay.setHours(0, 0, 0, 0);
                    if (dataDay.valueOf() !== filterDay.valueOf()) {
                        filterDay.setHours(5);//prevent time zone day before
                        json.splice(0, 0, {
                            day: filterDay.toISOString().split('T')[0],
                            count: 0
                        });
                    }
                }

                if (createdAtTo) {
                    let dataDay = new Date(json[json.length - 1].day);
                    dataDay.setHours(0, 0, 0, 0);
                    let filterDay = new Date(createdAtTo.replace('Z', '')); // Z at the end of datetime adds 2 hours and it leads to shifting date to next day
                    filterDay.setHours(0, 0, 0, 0);
                    if (dataDay.valueOf() !== filterDay.valueOf()) {
                        filterDay.setHours(5);//prevent time zone day before
                        json.push({
                            day: filterDay.toISOString().split('T')[0],
                            count: 0
                        });
                    }
                }

                if (json.length === 1){
                    let filterDayFrom = new Date(createdAtFrom ? createdAtFrom.replace('Z', '') : createdAtFrom);
                    filterDayFrom.setHours(0, 0, 0, 0);
                    let filterDayTo = new Date(createdAtTo ? createdAtTo.replace('Z', '') : createdAtTo);
                    filterDayTo.setHours(0, 0, 0, 0);
                    if (filterDayFrom.valueOf() === filterDayTo.valueOf()){
                        const tmpDate = new Date(json[0].day);
                        tmpDate.setHours(5, 0, 0, 0); //prevent time zone day before
                        json.splice(0, 0, {
                            day: tmpDate.toISOString().split('T')[0],
                            label: '',
                            count: json[0].count
                        });
                        json.push({
                            day: tmpDate.toISOString().split('T')[0],
                            label: '',
                            count: json[0].count
                        });
                    }
                }

                for (let index = 0; index < json.length; index++) {
                    tmpData.push(json[index]);
                    if(index === json.length - 1) {
                        break;
                    }
                    let date = new Date(json[index].day);
                    date.setDate(date.getDate() + 1);

                    while (date < new Date(json[index + 1].day)) {
                        tmpData.push({
                            day: date.toISOString().split('T')[0],
                            count: 0
                        });
                        date.setDate(date.getDate() + 1);
                    }
                }
            }
            setData(tmpData);
        });
    }, [secureFetch, params, appValues.user.roles]);

    const chartData = {
        labels: data.map((val) => val.label === '' ? val.label : formatDate(val.day)),
        datasets: [
            {
                label: t('label.packages'),
                data: data.map((val) => val.count),
                backgroundColor: 'rgb(32, 87, 144, 0.2)',
                borderColor: 'rgba(32, 87, 144, 0.8)',
            },
        ],
    }

    const options = {
        legend: {
            display: false
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                title: function (tooltipItems) {
                    return formatDate(data[tooltipItems[0].index].day);
                }
            }
        }
    }

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <Typography gutterBottom variant="h4" component="h2" align="center">
                    {t('title.count_of_packages') + (data.length ? ' ' + formatDate(data[0].day) + ' - ' + formatDate(data[data.length-1].day) : '') }
                </Typography>
                <Line height={80} data={chartData} options={options} />
            </CardContent>
        </Card>
    );
}

export default PackageCountChartStatistics;