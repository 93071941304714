import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Typography,
    TextField,
    Grid,
    makeStyles, FormControlLabel, Checkbox
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import sk from "date-fns/locale/sk";

const useStyles = makeStyles(() => ({
    root: {
    }
}));

const PriceForm = ({ className, id, handleDialogkOpenClose, loadStatuses, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [statuses, setStatuses] = useState([]);
    const [status, setStatus] = useState(null);
    const [createdAt, setCreatedAt] = useState(new Date());
    const [asLast, setAsLast] = useState(true);

    const DISABLED_AS_LAST = ['163', '166', '86001', '86002', '99004', '99005', '99006'];

    useEffect(() => {
        secureFetch(getApiRoute('admin_statustype', [id])).then(json => {
            setStatuses(json.sort((a, b) => (a.name > b.name) ? 1 : -1));
        })
    }, [secureFetch, id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        let tmpVal = { status_type: status.id, created_at: {}, set_as_last: asLast };
        tmpVal.created_at.date = createdAt.toISOString().split('T')[0];
        tmpVal.created_at.time = {};
        tmpVal.created_at.time.hour = createdAt.getHours();
        tmpVal.created_at.time.minute = createdAt.getMinutes();
        tmpVal.created_at.time.second = createdAt.getSeconds();
        secureFetch(getApiRoute('admin_status', [id]), tmpVal, 'POST').then(json => {
            handleDialogkOpenClose();
            loadStatuses();
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.create_status')}
            </Typography>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                    <Autocomplete
                        options={statuses}
                        value={status}
                        onChange={(event, status) => {
                            setStatus(status);
                            setAsLast(!DISABLED_AS_LAST.includes(status.code));
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField
                            {...params}
                            label={t('label.status')}
                            required
                            fullWidth
                        />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={sk}>
                        <DateTimePicker
                            autoOk
                            ampm={false}
                            disableFuture
                            onChange={(val) => setCreatedAt(val)}
                            value={createdAt}
                            label={t('label.operation_date')}
                            inputFormat="dd.MM.yyyy HH:mm"
                            renderInput={(params) => <TextField
                                {...params}
                                fullWidth
                                required
                            />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={asLast}
                                onChange={(e) => {setAsLast(e.target.checked)}}
                                name="asLast"
                                color="primary"
                            />
                        }
                        label={t('label.set_as_last_status')}
                    />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Button color="secondary" variant="text" onClick={handleDialogkOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.create')}
                </Button>
            </Box>
        </form>
    );
};

PriceForm.propTypes = {
    className: PropTypes.string
};

export default PriceForm;
