import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Box,
    Tooltip,
    makeStyles, CardHeader
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from "../../context/AppContext";

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const IbansTable = ({ className, user, ...rest }) => {
    const classes = useStyles();
    const { appValues } = useContext(AppContext);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);

    const load = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('iban', [user.id]) ).then(json => {
            setRows(json);
            setLoading(false);
        });
    }, [secureFetch])

    useEffect(() => {
        load();
    }, [load]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'id'
        },
        headCells: [
            { id: 'id', numeric: true, label: t('label.id'), search: false, hide: true },
            { id: 'state_to', numeric: false, label: t('label.state_to'), search: true },
            { id: 'shipper', numeric: false, label: t('label.shipper'),render: (data) => appValues.shippers.find(s => s.id === data.shipper).shortcut},
            { id: 'iban', numeric: false, label: t('label.iban'), search: false, render: (data) => data.iban },
        ],
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default IbansTable;