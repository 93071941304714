import React, {useContext, useEffect} from 'react';
import {
    Grid,
    colors,
    Typography,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import PackageStatusCard from './PackageStatusCard';
import PackageCodCard from './PackageCodCard';
import CheckIcon from '@material-ui/icons/Check';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ClearIcon from '@material-ui/icons/Clear';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserUpdatableContext from "../../context/UserUpdatableContext";
import {Book} from "@material-ui/icons";
import getApiRoute from "../../routes/apiRoutes";
import SecureFetchContext from "../../context/SecureFetchContext";
import {useLocation} from "react-router-dom";

const AdminDashboard = ({ count, ...rest }) => {
    const { t } = useTranslation();
    const { userUpdatable, setUserUpdatable } = useContext(UserUpdatableContext);
    const secureFetch = useContext(SecureFetchContext);
    const {state} = useLocation();

    useEffect(() => {
        if (!state || !state.user){
            secureFetch(getApiRoute('user')).then(json => {
                setUserUpdatable({statistics: json.others, credit: json.credit, last_update: new Date()});
            })
        }
    }, []);

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid item xs={12} >
                <Typography variant="h2">
                    {t('title.premium_version')}
                </Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12} >
                <PackageStatusCard
                    title={t('title.delivered_packages')}
                    icon={<CheckIcon />}
                    iconBg={colors.green[600]}
                    type="delivered"
                    data={userUpdatable.statistics['statuses']}
                />
            </Grid>
            <Grid item lg={4} sm={6} xs={12} >
                <PackageStatusCard
                    title={t('title.intransport_packages')}
                    icon={<LocalShippingIcon />}
                    iconBg={colors.orange[600]}
                    type="transit"
                    data={userUpdatable.statistics['statuses']}
                />
            </Grid>
            <Grid item lg={4} sm={6} xs={12} >
                <PackageStatusCard
                    title={t('title.notdelivered_packages')}
                    icon={<ClearIcon />}
                    iconBg={colors.red[600]}
                    type="notdelivered"
                    data={userUpdatable.statistics['statuses']}
                />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <PackageCodCard
                    title='Vyplatené dobierky kartou'
                    data={userUpdatable.statistics.cod_statistics ? userUpdatable.statistics.cod_statistics : {}}
                    dataKey={'card'}
                    icon={<CreditCardIcon />}
                    iconBg={colors.indigo[300]}
                    location={['/app/cod/paid', {state: {cod_payment: 1}}]}
                />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <PackageCodCard
                    title='Vyplatené dobierky hotovosťou'
                    data={userUpdatable.statistics.cod_statistics ? userUpdatable.statistics.cod_statistics : {}}
                    dataKey={'cash'}
                    icon={<FontAwesomeIcon icon="money-bill-wave" />}
                    iconBg={colors.indigo[500]}
                    iconStyles={{fontSize: "1rem"}}
                    location={['/app/cod/paid', {state: {cod_payment: 0}}]}
                />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <PackageCodCard
                    title='Nevyplatené dobierky'
                    data={userUpdatable.statistics.cod_statistics ? userUpdatable.statistics.cod_statistics : {}}
                    dataKey={'unpaid'}
                    icon={<FontAwesomeIcon icon={['fab', 'creative-commons-nc-eu']} />}
                    iconBg={colors.red[600]}
                    location={['/app/cod/unpaid']}
                />
            </Grid>
            <Grid item xs={12} >
                <Typography variant="h2">
                    {t('title.basic_version')}
                </Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12} >
                <PackageStatusCard
                    title={t('title.generated_labels')}
                    icon={<Book />}
                    iconBg={colors.green[600]}
                    type="service"
                    data={userUpdatable.statistics['statuses']}
                />
            </Grid>
        </Grid>
    );
};

export default AdminDashboard;
