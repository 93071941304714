import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent, CardHeader, Checkbox,
    Divider,
    FormControlLabel,
    Grid, TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import getApiRoute from "../../routes/apiRoutes";
import AppContext from "../../context/AppContext";
import SecureFetchContext from "../../context/SecureFetchContext";
import FormControl from "@material-ui/core/FormControl";

const UserMicrocampaignSettings = ({user, ...rest}) => {
    const {t} = useTranslation();
    const { appValues } = useContext(AppContext);
    const secureFetch = useContext(SecureFetchContext);
    const [successMessage, setSuccessMessage] = useState('');
    const [values, setValues] = React.useState({});


    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    useEffect(() => {
        if (user) {
            secureFetch( getApiRoute('microcampaign_settings', [user.id], true) ).then(json => {
                setValues(json);
            });
        }
    }, [secureFetch, appValues.states, user]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (user) {
            secureFetch(getApiRoute('microcampaign_settings', [user.id], true) , values, 'POST').then(json => {
                setSuccessMessage(t('messages.data_saved'));
            });
        }
    }

    return (
        <Card
            {...rest}
        >
            <CardHeader
                subheader={t('messages.microcampaign_settings')}
                title={t('title.microcampaign_settings')}
            />
            <Divider />
            <CardContent>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <Grid style={{maxWidth: '900px'}} container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    label="Webhook 1 (expedícia)"
                                    name="webhook1"
                                    value={values.webhook1 || ''}
                                    onChange={handleChange}
                                    rows={2}
                                    variant="outlined"
                                    multiline
                                    required={true}
                                />
                            </FormControl>
                            <Box pt={2} />
                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    label="Webhook 2 (doručenie)"
                                    name="webhook2"
                                    value={values.webhook2 || ''}
                                    onChange={handleChange}
                                    rows={2}
                                    variant="outlined"
                                    multiline
                                    required={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                successMessage === '' ? null :
                                    <Box pb={2}>
                                        <Alert variant="filled" severity="success">
                                            {successMessage}
                                        </Alert>
                                    </Box>
                            }
                            <Button color="primary" variant="contained" type="submit">
                                {t('button.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
}

export default UserMicrocampaignSettings;