import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import FormControl from '@material-ui/core/FormControl';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import AppContext from 'context/AppContext';

const useStyles = makeStyles(() => ({
    root: {}
}));

const NotificationEmailsForm = ({ className, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const { appValues, setAppValues } = useContext(AppContext);

    useEffect(() => {
        secureFetch(getApiRoute('usersettings', ['byname', 'NOTIFICATION_EMAILS'])).then(json => {
            if (json !== null) {
                setValues({
                    notification_emails: json.value
                })
            } else {
                setValues({
                    notification_emails: appValues.user.address ? appValues.user.address.email : ""
                })
            }
        });

    },[secureFetch]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch( getApiRoute('usersettings'), values, 'POST' ).then(json => {
            setSuccessMessage(t('messages.data_saved'));
        });
    }

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Card>
                <CardHeader
                    subheader={t('messages.notification_emails')}
                    title={t('title.notification_emails')}
                />
                <Divider />
                <CardContent>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                label={t('label.notification_emails')}
                                name="notification_emails"
                                onChange={handleChange}
                                value={values.notification_emails || ''}
                                required
                            />
                        </FormControl>
                    </Grid>
                </CardContent>
                { 
                    successMessage === '' ? null : 
                    <Box px={2}>
                        <Alert variant="filled" severity="success">
                            {successMessage}
                        </Alert>
                    </Box>
                }
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button color="primary" variant="contained" type="submit">
                        { t('button.save') }
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

NotificationEmailsForm.propTypes = {
    className: PropTypes.string,
};

export default NotificationEmailsForm;
