import React, { useState, useCallback, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    IconButton,
    Tooltip,
    makeStyles,
    Box, Button
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import { NavLink as RouterLink } from 'react-router-dom';
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIconDialog from "../../../components/DeleteIconDialog";
import NewPackageAddressDialog from "../../Address/AddressNewView/NewPackageAddressDialog";
import {isAdmin} from "../../../helpers";

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const AddressesTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);

    const loadAddresses = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('address_list') ).then(json => {
            setRows(json);
            setLoading(false);
        })
    }, [secureFetch])

    const getUniqStatesCode = () => {
        let statesCodes = rows.map((el) => {
            return { id: el.state_code.toLowerCase(), name: el.state_code }
        })

        return statesCodes.filter( (el, index) => 
            statesCodes.findIndex( obj => obj.id === el.id ) === index
        );
    };

    useEffect(() => {
        loadAddresses();
    }, [loadAddresses]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'name'
        }, 
        headCells: [
            { id: 'name', numeric: false, label: t('label.name'), search: true },
            { id: 'company', numeric: false, label: t('label.company'), search: true },
            { id: 'street', numeric: false, label: t('label.street'), search: true },
            { id: 'house_number', numeric: false, label: t('label.house_number'), search: false },
            { id: 'zip', numeric: false, label: t('label.zip'), search: true },
            { id: 'city', numeric: false, label: t('label.city'), search: true },
            { id: 'state_code', numeric: false, label: t('label.state'), search: true, searchType: 'select', selectChoices: getUniqStatesCode() },
            { id: 'email', numeric: false, label: t('label.email'), search: true },
            { id: 'phone', numeric: false, label: t('label.phone'), search: true },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, hide: isAdmin(appValues.user), render: (data) => {
                return (
                    <Box display="flex">
                        <NewPackageAddressDialog address={data.id} />
                        <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')}>
                            <IconButton
                                aria-label={t('tooltip.edit')}
                                className={classes.rowButtons}
                                component={RouterLink}
                                size="small"
                                to={"/app/address/edit/" + data.id}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <DeleteIconDialog
                            text={
                                <React.Fragment>
                                    {t('messages.delete_address')}
                                    <br/>
                                    <pre>
                                {JSON.stringify(data, null, 2)}
                            </pre>
                                </React.Fragment>
                            }
                            route={getApiRoute('address', [data.id], false, 'DELETE')}
                            onDelete={loadAddresses}
                        />
                    </Box>
                );
            } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    {
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            size="small"
                            startIcon={<AddIcon />}
                            component={RouterLink}
                            to={'/app/address/new'}
                        >
                            {t('button.create_address')}
                        </Button>
                    }
                </React.Fragment>
            );
        },
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default AddressesTable;