import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { Bar } from 'react-chartjs-2';

const ServiceProfitByMonthStatistics = ({ params, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [data, setData] = useState({});
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        secureFetch(getApiRoute('admin_statistics', ['monthserviceprofit']) + '?' + params).then(json => {
            let tmpData = {
                service_fees: [],
                shipper_add_fees: [],
                programming_fees: [],
            };
            let tmpLabels = [];
            for (const obj of json) { //sort by year, month
                tmpLabels.push(obj.month);
                tmpData.service_fees.push( obj.service_fees );
                tmpData.shipper_add_fees.push( obj.shipper_add_fees );
                tmpData.programming_fees.push( obj.programming_fees );
            }

            setData(tmpData);
            setLabels(tmpLabels);
        });
    }, [secureFetch, params]);
  
    const chartData = {
        labels: labels.map((val) => {
            let date = new Date(val);
            return (date.getMonth() + 1) + '/' + date.getFullYear();
        }),
        datasets: data.length === 0 ? null : [
            {
                label: t('label.service_fees_long'),
                data: data.service_fees,
                backgroundColor: 'rgb(32, 87, 144)',
            },
            {
                label: t('label.shipper_add_fees'),
                data: data.shipper_add_fees,
                backgroundColor: 'rgb(88, 168, 60)',
            },
            {
                label: t('label.programming_fees'),
                data: data.programming_fees,
                backgroundColor: 'rgb(230, 96, 34)',
            }
        ],
    }

    const options = {
        scales: {
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                },
            ],
        },
        tooltips: {
            // enabled: false,
            callbacks: {
                label: (tooltipItem, data) => {
                    const name = data.datasets[tooltipItem.datasetIndex].label;
                    const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    let total = 0;
                    for (const v of data.datasets) {
                        total += parseFloat(v.data[tooltipItem.index]);
                    }
                    return `${name}: ${parseFloat(value).toFixed(2)} € / ${parseFloat(total).toFixed(2)} €`;
                }
            }
        }
    }

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <Typography gutterBottom variant="h4" component="h2" align="center">
                    {t('title.profit_service_month')}
                </Typography>
                <Bar height={80} data={chartData} options={options} />
            </CardContent>
        </Card>
    );
}

export default ServiceProfitByMonthStatistics;