import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { Bar } from 'react-chartjs-2';
import AppContext from 'context/AppContext';

const PackageByWeightStatistics = ({ params, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [data, setData] = useState([]);
    const { appValues } = useContext(AppContext);
    const [chartData, setChartData] = useState({labels: '', datasets: null});

    useEffect(() => {
        let routeName = 'statistics';
        routeName = appValues.user.roles.includes('ROLE_ADMIN') ? 'admin_statistics' : routeName;
        routeName = appValues.user.roles.includes('ROLE_PARTNER') ? 'partner_statistics' : routeName;
        secureFetch(getApiRoute(routeName, ['packageweight']) + '?' + params).then(json => {
            setData(json);
        });
    }, [secureFetch, params, appValues.user.roles]);

    useEffect(() => {
        setChartData({
            labels: data.length === 0 ? '' : Object.keys(data.local).map((val) => val),
            datasets: data.length === 0 ? null : [
                {
                    label: t('label.domestic_packages'),
                    data: Object.keys(data.local).map((val) => data.local[val]),
                    backgroundColor: 'rgb(32, 87, 144)',
                },
                {
                    label: t('label.foreign_packages'),
                    data: Object.keys(data.international).map((val) => data.international[val]),
                    backgroundColor: 'rgb(88, 168, 60)',
                },
                {
                    label: t('label.parcelshop_local'),
                    data: Object.keys(data.parcelshop_local).map((val) => data.parcelshop_local[val]),
                    backgroundColor: 'rgb(230, 96, 34)',
                },
                {
                    label: t('label.parcelshop_international'),
                    data: Object.keys(data.parcelshop_international).map((val) => data.parcelshop_international[val]),
                    backgroundColor: 'rgb(240, 252, 47)',
                },
            ],
        })
    }, [data])

    const options = {
        scales: {
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                },
            ],
        },
        /* tooltips: {
            callbacks: {
                label: function (tooltipItem) {
                    switch (tooltipItem.datasetIndex) {
                        case 0:
                            return t('label.domestic_packages') + ': ' + parseFloat(tooltipItem.value).toFixed(2) + ' €';
                        case 1:
                            return t('label.foreign_packages') + ': ' + parseFloat(tooltipItem.value).toFixed(2) + ' €';
                        default:
                            return t('label.parcelshop') + ': ' + parseFloat(tooltipItem.value).toFixed(2) + ' €';
                    } ;
                }
            }
        } */
        tooltips: {
            // enabled: false,
            callbacks: {
                label: (tooltipItem, data) => {
                    const name = data.datasets[tooltipItem.datasetIndex].label;
                    const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    let total = 0;
                    for (const v of data.datasets) {
                        total += v.data[tooltipItem.index];
                    }
                    return `${name}: ${value} ks / ${total} ks`;
                }
            }
        }
    }

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <Typography gutterBottom variant="h4" component="h2" align="center">
                    {t('title.count_of_packages_weight')}
                </Typography>
                <Bar height={80} data={chartData} options={options} />
            </CardContent>
        </Card>
    );
}

export default PackageByWeightStatistics;