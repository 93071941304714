import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    TextField,
    Grid,
    makeStyles, Typography
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import InputAdornment from "@material-ui/core/InputAdornment";
import {DatePicker, LocalizationProvider} from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import sk from "date-fns/locale/sk";

const useStyles = makeStyles(() => ({
    root: {}
}));

const EditDiscountForm = ({ className, discount, handleEditDialogOpenClose, load, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        if ( discount.discount ) {
            setValues({
                start_date: discount.start_date,
                end_date: discount.end_date,
                discount: discount.discount,
            });
        }
    }, [discount]);

    const handleSubmit = (event) => {
        event.preventDefault();
        let tmpValues = { ...values, start_date : new Date(values.start_date).toLocaleDateString("fr-FR"), end_date : new Date(values.end_date).toLocaleDateString("fr-FR")}
        secureFetch(getApiRoute('admin_user_discount', [discount.id]), tmpValues, 'PUT').then(json => {
            handleEditDialogOpenClose();
            load();
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.edit_discount')}
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={sk}>
                        <DatePicker
                            ampm={false}
                            views={["year", "month"]}
                            openTo="month"
                            minDate={new Date()}
                            onChange={(val) => setValues({...values, start_date: val})}
                            value={values.start_date || ''}
                            label={t('label.start_date')}
                            name="start_date"
                            inputFormat="MM/yyyy"
                            renderInput={(params) => <TextField
                                {...params}
                                fullWidth
                                required
                            />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={sk}>
                        <DatePicker
                            autoOk
                            ampm={false}
                            views={["year", "month"]}
                            openTo="month"
                            minDate={new Date()}
                            onChange={(val) => setValues({...values, end_date: val})}
                            value={values.end_date || ''}
                            label={t('label.end_date')}
                            name="end_date"
                            inputFormat="MM/yyyy"
                            renderInput={(params) => <TextField
                                {...params}
                                fullWidth
                                required
                            />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('label.discount')}
                        name="discount"
                        onChange={handleChange}
                        value={values.discount || ''}
                        type="number"
                        inputProps={{ step: "10" }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        required
                    />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Button color="secondary" variant="text" onClick={handleEditDialogOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.save')}
                </Button>
            </Box>

        </form>
    );
};

EditDiscountForm.propTypes = {
    className: PropTypes.string
};

export default EditDiscountForm;
