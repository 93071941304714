import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Button,
    makeStyles, Grid, TextField, FormControlLabel
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import AppContext from 'context/AppContext';
import Alert from '@material-ui/lab/Alert';
import SecureFetchContext from "../../../context/SecureFetchContext";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import getApiRoute from "../../../routes/apiRoutes";
import Checkbox from "@material-ui/core/Checkbox";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    root: {},
    button: {
        marginRight: "10px"
    }
}));

const NewDeliveryAddressDialog = ({ className, user, address = false, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [values, setValues] = useState(address ? {
        ...address.address,
        state: address.address.state.id,
        active: address.active
    } : {});
    const [successMessage, setSuccessMessage] = useState('')
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenClose = () => {
        setOpenDialog(!openDialog);
    };

    const handleCheckBoxChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.checked
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const url = address.id ? getApiRoute('admin_user', ['pickup', address.id]) : getApiRoute('admin_user', ['pickup', user.id]);
        const method = address.id ? 'PUT' : 'POST';
        const {id: _, ...tmpAddress} = values;
        secureFetch( url, tmpAddress, method ).then(json => {
            setSuccessMessage(t('messages.data_saved'));
            window.location.reload();
        });
    }

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    return (
        <React.Fragment>
            <Button color="secondary" variant="contained" className={classes.button} onClick={handleOpenClose}>
                { address.id ? t('button.edit') : t('button.add_address') }
            </Button>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                disableEnforceFocus
                fullWidth
                maxWidth='md'
                onClose={handleOpenClose}
                aria-labelledby="new-product-slide-title"
                aria-describedby="new-product-slide-description"
            >
                <DialogTitle disableTypography={true}>
                    <Typography
                        variant="h2"
                    >
                        {t('tooltip.new_pickup_address')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <form
                        autoComplete="off"
                        className={clsx(classes.root, className)}
                        onSubmit={handleSubmit}
                        {...rest}
                    >
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('label.name')}
                                            name="name"
                                            onChange={handleChange}
                                            value={values.name || ''}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('label.company')}
                                            name="company"
                                            onChange={handleChange}
                                            value={values.company || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('label.email')}
                                            name="email"
                                            onChange={handleChange}
                                            value={values.email || ''}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('label.phone')}
                                            name="phone"
                                            onChange={handleChange}
                                            value={values.phone || ''}
                                            required
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item lg={7} xs={8}>
                                        <TextField
                                            fullWidth
                                            label={t('label.street')}
                                            name="street"
                                            onChange={handleChange}
                                            value={values.street || ''}
                                            required
                                        />
                                    </Grid>
                                    <Grid item lg={5} xs={4}>
                                        <TextField
                                            fullWidth
                                            label={t('label.house_number')}
                                            name="house_number"
                                            onChange={handleChange}
                                            value={values.house_number || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('label.city')}
                                            name="city"
                                            onChange={handleChange}
                                            value={values.city || ''}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('label.zip')}
                                            name="zip"
                                            onChange={handleChange}
                                            value={values.zip || ''}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel id="deliverystates">{t('label.state')}</InputLabel>
                                            <Select
                                                labelId="deliverystates"
                                                id="demo-simple-select"
                                                value={ values.state || '' }
                                                name="state"
                                                onChange={handleChange}
                                            >
                                                {appValues.states.map((option) => (
                                                    <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                    >
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    {
                        successMessage === '' ? null :
                            <Box py={2}>
                                <Alert variant="filled" severity="success">
                                    {successMessage}
                                </Alert>
                            </Box>
                    }
                    <Box display="flex" justifyContent="flex-end" py={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!values.active}
                                    name="active"
                                    onChange={handleCheckBoxChange}
                                />
                            }
                            label="Zapnutá"
                        />
                        <Button color="primary" variant="contained" onClick={handleSubmit}>
                            { t('button.save') }
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>

    );
};

export default NewDeliveryAddressDialog;
