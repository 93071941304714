import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Checkbox,
    Typography,
    Button,
    Box,
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { getErrorMessage, isInputError } from 'helpers';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AddressTypeService from "./AddressTypeService";
import Shader from "../../../components/Shader";

const useStyles = makeStyles(() => ({
    root: {},
    highlight: {
        backgroundColor: '#f5f5f5'
    },
    disabled: {
        opacity: 0.4,
        pointerEvents: 'none'
    }
}));

const ServicesForm = ({ className, services, title, values, handleChange, handleCheckBoxChange, errors, notAllowedServices, onlyCheck, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleAddressChange = (service, address) => {
        handleChange({
            target: {
                name: service,
                value: address
            }
        })
    }

    const handleTimeRangeChange = (e, type) => {
        let tmpValue = values[e.target.name] ? values[e.target.name] : '00:00-00:00';
        if (type === 'from') {
            tmpValue = e.target.value + tmpValue.substring(5);
        }
        else{
            tmpValue = tmpValue.substring(0, 6) + e.target.value;
        }
        e.target.value = tmpValue;
        handleChange(e);
    };

    const getTimeChoices = (to = false) => {
        let choices = [];
        for (let i = 8; i <= 18; i++) {
            let numPadded = ("0" + i).slice(-2);;
            choices.push(numPadded + ':00');
            choices.push(numPadded + ':30');
        }
        choices.pop();
        if (to) {
            choices.push('18:30');
            choices.push('19:00');
            choices.push('19:30');
            choices.push('20:00');
            return choices.slice(4);
        }
        return choices;
    }

    const renderInput = (service) => {
        switch (service.input_type) {
            case 1: //text
                return (
                    <TextField
                        error={isInputError(errors, service.code)}
                        fullWidth
                        label={service.input_name}
                        name={service.code + 'value'}
                        onChange={handleChange}
                        value={values[service.code + 'value'] || ''}
                        helperText={
                            <React.Fragment>
                                {getErrorMessage(errors, service.code)}
                                <br />
                                {service.description}
                            </React.Fragment>
                        }
                        disabled={!values[service.code]}
                    />
                );
            case 2: //date
                return (
                    <TextField
                        error={isInputError(errors, service.code)}
                        fullWidth
                        label={service.input_name}
                        name={service.code + 'value'}
                        onChange={handleChange}
                        type={'date'}
                        value={values[service.code + 'value'] || ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={
                            <React.Fragment>
                                {getErrorMessage(errors, service.code)}
                                <br/>
                                {service.description}
                            </React.Fragment>
                        }
                        disabled={!values[service.code]}
                    />
                );
            case 3: //hour range
                return (
                    <Box>
                        <FormControl 
                            className={classes.formControl}
                            error={isInputError(errors, service.code)}
                            style={{ width: '50%' }}
                            disabled={!values[service.code]}
                            >
                            <InputLabel id={service.code + "-from-label"}>{t('label.from')}</InputLabel>
                            <Select
                                labelId={service.code + "-to-label"}
                                id={service.code}
                                label={service.input_name}
                                name={service.code + 'value'}
                                value={values[service.code + 'value'] ? values[service.code + 'value'].substring(0, 5) : ''}
                                onChange={(e) => handleTimeRangeChange(e, 'from')}
                            >
                                {
                                    getTimeChoices().map((val) => <MenuItem key={val} value={val}>{val}</MenuItem> )
                                }
                            </Select>
                        </FormControl>
                        <FormControl
                            className={classes.formControl}
                            error={isInputError(errors, service.code)}
                            style={{ width: '50%' }}
                            disabled={!values[service.code]}
                            >
                        
                            <InputLabel id={service.code + "-to-label"}>{t('label.to')}</InputLabel>
                            <Select
                                labelId={service.code + "-to-label"}
                                id={service.code + '-to'}
                                label={service.input_name}
                                name={service.code + 'value'}
                                value={values[service.code + 'value'] ? values[service.code + 'value'].substring(6) : ''}
                                onChange={(e) => handleTimeRangeChange(e, 'to')}
                            >
                                {
                                    getTimeChoices(true).map((val) => <MenuItem key={val} value={val}>{val}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <FormControl
                            className={classes.formControl}
                            error={isInputError(errors, service.code)}
                            disabled={!values[service.code]}
                        >
                            <FormHelperText>
                                <React.Fragment>
                                    {getErrorMessage(errors, service.code)}
                                    <br/>
                                    {service.description}
                                </React.Fragment>
                            </FormHelperText>
                        </FormControl>
                    </Box>
                );
            case 4:
                return (
                    <Box>
                        <FormControl
                            fullWidth
                            className={classes.formControl}
                            error={isInputError(errors, service.code)}
                        >
                            <AddressTypeService
                                values={values}
                                service={service}
                                handleChange={handleAddressChange}
                                errors={errors}
                            />
                        </FormControl>
                        <FormControl
                            className={classes.formControl}
                            error={isInputError(errors, service.code)}
                            disabled={!values[service.code]}
                        >
                            <FormHelperText>
                                <React.Fragment>
                                    {getErrorMessage(errors, service.code)}
                                    <br/>
                                    {service.description}
                                </React.Fragment>
                            </FormHelperText>
                        </FormControl>
                    </Box>
                );
            default:
                return (
                    <Box>
                        <FormControl
                            className={classes.formControl}
                            error={isInputError(errors, service.code)}
                            disabled={!values[service.code]}
                        >
                        <FormHelperText>
                            <React.Fragment>
                                {getErrorMessage(errors, service.code)}
                                <br/>
                                {service.description}
                            </React.Fragment>
                        </FormHelperText>
                        </FormControl>
                    </Box>
                );
        }
    }


    return (
        <Card>
            <CardHeader
                title={title}
            />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    {
                        services.map((service, index) => {
                            return (
                                <Grid key={service.name} item xs={12} sm={6} md={4} 
                                    className = {`${index % 2 ? classes.highlight : null} ${notAllowedServices.includes(service.code) ? classes.disabled : null}`}
                                >
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography variant="subtitle2">
                                            {service.name}
                                        </Typography>
                                        <Checkbox
                                            style={{ padding: '0px 0px 0px 8px'}}
                                            checked={values && values[service.code] ? true : false}
                                            onChange={handleCheckBoxChange}
                                            name={service.code}
                                            value="1"
                                            color="primary"
                                        />
                                    </Box>
                                    {onlyCheck ? <Typography variant="caption">
                                        {service.description}
                                    </Typography> : renderInput(service)}
                                </Grid>
                            )
                        })
                    }
                    {
                        onlyCheck ? null :
                        <Grid item xs={12} >
                            <Box display="flex" justifyContent="flex-end" >
                                <Button color="primary" variant="contained" type="submit">
                                    {t('button.save')}
                                </Button>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
};

ServicesForm.propTypes = {
    className: PropTypes.string,
    states: PropTypes.array
};

export default ServicesForm;
