import React from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import { fuelSign } from 'shippers';
import {getCarrierTypes} from "../../helpers";

const PriceTable = ({ className, prices, shipper, ...rest }) => {
    const { t } = useTranslation();

    const settings = {
        rows: prices,
        loading: false,
        colOrder: {
            order: 'desc',
            orderBy: 'price'
        },
        headCells: [
            { id: 'state_from', numeric: false, label: t('label.state_from'), search: true },
            { id: 'state_to', numeric: false, label: t('label.state_to'), search: true },
            { id: 'weight_from', numeric: true, label: t('label.weight_from'), search: true, render: (data) => data.weight_from + ' kg' },
            { id: 'weight_to', numeric: true, label: t('label.weight_to'), search: true, render: (data) => data.weight_to + ' kg' },
            { id: 'price', numeric: true, label: t('label.price'), search: true },
            { id: 'percentage', numeric: true, label: t('label.percentage'), search: true, render: (data) => `${parseFloat(data.percentage ? data.percentage : 0).toFixed(2)} %` },
            { id: 'toll', numeric: true, label: t('label.toll'), search: true, render: (data) => data.toll + ' €' },
            { id: 'fuel', numeric: true, label: t('label.fuel'), search: true, render: (data) => data.fuel + ' ' + fuelSign(shipper) },
            { id: 'delivery_type', numeric: false, label: t('label.delivery_type'), search: true },
        ],
    };

    if ( shipper.shortcut == 'Packeta')  {
        settings.headCells.splice(9, 0, { id: 'carrier_type', numeric: false, label: t('label.carrier_type'), search: true, render: (data) => {
                return (
                    <React.Fragment>
                        { getCarrierTypes().find(option => option.value === data.carrier_type).name }<span style={{fontSize:'small', paddingLeft: '10px'}}>{data.carrier_type}</span>
                    </React.Fragment>
                )}},);
    }

    return (
        <Card
            className={clsx(className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default PriceTable;