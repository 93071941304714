import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import AddIcon from '@material-ui/icons/Add';
import { useNavigate } from "react-router-dom";
import AppContext from 'context/AppContext';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.secondary.main,
        height: '100%',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.5)'
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        height: 56,
        width: 56
    },
    button: {
       marginLeft: "-0.5em"
    },
    textAlign: {
        alignSelf: "flex-end"
    }
}));

const ChargeCredit = ({ count, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { appValues } = useContext(AppContext);

    return (
        <Card
            className={clsx(classes.root, "cardHover")}
            onClick={() => navigate('/app/credit')}
        >
            <CardContent style={{ height: '100%' }}>
                <Grid
                    container
                    spacing={3}
                    style={{ height: '100%' }}
                    wrap="nowrap"
                >
                    <Grid item className={classes.textAlign} style={{ height: '100%' }}>
                        <Typography
                            gutterBottom
                            variant="h2"
                            color="primary"
                            style={{ color: 'rgba(255,255,255,1)' }}
                        >
                            {t('button.recharge_credit')}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                            style={{ color: 'rgba(255,255,255,1)' }}
                        >
                            {t('label.variable_number')}: {appValues.user.variable_number}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <AddIcon />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

ChargeCredit.propTypes = {
    count: PropTypes.number
};

export default ChargeCredit;
