import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    TextField,
    InputAdornment,
    Button,
    Box,
    makeStyles,
    IconButton,
    Tooltip
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InsurancePriceForm from './InsurancePriceForm';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import SaveIcon from '@material-ui/icons/Save';
import Alert from '@material-ui/lab/Alert';
import DeleteIconDialog from 'components/DeleteIconDialog';
import ManagePriceDialog from './ManagePriceDialog';
import EditInsurancePriceForm from "./EditInsurancePriceForm";

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const InsurancePriceTable = ({ className, pricelist, shipper, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openEditPriceDialog, setOpenEditPriceDialog] = React.useState(false);
    const secureFetch = useContext(SecureFetchContext);
    const [editMode, setEditMode] = useState(false);
    const [editedValues, setEditedValues] = useState({});
    const [editPriceData, setEditPriceData] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const renderedData = useRef(null);

    const handleDialogOpenClose = () => {
        setOpenCreateDialog(!openCreateDialog);
    };

    const handleEditDialogOpenClose = (data) => {
        if(openEditPriceDialog) {
            setOpenEditPriceDialog(false);
        } else {
            setEditPriceData(data);
            setOpenEditPriceDialog(true);
        }
    };

    const editModeChange = () => {
        if (editMode) {
            setEditedValues({});
        }
        setEditMode(!editMode);
    };

    const onEditChange = (event, index) => {
        let tmpValues = editedValues;
        tmpValues[index] = { ...tmpValues[index], [event.target.name]: event.target.value };
        setEditedValues({ ...tmpValues });
    };

    const saveChanges = () => {
        secureFetch(getApiRoute('admin_insuranceprice'), { prices: editedValues }, 'PUT').then(json => {
            loadPrices();
        });
    };

    const loadPrices = useCallback(() => {
        if (pricelist.id) {
            setLoading(true);
            secureFetch( getApiRoute('admin_insuranceprice_list', [pricelist.id]) ).then(json => {
                setRows(json);
                setLoading(false);
                setEditedValues({});
                setEditMode(false);
                setSuccessMessage('');
            })
        }
    }, [secureFetch, pricelist])

    useEffect(() => {
        loadPrices();
    }, [loadPrices]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'desc',
            orderBy: 'price'
        },
        headCells: [
            { id: 'state_to', numeric: false, label: t('label.state_to'), search: true },
            { id: 'amount_from', numeric: true, label: t('label.amount_from'), search: true, render: (data) => data.amount_from + ' ' + data.symbol },
            { id: 'amount_to', numeric: true, label: t('label.amount_to'), search: true, render: (data) => data.amount_to + ' ' + data.symbol },
            { id: 'price', numeric: true, label: t('label.price'), search: true, render: (data) => {
                return (
                    editMode ? 
                    <TextField
                        fullWidth
                        name="price"
                        onChange={ (event) => { onEditChange(event, data.id) } }
                        value={  editedValues[data.id] && editedValues[data.id].price ? editedValues[data.id].price : data.price }
                        type="number"
                        inputProps={{ step: "0.001" }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        required
                    /> : data.price + ' €'
                )
            } },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                return (
                    <Box display="flex">
                        <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')} onClick={ () => { handleEditDialogOpenClose(data) } }>
                            <IconButton
                                size="small"
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <DeleteIconDialog
                            text={
                                <React.Fragment>
                                    {t('messages.delete_price')}
                                    <br/>
                                    <pre>
                                        {JSON.stringify(data, null, 2)}
                                    </pre>
                                </React.Fragment>
                            }
                            route={getApiRoute('admin_insuranceprice', [data.id])}
                            onDelete={loadPrices}
                        />
                    </Box>
                )
            } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleDialogOpenClose}
                    >
                        {t('button.create_price')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        size="small"
                        startIcon={ editMode ? <ClearIcon/> : <EditIcon /> }
                        onClick={editModeChange}
                    >
                        {t('button.edit_mode')}
                    </Button>
                    {
                        !editMode ? null :
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            size="small"
                            startIcon={ <SaveIcon /> }
                            onClick={ saveChanges }
                        >
                            {t('button.save_changes')}
                        </Button>
                    }
                    <ManagePriceDialog
                        prices={renderedData}
                        route={getApiRoute('admin_insuranceprice')}
                        loadPrices={loadPrices}
                    />
                </React.Fragment>
            );
        }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                { 
                    successMessage === '' ? null : 
                    <Box px={2} pb={1} >
                        <Alert variant="filled" severity="success">
                            {successMessage}
                        </Alert>
                    </Box>
                }
                {
                    shipper.shortcut === 'SPS' &&
                    <Box pb={1} >
                        <Alert variant="filled" severity="info">
                            {t('messages.sps_insurance')}
                        </Alert>
                    </Box>
                }
                {
                    shipper.shortcut === '123' &&
                    <Box pb={1} >
                        <Alert variant="filled" severity="info">
                            {t('messages.123_insurance')}
                        </Alert>
                    </Box>
                }
                <ClientTable settings={settings} renderedDataRef={renderedData} />
            </CardContent>
            <Dialog
                open={openCreateDialog}
                onClose={handleDialogOpenClose}
                maxWidth="sm"
            >
                <DialogContent>
                    <InsurancePriceForm handleDialogOpenClose={handleDialogOpenClose} pricelist={pricelist} loadPrices={loadPrices} />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openEditPriceDialog}
                onClose={handleEditDialogOpenClose}
                maxWidth="sm"
            >
                <DialogContent>
                    <EditInsurancePriceForm data={editPriceData} handleDialogOpenClose={handleEditDialogOpenClose} pricelist={pricelist} loadPrices={loadPrices} />
                </DialogContent>
            </Dialog>
        </Card>
    );
}

export default InsurancePriceTable;