import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const UnreadMessageUsersTable = ({ className, messageId, ...rest }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);

    useEffect(() => {
        if (messageId && rows.length === 0) {
            setLoading(true);
            secureFetch(getApiRoute('admin_message', ['unreadlist', messageId])).then(json => {
                setRows(json);
                setLoading(false);
            });
        }
    }, [secureFetch, appValues.user.roles, messageId, rows.length]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'username'
        },
        headCells: [
            {
                id: 'read', numeric: false, label: t('label.read'), search: true, disableOrder: true, searchType: 'select', hide: appValues.user.roles.includes('ROLE_PARTNER'), selectChoices: [
                    { id: true, name: t('label.read') },
                    { id: false, name: t('label.not_read') },
                ], render: (row) => {
                    return (
                        row.read ? <VisibilityIcon /> : <VisibilityOffIcon />
                    );
                }
            },
            { id: 'username', numeric: false, label: t('label.name'), search: true },
            { id: '' },
        ],
    };

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default UnreadMessageUsersTable;