import React from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Box,
    Card,
    CardContent,
    IconButton,
    makeStyles,
    Tooltip
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const ServicePriceTable = ({ className, prices, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const settings = {
        rows: prices,
        loading: false,
        colOrder: {
            order: 'desc',
            orderBy: 'price'
        },
        headCells: [
            { id: 'state_to', numeric: false, label: t('label.state_to'), search: true },
            { id: 'code', numeric: false, label: t('label.code'), search: true },
            { id: 'name', numeric: false, label: t('label.name'), search: true },
            { id: 'percentage', numeric: true, label: t('label.percentage'), search: true, render: (data) => `${parseFloat(data.percentage).toFixed(2)} %` },
            { id: 'price', numeric: true, label: t('label.price'), search: true, render: (data) => data.price + ' €' },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                return (
                    <Box display="flex">
                        <Tooltip style={{fontSize: '1rem'}} title={data.description || t('messages.no_tooltip')}>
                            <IconButton size="small" aria-label="delete">
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            } },
        ],
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default ServicePriceTable;