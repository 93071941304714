import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
    Tooltip,
    Typography,
    Button,
    Box,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import ClientTable from 'components/ClientTable';
import AppContext from 'context/AppContext';
import {isPartner} from "../../../helpers";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const PartnerProfitTableActions = ({ className, data, ...rest }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [rows, setRows] = useState([]);
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);


    useEffect(() => {
        if (open) {
            let route = getApiRoute('admin_statistics', ['partnerprofit', data.partner_id, data.year, data.month]);
            if (isPartner(appValues.user)) {
                route = getApiRoute('partner_statistics', ['partnerprofit', data.year, data.month]);
            }

            secureFetch(route).then(json => {
                let index = 0;
                for (const val of json) {
                    val.id = index++;
                }
                setRows(json);
            });
        }
    }, [secureFetch, open, data, appValues.user]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const settings = {
        rows: rows,
        loading: false,
        colOrder: {
            order: 'desc',
            orderBy: 'year'
        },
        headCells: [
            { id: 'year', numeric: true, label: t('label.year'), search: true },
            { id: 'month', numeric: true, label: t('label.month'), search: true },
            { id: 'partner', numeric: false, label: t('label.partner'), search: true, hide: isPartner(appValues.user) },
            { id: 'eshop', numeric: false, label: t('label.eshop_name'), search: true },
            { id: 'count', numeric: true, label: t('label.count'), search: false },
            { id: 'commission', numeric: false, label: t('label.commission'), search: false, render: (data) => parseFloat(data.commission).toFixed(2) + ' €' },
            { id: 'profit', numeric: false, label: t('label.profit'), search: false, render: (data) => parseFloat(data.profit).toFixed(2) + ' €' },
        ],
    };

    return (
        <Box display="flex">
            <Tooltip title={t('tooltip.detail')} aria-label={t('tooltip.detail')}>
                <IconButton
                    aria-label={t('tooltip.detail')}
                    onClick={handleClickOpen}
                    size="small"
                >
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogContent>
                    <Typography variant="h3" component="h3">
                        {t('title.detail')}
                    </Typography>
                    <ClientTable settings={settings} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {t('button.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default PartnerProfitTableActions;