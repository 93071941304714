import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Grid,
    Card,
    CardContent,
    Divider,
    TextField, Checkbox, FormControlLabel, FormGroup, Typography, Slider, FormLabel
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute, {getLogoPath, getUploadPath} from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import Alert from '@material-ui/lab/Alert';
import { convertToRaw } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';
import {isAdmin, rteStateToHtml, hexToRgbA} from 'helpers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ProgressButton from 'components/ProgressButton';
import FormControl from "@material-ui/core/FormControl";
import AntSwitch from "../../components/AntSwitch";

const getDefSettings = (user) => {
    const source = user.others.email ? user.others.email : null;
    return {
        variant: source ? source.variant : 1,
        iconFbLink: source ? source.iconFbLink : '',
        iconLinkedInLink: source ? source.iconLinkedInLink : '',
        iconInstagramLink: source ? source.iconInstagramLink : '',
        iconYoutubeLink: source ? source.iconYoutubeLink : '',
        btnBackground: source ? source.btnBackground : '#ec4f30',
        btnColor: source ? source.btnColor : '#FFFFFF',
        socialBtnColor: source ? source.socialBtnColor : null,
        socialBtnPosition: source ? source.socialBtnPosition : 'top',
        text: source ? source.text : 'Lorem ipsum...',
        text2: source ? source.text2 : 'Lorem ipsum...',
        bgImage: source ? source.bgImage : '',
        bgColor: source ? source.bgColor : '#FFFFFF',
        bgOpacity: source ? parseFloat(source.bgOpacity) : 0.5,
    }
};

const EmailEdit = ({ className, user, setUser, template, successMessage, setSuccessMessage, successEmailMessage, setSuccessEmailMessage, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [html, setHtml] = React.useState('');
    const { appValues } = useContext(AppContext);
    const [testMail, setTestMail] = useState('');
    const [editorState1, setEditorState1] = useState(null);
    const [editorState2, setEditorState2] = useState(null);
    const [stateId, setStateId] = useState(appValues.states.find(s => s.code === 'SK').id);
    const [templateSettings, setTemplateSettings] = React.useState( getDefSettings(user) );
    const statesOther = [{ id: 'other', name: t('label.other') }].concat(appValues.states.filter((s) => ['SK', 'CZ', 'HU', 'DE', 'AT' ].includes(s.code)));

    const prepareBackground = (color, opacity) => {
        color = color || '#FFFFFF';
        opacity = opacity || .5;
        return hexToRgbA(color, opacity);
    }

    const processTemplate = () => {
        const domparser = new DOMParser()
        const doc = domparser.parseFromString(html, 'text/html')
        if ( html !== '' ) {
            if(doc.documentElement.querySelector('#logo')) doc.documentElement.querySelector('#logo').src = !user.logo ? '/images/unknown-logo.png' : getLogoPath(user.logo);

            if(template.allowed_options.includes('iconFbLink')){
                for (const obj of doc.documentElement.querySelectorAll('.iconFb')) {
                    obj.style.display = templateSettings.iconFbLink ? 'inline-block' : 'none';
                }
            }
            if(template.allowed_options.includes('iconLinkedInLink')){
                for (const obj of doc.documentElement.querySelectorAll('.iconLinkedIn')) {
                    obj.style.display = templateSettings.iconLinkedInLink ? 'inline-block' : 'none';
                }
            }
            if(template.allowed_options.includes('iconInstagramLink')){
                for (const obj of doc.documentElement.querySelectorAll('.iconInstagram')) {
                    obj.style.display = templateSettings.iconInstagramLink ? 'inline-block' : 'none';
                }
            }
            if(template.allowed_options.includes('iconYoutubeLink')){
                for (const obj of doc.documentElement.querySelectorAll('.iconYoutube')) {
                    obj.style.display = templateSettings.iconYoutubeLink ? 'inline-block' : 'none';
                }
            }

            if(template.allowed_options.includes('text') && doc.documentElement.querySelector('#text')) doc.documentElement.querySelector('#text').innerHTML = templateSettings.text;
            if(template.allowed_options.includes('text2') && doc.documentElement.querySelector('#text2')){
                doc.documentElement.querySelector('#text2').innerHTML = templateSettings.text2;
                if (templateSettings.text2 === '<p><br></p>') {
                    doc.documentElement.querySelector('#text2').style.display = 'none';
                } else {
                    doc.documentElement.querySelector('#text2').style.display = 'block';
                }
            }
            if(doc.documentElement.querySelector('#sender')) doc.documentElement.querySelector('#sender').innerHTML = user.address && user.address.company ? user.address.company : '';
            if(template.allowed_options.includes('bgImage') && doc.documentElement.querySelector('#bgImage')) doc.documentElement.querySelector('#bgImage').style.background = `url('${templateSettings.bgImage}') no-repeat center center / cover`;
            if(doc.documentElement.querySelector('#hero-image-overlay')) doc.documentElement.querySelector('#hero-image-overlay').style.background = prepareBackground(templateSettings.bgColor, templateSettings.bgOpacity);
            for (const obj of doc.documentElement.querySelectorAll('.btn')) {
                if (template.allowed_options.includes('btnBackground')) obj.style.backgroundColor = templateSettings.btnBackground;
                if (template.allowed_options.includes('btnBackground')) obj.style.borderColor = templateSettings.btnBackground;
                if (template.allowed_options.includes('btnColor')) obj.style.color = templateSettings.btnColor;
            }

            if (template.allowed_options.includes('socialBtnColor') && templateSettings.socialBtnColor){
                for (const obj of doc.documentElement.querySelectorAll('.social-button')){
                    obj.style.backgroundColor = templateSettings.socialBtnColor;
                }
            }
            if (template.allowed_options.includes('socialBtnPosition') && templateSettings.socialBtnPosition) {
                const showSelector = templateSettings.socialBtnPosition === 'top' ? '#socialBtnPosition_top' : '#socialBtnPosition_bottom';
                const hideSelector = templateSettings.socialBtnPosition === 'top' ? '#socialBtnPosition_bottom' : '#socialBtnPosition_top';
                if (doc.documentElement.querySelector(showSelector)) doc.documentElement.querySelector(showSelector).style.display = 'table-row';
                if (doc.documentElement.querySelector(hideSelector)) doc.documentElement.querySelector(hideSelector).style.display = 'none';
            }
        }
        let iframe = document.createElement('iframe');
        iframe.src = 'data:text/html;charset=utf-8,' + encodeURI(doc.documentElement.innerHTML);

        return doc.documentElement.innerHTML;
    };

    useEffect(() => {
        if (template && template.preview_template_html) {
            setHtml(template.preview_template_html);
        }
    }, [template]);

    const getEmailRoute = useCallback(
        () => {
            let route;
            if (isAdmin(appValues.user)) {
                route = getApiRoute('admin_email', [user.id]);
                route += stateId !== 'other' ? '/' + stateId : ''; // bug symfony route remove last slash
            } else {
                route = getApiRoute('email');
                route = stateId !== 'other' ? route + stateId : route.slice(0, -1); // bug symfony route remove last slash
            }
            return route;
        },
        [appValues.user, user, stateId],
    );

    useEffect(() => {
        secureFetch(getEmailRoute()).then(json => {
            if (json && json.value) {
                json.value.iconFbLink = json.value.iconFbLink === 'null' ? '' : json.value.iconFbLink;
                json.value.iconInstagramLink = json.value.iconInstagramLink === 'null' ? '' : json.value.iconInstagramLink;
                json.value.iconLinkedInLink = json.value.iconLinkedInLink === 'null' ? '' : json.value.iconLinkedInLink;
                json.value.iconYoutubeLink = json.value.iconYoutubeLink === 'null' ? '' : json.value.iconYoutubeLink;
                json.value.text2 = json.value.text2 === undefined ? '' : json.value.text2;
                json.value.socialBtnPosition = json.value.socialBtnPosition === undefined ? 'top' : json.value.socialBtnPosition;
                json.value.bgImage = getUploadPath('email_templates/') +  json.value.bgImageFile;
                setTemplateSettings(json.value);
            }
            else {
                setTemplateSettings(getDefSettings(user))
            }
        });
    }, [secureFetch, user, appValues.user.roles, stateId, getEmailRoute]);

    const handleSettingsChange = (event) => {
        setTemplateSettings({ ...templateSettings, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let formData = new FormData();
        let html = rteStateToHtml(editorState1);
        let content = JSON.stringify(convertToRaw(editorState1.getCurrentContent()));
        let html2 = rteStateToHtml(editorState2);
        let content2 = JSON.stringify(convertToRaw(editorState2.getCurrentContent()));
        let tmpTemplateSettings = {
            ...templateSettings,
            text: html,
            content: content,
            text2: html2,
            content2: content2,
            variant: template.id,
            bgRgbaColor: prepareBackground(templateSettings.bgColor, templateSettings.bgOpacity)
        };

        for (let key in tmpTemplateSettings) {
            if (!['bgImage'].includes(key)) // ignores bgImage key (dont append it to the form data)
                formData.append(key, tmpTemplateSettings[key]);
        }

        secureFetch(getEmailRoute(), formData, 'POST', true, false).then(json => {
            setTemplateSettings(tmpTemplateSettings);
            setSuccessMessage(t('messages.data_saved'));
        });
    }

    const sendTestMail = () => {
        setSuccessEmailMessage('');
        if (testMail) {
            return secureFetch(getEmailRoute(), {email: testMail}, 'PUT');
        }
    };

    const handleTemplateChange = (state, name) => {
        let editor;
        switch (name){
            case 'text2':
                editor = setEditorState2;
                break;
            default:
                editor = setEditorState1;
        }
        editor(state);
        let html = rteStateToHtml(state);

        if (html !== templateSettings[name]) {
            setTemplateSettings({
                ...templateSettings,
                [name]: html,
            });
        }
    }

    const handleBgImageChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = function () {
                setTemplateSettings({ ...templateSettings, bgImage: reader.result, bgImageFile: files[0] });
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    };

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} >
                            <Grid container spacing={3}>
                                {
                                    template && template.id !== 0 &&
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={statesOther}
                                            value={statesOther.find(s => s.id === stateId)}
                                            onChange={(event, val) => (val ? setStateId(val.id) : null)}
                                            getOptionLabel={(option) => option.name}
                                            fullWidth
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label={t('label.state')}
                                            />}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} style={{display: template && template.allowed_options.includes('text') ? 'block' : 'none'}}>
                                    <MUIRichTextEditor
                                        controls={[ "redo", "undo", "title", "bold", "italic", "underline", "highlight", "bulletList", "numberList", "link", "media" ]}
                                        label="Text..."
                                        onChange={(state) => handleTemplateChange(state, 'text')}
                                        defaultValue={templateSettings.content || ''}
                                    />
                                    <Box pb={3}/>
                                </Grid>
                                <Grid item xs={12} style={{display: template && template.allowed_options.includes('text2') ? 'block' : 'none'}}>
                                    <MUIRichTextEditor
                                        controls={[ "redo", "undo", "title", "bold", "italic", "underline", "highlight", "bulletList", "numberList", "link", "media" ]}
                                        label="Text..."
                                        onChange={(state) => handleTemplateChange(state, 'text2')}
                                        defaultValue={templateSettings.content2 || ''}
                                    />
                                    <Box pb={3}/>
                                </Grid>
                                {
                                    template && template.allowed_options.includes('btnBackground') &&
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('email.btn_bg')}
                                            name="btnBackground"
                                            value={templateSettings.btnBackground || ''}
                                            onChange={handleSettingsChange}
                                            type="color"
                                        />
                                    </Grid>
                                }
                                {
                                    template && template.allowed_options.includes('btnColor') &&
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('email.btn_color')}
                                            name="btnColor"
                                            value={templateSettings.btnColor || ''}
                                            onChange={handleSettingsChange}
                                            type="color"
                                        />
                                    </Grid>
                                }
                                {
                                    template && template.allowed_options.includes('iconFbLink') &&
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('email.fb_link')}
                                            name="iconFbLink"
                                            value={templateSettings.iconFbLink || ''}
                                            onChange={handleSettingsChange}
                                        />
                                    </Grid>
                                }
                                {
                                    template && template.allowed_options.includes('iconLinkedInLink') &&
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('email.linkedin_link')}
                                            name="iconLinkedInLink"
                                            value={templateSettings.iconLinkedInLink || ''}
                                            onChange={handleSettingsChange}
                                        />
                                    </Grid>
                                }
                                {
                                    template && template.allowed_options.includes('iconInstagramLink') &&
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('email.instagram_link')}
                                            name="iconInstagramLink"
                                            value={templateSettings.iconInstagramLink || ''}
                                            onChange={handleSettingsChange}
                                        />
                                    </Grid>
                                }
                                {
                                    template && template.allowed_options.includes('iconYoutubeLink') &&
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('email.youtube_link')}
                                            name="iconYoutubeLink"
                                            value={templateSettings.iconYoutubeLink || ''}
                                            onChange={handleSettingsChange}
                                        />
                                    </Grid>
                                }
                                {
                                    template && template.allowed_options.includes('bgColor') &&
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('email.bg_color')}
                                            name="bgColor"
                                            value={templateSettings.bgColor || "#FFFFFF"}
                                            onChange={handleSettingsChange}
                                            type="color"
                                        />
                                    </Grid>
                                }
                                {
                                    template && template.allowed_options.includes('bgOpacity') &&
                                    <Grid item xs={12}>
                                        <Box sx={12}>
                                            <Typography id="input-slider" gutterBottom>
                                                {t("email.bg_opacity")}
                                            </Typography>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item>
                                                    <Typography variant="body1" color="primary">0</Typography>
                                                </Grid>
                                                <Grid item xs>
                                                    <Slider
                                                        value={isNaN(parseFloat(templateSettings.bgOpacity))? 0.5 : parseFloat(templateSettings.bgOpacity)}
                                                        onChange={(event, newValue) => handleSettingsChange({target: {name: 'bgOpacity', value: newValue}})}
                                                        name="bgOpacity"
                                                        aria-labelledby="input-slider"
                                                        min={0}
                                                        max={1}
                                                        step={0.05}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" color="primary">1</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                }
                                {
                                    template && template.allowed_options.includes('socialBtnColor') &&
                                    <Grid item xs={12} hidden={!(templateSettings.iconFbLink || templateSettings.iconLinkedInLink || templateSettings.iconInstagramLink || templateSettings.iconYoutubeLink)}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={3}>
                                                <FormGroup>
                                                    <FormControlLabel control={
                                                        <Checkbox
                                                            checked={!!templateSettings.socialBtnColor}
                                                            onChange={(event) => handleSettingsChange({target: {name: 'socialBtnColor', value: event.target.checked ? '#00f7ff' : null}})}
                                                        />}
                                                      label={t('email.custom')}
                                                      labelPlacement="start"
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    label={t('email.social_btn_color')}
                                                    name="socialBtnColor"
                                                    value={templateSettings.socialBtnColor || '#000000'}
                                                    disabled={!templateSettings.socialBtnColor}
                                                    onChange={handleSettingsChange}
                                                    type="color"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    template && template.allowed_options.includes('socialBtnPosition') &&
                                    <Grid item xs={9}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{t('label.social_buttons_placement')}</FormLabel>
                                            <FormGroup>
                                                <AntSwitch
                                                    name={'socialBtnPosition'}
                                                    leftLabel={t('label.top')}
                                                    leftValue={'top'}
                                                    rightLabel={t('label.bottom')}
                                                    rightValue={'bottom'}
                                                    checked={() => templateSettings.socialBtnPosition !== 'top'}
                                                    onChange={handleSettingsChange}
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                }
                                {
                                    template && template.allowed_options.includes('bgImage') &&
                                    <Grid item xs={12}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            component="label"
                                            fullWidth
                                        >
                                            { t('button.upload_background_image') }
                                            <input
                                                type="file"
                                                name="bgImage"
                                                accept="image/*"
                                                style={{ display: "none" }}
                                                onChange={handleBgImageChange}
                                            />
                                        </Button>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    {
                                        successMessage === '' ? null :
                                            <Box>
                                                <Alert variant="filled" severity="success">
                                                    {successMessage}
                                                </Alert>
                                            </Box>
                                    }
                                    <Box display="flex" justifyContent="center" p={2}>
                                        <Button color="primary" variant="contained" type="submit">
                                            {t('button.save')}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            template && template.id !== 0 &&
                            <Grid item xs={12} md={6}>
                                <div
                                    dangerouslySetInnerHTML={{ __html: processTemplate()}}>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </form>
                {
                    templateSettings.content && template && template.id !== 0 &&
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Grid container spacing={3} alignItems="flex-end">
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={'auto'}>
                                <TextField
                                    fullWidth
                                    label={t('label.email')}
                                    type="email"
                                    value={testMail}
                                    onChange={(event) => setTestMail(event.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={'auto'}>
                                <ProgressButton
                                    type="submit"
                                    name={t('button.send_test_mail')}
                                    color="primary"
                                    variant="contained"
                                    onClick={sendTestMail}
                                    onSuccess={() => setSuccessEmailMessage(t('messages.email_was_sended'))}
                                />
                            </Grid>
                            {
                                successEmailMessage === '' ? null :
                                    <Grid item xs={'auto'}>
                                        <Box>
                                            <Alert variant="filled" severity="success">
                                                {successEmailMessage}
                                            </Alert>
                                        </Box>
                                    </Grid>
                            }
                        </Grid>
                    </form>
                }
            </CardContent>
        </Card>
    );
};

export default EmailEdit;
