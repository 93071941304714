import React from 'react';
import i18n from 'i18n';

export const defAppValues = {
    states: [],
    shippers: [],
    currencies: [],
    messages: [],
    paymentTypes: [
        {
            value: 0,
            name: i18n.t('label.payment_cash'),
            shortName: i18n.t('label.payment_cash_short')
        },
        {
            value: 1,
            name: i18n.t('label.payment_card'),
            shortName: i18n.t('label.payment_card_short')
        }
    ],
    commissions: [
        {
            value: 1,
            name: i18n.t('label.commission_3_premium')
        },
        {
            value: 2,
            name: i18n.t('label.commission_6_premium')
        },
        {
            value: 3,
            name: i18n.t('label.commission_service_premium')
        },
        {
            value: 4,
            name: i18n.t('label.commission_service_6_premium')
        },
        {
            value: 5,
            name: i18n.t('label.commission_3_basic')
        },
        {
            value: 6,
            name: i18n.t('label.commission_6_basic')
        },
        {
            value: 7,
            name: i18n.t('label.commission_service_basic')
        },
        {
            value: 8,
            name: i18n.t('label.commission_service_6_basic')
        }
    ],
    pricelistTypes: ['purchase', 'sale', 'fix'],
    roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_PARTNER'],
    deliveryTypes: [
        {
            value: 0,
            name: i18n.t('label.courier')
        },
        {
            value: 1,
            name: i18n.t('label.parcelshop')
        },
        {
            value: 2,
            name: i18n.t('label.return_package')
        },
    ],
    inputTypes: [
        {
            value: 1,
            name: 'TEXT_FIELD'
        },
        {
            value: 2,
            name: 'DATE_FIELD'
        },
        {
            value: 3,
            name: 'HOUR_RANGE'
        },
        {
            value: 4,
            name: 'ADDRESS_TYPE'
        },
    ],
};

const AppContext = React.createContext();

export default AppContext