import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    TextField,
    Grid,
    makeStyles, Typography
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {getNeoshipServiceTypes} from "../../helpers";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles(() => ({
    root: {}
}));

const EditServiceNeoshipForm = ({ className, service, handleEditDialogOpenClose, load, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        if ( service.type ) {
            setValues({
                type: service.type,
                price: service.price,
            });
        }
    }, [service]);

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch(getApiRoute('admin_service_neoship', [service.id]), values, 'PUT').then(json => {
            handleEditDialogOpenClose();
            load();
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.edit_service')}
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <InputLabel >{t('label.type')}</InputLabel>
                    <Select
                        fullWidth
                        value={values.type || ''}
                        name="type"
                        onChange={handleChange}
                        required
                    >
                        {getNeoshipServiceTypes().map((val) => (
                            <MenuItem
                                key={val.value}
                                value={val.value}
                            >
                                {val.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('label.price')}
                        name="price"
                        onChange={handleChange}
                        value={values.price || ''}
                        type="number"
                        inputProps={{ step: "0.01" }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        required
                    />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Button color="secondary" variant="text" onClick={handleEditDialogOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.save')}
                </Button>
            </Box>

        </form>
    );
};

EditServiceNeoshipForm.propTypes = {
    className: PropTypes.string
};

export default EditServiceNeoshipForm;
