import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Box
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import {  useNavigate } from "react-router-dom";
import TabPanel from 'components/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PriceTable from './PriceTable';
import CodPriceTable from './CodPriceTable';
import InsurancePriceTable from './InsurancePriceTable';
import ServicePriceTable from './ServicePriceTable';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTab-root': {
            minWidth: 'initial',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    appBar:{
        background: 'white'
    }
}));

export default function PricesTabs({ pricelist, tab, localNavigation = false,  ...rest }) {
    const classes = useStyles();
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(tab);

    const handleChange = (event, newValue) => {
        if (localNavigation) {
            setActiveTab(newValue);
        } else {
            navigate("/app/admin/pricelist/"+pricelist.id+"/"+newValue);
        }
    };

    useEffect(() => {
        if ( !localNavigation ) {
            setActiveTab(tab);
        }
    }, [tab, localNavigation]);

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="static" color="default">
                <Tabs 
                    value={activeTab}
                    onChange={handleChange}
                    aria-label="scrollable auto tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    <Tab value="shipping_price" label={t('title.shipping_price')} />
                    <Tab value="cod_price" label={t('title.cod_price')} />
                    <Tab value="service_price" label={t('title.service_price')} />
                    <Tab value="insurance_price" label={t('title.insurance_price')} />
                </Tabs>
            </AppBar>
            <Box pt={3}>
                <Alert variant="filled" severity="error">
                    {t('messages.pricelist_warning')}
                </Alert>
            </Box>
            <TabPanel className={classes.tabPanel} value={activeTab} index={"shipping_price"}>
                <PriceTable pricelist={pricelist} />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={activeTab} index={"cod_price"}>
                <CodPriceTable pricelist={pricelist} />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={activeTab} index={"service_price"}>
                <ServicePriceTable pricelist={pricelist} />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={activeTab} index={"insurance_price"}>
                <InsurancePriceTable pricelist={pricelist} shipper={pricelist.shipper} />
            </TabPanel>
        </div>
    );
}