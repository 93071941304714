import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getErrorMessage, isInputError } from 'helpers';
import AppContext from "../../../context/AppContext";

const AddressTypeService = ({ values, service, handleChange, errors, ...rest }) => {
    const { t } = useTranslation();
    const { appValues } = useContext(AppContext);

    const states = appValues.states.filter(st => ['SK', 'CZ', 'HU', 'PL'].includes(st.code))

    useEffect(() => {
       console.log('values changed', values);
    }, [values]);

    const handleAddressChange = (e) => {
        console.log('event', e);
        let tmpServiceValues = {...values[service.code + 'value']};
        console.log('tmp before', tmpServiceValues);
        tmpServiceValues[e.target.name] = e.target.value;
        console.log('tmp after', tmpServiceValues);
        handleChange(service.code + 'value', tmpServiceValues);
    }

    return (
        <Card style={values[service.code] ? {} : {opacity: 0.5, pointerEvents: 'none'}}>
            <CardHeader
                title={ service.input_name }
            />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            error={ isInputError(errors[service.code+'value'] || {}, "name") }
                            helperText={ getErrorMessage(errors[service.code+'value'] || {}, "name") }
                            fullWidth
                            label={t('label.name')}
                            name={"name"}
                            onChange={handleAddressChange}
                            value={values[service.code+'value'] ? (values[service.code+'value']["name"] || '') : ''}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={ isInputError(errors[service.code+'value'] || {}, "company") }
                            helperText={ getErrorMessage(errors[service.code+'value'] || {}, "company") }
                            fullWidth
                            label={t('label.company')}
                            name={"company"}
                            onChange={handleAddressChange}
                            value={values[service.code+'value'] ? (values[service.code+'value']["company"] || '') : ''}
                        />
                    </Grid>
                    <Grid item sm={7} xs={12}>
                        <TextField
                            error={ isInputError(errors[service.code+'value'] || {}, "street") }
                            helperText={ getErrorMessage(errors[service.code+'value'] || {}, "street") }
                            fullWidth
                            label={t('label.street')}
                            name={"street"}
                            onChange={handleAddressChange}
                            value={values[service.code+'value'] ? (values[service.code+'value']["street"] || '') : ''}
                            required
                        />
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <TextField
                            error={ isInputError(errors[service.code+'value'] || {}, "house_number") }
                            helperText={ getErrorMessage(errors[service.code+'value'] || {}, "house_number") }
                            fullWidth
                            label={t('label.house_number')}
                            name={"house_number"}
                            onChange={handleAddressChange}
                            value={values[service.code+'value'] ? (values[service.code+'value']["house_number"] || '') : ''}
                            required
                        />
                    </Grid>
                    <Grid item sm={7} xs={12}>
                        <TextField
                            error={ isInputError(errors[service.code+'value'] || {}, "city") }
                            helperText={ getErrorMessage(errors[service.code+'value'] || {}, "city") }
                            fullWidth
                            label={t('label.city')}
                            name={"city"}
                            onChange={handleAddressChange}
                            value={values[service.code+'value'] ? (values[service.code+'value']["city"] || '') : ''}
                            required
                        />
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <TextField
                            error={ isInputError(errors[service.code+'value'] || {}, "zip") }
                            helperText={ getErrorMessage(errors[service.code+'value'] || {}, "zip") }
                            fullWidth
                            label={t('label.zip')}
                            name={"zip"}
                            onChange={handleAddressChange}
                            value={values[service.code + "value"] ? (values[service.code+'value']["zip"] || '') : ''}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={states}
                            value={ values[service.code+'value'] && values[service.code+'value']['state_code'] ? states.find(st => st.code == values[service.code+'value']['state_code']) : ''}
                            onChange={ (event, pState) => handleAddressChange({target: {name: 'state_code', 'value': pState ? pState.code : ''}}) }
                            getOptionLabel={(option) => option === null ? '' : option.name}
                            getOptionSelected={(option, value) => option.code === value.code}
                            renderInput={(params) => <TextField 
                                error={ isInputError(errors[service.code+'value'] || {}, "state_code") }
                                helperText={ getErrorMessage(errors[service.code+'value'] || {}, "state_code") }
                                {...params} 
                                label={t('label.state')} 
                                required
                            />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={ isInputError(errors[service.code+'value'] || {}, "email") }
                            helperText={ getErrorMessage(errors[service.code+'value'] || {}, "email") }
                            fullWidth
                            label={t('label.email')}
                            name={"email"}
                            onChange={handleAddressChange}
                            type="email"
                            value={values[service.code+'value'] ? (values[service.code+'value']["email"] || '') : ''}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={ isInputError(errors[service.code+'value'] || {}, "phone") }
                            helperText={ getErrorMessage(errors[service.code+'value'] || {}, "phone") }
                            fullWidth
                            label={t('label.phone')}
                            name={"phone"}
                            onChange={handleAddressChange}
                            value={values[service.code+'value'] ? (values[service.code+'value']["phone"] || '') : ''}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={ isInputError(errors[service.code+'value'] || {}, "count_of_packages") }
                            helperText={ getErrorMessage(errors[service.code+'value'] || {}, "count_of_packages") }
                            fullWidth
                            label={t('label.count_of_packages')}
                            name={"count_of_packages"}
                            onChange={handleAddressChange}
                            value={values[service.code+'value'] ? (values[service.code+'value']["count_of_packages"] || '') : ''}
                            required
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AddressTypeService;
