import React, { useState, useContext } from 'react';
import {
    Container,
    Card,
    CardContent,
    Divider,
    CardHeader,
    Button,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import getApiRoute from 'routes/apiRoutes';
import SecureFetchContext from 'context/SecureFetchContext';
import AppContext from 'context/AppContext';
import {useSearchParams} from "react-router-dom";
import { isAdmin } from "helpers";

export default function DebugCallCommand() {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [searchParams] = useSearchParams();
    const [commandResponse, setCommandResponse] = useState(null);

    const handleClick = (e) => {
        secureFetch(getApiRoute('run_command'), Object.fromEntries(searchParams), 'POST').then((response) => {
                console.log(response);
                setCommandResponse(response.message);
            })
    }

    return (
        isAdmin(appValues.user) &&
        <Page
            title={t('title.call_command')}
        >
            <Container maxWidth={false}>
                <Card>
                    <CardHeader
                        title={t('title.call_command') + ': ' + searchParams.get("command")}
                    />
                    <Divider />
                    <CardContent>
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            onClick={handleClick}
                        >
                            {t('button.run')}
                        </Button>
                        {
                            commandResponse &&
                            <pre dangerouslySetInnerHTML={{ __html: commandResponse }} style={{marginTop: '1rem'}} />
                        }
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
}