import React, { useState, useContext } from 'react';
import {
    Box,
    Button,
    Typography,
    TextField,
    Grid,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import Autocomplete from '@material-ui/lab/Autocomplete';

const CodPriceForm = ({  handleDialogkOpenClose, loadCodLimits, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [state, setState] = useState(null);
    const [currency, setCurrency] = useState(0);
    const [shipper, setShipper] = useState(0);
    const [limitCod, setLimitCod] = useState(0);
    const { appValues } = useContext(AppContext);


    const handleSubmit = (event) => {
        event.preventDefault();

        let values = {
            state: state.id,
            currency: currency.id,
            shipper: shipper.id,
            limit_cod: limitCod,
        };
      
        secureFetch(getApiRoute('admin_codlimit'), values, 'POST').then(json => {
            handleDialogkOpenClose();
            loadCodLimits();
        });
    };

    return (
        <form
            autoComplete="off"
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.create_price')}
            </Typography>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={6}>
                    <Autocomplete
                        options={appValues.currencies}
                        onChange={ (event, pCurrency) => setCurrency(pCurrency) }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label={t('label.currency')} required/>}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        options={appValues.states}
                        onChange={ (event, pState) => setState(pState) }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label={t('label.state')} placeholder={t('label.state')} />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        options={appValues.shippers}
                        onChange={(event, pShipper) => setShipper(pShipper)}
                        getOptionLabel={(option) => option.shortcut}
                        renderInput={(params) => <TextField {...params} label={t('label.shipper')} required />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('label.limit_cod')}
                        name="limit_cod"
                        onChange={(e) => { setLimitCod(e.target.value) }}
                        value={limitCod}
                        type="number"
                        inputProps={{ step: "0.001" }}
                        required
                    />
                </Grid>
            </Grid>
          
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Button color="secondary" variant="text" onClick={handleDialogkOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.create')}
                </Button>
            </Box>
        </form>
    );
};

export default CodPriceForm;
