import React from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout';
import LoginView from 'views/LoginView';
import NotFoundView from 'views/NotFoundView';
import TrackingView from 'views/TrackingView';

const roleAllRoutes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { path: 'login', element: <LoginView /> },
            { path: 'tracking/:tracking_number', element: <TrackingView /> },
            { path: '404', element: <NotFoundView /> },
            { path: '/', element: <Navigate to="/login" /> },
            { path: '*', element: <Navigate to="/404" replace={true} /> }
        ]
    },
];

export default roleAllRoutes;