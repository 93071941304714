import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
    CircularProgress,
    Grid,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useLocation } from "react-router-dom";
import AppContext from 'context/AppContext';
import { DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import sk from "date-fns/locale/sk";
import {formatDate, getAllPickups, isAdmin, isPartner, isUser} from "../helpers";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TableFilter = ({ className, enabled, setFilterValue, filter, is_service, ...rest }) => {
    const { t } = useTranslation();
    const [values, setValues] = useState({});
    const secureFetch = useContext(SecureFetchContext);
    const [statusTypes, setStatusTypes] = useState([]);
    const [lastStatusTypes, setLastStatusTypes] = useState([]);
    const [states, setStates] = useState([]);
    const [currency, setCurrency] = useState(null);
    const [operation, setOperation] = useState(null);
    const [codPaymentType, setCodPaymentType] = useState(null);
    const [opened, setOpened] = useState(false);
    const { appValues } = useContext(AppContext);
    const [dateRangeValue, setDateRangeValue] = React.useState({ created_at: [null, null] });
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [partner, setPartner] = useState(null);
    const [deliveryType, setDeliveryType] = useState(null);
    const [shipper, setShipper] = useState(null);
    const [extendedFilters, setExtendedFilters] = useState([]);
    const [extendedFilterOptions, setExtendedFilterOptions] = useState([]);
    const [roles, setRoles] = useState(null);
    const { state } = useLocation();
    const [codSums, setCodSums] = useState([]);
    const [codSum, setCodSum] = useState(null);
    const [pickups, setPickups] = useState([]);
    const [open, setOpen] = React.useState(false);
    const loading = open && codSums.length === 0;
    const [shippersMulti, setShippersMulti] = useState([]);

    useEffect(() => { // if filter reset
        if (Object.keys(filter).length === 0) {
            setStatusTypes([]);
            setLastStatusTypes([]);
            setStates([]);
            setCodPaymentType(null);
            setCurrency(null);
            setOperation(null);
            setCodSum(null);
            setShippersMulti([]);
        }
    }, [filter]);

    useEffect(() => {
        if (state) {
            let tmpFilter = {};
            if (state.last_status ) {
                setLastStatusTypes( state.last_status );
                tmpFilter.last_status = state.last_status.map(val => val.id);
            }
        }
    }, [state]);

    useEffect(() => {
        if ((enabled.user || enabled.partner) && !users.length) {
            secureFetch( getApiRoute( isAdmin(appValues.user) ? 'admin_user' : 'partner_user') ).then(json => {
                setUsers(json.map(user => {
                    return {
                        id: user.id,
                        name: user.eshop || '',
                        roles: user.roles
                    }
                }));
            })
        }
    }, [appValues.user, secureFetch, enabled.partner, enabled.user, users.length]);

    useEffect(() => {
        if ((enabled.extended) && !extendedFilterOptions.length) {
            let shipper_id = filter.shipper ? filter.shipper : '0';
            if (isUser(appValues.user)) {
                let pricelists = appValues.user.user_shipper_price_lists;
                if (pricelists.length === 1) {
                    shipper_id = pricelists[0].shipper.id;
                }
            }
            secureFetch( getApiRoute( 'extended_statustype', [shipper_id])).then(json => {
                json.push(
                    {code: "or_reverse", name: t('label.reverse')},
                    {code: "or_composed", name: t('label.composed')},
                    {code: "or_parcelshops", name: t('label.parcelshops')},
                    {code: "or_claim_assistant", name: t('label.returning_package')},
                    {code: "or_is_reserved", name: t('label.is_reserved')}
                );
                setExtendedFilterOptions(json);
            })
        }
    }, [appValues.user, secureFetch, enabled.extended, extendedFilterOptions.length]);

    useEffect(() => {
        if ((enabled.status_type || enabled.last_status) && !values.status_type) {
            let shipper_id = filter.shipper ? filter.shipper : '0';
            if (isUser(appValues.user)) {
                let pricelists = appValues.user.user_shipper_price_lists;
                if (pricelists.length === 1) {
                    shipper_id = pricelists[0].shipper.id;
                }
            }
            secureFetch(getApiRoute('statustype', [shipper_id])).then(json => {
                setValues({ ...values, status_type: json});
            })
        }
    }, [secureFetch, filter.shipper, enabled.status_type, enabled.last_status, appValues.user.roles, appValues.user.user_shipper_price_lists, values]);

    useEffect(() => {
        let active = true;

        if (!loading) {
          return undefined;
        }

        const fetchCodSums = async () => {
            const response = secureFetch(getApiRoute('cod_sum', [appValues.user.id]));
            const cods = await response;

            if (cods.length) {
                if (active) {
                    setCodSums(cods);
                }
            } else {
                setCodSums([{sum:'Žiadne dobierky'}]);
            }
        }
        fetchCodSums();

        return () => {
          active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (enabled.shipper_multi_user_list  && !shippersMulti.length) {
            let shipper_sale = appValues.shippers.map(shipper => { return { id: shipper.id+'_sale', name: shipper.shortcut+' - Prémium', shortcut: shipper.shortcut } });
            let shipper_service = appValues.shippers.map(shipper => { return { id: shipper.id+'_fix', name: shipper.shortcut+' - Základ', shortcut: shipper.shortcut+'_service'} });
            let all = shipper_sale.concat(shipper_service);
            setShippersMulti(all);
        }
    }, [enabled.shipper_multi_user_list, shippersMulti.length ]);

    useEffect(() => {
        if (!open) {
          setCodSums([]);
        }
    }, [open]);

    const handleExtendedFiltersChange = (selected = null) => {
        setFilterValue('or_reverse', '');
        setFilterValue('or_composed', '');
        setFilterValue('or_parcelshops', '');
        setFilterValue('or_claim_assistant', '');
        setFilterValue('or_is_reserved', '');
        let statuses = [];
        const iterable = selected || extendedFilters;
        iterable.forEach((value) => {
            switch (value.code) {
                case 'or_reverse':
                    setFilterValue('or_reverse', 1);
                    break;
                case 'or_composed':
                    setFilterValue('or_composed', 1)
                    break;
                case 'or_parcelshops':
                    setFilterValue('or_parcelshops', 1)
                    break;
                case 'or_claim_assistant':
                    setFilterValue('or_claim_assistant', 1)
                    break;
                case 'or_is_reserved':
                    setFilterValue('or_is_reserved', 1)
                    break;
                default:
                    statuses.push(value.code)
            }
        });
        setFilterValue('status_type_code', statuses)
    }

    const filterChangeLocalDateRange = (data, id) => {
        data[0].setHours(0, 0, 0, 0);

        if (!data[0] || !filter[id + '_from'] || data[0].getTime() !== filter[id + '_from'].getTime()) {
            setFilterValue(id + '_from', data[0]);
        } else if (filter[id + '_from'] && data[1] && (!filter[id + '_to'] || data[1].getTime() !== filter[id + '_to'].getTime())) {
            data[1].setHours(23, 59, 59, 59);
            setFilterValue(id + '_to', data[1]);
        }

        setDateRangeValue({ ...dateRangeValue, [id]: data});
    };

    const onOpen = () => {
        setOpened(true);
    }

    return (
        <Grid container spacing={3}>
            {
                enabled.last_status &&
                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            disabled={enabled.is_service ? true : false}
                            multiple
                            disableCloseOnSelect
                            groupBy={(option) => option.shipper.shortcut}
                            options={values.status_type || []}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, selected, reason) => {
                                setLastStatusTypes(selected);
                                if (reason === 'remove-option' && !opened) {
                                    setFilterValue('last_status', selected.map(val => val.id));
                                }
                            }}
                            onOpen={onOpen}
                            onClose={() => {
                                setOpened(false);
                                setFilterValue('last_status', lastStatusTypes.map(val => val.id));
                            }}
                            loading={values.status_type ? false : true}
                            value={lastStatusTypes}
                            defaultValue={state ? state.last_status : []}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label={t('label.statuses')}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
            }
            {
                enabled.status_type &&
                <Grid item xs={12} md={6} lg={4}>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        groupBy={(option) => option.shipper.shortcut}
                        options={values.status_type || []}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, selected, reason) => {
                            setStatusTypes(selected);
                            if (reason === 'remove-option' && !opened) {
                                setFilterValue('status_type', selected.map(val => val.id));
                            }
                        } }
                        onOpen={ onOpen }
                        onClose={() => {
                            setOpened(false);
                            setFilterValue('status_type', statusTypes.map(val => val.id));
                        } }
                        loading={values.status_type ? false : true}
                        value={statusTypes}
                        defaultValue={state ? state.status_type : []}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={t('label.statuses')}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
            }
            {
                enabled.states &&
                <Grid item xs={12} md={6} lg={4}>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={appValues.states}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, selected, reason) => {
                            setStates(selected);
                            if (reason === 'remove-option' && !opened) {
                                setFilterValue('receiver_state_code', selected.map(val => val.code));
                            }
                        } }
                        onOpen={ onOpen }
                        onClose={() => {
                            setOpened(false);
                            setFilterValue('receiver_state_code', states.map(val => val.code));
                        } }
                        value={states}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={t('label.states_to')}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
            }
            {
                enabled.cod_payment &&
                <Grid item xs={12} md={6} lg={4}>
                    <Autocomplete
                        disabled={enabled.is_service ? true : false}
                        options={appValues.paymentTypes}
                        getOptionLabel={(option) => {
                            return option.name
                        }}
                        value={codPaymentType}
                        onChange={(event, selected) => {
                            setCodPaymentType(selected);
                            setFilterValue('cod_payment', selected ? selected.value : null);
                        } }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={t('label.payment_type')}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
            }
            {
                enabled.cod_currency_code &&
                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            options={appValues.currencies}
                            getOptionLabel={(option) => option.code }
                            value={currency}
                            onChange={(event, selected) => {
                                setCurrency(selected);
                                setFilterValue('cod_currency_code', selected ? selected.code : null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label={t('label.currency')}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
            }
            {
                enabled.operation &&
                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            options={['+', '-']}
                            getOptionLabel={(option) => option}
                            value={operation}
                            onChange={(event, selected) => {
                                setOperation(selected);
                                setFilterValue('operation', selected ? selected : null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label={t('label.operation')}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
            }
            {
                enabled.created_at &&
                    <Grid item xs={12} md={6} lg={4}>
                        <LocalizationProvider dateAdapter={DateFnsAdapter} locale={sk}>
                            <DateRangePicker
                                value={dateRangeValue['created_at']}
                                onChange={(newValues) => filterChangeLocalDateRange(newValues, 'created_at')}
                                renderInput={(startProps, endProps) => {
                                    startProps.inputProps.value = filter['created_at_from'] ? filter['created_at_from'].toLocaleDateString() + ' - ' : '';
                                    startProps.inputProps.value += filter['created_at_to'] ? filter['created_at_to'].toLocaleDateString() : '';
                                    return (
                                        <React.Fragment>
                                            <TextField
                                                {...startProps}
                                                label={t('label.created_at')}
                                                name={'created_at'}
                                                variant={'standard'}
                                                type="text"
                                                helperText=""
                                                fullWidth
                                            />
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
            }
            {
                enabled.user &&
                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            options={users.filter((user) => isUser(user))}
                            value={user}
                            onChange={(event, selected) => {
                                setUser(selected);
                                setFilterValue('user', selected ? selected.id : null);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t('label.eshop_name')}
                            />}
                        />
                    </Grid>
            }
            {
                enabled.partner &&
                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            options={users.filter((user) => isPartner(user))}
                            value={partner}
                            onChange={(event, selected) => {
                                setPartner(selected);
                                setFilterValue('partner', selected ? selected.id : null);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t('label.partner')}
                            />}
                        />
                    </Grid>
            }
            {
                enabled.delivery_type &&
                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            options={[
                                { id: 'local', name: t('label.local') }, 
                                { id: 'international', name: t('label.international') }, 
                                { id: 'parcelshop_local', name: t('label.parcelshop_local') },
                                { id: 'parcelshop_international', name: t('label.parcelshop_international') },
                            ]}
                            value={deliveryType}
                            onChange={(event, selected) => {
                                setDeliveryType(selected);
                                setFilterValue('delivery_type', selected ? selected.id : null);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t('label.delivery_type')}
                            />}
                        />
                    </Grid>
            }
            {
                enabled.shipper &&
                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            options={isUser(appValues.user) ? appValues.user.user_shipper_price_lists.map(v => v.shipper) : appValues.shippers}
                            value={shipper}
                            onChange={(event, selected) => {
                                setShipper(selected);
                                setFilterValue('shipper', selected ? selected.id : null);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t('label.shipper')}
                            />}
                        />
                    </Grid>
            }
            {
                enabled.shipper_multi &&
                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            options={appValues.shippers}
                            getOptionLabel={(option) => option.shortcut}
                            onChange={(event, selected, reason) => {
                                setShipper(selected);
                                if (reason === 'remove-option' && !opened) {
                                    setFilterValue('shipper', selected.map(val => val.id));
                                }
                            }}
                            onOpen={onOpen}
                            onClose={() => {
                                setOpened(false);
                                setFilterValue('shipper', shipper.map(val => val.id));
                            }}
                            loading={values.shipper ? false : true}
                            value={shipper || []}
                            defaultValue={state ? JSON.parse(state).shipper : []}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.shortcut}
                                </React.Fragment>
                            )}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t('label.shipper')}
                            />}
                        />
                    </Grid>
            }
            {
                enabled.shipper_multi_user_list &&
                <Grid item xs={12} md={6} lg={4}>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={shippersMulti}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, selected, reason) => {
                            setShipper(selected);
                            if (reason === 'remove-option' && !opened) {
                                setFilterValue('shipper', selected.map(val => val.id));
                            }
                        }}
                        onOpen={onOpen}
                        onClose={() => {
                            setOpened(false);
                            if (shipper !== null)
                                setFilterValue('shipper', shipper.map(val => val.id));
                        }}
                        loading={values.shipper ? false : true}
                        value={shipper || []}
                        defaultValue={state ? JSON.parse(state).shipper : []}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </React.Fragment>
                        )}
                        renderInput={(params) => <TextField
                            {...params}
                            label={t('label.shipper')}
                        />}
                    />
                </Grid>
            }
            {
                enabled.roles &&
                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            options={appValues.roles}
                            value={roles}
                            onChange={(event, selected) => {
                                setRoles(selected);
                                setFilterValue('roles', selected ? selected : null);
                            }}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => <TextField
                                {...params}
                                label={t('label.roles')}
                            />}
                        />
                    </Grid>
            }
            {
                enabled.extended &&
                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            options={extendedFilterOptions}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, selected, reason) => {
                                setExtendedFilters(selected);
                                if (reason === 'remove-option' && !opened) {
                                    handleExtendedFiltersChange(selected)
                                }
                            }}
                            getOptionSelected={(option, value) => option.code === value.code}
                            onOpen={onOpen}
                            onClose={() => {
                                setOpened(false);
                                handleExtendedFiltersChange();
                            }}
                            getOptionDisabled={(option) => {
                                if (enabled.is_service)
                                    return ['166', '163'].includes(option.code);
                            }}
                            loading={!!extendedFilterOptions}
                            value={extendedFilters}
                            defaultValue={[]}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label={t('label.extended_filters')}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
            }
            {
                enabled.reverse &&
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel control={
                            <Checkbox
                                checked={filter['reverse'] ? true : false}
                                onChange={(event) => setFilterValue('reverse', event.target.checked ? 1 : null)}
                            />
                        } 
                        label={t('label.reverse')} />
                    </Grid>
            }
            {
                enabled.composed &&
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel control={
                            <Checkbox
                                checked={filter['composed'] ? true : false}
                                onChange={(event) => setFilterValue('composed', event.target.checked ? 1 : null)}
                            />
                        }
                        label={t('label.composed')} />
                    </Grid>
            }
            {
                enabled.exported &&
                <Grid item xs={12} md={6} lg={4}>
                    <FormControlLabel control={
                        <Checkbox
                            checked={!!filter['exported']}
                            onChange={(event) => setFilterValue('exported', event.target.checked ? 1 : '')}
                        />
                    }
                    label={t('label.exported')} />
                </Grid>
            }
            {
                enabled.cod_sum && !isAdmin(appValues.user) &&
                <Grid item xs={12} md={6} lg={4}>
                    <Autocomplete
                        open={open}
                        onOpen={() => {setOpen(true); }}
                        onClose={() => {setOpen(false);}}
                        options={codSums}
                        getOptionLabel={(option) => typeof option.sum === 'string'
                        || option.sum instanceof String ? option.sum : ""}
                        value={codSum || ""}
                        onChange={(event, selected) => {
                            setCodSum(selected || "");
                            setFilterValue('shipper', selected ? selected.shipper_id : "");
                            setFilterValue('invoice_number', selected ? selected.invoice_number : "");
                        }}
                        loading={loading}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.sum} {option.cod_currency_code} {" "} <span style={{fontSize:'small', paddingLeft: '10px'}}>({formatDate(option.invoice_date)})</span>
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={t('label.cod_sum')}
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
            }
            {
                enabled.pickup_address &&
                <Grid item xs={12} md={6} lg={4}>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={getAllPickups(appValues.user)}
                        getOptionLabel={(option) => `${option.address.city}, ${option.address.street}`}
                        onChange={(event, selected, reason) => {
                            setPickups(selected);
                            if (reason === 'remove-option' && !opened) {
                                setFilterValue('pickups', selected.map(val => val.id));
                            }
                        } }
                        onOpen={ onOpen }
                        onClose={() => {
                            setOpened(false);
                            setFilterValue('pickups', pickups.map(val => val.id));
                        } }
                        value={pickups}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {`${option.address.city}, ${option.address.street}`}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={t('label.pickup_address')}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
            }
        </Grid>
    );
}

export default TableFilter;