import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid, makeStyles,
    TextField, Typography,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {getCarrierTypes, getErrorMessage, getFilteredCarrierTypes, isInputError} from 'helpers';


const useStyles = makeStyles((theme) => ({
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const Address = ({ className, states, state, id, prefix, title, values, setValues, handleChange, errors, parcelshop, parcelshops, onParcelshopChange, onStateChange, addresses, address, setAddress, pickupAddress, carrierTypes, onCarrierTypesChange, shipper, ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Card>
            <CardHeader
                title={
                    <Typography variant="h5" className={classes.spaceBetween}>
                        {title}
                        {
                            !!pickupAddress &&
                            <span>
                                {pickupAddress}
                            </span>
                        }
                    </Typography>
            }
            />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            error={ isInputError(errors, prefix + "name") }
                            helperText={ getErrorMessage(errors, prefix + "name") }
                            fullWidth
                            label={t('label.name')}
                            name={prefix + "name"}
                            onChange={handleChange}
                            value={values[prefix + "name"] || ''}
                            required
                        />
                    </Grid>
                    {   (parcelshop && id) &&
                    <Grid item xs={12}>
                        <Autocomplete
                            options={parcelshops}
                            value={(parcelshops.length && values.parcelshop) ? parcelshops.find(p => p.parcelshop_id === values.parcelshop) : ''}
                            onChange={ (event, pParcelshop) => onParcelshopChange(event, pParcelshop, prefix + "parcelshop") }
                            getOptionLabel={(option) => option ? option.city + ', ' + option.street + ' (ID: ' + option.parcelshop_id + ')' : ''}
                            renderInput={(params) => <TextField
                                error={ isInputError(errors, prefix + "parcelshop") }
                                helperText={ getErrorMessage(errors, prefix + "parcelshop") }
                                {...params}
                                label={t('label.parcelshop_id')}
                                required
                            />}
                        />
                    </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            error={ isInputError(errors, prefix + "company") }
                            helperText={ getErrorMessage(errors, prefix + "company") }
                            fullWidth
                            label={t('label.company')}
                            name={prefix + "company"}
                            onChange={handleChange}
                            value={values[prefix + "company"] || ''}
                            disabled={parcelshop ? true : false}
                        />
                    </Grid>
                    <Grid item sm={7} xs={12}>
                        <TextField
                            error={ isInputError(errors, prefix + "street") }
                            helperText={ getErrorMessage(errors, prefix + "street") }
                            fullWidth
                            label={t('label.street')}
                            name={prefix + "street"}
                            onChange={handleChange}
                            value={values[prefix + "street"] || ''}
                            disabled={parcelshop ? true : false}
                            required
                        />
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <TextField
                            error={ isInputError(errors, prefix + "house_number") }
                            helperText={ getErrorMessage(errors, prefix + "house_number") }
                            fullWidth
                            label={t('label.house_number')}
                            name={prefix + "house_number"}
                            onChange={handleChange}
                            value={values[prefix + "house_number"] || ''}
                            disabled={parcelshop ? true : false}
                            required
                        />
                    </Grid>
                    <Grid item sm={7} xs={12}>
                        <TextField
                            error={ isInputError(errors, prefix + "city") }
                            helperText={ getErrorMessage(errors, prefix + "city") }
                            fullWidth
                            label={t('label.city')}
                            name={prefix + "city"}
                            onChange={handleChange}
                            value={values[prefix + "city"] || ''}
                            disabled={parcelshop ? true : false}
                            required
                        />
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <TextField
                            error={ isInputError(errors, prefix + "zip") }
                            helperText={ getErrorMessage(errors, prefix + "zip") }
                            fullWidth
                            label={t('label.zip')}
                            name={prefix + "zip"}
                            onChange={handleChange}
                            value={values[prefix + "zip"] || ''}
                            disabled={parcelshop ? true : false}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={states}
                            value={ state }
                            onChange={ (event, pState) => onStateChange(event, pState, prefix + "state_code") }
                            getOptionLabel={(option) => option === null ? '' : option.name}
                            getOptionSelected={(option, value) => option.code === value.code}
                            disabled={parcelshop ? true : false}
                            renderInput={(params) => <TextField 
                                error={ isInputError(errors, prefix + "state_code") }
                                helperText={ getErrorMessage(errors, prefix + "state_code") }
                                {...params} 
                                label={t('label.state')} 
                                required
                            />}
                        />
                    </Grid>
                    {  ( shipper !== undefined && shipper.shortcut.toUpperCase() === 'PACKETA' && !parcelshop && prefix === 'receiver_' ) &&
                        <Grid item xs={12}>
                            <Autocomplete
                                options={getFilteredCarrierTypes(carrierTypes)}
                                value={(values.carrier_type) ? getCarrierTypes().find(ct => ct.value === values.carrier_type) :''}
                                onChange={ (event, cCarrierType) => onCarrierTypesChange(event, cCarrierType) }
                                getOptionLabel={(option) => option ? option.name : ''}
                                renderInput={(params) =>
                                    <TextField
                                    error={ isInputError(errors, "carrier_type") }
                                    helperText={ getErrorMessage(errors, "carrier_type") }
                                    {...params}
                                    label={t('label.carrier_type')}
                                    required={true}
                                />}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            error={ isInputError(errors, prefix + "email") }
                            helperText={ getErrorMessage(errors, prefix + "email") }
                            fullWidth
                            label={t('label.email')}
                            name={prefix + "email"}
                            onChange={handleChange}
                            type="email"
                            value={values[prefix + "email"] || ''}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={ isInputError(errors, prefix + "phone") }
                            helperText={ getErrorMessage(errors, prefix + "phone") }
                            fullWidth
                            label={t('label.phone')}
                            name={prefix + "phone"}
                            onChange={handleChange}
                            value={values[prefix + "phone"] || ''}
                            required
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

Address.propTypes = {
    className: PropTypes.string,
    states: PropTypes.array
};

export default Address;
