import React from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Page from 'components/Page';
import { useLocation } from "react-router-dom";
import { getShipperLogoPath } from 'helpers';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {},
    card: {
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px'
    }
}));

const NewPackageImport = ({ className, shipper, handlePackageClose, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { state } = useLocation();
    let navigate = useNavigate();
    console.log(state);

//    to = { '/app/package/upload/'+shipper.id }

    const imports = [
        {
            alt: 'csv',
            image: 'csv-file.svg',
            condition: (shipper) => shipper.shortcut.toUpperCase() !== 'PACKETA'
        },
        {
            alt: 'xml',
            image: 'xml-file.svg',
            condition: (shipper) => shipper.shortcut.toUpperCase() !== 'PACKETA'
        },
        {
            alt: 'omega',
            image: 'omega.png',
            condition: (shipper) => shipper.shortcut.toUpperCase() !== 'PACKETA'
        },
        {
            alt: 'pohoda',
            image: 'pohoda.png',
            condition: (shipper) => shipper.shortcut.toUpperCase() !== 'PACKETA'
        },
        {
            alt: 'biznisweb',
            image: 'biznisweb.png',
            condition: (shipper) => shipper.shortcut.toUpperCase() !== 'PACKETA'
        },
        {
            alt: 'packeta',
            image: 'packeta_csv.png',
            condition: (shipper) => shipper.shortcut.toUpperCase() === 'PACKETA'
        },
        {
            alt: 'csv-gls-parcelshop',
            image: 'csv-gls-parcelshop.png',
            condition: (shipper) => shipper.shortcut.toUpperCase() === 'GLS'
        },
    ];

    return (
        <Page
            title={t('title.import_packages') + ' - ' + state.shipper.shortcut}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    <img src={getShipperLogoPath(state.shipper.shortcut)} alt={state.shipper.shortcut} height={30} />
                    &nbsp;&nbsp;
                    {t('title.import_packages')}
                </Typography>
                <Grid container spacing={3}>
                    {
                        imports.map(v => 
                            (!v.condition || v.condition(state.shipper)) &&
                            <Grid item className={classes.item} key={v.alt}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Box textAlign="center">
                                            <img src={"/images/" + v.image} alt={v.alt} height={80} width={140} style={{ objectFit: "contain" }} />
                                        </Box>
                                    </CardContent>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => navigate(`/app/package/import/${v.alt}`, {state: state})}
                                    >
                                        {t('button.import')}
                                    </Button>
                                </Card>
                            </Grid>
                        )
                    }
                </Grid>
            </Container>
        </Page>
    );
};

export default NewPackageImport;
