import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    TextField,
    Grid,
    makeStyles,
    Chip,
    CircularProgress,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import AppContext from 'context/AppContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
    root: {
    }
}));

const ComplaintFormSpsDamaged = ({ className, data, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [values, setValues] = useState({
        email: appValues.user.address ? appValues.user.address.email : "",
    });
    const [files, setFiles] = useState([]);
    const [sending, setSending] = useState(false);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const onFilesChange = (event) => {
        setFiles(Array.from(event.target.files));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSending(true);

        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('surname', values.surname);
        formData.append('email', values.email);
        formData.append('problem_description', values.problem_description);
        formData.append('goods_description', values.goods_description);
        for (const file of files) {
            formData.append('files[]', file);
        }
        secureFetch(getApiRoute('package', ['complaint', data.type, data.package.id]), formData, 'POST', true, false).then(json => {
            setSending(null);
        });
    };

    return (
            <form
                autoComplete="off"
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t('label.name')}
                            name="name"
                            onChange={handleChange}
                            value={values.name || ''}
                            required
                        />
                    </Grid>
                     <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t('label.surname')}
                            name="surname"
                            onChange={handleChange}
                            value={values.surname || ''}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t('label.email')}
                            name="email"
                            onChange={handleChange}
                            value={values.email || ''}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t('label.problem_description')}
                            name="problem_description"
                            onChange={handleChange}
                            value={values.problem_description || ''}
                            required
                            multiline
                            rows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t('label.goods_description')}
                            name="goods_description"
                            onChange={handleChange}
                            value={values.goods_description || ''}
                            required
                            multiline
                            rows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            component="label"
                        >
                            {t('button.add_attachment')}
                            <input
                                type="file"
                                name="files"
                                onChange={onFilesChange}
                                multiple
                                hidden
                            />
                        </Button>
                        {
                            files.map((file) => {
                                return(
                                    <Box padding={1} display="inline">
                                        <Chip label={file.name} />
                                    </Box>
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={8}>
                        <Alert severity="warning" className={classes.yMargin}>
                            <strong>Upozornenie:</strong> V prípade, že budete chcieť refundovať aj prepravné, prepravca vyžaduje, aby ste túto požiadavku zapísali do <strong> popisu
                            problému. </strong>
                        </Alert>
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-start" py={2}>

                    {
                        sending === null ?
                        <Alert variant="filled" severity="success">
                            {t('messages.sended')}
                        </Alert> :

                            <Button color="primary" variant="contained" type="submit" disabled={sending}>
                            {t('button.send')}
                            {
                                sending && <CircularProgress size={24} color="primary" />
                            }
                        </Button>
                    }
                </Box>
            </form>
    );
};

export default ComplaintFormSpsDamaged;
