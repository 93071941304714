import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles, Tooltip
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import AppContext from 'context/AppContext';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(() => ({
    root: {}
}));

const InvoiceAddressForm = ({ className, user, loadUser, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [values, setValues] = useState({});
    const { appValues } = useContext(AppContext);
    const [successMessage, setSuccessMessage] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [isDPH, setIsDPH] = useState(user.invoice_address ? user.invoice_address.vatin ? true : false : false);
    const secureFetch = useContext(SecureFetchContext);

    useEffect(() => {
        user.invoice_address = user.invoice_address ? user.invoice_address : {};
        setValues({
            name: user.invoice_address.name,
            company: user.invoice_address.company,
            email: user.invoice_address.email,
            phone: user.invoice_address.phone,
            street: user.invoice_address.street,
            city: user.invoice_address.city,
            zip: user.invoice_address.zip,
            house_number: user.invoice_address.house_number,
            state: user.invoice_address.state ? user.invoice_address.state.id : appValues.states[ appValues.states.findIndex(i => i.code === 'SK') ].id || null,
            oin: user.invoice_address.oin,
            tin: user.invoice_address.tin,
            vatin: user.invoice_address.vatin,
            bank_account: user.invoice_address.bank_account,
            invoice_email: user.invoice_address.invoice_email,
        })
    }, [user, appValues.states]);

    const findByIco = () => {
        secureFetch( getApiRoute('admin_usersettings_findico', [values.oin]) ).then(json => {
            if ( Array.isArray(json) && json.length ) {
                let data = json[0];
                let statutory = '';
                if ( Array.isArray(data['statutory']) && data['statutory'].length ) {
                    statutory = data['statutory'][0];
                }
                if ( data['vatin'] !== null ) {
                    let iban='';
                     secureFetch( getApiRoute('admin_usersettings_findiban', [data['vatin']]) ).then(json => {
                         if (json.code == '404'){
                             iban = '';
                             setIsDPH(false);
                         } else {
                             iban = json.data[0].iban;
                             setIsDPH(true);
                         }
                         setValues({
                             ...values,
                             name: statutory !== '' ? statutory['first_name'] + " " + statutory['last_name'] : '',
                             company: data['name'],
                             street: data['street'] ? data['street'] : data['municipality'],
                             city: data['municipality'],
                             zip: data['postal_code'],
                             house_number: data['street_number'],
                             state: appValues.states[ appValues.states.findIndex(i => i.code === 'SK') ].id,
                             oin: data['cin'],
                             tin: data['tin'],
                             vatin: data['vatin'],
                             bank_account: iban
                         });
                    });
                    setIsDPH(true);
                } else {
                    setValues({
                        ...values,
                        name: statutory !== '' ? statutory['first_name'] + " " + statutory['last_name'] : '',
                        company: data['name'],
                        street: data['street'] ? data['street'] : data['municipality'],
                        city: data['municipality'],
                        zip: data['postal_code'],
                        house_number: data['street_number'],
                        state: appValues.states[ appValues.states.findIndex(i => i.code === 'SK') ].id,
                        oin: data['cin'],
                        tin: data['tin'],
                        vatin: data['vatin']
                    });
                }
                setAlertMessage("");
                setSuccessMessage("Údaje sa aktualizovali na základe registrov. Nezabudnite ich uložiť.");
            }
            else {
                setSuccessMessage("");
                setAlertMessage( "Nenašli sa žiadne informácie. Je IČO správne?" );
            }
        });
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch( getApiRoute('admin_user', [user.id]), { 'invoice_address': values}, 'PUT' ).then(json => {
            setSuccessMessage(t('messages.data_saved'));
            loadUser();
        });
    }

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Card>
                <CardHeader
                    subheader={t('messages.invoice_data_desc')}
                    title={t('title.invoice_data')}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.company')}
                                        name="company"
                                        onChange={handleChange}
                                        value={values.company || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.statutory')}
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.contact_email')}
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.invoice_email')}
                                        name="invoice_email"
                                        onChange={handleChange}
                                        value={values.invoice_email || ''}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.phone')}
                                        name="phone"
                                        onChange={handleChange}
                                        value={values.phone || ''}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item lg={7} xs={8}>
                                    <TextField
                                        fullWidth
                                        label={t('label.street')}
                                        name="street"
                                        onChange={handleChange}
                                        value={values.street || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item lg={5} xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('label.house_number')}
                                        name="house_number"
                                        onChange={handleChange}
                                        value={values.house_number || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.city')}
                                        name="city"
                                        onChange={handleChange}
                                        value={values.city || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t('label.zip')}
                                        name="zip"
                                        onChange={handleChange}
                                        value={values.zip || ''}
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="deliverystates">{t('label.state')}</InputLabel>
                                        <Select
                                            disabled
                                            labelId="deliverystates"
                                            value={ values.state || '' }
                                            name="state"
                                            onChange={handleChange}
                                        >
                                            {appValues.states.map((option) => (
                                                <MenuItem 
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('label.oin')}
                                        name="oin"
                                        onChange={handleChange}
                                        value={values.oin || ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={findByIco}
                                                    >
                                                        <Tooltip title="Aktualizovať/Doplniť údaje podľa IČO" placement="top-start">
                                                            <SearchIcon/>
                                                        </Tooltip>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('label.tin')}
                                        name="tin"
                                        onChange={handleChange}
                                        value={values.tin || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('label.vatin')}
                                        name="vatin"
                                        onChange={handleChange}
                                        value={values.vatin || ''}
                                        disabled={isDPH}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('label.bank_account')}
                                name="bank_account"
                                onChange={handleChange}
                                value={values.bank_account || ''}
                                required
                                multiline
                                disabled={isDPH}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                { 
                    successMessage === '' ? null : 
                    <Box px={2}>
                        <Alert variant="filled" severity="success">
                            {successMessage}
                        </Alert>
                    </Box>

                }
                {
                    alertMessage === '' ? null :
                        <Box px={2}>
                            <Alert variant="filled" severity="error">
                                {alertMessage}
                            </Alert>
                        </Box>

                }
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button color="primary" variant="contained" type="submit">
                        { t('button.save') }
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

InvoiceAddressForm.propTypes = {
    className: PropTypes.string,
    states: PropTypes.array
};

export default InvoiceAddressForm;
