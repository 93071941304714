import React, { useContext } from 'react';
import {
    Container,
    Typography,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Box,
    Button,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import PackagesStep from './PackagesStep';
import { useNavigate, useLocation } from "react-router-dom";
import { getShipperLogoPath } from 'helpers';
import ProgressButton from "../../../components/ProgressButton";
import AppContext from "../../../context/AppContext";

export default function ImportBiznisweb() {
    const { t } = useTranslation();
    const [file, setFile] = React.useState(null);
    const secureFetch = useContext(SecureFetchContext);
    const [alert, setAlert] = React.useState('');
    const [packages, setPackages] = React.useState([]);
    let navigate = useNavigate();
    const { appValues } = useContext(AppContext);
    const { state } = useLocation();


    const inputChange = (e) => {
        setAlert('');
        let promise = createBiznisweb(null, e.target.files[0]);
        setFile(e.target.files[0]);
        return promise;
    };

    const createBiznisweb = (e, pFile = null) => {
        const formData = new FormData();
        formData.append('biznisweb', pFile ? pFile : file);

        if (packages.length > 0) {
            formData.append('fields', JSON.stringify(getPackageFields()));
        }

        let promise = secureFetch(getApiRoute('import', [state.shipper.id, 'biznisweb']), formData, 'POST', false, false);
        promise.then((json) => {
            if (Object.keys(json).length === 0) {
                const uspl = appValues.user.user_shipper_price_lists.find(uspl => uspl.shipper.id === parseInt(state.shipper.id));
                navigate(uspl.sale_price_list.type === 'fix' ? '/app/package/service' : '/app/package');
                return;
            }
            setPackages(json);
            setAlert('');
        }).catch((err) => {
            if (typeof err === 'string') {
                setAlert(err);
            } else {
                setAlert(JSON.stringify(err));
            }
        });

        return promise;
    };

    const getPackageFields = () => {
        let fields = [];
        for (const pack of packages) {
            let tmpFields = {};
            for (const key in pack.package) {
                tmpFields[key] = pack.package[key];
            }
            tmpFields.pickup_address = state.pickup.id;
            fields.push(tmpFields);
        }
        return fields;
    }

    return (
        <Page
            title={t('title.import_packages') + ' - ' + state.shipper.shortcut}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    <img src={getShipperLogoPath(state.shipper.shortcut)} alt={state.shipper.shortcut} height={30} />
                    &nbsp;&nbsp;
                    {t('title.import_packages')}
                </Typography>
                <Card>
                    <CardHeader
                        title={"Biznisweb"}
                    />
                    <Divider />
                    <CardContent>
                        {
                            alert === '' ? null :
                                <Box pb={2}>
                                    <Alert variant="filled" severity="error">
                                        {alert}
                                    </Alert>
                                </Box>
                        }
                        {
                            packages.length > 0  ?
                            <form onSubmit={(e) => e.preventDefault()}>
                                <PackagesStep packages={packages} setPackages={setPackages}  shipper={state.shipper.shortcut} />
                                <Box pb={2}/>
                                <ProgressButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    name={t('button.save')}
                                    onClick={createBiznisweb}
                                />
                            </form>
                            :
                            <ProgressButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                name={ t('button.choose_file') }
                                acceptFile=".xml"
                                file={true}
                                onClick={inputChange}
                            />
                        }
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
}