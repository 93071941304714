import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Tooltip,
    Box,
    IconButton,
    makeStyles
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AddIcon from '@material-ui/icons/Add';
import { NavLink as RouterLink } from 'react-router-dom';
import DeleteIconDialog from 'components/DeleteIconDialog';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const ShipperTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);

    const loadShippers = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('shippers') ).then(json => {
            setRows(json);
            setLoading(false);
        });
    }, [secureFetch])

    useEffect(() => {
        loadShippers();
    }, [loadShippers]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'name'
        },
        headCells: [
            { id: 'name', numeric: false, label: t('label.name'), search: true },
            { id: 'shortcut', numeric: false, label: t('label.shortcut'), search: true },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                return (
                    <Box display="flex">
                        <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')}>
                            <IconButton
                                aria-label={t('tooltip.edit')}
                                className={classes.rowButtons}
                                component={RouterLink}
                                size="small"
                                to={"/app/admin/shipper/" + data.id}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <DeleteIconDialog
                            text={
                                <React.Fragment>
                                    {t('messages.really_delete')}
                                    <br />
                                    <pre>
                                        {JSON.stringify(data, null, 2)}
                                    </pre>
                                </React.Fragment>
                            }
                            route={getApiRoute('admin_shipper', [data.id])}
                            onDelete={loadShippers}
                        />
                    </Box>
                );
            } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        size="small"
                        startIcon={<AddIcon />}
                        component={RouterLink}
                        to={'/app/admin/shipper/new'}
                    >
                        {t('button.create_shipper')}
                    </Button>
                </React.Fragment>
            );
        }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default ShipperTable;