import React, { useState, useContext, useEffect } from 'react';
import {
    Typography,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Divider,
    makeStyles,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { formatNumber } from 'helpers';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { NavLink as RouterLink } from 'react-router-dom';
import DeleteIconDialog from 'components/DeleteIconDialog';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {},
    iconCell: {
        paddingTop: '3px',
        paddingBottom: 0,
    }
}));


const AdditionalInfoPreview = ({ className, values, loadPackage, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [services, setServices] = useState([]);
    let navigate = useNavigate();

    const onClickOldTracking = (tracking_number, old) => {
        navigate(`/tracking/${tracking_number}`, { state: {
                old: old
            } });
    }

    useEffect(() => {
        if (!services.length) {
            secureFetch(getApiRoute('services')).then(json => {
                setServices(json);
            });
        }
    }, [secureFetch, services]);

    const getServiceNameByCode = (code) => {
        let service = services.find(s => s.code === code);
        return service ? service.name : code;
    }

    const printServiceValue = (serviceValue) => {
        if(serviceValue.constructor.name === "Object"){
            return Object.values(serviceValue).join(', ');
        } else {
            return serviceValue;
        }
    }

    const deleteService = (service) => {
        return secureFetch(getApiRoute('admin_package', ['deleteservice', values.id, service]), {}, 'POST');
    };

    const afterDeleteService = (service) => {
        loadPackage();
    };

    return (
        <Card style={{ height: '100%' }}>
            <CardHeader
                title={t('title.additiona_info')}
                className={className}
            />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography component="h3" color="primary">
                                            {t('title.additiona_info')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" variant="head">
                                        {t('label.reference_number')}
                                    </TableCell>
                                    <TableCell>
                                        {values.reference_number}
                                    </TableCell>
                                </TableRow>
                                {
                                    values.shipper && ['GLS', '123', 'DPD'].includes(values.shipper.shortcut.toUpperCase()) &&
                                    <TableRow>
                                        <TableCell component="th" variant="head">
                                            {t('label.cod_reference')}
                                        </TableCell>
                                        <TableCell>
                                            {values.cod_reference}
                                        </TableCell>
                                    </TableRow>
                                }
                                <TableRow>
                                    <TableCell component="th" variant="head">
                                        {t('label.tracking_number')}
                                    </TableCell>
                                    <TableCell>
                                        {values.tracking_number}
                                    </TableCell>
                                </TableRow>
                                {
                                    values.others && values.others.old_tracking_number &&
                                        <TableRow>
                                            <TableCell component="th" variant="head">
                                                {t('label.old_tracking_number')}
                                            </TableCell>
                                            <TableCell>
                                                {values.others.old_tracking_number}
                                                <IconButton
                                                    aria-label={t('tooltip.tracking')}
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    className={classes.rowButtons}
                                                    size="small"
                                                    onClick={() => onClickOldTracking(values.tracking_number, 'old')}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                }
                                <TableRow>
                                    <TableCell component="th" variant="head">
                                        {t('label.cod_price')}
                                    </TableCell>
                                    <TableCell>
                                        {values.cod_price > 0 ? formatNumber(values.cod_price, 2) + ' ' + values.cod_currency_code : ''}
                                    </TableCell>
                                </TableRow>
                                {
                                    values.others && values.others.old_cod_price &&
                                    <TableRow>
                                        <TableCell component="th" variant="head">
                                            {t('label.old_cod_price')}
                                        </TableCell>
                                        <TableCell>
                                            {formatNumber(values.others.old_cod_price, 2) + ' ' + values.cod_currency_code}
                                        </TableCell>
                                    </TableRow>
                                }
                                <TableRow>
                                    <TableCell component="th" variant="head">
                                        {t('label.insurance')}
                                    </TableCell>
                                    <TableCell>
                                        {values.insurance}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" variant="head">
                                        {t('label.reverse')}
                                    </TableCell>
                                    <TableCell className={classes.iconCell}>
                                        {values.reverse ? <CheckIcon fontSize="small"/> : <ClearIcon fontSize="small"/>}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" variant="head">
                                        {t('label.status')}
                                    </TableCell>
                                    <TableCell>
                                        {values.statuses && values.statuses.length > 0 ? values.statuses.pop().status_type.name : '-'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" variant="head">
                                        {t('label.weight')}
                                    </TableCell>
                                    <TableCell>
                                        {values.weight ? formatNumber(values.weight, 3) : '-'}
                                        {values.total_weight && parseFloat(values.total_weight) !== parseFloat(values.weight) ? `(${formatNumber(values.total_weight, 3)})` : ''}
                                        &nbsp;kg
                                    </TableCell>
                                </TableRow>
                                {
                                    values.others && values.others.pickup_date &&
                                    <TableRow>
                                        <TableCell component="th" variant="head">
                                            {t('label.pickup_date')}
                                        </TableCell>
                                        <TableCell>
                                            {values.others.pickup_date}
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography component="h3" color="primary">
                                            {t('title.services')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    values.services && Object.keys(values.services).map((key) => {
                                        return (
                                            values.services[key] === true &&
                                            <React.Fragment key={key}>
                                                <TableRow >
                                                    <TableCell component="th" variant="head">
                                                        {getServiceNameByCode(key)}
                                                    </TableCell>
                                                    <TableCell className={classes.iconCell}>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            {<CheckIcon fontSize="small" />}
                                                            <DeleteIconDialog
                                                                onDelete={afterDeleteService}
                                                                customDelete={() => deleteService(key)}
                                                                text={t('messages.delete_service', { service: getServiceNameByCode(key) })}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    values.services[key+'value'] &&
                                                        <TableRow>
                                                            <TableCell component="th" variant="head">
                                                            </TableCell>
                                                            <TableCell className={classes.iconCell}>
                                                                {printServiceValue(values.services[key+'value'])}
                                                            </TableCell>
                                                        </TableRow>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                    {
                        values.packages && values.packages.length > 0 &&
                        <Grid item xs={12}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <Typography component="h3" color="primary">
                                                {t('title.subpackages')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" variant="head">
                                            {t('label.reference_number')}
                                        </TableCell>
                                        <TableCell component="th" variant="head">
                                            {t('label.tracking_number')}
                                        </TableCell>
                                        <TableCell component="th" variant="head">
                                            {t('label.weight')}
                                        </TableCell>
                                        <TableCell component="th" variant="head">
                                            {t('label.actions')}
                                        </TableCell>
                                    </TableRow>
                                    {
                                        values.packages.map((p, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {p.reference_number}
                                                    </TableCell>
                                                    <TableCell>
                                                        {p.tracking_number}
                                                    </TableCell>
                                                    <TableCell>
                                                        {p.weight ? parseFloat(p.weight).toFixed(2) + ' kg' : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            p.tracking_number &&
                                                            <Tooltip title={t('tooltip.tracking')} aria-label={t('tooltip.tracking')}>
                                                                <IconButton
                                                                    aria-label={t('tooltip.tracking')}
                                                                    className={classes.rowButtons}
                                                                    target="_blank"
                                                                    size="small"
                                                                    component={RouterLink}
                                                                    to={"/tracking/" + p.tracking_number}
                                                                >
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    }
                </Grid>

            </CardContent>
        </Card>

    );
};

export default AdditionalInfoPreview;
