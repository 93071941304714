import React from 'react';
import { stateToHTML } from 'draft-js-export-html';
import i18n from "./i18n";

export const rteStateToHtml = (state) => {
    let options = {
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            if (entityType === 'image') {
                const data = entity.getData();
                return {
                    element: 'img',
                    attributes: {
                        src: data.url,
                        width: data.width,
                        height: data.height,
                    },
                    style: {
                        // Put styles here...
                    },
                };
            }
        },
    };
    return stateToHTML(state.getCurrentContent(), options);
}

export const formatNumber = (num, maxDecimal = null) => {
    if (maxDecimal) {
        num = parseFloat(num).toFixed(maxDecimal);
    }
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const getLastNDaysDates = (days) => {
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let result = [];

    for (let i = 0; i < days; i++) {
        let d = new Date();
        d.setDate(d.getDate() - i);
        result.push( d.getDay() + ' ' + months[d.getMonth()] )
    }
    return result;
}

export const isInputError = (errors, fieldname) => {
    return errors[fieldname] && errors[fieldname].length > 0;
};

export const getErrorMessage = (errors, fieldname) => {
    if (typeof errors[fieldname] === 'string') {
        return errors[fieldname];
    }
    return errors[fieldname] && errors[fieldname].length > 0 ? errors[fieldname].join(', ') : '';
};

export const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const formatDate = (date, time = false) => {
    if (date) {
        let d = new Date(date.replace(/ /g, "T")),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        let dateString = [day, month, year].join('.');

        if (time) {
            let hour = ('0' + (d.getHours())).slice(-2),
                minute = ('0' + d.getMinutes()).slice(-2),
                seconds = ('0' + d.getSeconds()).slice(-2);
            let timeString = [hour, minute, seconds].join(':');
            return `${dateString} ${timeString}`;
        }

        return dateString;
    }

    return '';

   /*  if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day; */

}

export const normalizeDate = (date) => {
    let tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    return new Date(date - tzoffset).toISOString();
}

export const createUrlQuery = (data) => {
    let arrayConditions = '';
    let params = new URLSearchParams();

    for (const key in data) {

        if (data[key] === null) {
            continue;
        }

        if (Array.isArray(data[key])) {
            arrayConditions += data[key].map(function (el, idx) {
                return key + '[]=' + el;
            }).join('&');
            delete data[key];
        }
        else if (data[key] instanceof Date) {
            const tzoffset = data[key].getTimezoneOffset() * 60000; //offset in milliseconds
            const normalizedDate = (new Date(data[key] - tzoffset)).toISOString();
            params.append(key, normalizedDate);
        }
        else {
            params.append(key, data[key]);
        }
    }

    return params.toString().length ? params.toString() + '&' + arrayConditions : arrayConditions;
};

export const getShipperLogoPath = (shipper) => {
    switch (shipper.toUpperCase()) {
        case "GLS":
            return '/images/gls_logo.png';
        case "SPS":
            return '/images/sps_logo.png';
        case "PACKETA":
            return '/images/packeta_logo.png';
        case "PACKETAHD":
            return '/images/packetahd_logo.png';
        case "123":
            return '/images/123kurier_logo.png';
        case "DPD":
            return '/images/dpd_logo.png';
        case "QDL":
            return '/images/qdl_logo.png';
        default:
            return "";
    }
};

export const getShipperIcon = (data) => {
    return <img alt="shipper" style={{verticalAlign:'middle'}} height="16" src={getShipperLogoPath(data.shipper)} />
};

export const filterStates = (states, prop) => {
    // example of prop - "SK, PL, CZ" or "SK"
    const dataStates = prop.split(", ");
    return states.filter(state => dataStates.includes(state.code))
}

export const formatErrors = (json, t) => {
    return Object.keys(json).map((key, index) =>
        <React.Fragment key={index}>
            {
                json[key].length > 0 &&
                <p>
                    {t(`label.${key}`) + ": " + (Array.isArray(json[key]) ? json[key].join(', ') : json[key])}
                </p>
            }
        </React.Fragment>
    );
};

export const isUser = (user) => {
    return user.roles.includes('ROLE_USER');
}

export const isAdmin = (user) => {
    return user.roles.includes('ROLE_ADMIN');
}

export const isPartner = (user) => {
    return user.roles.includes('ROLE_PARTNER');
}

export const hexToRgbA = (hex, opacity= 1) => {
    let c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+ opacity +')';
    }
    console.log(`Bad Hex: ${hex}`);
    return 'rgba(0, 0, 0, 1)';
}

export const isRevExp = (p) => { // is exported reverse package ??
    return p.reverse && !!p.exported_at;
}

export const isExp = (p) => { // is package exported ??
    return !!p.exported_at;
}

export const openNewTab = (url) => {
    window.open(url);
}

export const getNeoshipServiceTypes = () => {
    return [
        {
            value: 20,
            name: "Debet"
        },
        {
            value: 21,
            name: i18n.t('label.activation')
        },
        {
            value: 22,
            name: i18n.t('label.shipper_add')
        },
        {
            value: 23,
            name: i18n.t('label.service_0_100')
        },
        {
            value: 24,
            name: i18n.t('label.service_101_700')
        },
        {
            value: 25,
            name: i18n.t('label.service_701')
        },
        {
            value: 26,
            name: i18n.t('label.programming_fee')
        }
    ]
}

export const isFix = (price_list) => {
    return price_list.sale_price_list.type === 'fix'
}

export const isStandard = (price_list) => {
    return price_list.sale_price_list.type === 'sale'
}

export const hasService = (user) => {
    return user.user_shipper_price_lists.find(isFix)
}

export const hasStandard = (user) => {
    return user.user_shipper_price_lists.find(isStandard)
}

export const getService = (user) => {
    return user.user_shipper_price_lists.filter(isFix)
}

export const getStandard = (user) => {
    return user.user_shipper_price_lists.filter(isStandard)
}

export const getDefaultAddress = (user) => {
    if (!user.address) {
        return undefined;
    }
    return user.additional_pickup_addresses.find(addr => addr.address.id === user.address.id);
}

//only enabled pickups
export const getAllPickups = (user, includeDefault=true) => {
    const exceptDefault = user.additional_pickup_addresses.filter(addr => addr.address.id !== user.address.id && addr.active);
    if(includeDefault){
        return [getDefaultAddress(user), ...exceptDefault];
    } else {
        return exceptDefault;
    }
}

//include disabled pickups also
export const getAllAdminPickups = (user, includeDefault=true) => {
    const exceptDefault = user.additional_pickup_addresses.filter(addr => addr.address.id !== user.address.id);
    if(includeDefault){
        return [getDefaultAddress(user), ...exceptDefault];
    } else {
        return exceptDefault;
    }
}

export const getCarrierTypes = () => {
    return [
        {
            value: null,
            name: ""
        },
        {
            value: 131,
            name: i18n.t('label.SK_PACKETA_HOME_HD')
        },
        {
            value: 132,
            name: i18n.t('label.SK_PACKETA_BRATISLAVA_HD')
        },
        {
            value: 106,
            name: i18n.t('label.CZ_PACKETA_HOME_HD')
        },
        {
            value: 136,
            name: i18n.t('label.CZ_PACKETA_BRNO_HD')
        },
        {
            value: 134,
            name: i18n.t('label.CZ_PACKETA_OSTRAVA_HD')
        },
        {
            value: 18928,
            name: i18n.t('label.CZ_PACKETA_PRAHA_HD')
        },
        {
            value: 4159,
            name: i18n.t('label.HU_PACKETA_HOME_HD')
        },
        {
            value: 805,
            name: i18n.t('label.HU_DPD_HD')
        },
        {
            value: 763,
            name: i18n.t('label.HU_HUNGARY_POST_HD')
        },
        {
            value: 590,
            name: i18n.t('label.RO_CARGUS_HD')
        },
        {
            value: 4161,
            name: i18n.t('label.RO_PACKETA_HOME_HD')
        },
        {
            value: 762,
            name: i18n.t('label.RO_FAN_COURIER_HD')
        },
        {
            value: 7455,
            name: i18n.t('label.RO_SAMEDAY_BOX')
        },
        {
            value: 7397,
            name: i18n.t('label.RO_SAMEDAY_HD')
        }
    ]
}

export const getFilteredCarrierTypes = (filtered) => {
    let ctFiltered = filtered.map( itemF => { return itemF.carrier_type; });
    let filteredCarrierTypes = getCarrierTypes().filter( itemCT => ctFiltered.includes(itemCT.value));
    return filteredCarrierTypes;
}