import React, {useContext} from 'react';
import {
    Typography,
    Grid,
    makeStyles, Box
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { Package as PackageIcon } from 'react-feather';
import Button from '@material-ui/core/Button';
import {NavLink as RouterLink, useNavigate} from 'react-router-dom';
import { getShipperLogoPath } from "helpers";
import PickupAddressSelector from "../../../components/PickupAddressSelector";
import AppContext from "../../../context/AppContext";

const useStyles = makeStyles(() => ({
    root: {},
}));

const NewPackageShipper = ({ className, shipper, handlePackageClose, address, pickup, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appValues } = useContext(AppContext);
    const navigate = useNavigate();

    return (
        <Grid container spacing={3} alignItems="center" >
            <Grid item xs={12} md={6}>
                <Typography variant="h1" component="h2" color="primary" align="center">
                    <img width={150} src={getShipperLogoPath(shipper.shortcut)} alt={shipper.shortcut}/>
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                {
                    !['123'].includes(shipper.shortcut.toUpperCase()) &&
                    <React.Fragment>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<PackageIcon />}
                            size="small"
                            component={RouterLink}
                            state={{pickup: pickup}}
                            to={'/app/package/newaddress/' + shipper.id + '/' + address}
                            onClick={handlePackageClose}
                            fullWidth
                        >
                            {t('button.to_address')}
                        </Button>
                        <Box p={1} />
                    </React.Fragment>
                }
                {
                    ['123'].includes(shipper.shortcut.toUpperCase()) && appValues.user.additional_pickup_addresses.length > 1 &&
                    <React.Fragment>
                        <PickupAddressSelector isSelect={false} onSelect={(addr) => {
                            navigate("/app/package/new/" + shipper.id + '/', {state: {pickup: addr}});
                            handlePackageClose();
                        }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<PackageIcon />}
                                size="small"
                                state={{pickup: pickup}}
                                fullWidth
                            >
                                {t('button.to_address')}
                            </Button>
                        </PickupAddressSelector>

                        <Box p={1} />
                    </React.Fragment>
                }
                {
                    ['123'].includes(shipper.shortcut.toUpperCase()) && appValues.user.additional_pickup_addresses.length === 1 &&
                    <React.Fragment>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<PackageIcon />}
                            size="small"
                            component={RouterLink}
                            state={{pickup: pickup}}
                            to={'/app/package/newaddress/' + shipper.id + '/' + address}
                            onClick={handlePackageClose}
                            fullWidth
                        >
                            {t('button.to_address')}
                        </Button>
                        <Box p={1} />
                    </React.Fragment>
                }

            </Grid>
        </Grid>
    );
};

export default NewPackageShipper;
