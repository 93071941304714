import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Box,
    Tooltip,
    makeStyles
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AddIcon from '@material-ui/icons/Add';
import { NavLink as RouterLink } from 'react-router-dom';
import DeleteIconDialog from 'components/DeleteIconDialog';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {getNeoshipServiceTypes} from "../../helpers";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import NewServiceNeoshipForm from "./NewServiceNeoshipForm";
import EditServiceNeoshipForm from "./EditServiceNeoshipForm";

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const ServiceNeoshipTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openEditServiceDialog, setOpenEditServiceDialog] = React.useState(false);
    const [editServiceData, setEditServiceData] = useState({});

    const load = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('admin_service_neoship') ).then(json => {
            setRows(json);
            setLoading(false);
        });
    }, [secureFetch])

    const handleDialogOpenClose = () => {
        setOpenCreateDialog(!openCreateDialog);
    };

    const handleEditDialogOpenClose = (data) => {
        if(openEditServiceDialog) {
            setOpenEditServiceDialog(false);
        } else {
            setEditServiceData(data);
            setOpenEditServiceDialog(true);
        }
    };

    useEffect(() => {
        load();
    }, [load]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'type'
        },
        headCells: [
            { id: 'id', numeric: true, label: t('label.id'), search: false, hide: true },
            { id: 'type', numeric: false, label: t('label.type'), search: false, render: (data) => getNeoshipServiceTypes().find((val) => val.value === parseInt(data.type)).name},
            { id: 'price', numeric: false, label: t('label.price'), search: false, render: (data) => data.price  + ' €' },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                    return (
                        <Box display="flex">
                            <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')} onClick={() => {handleEditDialogOpenClose(data)}}>
                                <IconButton
                                    size="small"
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <DeleteIconDialog
                                text={
                                    <React.Fragment>
                                        {t('messages.really_delete')}
                                        <br/>
                                        <pre>
                                        {JSON.stringify(data, null, 2)}
                                    </pre>
                                    </React.Fragment>
                                }
                                route={getApiRoute('admin_service_neoship', [data.id])}
                                onDelete={load}
                            />
                        </Box>
                    );
                } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleDialogOpenClose}
                    >
                        {t('button.create_service')}
                    </Button>
                </React.Fragment>
            );
        }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
            <Dialog
                fullWidth
                open={openCreateDialog}
                onClose={handleDialogOpenClose}
                maxWidth="sm"
            >
                <DialogContent>
                    <NewServiceNeoshipForm handleDialogOpenClose={handleDialogOpenClose} load={load} />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openEditServiceDialog}
                onClose={handleEditDialogOpenClose}
                maxWidth="md"
            >
                <DialogContent>
                    <EditServiceNeoshipForm service={editServiceData} handleEditDialogOpenClose={handleEditDialogOpenClose} load={load} />
                </DialogContent>
            </Dialog>
        </Card>
    );
}

export default ServiceNeoshipTable;