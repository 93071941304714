import React from 'react';
import {Switch, Grid, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const AntSwitchSlider = withStyles((theme) => ({
    root: {
        width: 40,
        height: 20,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.primary.main,
        '&$checked': {
            transform: 'translateX(20px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 16,
        height: 16,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

const AntSwitch = ({name, leftLabel, leftValue, rightLabel, rightValue, checked, onChange, ...rest}) => {
    leftLabel = leftLabel || leftValue;
    rightLabel = rightLabel || rightValue;

    return (
        <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>{leftLabel}</Grid>
                <Grid item>
                    <AntSwitchSlider
                        checked={checked()}
                        name={name}
                        onChange={
                            (event) => {
                                const tmpEvent = {
                                    target: {
                                        name: event.target.name,
                                        value: event.target.checked ? rightValue : leftValue
                                    }
                                }
                                onChange(tmpEvent);
                            }
                        }
                    />
                </Grid>
                <Grid item>{rightLabel}</Grid>
            </Grid>
        </Typography>
    );
}

export default AntSwitch;