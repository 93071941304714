import React, { useState, useCallback, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    makeStyles
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import Button from '@material-ui/core/Button';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import NewStatusForm from './NewStatusForm';
import DeleteIconDialog from 'components/DeleteIconDialog';
import { formatDate } from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const ParcelshopTable = ({ className, id, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleDialogkOpenClose = () => {
        setOpenDialog(!openDialog);
    };

    const loadStatuses = useCallback(() => {
        setLoading(true);
        secureFetch(getApiRoute('admin_status', [id] ) ).then(json => {
            setRows(json);
            setLoading(false);
        })
    }, [secureFetch, id])
    
    useEffect(() => {
        loadStatuses();
    }, [loadStatuses]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'desc',
            orderBy: 'created_at'
        }, 
        headCells: [
            { id: 'created_at', numeric: false, label: t('label.created_at'), search: true, searchType: 'dateRange', disableOrder: true, render: (data) => formatDate(data.created_at, true) },
            { id: 'name', numeric: false, label: t('label.title'), search: true, disableOrder: true },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                return (
                    <DeleteIconDialog
                        text={
                            <React.Fragment>
                                {t('messages.really_delete')}
                                <br />
                                <pre>
                                    {JSON.stringify(data, null, 2)}
                                </pre>
                            </React.Fragment>
                        }
                        route={getApiRoute('admin_status', [data.id])}
                        onDelete={loadStatuses}
                    />
                );
            } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleDialogkOpenClose}
                    >
                        {t('button.create_status')}
                    </Button>
                </React.Fragment>
            );
        }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
            <Dialog
                open={openDialog}
                onClose={handleDialogkOpenClose}
                maxWidth="md"
                disableEnforceFocus
            >
                <DialogContent>
                    <NewStatusForm handleDialogkOpenClose={handleDialogkOpenClose} id={id} loadStatuses={loadStatuses} />
                </DialogContent>
            </Dialog>
        </Card>
    );
}

export default ParcelshopTable;