import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { Bar } from 'react-chartjs-2';

const PackageCountStatistics = ({ params, ...rest }) => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [data, setData] = useState([]);
    const [sum, setSum] = useState(1);

    useEffect(() => {
        secureFetch(getApiRoute('admin_statistics', ['profitpackagecount']) + '?' + params).then(json => {
            let sum = 0;
            for (const val of json) {
                sum += +val.count;
            }
            setSum(sum);
            setData(json);
        });
    }, [secureFetch, params]);

    const backgroundColor = [
        'rgba(32, 87, 144, 0.6)',
        'rgba(88, 168, 60, 0.6)',
        'rgba(230, 96, 34, 0.6)',
        'rgba(250, 164, 25, 0.6)',
        'rgba(240, 252, 47, 0.6)',
        'rgba(70, 247, 247, 0.6)',
        'rgba(218, 191, 244, 0.6)',
        'rgba(241, 92, 128, 0.6)',
        'rgba(228, 211, 84, 0.6)',
        'rgba(43, 144, 143, 0.6)',
    ];

    const borderColor = [
        'rgba(32, 87, 144, 1)',
        'rgba(88, 168, 60, 1)',
        'rgba(230, 96, 34, 1)',
        'rgba(250, 164, 25, 1)',
        'rgba(240, 252, 47, 1)',
        'rgba(70, 247, 247, 1)',
        'rgba(218, 191, 244, 1)',
        'rgba(241, 92, 128, 1)',
        'rgba(228, 211, 84, 1)',
        'rgba(43, 144, 143, 1)',
    ];

    const chartData = {
        labels: data.map((val) => val.eshop || ''),
        datasets: data.length === 0 ? null : data.map((val, index) => {
            return {
                label: val.eshop || '',
                data: data.map((v) => {
                    return v.username === val.username ? v.count : 0
                }),
                backgroundColor: backgroundColor[index],
                borderColor: borderColor[index],
            }
        })
    }

    const options = {
        scales: {
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem) {
                    return t('label.packages') + ': ' + parseFloat(data[tooltipItem.index].count).toFixed(2) + ' (' + (data[tooltipItem.index].count / sum * 100).toFixed(2) + '%)';
                }
            }
        }
    }

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <Typography gutterBottom variant="h4" component="h2" align="center">
                    {t('title.statistics_profit_by_packages_count')}
                </Typography>
                <Bar data={chartData} options={options} redraw/>
            </CardContent>
        </Card>
    );
}

export default PackageCountStatistics;