import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from "react-i18next";
import {
    Grid, Typography,
} from '@material-ui/core';
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import sk from "date-fns/locale/sk";
import {DatePicker, LocalizationProvider} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getAllPickups} from "../../../helpers";
import AppContext from "../../../context/AppContext";

export default function PackagePrintProtocolFromDate({ buttonTitle, dialogTitle, setErrors, loadPackages, date, setDate, pickup, setPickup, handleClose, selectedShipper, ...rest }) {
    const { appValues } = useContext(AppContext);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {selectedShipper === 'PACKETA' &&
                <Typography variant='body1'>
                    Zvoľte <strong>dátum vytvorenia</strong> zásielok, od ktorého sa má pre dané zásielky vygenerovať preberací protokol.
                </Typography>
            }
            {selectedShipper === 'QDL' &&
                <Typography variant='body1'>
                    Zvoľte <strong>dátum zvozu</strong> zásielok, pre ktoré sa má vygenerovať preberací protokol.
                </Typography>
            }
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={DateFnsAdapter} locale={sk}>
                            <DatePicker
                                autoOk
                                ampm={false}
                                disableFuture={selectedShipper === 'PACKETA'}
                                disablePast={selectedShipper === '123'}
                                onChange={(val) => setDate(val)}
                                value={date || ''}
                                label={selectedShipper === 'PACKETA' ? t('label.date') : t('label.pickup_date')}
                                inputFormat="dd.MM.yyyy"
                                renderInput={(params) => <TextField
                                    {...params}
                                    fullWidth
                                    required
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {getAllPickups(appValues.user).length > 1 &&
                        <Grid item xs={6}>
                            <Autocomplete
                                options={getAllPickups(appValues.user)}
                                getOptionLabel={(option) => `${option.address.city}, ${option.address.street}`}
                                value={pickup}
                                onChange={(event, selected, reason) => {
                                    setPickup(selected);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={t('label.pickup_address')}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    }
                </Grid>
            </form>
        </React.Fragment>
    );
}
