import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ClientTableHead from 'components/ClientTableHead';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExportButtons from './ExportButtons';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    row: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
    table: {
        overflow: 'hidden'
    }
}));

const preprocessFilterState = (s, settings) => {
    let tmpFilter = {};
    if (s) {
        if (s.last_status && s.last_status.length) {
            tmpFilter.last_status = s.last_status.map(v => v.id);
        }
    }
    if (settings.loadFilterDate) {
        tmpFilter = {...tmpFilter, ...settings.loadFilterDate};
    }
    return tmpFilter;
};

const ServerTable = ({ settings, ...rest }) => {
    const { headCells, loading, rows, colOrder, rowsLength, handleTableUpdate, rowClass, handleRowClick, topRightButtons, addFilter, exportData, topLeftButtons } = settings;
    const rowsPerPageInitial = [50, 100, 200];
    const classes = useStyles();
    const { state } = useLocation();
    const [parameters, setParameters] = React.useState({
        order: colOrder.order,
        order_by: colOrder.order_by,
        rows_per_page: rowsPerPageInitial[0],
        page: 0,
    });
    const hasSubRows = settings.subRow ? true : false;
    const [filter, setFilter] = React.useState( preprocessFilterState(state, settings) );

    if (settings.refFilter) {
        settings.refFilter.current = filter;
    }

    useEffect(() => {
        handleTableUpdate({...filter, ...parameters});
    }, [filter, parameters, handleTableUpdate]);


    const updateParameters = ( pParameters ) => {
        setParameters({...parameters, ...pParameters});
    }

    const filterChange = ( pFilter ) => {
        let normalizedFilter = {};
        for (const key in pFilter) {
            if ( typeof pFilter[key] === 'string' ) {
                normalizedFilter[key] = String(pFilter[key]).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            }
            else if ( pFilter[key] instanceof Date ) {
                var tzoffset = pFilter[key].getTimezoneOffset() * 60000; //offset in milliseconds
                normalizedFilter[key] = (new Date(pFilter[key] - tzoffset)).toISOString();
            }
            else{
                normalizedFilter[key] = pFilter[key];
            }
        }
        let tmpParams = {...parameters, ...normalizedFilter};
        setFilter({ ...tmpParams});
    };

    const handleRequestSort = (event, property) => {
        const isAsc = parameters.order_by === property && parameters.order === 'asc';
        updateParameters({
            order: isAsc ? 'desc' : 'asc',
            order_by: property
        })
    };

    const handleChangePage = (event, newPage) => {
        updateParameters({
            page: newPage,
        })
    };

    const handleChangeRowsPerPage = (event) => {
        updateParameters({
            page: 0,
            rows_per_page: parseInt(event.target.value, 10)
        })
    };

    return (
        <div className={classes.root}>
            { loading && <LinearProgress style={{ marginBottom: '1rem' }} />}
            <TableContainer>
                <Table
                    className={classes.table}
                    size={'small'}
                    aria-label="simple table"
                >
                    <ClientTableHead
                        classes={classes}
                        order={parameters.order}
                        orderBy={parameters.order_by}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                        filterChange={filterChange}
                        timeoutTime={500}
                        topRightButtons={topRightButtons}
                        addFilter={addFilter}
                        exportData={exportData}
                        topLeftButtons={topLeftButtons}
                        hasSubRows={hasSubRows}
                        filter={filter}
                        setFilter={setFilter}
                        pagination={
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageInitial}
                                component="div"
                                count={rowsLength}
                                rowsPerPage={parameters.rows_per_page}
                                page={parameters.page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        }
                    />
                    <TableBody>
                        {rows.map( (row, index) => {
                            let subRowData = hasSubRows ? settings.subRow(row) : false;
                                return (
                                    <React.Fragment key={index}>
                                        <TableRow
                                            onClick={handleRowClick ? (event) => handleRowClick(event, row) : null}
                                            role="checkbox"
                                            tabIndex={-1}
                                            className={`${classes.row} ${index % 2 ? 'rowbg' : null} ${rowClass ? rowClass(row) : null} `}
                                            key={index}
                                        >
                                            {
                                                hasSubRows &&
                                                <TableCell>
                                                    {
                                                        subRowData &&
                                                        <IconButton aria-label="expand row" size="small" onClick={() => settings.subRow(row, false)}>
                                                            {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            }
                                            {headCells.map((cell, index) => {
                                                return (
                                                    cell.hide ? null :
                                                        <TableCell className={cell.id} key={index} align={cell.numeric ? 'right' : 'left'} >
                                                            { cell.render ? cell.render(row) : row[ cell.id ] }
                                                        </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={3} justify="flex-end" alignItems="center">
                <Grid item xs={'auto'}>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageInitial}
                        component="div"
                        count={rowsLength}
                        rowsPerPage={parameters.rows_per_page}
                        page={parameters.page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} justify="space-between">
                <Grid item xs={'auto'} style={{ textAlign: 'right' }}>
                    {
                        topLeftButtons && topLeftButtons()
                    }
                </Grid>
                <Grid item xs={'auto'} style={{ textAlign: 'right' }}>
                    {
                        topRightButtons && !exportData && topRightButtons()
                    }
                    {
                        exportData && <ExportButtons topRightButtons={topRightButtons} exportData={exportData} filter={filter} />
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default ServerTable;