import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Tooltip,
    Box,
    IconButton,
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AddIcon from '@material-ui/icons/Add';
import { NavLink as RouterLink } from 'react-router-dom';
import DeleteIconDialog from 'components/DeleteIconDialog';
import EditIcon from '@material-ui/icons/Edit';
import AppContext from 'context/AppContext';

const PartnerCommissionTable = ({ className, ...rest }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);

    const loadCommisions = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('admin_partnercommission') ).then(json => {
            setRows(json);
            setLoading(false);
        });
    }, [secureFetch])

    useEffect(() => {
        loadCommisions();
    }, [loadCommisions]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'eshop'
        },
        headCells: [
            { id: 'eshop', numeric: false, label: t('label.eshop_name'), search: true },
            { id: 'type', numeric: false, label: t('label.type'), search: true, render: (data) => appValues.commissions.find((val) => val.value === parseInt(data.type)).name },
            { id: 'value', numeric: false, label: t('label.value'), search: true, render: (data) => data.value + ' €' },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                return (
                    <Box display="flex">
                        <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')}>
                            <IconButton
                                aria-label={t('tooltip.edit')}
                                component={RouterLink}
                                size="small"
                                to={"/app/admin/partnercommission/" + data.id}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <DeleteIconDialog
                            text={
                                <React.Fragment>
                                    {t('messages.really_delete')}
                                    <br />
                                    <pre>
                                        {JSON.stringify(data, null, 2)}
                                    </pre>
                                </React.Fragment>
                            }
                            route={getApiRoute('admin_partnercommission', [data.id])}
                            onDelete={loadCommisions}
                        />
                    </Box>
                );
            } },
        ],
        topRightButtons: () => {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        startIcon={<AddIcon />}
                        component={RouterLink}
                        to={'/app/admin/partnercommission/new'}
                    >
                        {t('button.create_partnercommission')}
                    </Button>
                </React.Fragment>
            );
        }
    };

    return (
        <Card
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default PartnerCommissionTable;