import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import {
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    inputButton: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: '0',
    }
}));

const ProgressButton = ({ name, onClick, onSuccess, file, acceptFile, ...rest }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    
    const onButtonClick = (e) => {
        setLoading(true);
        let promise = onClick(e);
        if (promise) {
            promise.then((json) => {
                setLoading(false)
                if (onSuccess) {
                    onSuccess(json);
                }
            }).catch(() => {
                setLoading(false)
            });
        } else {
            setLoading(false);
        }
    }

    return (
        <Button
            color="primary"
            className={classes.button}
            disabled={loading}
            onClick={file ? null : onButtonClick}
            {...rest}
        >
            {name}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {
                file &&
                <input
                    type="file"
                    className={classes.inputButton}
                    accept={acceptFile}
                    onChange={onButtonClick}
                />
            }
        </Button>
    );
};

export default ProgressButton;
